import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import SpinnerLoader from '../../common/spinner/Spinner.js';
import {
  Box,
  Flex,
  Textarea,
  Text,
} from '@chakra-ui/react';
import ChatImages from '../chat-images/ChatImages.js';
import SubmitButton from '../../form/SubmitButton.js';
import LeftIcon from '../../form/LeftIcon.js';

const ChatForm = ({ chatId, onSubmit, page, loading }) => {
  const [displayUploadedFiles, setDisplayUploadedFiles] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);
  const textareaRef = useRef(null);
  const {
    handleSubmit,
    register,
    control, 
    setValue,
    watch,
  } = useForm();
  const textareaValue = watch('text');
  
  const submitForm = (data) => { 
    if (page === 'dashboard') {;
      localStorage.setItem('data', JSON.stringify(data), 1000); 
      navigate(`/admin/chat`);
    } else {
      onSubmit(data);
    }

    setValue('text', '');
    setValue('attachment', '');
    textareaRef.current.value = '';
  }

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'inherit'; 
      const computed = window.getComputedStyle(textarea);
      const height = textarea.scrollHeight + parseInt(computed.borderTopWidth) + parseInt(computed.borderBottomWidth);
      textarea.style.height = `${height}px`;
    }
  }, [textareaValue]);

  const handleAttachFile = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.heic,.jpg,.png,.gif,.jpeg';
    document.body.appendChild(fileInput);

    fileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      setValue('attachment', file);
      handleSubmit(submitForm)();
      document.body.removeChild(fileInput);
    });
    fileInput.click();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(submitForm)();
    }
  }

  const submitFormWithMessage = (message) => {
    const data = {
      text: message,
      attachment: null
    }
    submitForm(data);

  }

  const showUploadedFiles = () => {
    setDisplayUploadedFiles(!displayUploadedFiles);
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
      <Flex sx={formContainerStyle}>
        <Box className="inner_container">
          {page === 'dashboard' &&
            <Flex className="premadeMessages">
              <Box className="messageItem">
                <Box className="messageItemInner" onClick={() => submitFormWithMessage('Tell me a short joke')}>
                  <Text>Tell me a short joke</Text>
                </Box>
              </Box>
              <Box className="messageItem">
                <Box className="messageItemInner" onClick={() => submitFormWithMessage('Create a shopping list')}>
                  <Text>Create a shopping list</Text>
                </Box>
              </Box>
              <Box className="messageItem">
                <Box className="messageItemInner" onClick={() => submitFormWithMessage('Get ideas for a  business')}>
                  <Text>Get ideas for a  business</Text>
                </Box>
              </Box>
              <Box className="messageItem">
                <Box className="messageItemInner" onClick={() => submitFormWithMessage('Get ideas for gifts')}>
                  <Text>Get ideas for gifts</Text>
                </Box>
              </Box>
            </Flex>
          }
          <Box className="textareaContainer">
            <LeftIcon />
 
            <Textarea
              {...register('text', { required: false })}
              onKeyPress={handleKeyPress}
              placeholder="Type your message"
              onChange={(e) => setValue('text', e.target.value)}
              ref={textareaRef}
            />
            <SubmitButton handleAttachFile={() => handleAttachFile()} showUploadedFiles={() => showUploadedFiles()} displayUploadedFiles={displayUploadedFiles} chat={true} activeState={textareaValue?.length > 0 ? true : false}/>
          </Box>
          {displayUploadedFiles === true &&
            <ChatImages chatId={chatId} />
          }
        </Box>
        <Controller
          name="attachment" 
          control={control}
          required={false}
          render={() => null}
        />
      </Flex>
      {loading === true &&
        <SpinnerLoader />
      }
    </form>
  );
};

const formContainerStyle = {
  maxWidth: '860px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '.inner_container': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '#fff',
    '.premadeMessages': {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '-5px',
      marginRight: '-5px',
      marginBottom: '20px',
      paddingTop: '10px',
      '.messageItem': {
        flex: '0 0 25%',
        padding: '0 5px',
        '.messageItemInner': {
          border: '1px solid #CFD5E3',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '50px',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          'p': {
            fontSize: '12px',
            lineHeight: '12px',
            color: '#8599CD',
            fontWeight: 600
          }
        },
        '&:hover': {
          '.messageItemInner': {
            background: '#CFD5E3'
          }
        }
      }
    },
    '.textareaContainer': {
      marginBottom: '10px',
      position: 'relative',
      paddingLeft: '50px',
      border: '1px solid #CFD5E3',
      borderRadius: '4px',
      'textarea': {
        minHeight: '1px !important',
        border: '0 none',
        resize: 'none',
        fontSize: '16px',
        color: '#1B518F',
        paddingTop: '15px',
        lineHeight: '20px',
        boxShadow: 'none',
        paddingRight: '120px',
        paddingBottom: 0,
        paddingLeft: '5px'
      },
      '.buttonsContainer': {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    '.buttonsContainer': {
      display: 'flex',
      alignItems: 'center',
      '.submitButton': {
        'button': {
          background: 'transparent',
          width: '34px',
          height: '34px',
          borderRadius: '34px',
          position: 'relative',
          padding: 0,
          minWidth: '1px',
          border: '1px solid #1E5DFF',
          transition: 'all 0.3s ease',
          'path': {
            transition: 'all 0.3s ease',
          },
          '&:hover': {
            background: '#1E5DFF',
            'path': {
              fill: '#fff'
            }
          }
        }
      },
      '.attachButtoon': {
        'button': {
          background: 'transparent'
        }
      }
    }
  },
  '@media all and (max-width: 1200px)': {
    '.premadeMessages': {
      display: 'none !important'
    }
  }
}
export default ChatForm;