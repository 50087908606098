import React from 'react';
import { Flex, Box, Menu, MenuButton, MenuList, MenuItem, useMenu, Text, Icon } from '@chakra-ui/react';
import MoreOptionsIcon from '../../../assets/images/MoreOptionsIcon.js';

function GlobalMenu({ clickHandle, menuItems, triggerButtonIcon }) {
  const foldersMenu = useMenu();

  return (
    <Flex>
        <Box 
            style={linkContainerStyle}
        >
            <Menu isOpen={foldersMenu.isOpen} onClose={foldersMenu.onClose} onOpen={foldersMenu.onOpen} strategy="fixed">
                <MenuButton>
                    <Flex align="center" spacing={2} sx={triggerButtonStyle}>
                        {triggerButtonIcon 
                            ? <Icon as={triggerButtonIcon} />
                            : <Icon as={MoreOptionsIcon} />
                        }
                    </Flex>
                </MenuButton>
                {menuItems && (
                    <MenuList style={menuContainerStyle}>
                        {menuItems.map((menuItem, key) => (
                            <MenuItem sx={menuItemStyle} key={key} onClick={() => {menuItem.onClick()}}>
                                {menuItem.icon && <Icon as={menuItem.icon} />}
                                <Text style={folderNameStyle}>{menuItem.label}</Text>
                            </MenuItem>
                        ))} 
                    </MenuList>
                )}
                
            </Menu>
        </Box>
    </Flex>
    )
}

const triggerButtonStyle = {
    minHeight: '10px',
    'svg': {
        marginRight: '10px'
    }
}

const menuContainerStyle = {
    border: '0 none',
    boxShadow: '0px 0px 16px 0px #0000001A',
    borderRadius: '6px',
    maxHeight: '270px',
    overflow: 'auto',
    padding: 0
}

const menuItemStyle = {
    display: 'flex',
    borderBottom: '1px solid #E1F2FF',
    height: 'auto',
    background: 'transparent',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#1B518F',
    padding: '10px'
}

const folderNameStyle = {
    color: '#1B518F',
    fontSize: '14px',
    marginLeft: '10px'
}

const linkContainerStyle = {
    position:'relative',
    width:'100%'
}

export default GlobalMenu;