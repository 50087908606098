import React from 'react';
import { Link } from 'react-router-dom';
import AppLogo from '../../../assets/images/AppLogo.js';
import {Flex, Box, Button, Icon} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import HamburgenMenuIcon from '../../../assets/images/HamburgerMenuIcon.js';
import { useAppContext } from '../../../contexts/AppContext.js';

const DesktopHeader = () => {
  const {mobileMenuOpen, openMobileMenu} = useAppContext();
  
  return (
    <Flex sx={headerContainerStyle}> 
        <Box display="flex" alignItems="center" w="45px">
            <Box as={Link} to="/admin/dashboard" display="block" className="logoContainer">
                <AppLogo maxHeight="50px"/>
            </Box>
        </Box>
        <Box className="innerNavigationContainer">
            {/* <InnerNavigation style="small"/> */}
        </Box>
        <Box w="50px" ml="auto">
            <Box display="flex" justifyContent="right">
                <Button onClick={() => openMobileMenu()} bg="transparent" p="0" mr="-10px">
                    <Icon as={mobileMenuOpen === false ? HamburgenMenuIcon : CloseIcon} />
                </Button>
            </Box>
        </Box>
    </Flex>
  )
};

const headerContainerStyle = {
    background:'#fff',
    padding:'10px 15px',
    borderLeft: '1px solid #E1F2FF',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    left:0,
    top: 0,
    width: '100%',
    zIndex: '99',
    '.logoContainer': {
        'svg': {
            width: 'auto',
            height: '34px'
        }
    },
    '.innerNavigationContainer': {
        flexGrow: '1',
        justifyContent: 'center'
    }
}

// const linkContainerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   '.linkLabel': {
//     color: '#9EC1DE',
//   }
// }

// const activeLinkContainerStyle = {
//   ...linkContainerStyle,
//   '.linkLabel': {
//     color: '#1B518F',
//   },
//   'svg': {
//     'path': {
//       stroke: '#1B518F'
//     },
//     'rect' : {
//       stroke: '#1B518F'
//     } 
//   }
// }

// const myAccountActiveLinkContainerStyle = {
//   ...activeLinkContainerStyle,
//   'svg': {
//     'path': {
//       stroke: 'none'
//     },
//   }
// }



// const buttonLabelStyle = {
//   fontSize:'22px',
//   fontWeight:400,
//   lineHeight:'24px',
//   marginLeft: '10px'
// }


export default DesktopHeader;