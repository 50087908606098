function AiChatDocumentIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <circle cx="11" cy="11" r="11" fill="#1E5DFF"/>
            <line x1="9.55723" y1="4.65622" x2="3.38179" y2="11.9545" stroke="white"/>
            <line x1="10.117" y1="6.90368" x2="5.62584" y2="12.1629" stroke="white"/>
            <line x1="12.9213" y1="6.34534" x2="7.86868" y2="12.1659" stroke="white"/>
            <line x1="18.518" y1="12.1366" x2="12.2071" y2="6.38706" stroke="white"/>
            <line x1="15.5831" y1="12.0079" x2="11.0919" y2="8.07806" stroke="white"/>
            <line x1="16.1203" y1="15.3536" x2="9.94489" y2="9.17811" stroke="white"/>
            <line x1="13.1052" y1="12.1316" x2="2.99996" y2="12.1316" stroke="white"/>
            <line x1="16.4739" y1="15.5" x2="5.80721" y2="15.5" stroke="white"/>
            <line x1="14.7896" y1="13.8157" x2="7.49131" y2="13.8157" stroke="white"/>
        </svg>
    );
}
  
export default AiChatDocumentIcon;