import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Flex, Box, Button, Text } from '@chakra-ui/react';

function NoWordsRemaining() {
    const navigate = useNavigate();
    return (
        <Button disabled="true" sx={formSubmitButtonNoWordsStyle} cursor="not-allowed" onClick={() => navigate('/admin/my-account/edit-profile')}>
            <Flex sx={creditsStructureStyle}>
                <Box className="iconContainer">
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.625 8.0252C4.03749 8 4.54602 8 5.2 8H10.8C11.454 8 11.9625 8 12.375 8.0252M3.625 8.0252C3.11022 8.05661 2.745 8.12731 2.43328 8.28613C1.93935 8.53778 1.53778 8.93931 1.28611 9.43325C1 9.99483 1 10.7298 1 12.2V13.425C1 14.8952 1 15.6302 1.28611 16.1918C1.53778 16.6857 1.93935 17.0872 2.43328 17.3389C2.99479 17.625 3.72986 17.625 5.2 17.625H10.8C12.2702 17.625 13.0052 17.625 13.5667 17.3389C14.0607 17.0872 14.4622 16.6857 14.7139 16.1918C15 15.6302 15 14.8952 15 13.425V12.2C15 10.7298 15 9.99483 14.7139 9.43325C14.4622 8.93931 14.0607 8.53778 13.5667 8.28613C13.255 8.12731 12.8898 8.05661 12.375 8.0252M3.625 8.0252V6.25C3.625 3.83376 5.58376 1.875 8 1.875C10.4162 1.875 12.375 3.83376 12.375 6.25V8.0252" stroke="white" stroke-Width="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Box>
                <Box className="textContainer">
                    <Text className="outOfCredits">Out of credits</Text>
                    <Text className="upgradeAccButton">Upgrade your account</Text>
                </Box>
            </Flex>
        </Button>
    )
}

const creditsStructureStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    '.iconContainer': {
        width: '34px',
        height: '34px',
        borderRadius: '34px',
        background: '#FF1284',
        textAlign: 'center',
        lineHeight: '34px',
        marginRight: '5px',
        position: 'relative',
        'svg': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    },
    '.textContainer': {
        textAlign: 'left',
        paddingLeft: '5px',
        '.outOfCredits': {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            color: '#1B518F'
        },
        '.upgradeAccButton': {
            fontSize: '12px',
            lineHeight: '16px',
            color: '#1B518F'
        }
    },
    '@media all and (max-width: 540px)': {
        '.textContainer': {
            display: 'none'
        }
    }
}

const formSubmitButtonStyle = {
    color:'#fff',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    background: 'transparent !important',
    cursor: 'pointer'
}

const formSubmitButtonNoWordsStyle = {
    ...formSubmitButtonStyle,
    '.upgradeAccButton': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 400,
        color: '#F6FBFF',

    }
}

export default NoWordsRemaining;