import React from 'react';
import GenerateContent from '../../components/generate-content/GenerateContent.js'; 


function MyDocumentsPage() {
    return (
        <GenerateContent />
    );
  }

export default MyDocumentsPage;
