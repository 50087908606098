import React from 'react';
import prices from '../../../../utils/prices.js';
import { useUserContext } from '../../../../contexts/UserContext.js';
import { Box, Text } from '@chakra-ui/react';

function PlanProgress(props) {
    const { userDataAndPlans } = useUserContext();
    const ReturnText = () => {
        if (props.userData.planType === 'unlimited') {
            return (
                <Text style={currentWordsRemainingStyle}>UNLIMITED CREDITS</Text>
            )
        }
        return (
            <Text style={currentWordsRemainingStyle}>
                {props.userData.wordsRemaining} / {prices[userDataAndPlans.planType][[userDataAndPlans.planCycleType]]?.credits} credits
            </Text>
        );
    };
    return (
        <Box textAlign="right" pr="10px">
            <Text sx={planTypeStyle}>{props.userData.planType}</Text>
            <Box style={wordsRemainingStyle}>
                <ReturnText />
            </Box>
        </Box>

    );
}
const planTypeStyle = {
    color: '#1B518F',
    fontSize: '9px',
    lineHeihgt: '10px',
    textTransform: 'uppercase',
    fontWeight: 700,
    '@media all and (max-width: 1200px)': {
        textAlign: 'left'
    }
}

const wordsRemainingStyle = {
    fontSize: '11px',
    lineHeight: '12px',
    fontWeight: 700,
    color: '#6D99BE',
    marginBottom: '5px',
    minWidth: '110px',
    textAlign: 'right'
}

const currentWordsRemainingStyle = {
    display: 'inline-block',
    color: '#0D78F5'
}

export default PlanProgress;
