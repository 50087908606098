function LandingPageCopywritingIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="21" fill="#F2F9FF"/>
            <path d="M14.7668 13.2C14.9693 13.2 15.1335 13.0358 15.1335 12.8333C15.1335 12.6308 14.9693 12.4667 14.7668 12.4667C14.5643 12.4667 14.4001 12.6308 14.4001 12.8333C14.4001 13.0358 14.5643 13.2 14.7668 13.2Z" fill="#6D99BE"/>
            <path d="M14.7668 13.2C14.9693 13.2 15.1335 13.0358 15.1335 12.8333C15.1335 12.6308 14.9693 12.4667 14.7668 12.4667C14.5643 12.4667 14.4001 12.6308 14.4001 12.8333C14.4001 13.0358 14.5643 13.2 14.7668 13.2Z" stroke="black"/>
            <path d="M13.3 13.2C13.5025 13.2 13.6667 13.0358 13.6667 12.8333C13.6667 12.6308 13.5025 12.4667 13.3 12.4667C13.0975 12.4667 12.9333 12.6308 12.9333 12.8333C12.9333 13.0358 13.0975 13.2 13.3 13.2Z" fill="#6D99BE"/>
            <path d="M13.3 13.2C13.5025 13.2 13.6667 13.0358 13.6667 12.8333C13.6667 12.6308 13.5025 12.4667 13.3 12.4667C13.0975 12.4667 12.9333 12.6308 12.9333 12.8333C12.9333 13.0358 13.0975 13.2 13.3 13.2Z" stroke="black"/>
            <path d="M11.8335 13.2C12.036 13.2 12.2001 13.0358 12.2001 12.8333C12.2001 12.6308 12.036 12.4667 11.8335 12.4667C11.631 12.4667 11.4668 12.6308 11.4668 12.8333C11.4668 13.0358 11.631 13.2 11.8335 13.2Z" fill="#6D99BE"/>
            <path d="M11.8335 13.2C12.036 13.2 12.2001 13.0358 12.2001 12.8333C12.2001 12.6308 12.036 12.4667 11.8335 12.4667C11.631 12.4667 11.4668 12.6308 11.4668 12.8333C11.4668 13.0358 11.631 13.2 11.8335 13.2Z" stroke="black"/>
            <path d="M31.6333 14.6667H10.3667C10.1643 14.6667 10 14.5024 10 14.3C10 14.0976 10.1643 13.9333 10.3667 13.9333H31.6333C31.8357 13.9333 32 14.0976 32 14.3C32 14.5024 31.8357 14.6667 31.6333 14.6667Z" fill="#6D99BE"/>
            <path d="M31.6333 14.6667H10.3667C10.1643 14.6667 10 14.5024 10 14.3C10 14.0976 10.1643 13.9333 10.3667 13.9333H31.6333C31.8357 13.9333 32 14.0976 32 14.3C32 14.5024 31.8357 14.6667 31.6333 14.6667Z" stroke="black"/>
            <path d="M30.9 11H11.1C10.4935 11 10 11.4935 10 12.1V28.9667C10 29.5731 10.4935 30.0667 11.1 30.0667H30.9C31.5065 30.0667 32 29.5731 32 28.9667V12.1C32 11.4935 31.5065 11 30.9 11ZM31.2667 28.9667C31.2667 29.1683 31.1017 29.3333 30.9 29.3333H11.1C10.8983 29.3333 10.7333 29.1683 10.7333 28.9667V12.1C10.7333 11.8983 10.8983 11.7333 11.1 11.7333H30.9C31.1017 11.7333 31.2667 11.8983 31.2667 12.1V28.9667Z" fill="#6D99BE"/>
            <path d="M30.9 11H11.1C10.4935 11 10 11.4935 10 12.1V28.9667C10 29.5731 10.4935 30.0667 11.1 30.0667H30.9C31.5065 30.0667 32 29.5731 32 28.9667V12.1C32 11.4935 31.5065 11 30.9 11ZM31.2667 28.9667C31.2667 29.1683 31.1017 29.3333 30.9 29.3333H11.1C10.8983 29.3333 10.7333 29.1683 10.7333 28.9667V12.1C10.7333 11.8983 10.8983 11.7333 11.1 11.7333H30.9C31.1017 11.7333 31.2667 11.8983 31.2667 12.1V28.9667Z" stroke="black"/>
            <path d="M27.2334 24.9333H29.4334C29.6358 24.9333 29.8 24.769 29.8 24.5666C29.8 24.3642 29.6358 24.2 29.4334 24.2H27.2334C27.031 24.2 26.8667 24.3642 26.8667 24.5666C26.8667 24.769 27.031 24.9333 27.2334 24.9333Z" fill="#1B518F"/>
            <path d="M27.2334 24.9333H29.4334C29.6358 24.9333 29.8 24.769 29.8 24.5666C29.8 24.3642 29.6358 24.2 29.4334 24.2H27.2334C27.031 24.2 26.8667 24.3642 26.8667 24.5666C26.8667 24.769 27.031 24.9333 27.2334 24.9333Z" stroke="black"/>
            <path d="M22.0998 26.4H24.2998C24.5022 26.4 24.6665 26.2357 24.6665 26.0333C24.6665 25.8309 24.5022 25.6666 24.2998 25.6666H22.0998C21.8974 25.6666 21.7332 25.8309 21.7332 26.0333C21.7332 26.2357 21.8974 26.4 22.0998 26.4Z" fill="#1B518F"/>
            <path d="M22.0998 26.4H24.2998C24.5022 26.4 24.6665 26.2357 24.6665 26.0333C24.6665 25.8309 24.5022 25.6666 24.2998 25.6666H22.0998C21.8974 25.6666 21.7332 25.8309 21.7332 26.0333C21.7332 26.2357 21.8974 26.4 22.0998 26.4Z" stroke="black"/>
            <path d="M22.0998 27.8666H25.7665C25.9689 27.8666 26.1332 27.7024 26.1332 27.5C26.1332 27.2976 25.9689 27.1333 25.7665 27.1333H22.0998C21.8974 27.1333 21.7332 27.2976 21.7332 27.5C21.7332 27.7024 21.8974 27.8666 22.0998 27.8666Z" fill="#1B518F"/>
            <path d="M22.0998 27.8666H25.7665C25.9689 27.8666 26.1332 27.7024 26.1332 27.5C26.1332 27.2976 25.9689 27.1333 25.7665 27.1333H22.0998C21.8974 27.1333 21.7332 27.2976 21.7332 27.5C21.7332 27.7024 21.8974 27.8666 22.0998 27.8666Z" stroke="black"/>
            <path d="M27.2334 27.8666H27.9667C28.1691 27.8666 28.3334 27.7024 28.3334 27.5C28.3334 27.2976 28.1691 27.1333 27.9667 27.1333H27.2334C27.031 27.1333 26.8667 27.2976 26.8667 27.5C26.8667 27.7024 27.031 27.8666 27.2334 27.8666Z" fill="#1B518F"/>
            <path d="M27.2334 27.8666H27.9667C28.1691 27.8666 28.3334 27.7024 28.3334 27.5C28.3334 27.2976 28.1691 27.1333 27.9667 27.1333H27.2334C27.031 27.1333 26.8667 27.2976 26.8667 27.5C26.8667 27.7024 27.031 27.8666 27.2334 27.8666Z" stroke="black"/>
            <path d="M22.0998 24.9333H25.7665C25.9689 24.9333 26.1332 24.769 26.1332 24.5666C26.1332 24.3642 25.9689 24.2 25.7665 24.2H22.0998C21.8974 24.2 21.7332 24.3642 21.7332 24.5666C21.7332 24.769 21.8974 24.9333 22.0998 24.9333Z" fill="#1B518F"/>
            <path d="M22.0998 24.9333H25.7665C25.9689 24.9333 26.1332 24.769 26.1332 24.5666C26.1332 24.3642 25.9689 24.2 25.7665 24.2H22.0998C21.8974 24.2 21.7332 24.3642 21.7332 24.5666C21.7332 24.769 21.8974 24.9333 22.0998 24.9333Z" stroke="black"/>
            <path d="M25.7668 26.4H29.4335C29.6359 26.4 29.8001 26.2357 29.8001 26.0333C29.8001 25.8309 29.6359 25.6666 29.4335 25.6666H25.7668C25.5644 25.6666 25.4001 25.8309 25.4001 26.0333C25.4001 26.2357 25.5644 26.4 25.7668 26.4Z" fill="#1B518F"/>
            <path d="M25.7668 26.4H29.4335C29.6359 26.4 29.8001 26.2357 29.8001 26.0333C29.8001 25.8309 29.6359 25.6666 29.4335 25.6666H25.7668C25.5644 25.6666 25.4001 25.8309 25.4001 26.0333C25.4001 26.2357 25.5644 26.4 25.7668 26.4Z" stroke="black"/>
            <path d="M19.9002 26.4H17.7002C17.4978 26.4 17.3335 26.2357 17.3335 26.0333C17.3335 25.8309 17.4978 25.6666 17.7002 25.6666H19.9002C20.1026 25.6666 20.2668 25.8309 20.2668 26.0333C20.2668 26.2357 20.1026 26.4 19.9002 26.4Z" fill="#1B518F"/>
            <path d="M19.9002 26.4H17.7002C17.4978 26.4 17.3335 26.2357 17.3335 26.0333C17.3335 25.8309 17.4978 25.6666 17.7002 25.6666H19.9002C20.1026 25.6666 20.2668 25.8309 20.2668 26.0333C20.2668 26.2357 20.1026 26.4 19.9002 26.4Z" stroke="black"/>
            <path d="M18.4334 27.8666H16.2334C16.031 27.8666 15.8667 27.7024 15.8667 27.5C15.8667 27.2976 16.031 27.1333 16.2334 27.1333H18.4334C18.6358 27.1333 18.8 27.2976 18.8 27.5C18.8 27.7024 18.6358 27.8666 18.4334 27.8666Z" fill="#1B518F"/>
            <path d="M18.4334 27.8666H16.2334C16.031 27.8666 15.8667 27.7024 15.8667 27.5C15.8667 27.2976 16.031 27.1333 16.2334 27.1333H18.4334C18.6358 27.1333 18.8 27.2976 18.8 27.5C18.8 27.7024 18.6358 27.8666 18.4334 27.8666Z" stroke="black"/>
            <path d="M14.7666 27.8666H12.5666C12.3642 27.8666 12.2 27.7024 12.2 27.5C12.2 27.2976 12.3642 27.1333 12.5666 27.1333H14.7666C14.969 27.1333 15.1333 27.2976 15.1333 27.5C15.1333 27.7024 14.969 27.8666 14.7666 27.8666Z" fill="#1B518F"/>
            <path d="M14.7666 27.8666H12.5666C12.3642 27.8666 12.2 27.7024 12.2 27.5C12.2 27.2976 12.3642 27.1333 12.5666 27.1333H14.7666C14.969 27.1333 15.1333 27.2976 15.1333 27.5C15.1333 27.7024 14.969 27.8666 14.7666 27.8666Z" stroke="black"/>
            <path d="M20.6333 22H13.3C13.0976 22 12.9333 21.8357 12.9333 21.6333C12.9333 21.4309 13.0976 21.2666 13.3 21.2666H20.6333C20.8357 21.2666 21 21.4309 21 21.6333C21 21.8357 20.8357 22 20.6333 22Z" fill="#1B518F"/>
            <path d="M20.6333 22H13.3C13.0976 22 12.9333 21.8357 12.9333 21.6333C12.9333 21.4309 13.0976 21.2666 13.3 21.2666H20.6333C20.8357 21.2666 21 21.4309 21 21.6333C21 21.8357 20.8357 22 20.6333 22Z" stroke="black"/>
            <path d="M19.9 24.9333H12.5666C12.3642 24.9333 12.2 24.769 12.2 24.5666C12.2 24.3642 12.3642 24.2 12.5666 24.2H19.9C20.1024 24.2 20.2666 24.3642 20.2666 24.5666C20.2666 24.769 20.1024 24.9333 19.9 24.9333Z" fill="#1B518F"/>
            <path d="M19.9 24.9333H12.5666C12.3642 24.9333 12.2 24.769 12.2 24.5666C12.2 24.3642 12.3642 24.2 12.5666 24.2H19.9C20.1024 24.2 20.2666 24.3642 20.2666 24.5666C20.2666 24.769 20.1024 24.9333 19.9 24.9333Z" stroke="black"/>
            <path d="M16.2333 26.4H12.5666C12.3642 26.4 12.2 26.2357 12.2 26.0333C12.2 25.8309 12.3642 25.6666 12.5666 25.6666H16.2333C16.4357 25.6666 16.6 25.8309 16.6 26.0333C16.6 26.2357 16.4357 26.4 16.2333 26.4Z" fill="#1B518F"/>
            <path d="M16.2333 26.4H12.5666C12.3642 26.4 12.2 26.2357 12.2 26.0333C12.2 25.8309 12.3642 25.6666 12.5666 25.6666H16.2333C16.4357 25.6666 16.6 25.8309 16.6 26.0333C16.6 26.2357 16.4357 26.4 16.2333 26.4Z" stroke="black"/>
            <path d="M29.4333 22H12.5666C12.3642 22 12.2 21.8357 12.2 21.6333V16.5C12.2 16.2976 12.3642 16.1333 12.5666 16.1333H29.4333C29.6357 16.1333 29.8 16.2976 29.8 16.5V21.6333C29.8 21.8357 29.6357 22 29.4333 22ZM12.9333 21.2666H29.0666V16.8666H12.9333V21.2666Z" fill="#1E5DFF"/>
            <path d="M29.4333 22H12.5666C12.3642 22 12.2 21.8357 12.2 21.6333V16.5C12.2 16.2976 12.3642 16.1333 12.5666 16.1333H29.4333C29.6357 16.1333 29.8 16.2976 29.8 16.5V21.6333C29.8 21.8357 29.6357 22 29.4333 22ZM12.9333 21.2666H29.0666V16.8666H12.9333V21.2666Z" stroke="black"/>
        </svg>

    );
  }
  
export default LandingPageCopywritingIcon;