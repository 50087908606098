import React from 'react';

// Importing SVGs
import YoutubeVideoScript from './YoutubeVideoScriptIcon.js';
import InstagramCaption from './InstagramCaptionIcon.js';
import FacebookPost from './FacebookPostIcon.js';
import TikTokVideoScript from './TikTokVideoScriptIcon.js';
import LinkedinPost from './LinkedinPostIcon.js';
import InstagramReelScript from './InstagramReelScriptIcon.js';
import TweetGenerator from './TweetGeneratorIcon.js';
import YoutubeVideoTitle from './YoutubeVideoTitleIcon.js';
import FullBlogGenerator from './FullBlogGeneratorIcon.js';
import ContentRephrase from './content_rephraseIcon.js';
import BlogTitleMetaDescription from './BlogTitleMetaDescriptionIcon.js';
import PlagiarismChecker from './PlagiarismCheckerIcon.js';
import BlogOutline from './BlogOutlineIcon.js';
import StoryGenerator from './StoryGeneratorIcon.js';
import FacebookAds from './FacebookAdsIcon.js';
import InstagramAds from './InstagramAdsIcon.js';
import GoogleAds from './GoogleAdsIcon.js';
import LinkedinAds from './LinkedinAdsIcon.js';
import TwitterAds from './TwitterAdsIcon.js';
import EmailNewsletter from './EmailNewsletterIcon.js';
import LandingPageCopywriting from './LandingPageCopywritingIcon.js';
import ProductMarketFit from './ProductMarketFitIcon.js';
import ProductTitle from './ProductTitleIcon.js';
import ProductDescription from './ProductDescriptionIcon.js';
import FeaturesToBenefits from './FeaturesToBenefitsIcon.js';
import BrandNameGenerator from './BrandNameGeneratorIcon.js';
import SloganGenerator from './SloganGeneratorIcon.js';
import BusinessPlanGenerator from './BusinessPlanGeneratorIcon.js';
import BrainstormIdeas from './BrainstormIdeasIcon.js';
import NutritionPlan from './NutritionPlanIcon.js';
import MealPlan from './MealPlanIcon.js';
import FinancialAdvisor from './FinancialAdvisorIcon.js';
import GoalGetter from './GoalGetterIcon.js';
import TravelGuide from './TravelGuideIcon.js';
import RegexGenerator from './RegexGeneratorIcon.js';
import AiTtsDocumentIcon from './AiTtsDocumentIcon.js';
import AiImageDocument from './AiImageDocumentIcon.js';
import AiChatDocument from './AiChatDocumentIcon.js';
import DefaultTemplate from './DefaultTemplateIcon.js';

// Map template types to SVGs
export const TEMPLATE_ICONS = {
    YoutubeVideoScript: <YoutubeVideoScript />,
    InstagramCaption: <InstagramCaption />,
    FacebookPost: <FacebookPost />,
    TikTokVideoScript: <TikTokVideoScript />,
    LinkedinPost: <LinkedinPost />,
    InstagramReelScript: <InstagramReelScript />,
    TweetGenerator: <TweetGenerator />,
    YoutubeVideoTitle: <YoutubeVideoTitle />,
    FullBlogGenerator: <FullBlogGenerator />,
    content_rephrase: <ContentRephrase />,
    BlogTitleMetaDescription: <BlogTitleMetaDescription />,
    PlagiarismChecker: <PlagiarismChecker />,
    BlogOutline: <BlogOutline />,
    StoryGenerator: <StoryGenerator />,
    FacebookAds: <FacebookAds />,
    InstagramAds: <InstagramAds />,
    GoogleAds: <GoogleAds />,
    LinkedinAds: <LinkedinAds />,
    TwitterAds: <TwitterAds />,
    EmailNewsletter: <EmailNewsletter />,
    LandingPageCopywriting: <LandingPageCopywriting />,
    ProductMarketFit: <ProductMarketFit />,
    ProductTitle: <ProductTitle />,
    ProductDescription: <ProductDescription />,
    FeaturesToBenefits: <FeaturesToBenefits />,
    BrandNameGenerator: <BrandNameGenerator />,
    SloganGenerator: <SloganGenerator />,
    BusinessPlanGenerator: <BusinessPlanGenerator />,
    BrainstormIdeas: <BrainstormIdeas />,
    NutritionPlan: <NutritionPlan />,
    MealPlan: <MealPlan />,
    FinancialAdvisor: <FinancialAdvisor />,
    GoalGetter: <GoalGetter />,
    TravelGuide: <TravelGuide />,
    RegexGenerator: <RegexGenerator />,
    tts: <AiTtsDocumentIcon />,
    AiImage: <AiImageDocument />,
    chat: <AiChatDocument />,
    DefaultTemplate: <DefaultTemplate />
};

