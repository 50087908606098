import React from 'react';
import {Flex, Box, Text} from '@chakra-ui/react';

const ChatHeader = () => {
    return (  
        <Flex sx={chatHeaderStyle}>
            <Box className="chatHeaderItem">
                <Box className="innerContainer">
                    <Text className="chatName">AI Chat</Text>
                </Box>
            </Box>
            {/* <Box className="createNewChat">
                <Button></Button>
            </Box> */}
        </Flex>
    );
}
const chatHeaderStyle = {
    marginTop: '-5px',
    marginBottom: '-5px',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    '.chatHeaderItem': {
        height: '55px',
        lineHeight: '55px',
        '.chatName': {
            fontSize: '18px',
            color: '#1B518F'
        }
    }
}
export default ChatHeader;
