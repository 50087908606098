function AiTtsDocumentIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <circle cx="11" cy="11" r="11" fill="#FF6712"/>
            <line x1="11.0957" y1="16.9082" x2="11.0957" y2="6.31969" stroke="white"/>
            <line x1="16.9714" y1="14.0579" x2="16.9714" y2="9.95327" stroke="white"/>
            <line x1="14.3381" y1="15.6541" x2="14.3381" y2="8.35699" stroke="white"/>
            <circle cx="14.0662" cy="17.3548" r="0.762868" transform="rotate(180 14.0662 17.3548)" fill="white"/>
            <circle cx="11.1156" cy="17.5864" r="0.762868" transform="rotate(180 11.1156 17.5864)" fill="white"/>
            <circle cx="7.78596" cy="17.3548" r="0.762868" transform="rotate(180 7.78596 17.3548)" fill="white"/>
            <line y1="-0.5" x2="4.1046" y2="-0.5" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 4.5293 14.0579)" stroke="white"/>
            <line y1="-0.5" x2="7.29706" y2="-0.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 7.1626 15.6541)" stroke="white"/>
            <mask id="path-10-inside-1_700_622" fill="white">
            <path d="M7.28236 7.2027C7.28236 6.15046 7.70036 5.14132 8.4444 4.39728C9.18844 3.65324 10.1976 3.23524 11.2498 3.23524C12.3021 3.23524 13.3112 3.65324 14.0552 4.39728C14.7993 5.14132 15.2173 6.15046 15.2173 7.2027L14.047 7.2027C14.047 6.46085 13.7523 5.74938 13.2277 5.22481C12.7031 4.70024 11.9917 4.40554 11.2498 4.40554C10.508 4.40554 9.7965 4.70024 9.27193 5.22481C8.74736 5.74938 8.45266 6.46085 8.45266 7.2027L7.28236 7.2027Z"/>
            </mask>
            <path d="M7.28236 7.2027C7.28236 6.15046 7.70036 5.14132 8.4444 4.39728C9.18844 3.65324 10.1976 3.23524 11.2498 3.23524C12.3021 3.23524 13.3112 3.65324 14.0552 4.39728C14.7993 5.14132 15.2173 6.15046 15.2173 7.2027L14.047 7.2027C14.047 6.46085 13.7523 5.74938 13.2277 5.22481C12.7031 4.70024 11.9917 4.40554 11.2498 4.40554C10.508 4.40554 9.7965 4.70024 9.27193 5.22481C8.74736 5.74938 8.45266 6.46085 8.45266 7.2027L7.28236 7.2027Z" fill="#1E5DFF" stroke="white" strokeWidth="4" mask="url(#path-10-inside-1_700_622)"/>
        </svg>

    );
}
  
export default AiTtsDocumentIcon;