function GoogleAdsIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#F2F9FF"/>
            <path d="M11.2911 4.25024L9.83717 5.70463C9.33756 5.29537 8.70121 5.04532 7.99697 5.04532C6.68324 5.04532 5.57951 5.89021 5.19369 7.07306L3.51367 5.78619C4.32242 4.13206 6.021 3 7.99697 3C9.26919 3 10.4047 3.47714 11.2911 4.25024Z" fill="#EA4335"/>
            <path d="M5.04434 8C5.04434 8.32233 5.09416 8.63147 5.19428 8.92303L3.5128 10.2138C3.18558 9.54815 3 8.79605 3 8C3 7.20394 3.18558 6.45038 3.5128 5.78619L5.19428 7.07306C5.09465 7.36364 5.04434 7.67767 5.04434 8Z" fill="#FBBC05"/>
            <path d="M11.2501 11.7546C10.3779 12.5688 9.21455 13 7.99752 13C6.02057 13 4.322 11.8684 3.51422 10.2138L5.19424 8.92694C5.58005 10.1088 6.68379 10.9547 7.99752 10.9547C8.64218 10.9547 9.20576 10.8043 9.65556 10.5176L11.2501 11.7546Z" fill="#34A853"/>
            <path d="M12.7675 8C12.7675 9.63118 12.1717 10.9049 11.2501 11.7546L9.6556 10.519C10.1782 10.1918 10.5415 9.68246 10.6778 9.02315H7.99756V7.09162H12.6542C12.7226 7.38611 12.7675 7.70453 12.7675 8Z" fill="#4285F4"/>
        </svg>

    );
  }
  
export default GoogleAdsIcon;