import React, { useEffect } from 'react';
import Header from '../../layouts/Header/Header.js';
import MyDocuments from '../../components/my-documents/MyDocuments.js';
import { useAppContext } from '../../contexts/AppContext.js';

function MyDocumentsPage() {
    const { openMobileMenu, mobileMenuOpen } = useAppContext();
    useEffect(() => {
        if (window.innerWidth <= 1200 && mobileMenuOpen === true) {
            openMobileMenu(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Header page="my-documents" />
            <MyDocuments />
        </>
    );
}

export default MyDocumentsPage;
