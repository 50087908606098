import React, {useState, useEffect} from 'react';
import api from '../../../utils/api.js';
import {
  Box,
  Flex
} from '@chakra-ui/react';
import SimpleBar from 'simplebar-react'; 
import 'simplebar/dist/simplebar.min.css';
import ImageItem from './ImageItem/ImageItem.js';

const DisplayImages = (images) => {
    return (
        <SimpleBar style={{maxHeight: '340px', width: '100%'}}>
            <Box className="imagesContainer">
                {images.map((item, key) => (
                    <ImageItem key={key} imageItem={item}/>
                ))}
            </Box>
        </SimpleBar>
    )
}

const ChatImages = ({chatId}) => {
    const [images, setImages] = useState();

    async function fetchImages() {
        try {
            const response = await api.post(`/admin/chat/get-chat-gallery`, {chatId: chatId});
            setImages(response.data);
        } catch (error) {
            console.error("Error fetching the folder:", error);
        }
    }

    useEffect(() => {
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Flex sx={imagesContainerStyle}> 
        {images && images.length > 0 
            ? DisplayImages(images)
            : `No image uploaded`
        }
    </Flex>
  );
};

const imagesContainerStyle = {
    '.imagesContainer': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

    }
}


export default ChatImages;