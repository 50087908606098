import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import prices from '../../../utils/prices.js';
import api from '../../../utils/api.js';
import PlanProgress from './plan-progress/PlanProgress.js';
import MyProfileIcon from '../../../assets/images/MyProfileIcon.js';
import SendFeedbackIcon from '../../../assets/images/SendFeedbackIcon.js';
import CreditCardIcon from '../../../assets/images/CreditCardIcon.js';
import {
  Flex,
  Box,
  Button,
  Text,
  Icon,
  Avatar,
  Textarea,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  List,
  ListItem,
  CircularProgress,
  useToast
} from '@chakra-ui/react';
import DefaultModal from '../../common/default-modal/DefaultModal.js';
import { useUserContext } from '../../../contexts/UserContext.js';
const MyProfileHeader = () => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef(null);
  const {
    handleSubmit,
    register,
    setValue,
  } = useForm();
  const { userDataAndPlans } = useUserContext();
  const planType = userDataAndPlans.planType;
  const planCycleType = userDataAndPlans.planCycleType;
  const progressValue = (userDataAndPlans.wordsRemaining / prices[planType]?.[planCycleType]?.credits) * 100;
  const color = progressValue <= 50 && progressValue >= 30 ? "#FF6712" : progressValue < 30 ? '#FF1284' : "#27EE07";

  const logout = async () => {
    try {
      const response = await api.post('/auth/logout');
      if (response.data.message === "Logged out successfully.") {
        localStorage.removeItem('token');
        window.location.href = "/";
      } else {
        // Handle any other responses
      }
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const onSubmit = async (data) => {
    data.userId = userDataAndPlans.id;
    data.email = userDataAndPlans.email;
    try {
      const response = await api.post('/admin/send-user-feedback', data);
      if (response.data.success === true) {
        setIsModalOpen(false);
        toast({
          title: "Thank you!",
          description: 'Your feedback has been sent.',
          status: "success",
          duration: 5000,
          isClosable: true,
      });
      }
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  }

  const submitFeedbackForm = () => {
    handleSubmit(onSubmit)();
  }

  const FeedbackForm = () => {
    return (
      <form ref={formRef} onSubmit={handleSubmit(submitFeedbackForm)}>
        <Textarea
          {...register('textarea', { required: true })}
          placeholder="Enter your feedback"
          onChange={(e) => setValue('textarea', e.target.value)}
          ref={formRef}
        />
      </form>
    )
  }

  return (
    <Flex sx={myProfileContainerStyle}>
      <Box className="leftBox">
        <Popover>
          <PopoverTrigger>
            {userDataAndPlans.id
              ? <Box style={topContainerStyle} className="boxContainer">
                <PlanProgress userData={userDataAndPlans} />
                <Box sx={avatarContainerStyle}>
                  <Box className="progressBarContianer">
                    <CircularProgress value={progressValue} color={color} size="46px" thickness="4px" />
                  </Box>
                  <Avatar name={userDataAndPlans.lastname.charAt(0).toUpperCase()} w="34px" h="34px" bgColor="#1E5DFF" mr="10px" />
                </Box>
              </Box>
              : <Box>...</Box>
            }
          </PopoverTrigger>
          {userDataAndPlans &&
            <PopoverContent style={popoverContentStyle}>
              <PopoverArrow />
              <Text style={myaccountDropdownNameStyle}>{userDataAndPlans.firstname} {userDataAndPlans.lastname}</Text>
              <Text style={myaccountDropdownEmailStyle}>{userDataAndPlans.email}</Text>
              <PopoverBody p="0">
                <Text style={labelMyAccountLinks}>My account</Text>
                <List spacing={2} mb="40px">
                  <ListItem mb="20px">
                    <RouterLink to="/admin/my-account/edit-profile">
                      <Box display="flex" alignItems="center">
                        <Icon as={MyProfileIcon} />
                        <Text sx={menuItemStyle}>My Profile</Text>
                      </Box>
                    </RouterLink>
                  </ListItem>
                  <ListItem mb="20px">
                    <RouterLink to="/admin/my-account/edit-profile">
                      <Box display="flex" alignItems="center">
                        <Icon as={CreditCardIcon} />
                        <Text sx={menuItemStyle}>Billing & Subscriptions</Text>
                      </Box>
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <Box display="flex" alignItems="center">
                      <Button onClick={() => setIsModalOpen(true)} sx={shareButtonStyle}>
                        <Icon as={SendFeedbackIcon} />
                        <Text sx={menuItemStyle}>Send Your Feedback</Text>
                      </Button>
                      <DefaultModal buttonStyleProps={menuItemStyle} modalTitle={"Send Your Feedback"} buttonLabel={"Send Your Feedback"} onSubmit={submitFeedbackForm} formFields={<FeedbackForm />} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)}/>
                    </Box>
                  </ListItem>
                </List>
                <RouterLink onClick={logout} style={logoutButtonStyle}>Logout</RouterLink>
              </PopoverBody>
            </PopoverContent>
          }
        </Popover>
      </Box>
    </Flex>

  )
};

const shareButtonStyle = {
  background: 'transparent',
  padding: 0,
  height: 'auto',
  '&:hover':{
    background: 'transparent'
  }
}

const avatarContainerStyle = {
  position: 'relative',
  '.progressBarContianer': {
    position: 'absolute',
    right: '4px',
    top: '-6px'
  }
}

const myProfileContainerStyle = {
  justifyContent: 'right',
  '.rightBoxInner': {
    marginLeft: '10px',
    '.upgradeButtonStyle': {
      background: '#0D78F5',
      fontSize: '14px',
      fontWeight: 700,
      lineHeihgt: '16px',
      color: '#fff'
    }
  },
  '@media (max-width: 1200px)': {
    flexDirection: 'row',
    width: 'calc(100% - 50px)',
    justifyContent: 'left',
    '.leftBox': {
      marginBottom: '10px',
      '.boxContainer': {
        flexFlow: 'row-reverse'
      }
    },
    '.rightBox': {
      marginLeft: '0',
    },
  }
}

const topContainerStyle = {
  minWidth: '160px',
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}
const logoutButtonStyle = {
  height: '40px',
  lineHeight: '40px',
  borderRadius: '4px',
  background: '#F6FBFF',
  textAlign: 'center',
  color: '#6D99BE',
  fontSize: '14px',
  display: 'block'
}

const menuItemStyle = {
  color: '#1E5DFF',
  fontSize: '16px',
  lineHeight: '18px',
  fontWeight: 400,
  marginLeft: '10px',
  background: 'trapsparent',
  padding: 0,
  height: 'auto',
  '&:hover': {
    background: 'transparent'
  }
}

const labelMyAccountLinks = {
  color: '#6D99BE',
  fontSize: '10px',
  lineHeight: '12px',
  textTransform: 'uppercase',
  marginBottom: '20px'
}

const popoverContentStyle = {
  padding: '20px',
  border: '0 none',
  outline: 'none',
  boxShadow: '0px 0px 16px 0px #0000001A',
}

const myaccountDropdownNameStyle = {
  color: '#1B518F',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '18px'
}

const myaccountDropdownEmailStyle = {
  color: '#6D99BE',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  marginBottom: '20px',
  borderBottom: '1px solid #E1F2FF',
  paddingBottom: '20px'
}

export default MyProfileHeader;