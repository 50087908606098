import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import Beer from '../../../../assets/images/beer.png';
import TitleWithIcon from '../../../common/frontend/title-with-icon/TitleWithIcon.js';
import Heading from '../../../common/frontend/heading/Heading.js';
import Description from '../../../common/frontend/description/Description.js';
import Button from '../../../common/frontend/button/Button.js';
import List from '../../../common/frontend/list/List.js';
import AiImageDocumentIcon from '../../../../assets/images/templates/AiImageDocumentIcon.js';

import ImageGenerator1 from '../../../../assets/images/ImageGenerator1.png';
import ImageGenerator2 from '../../../../assets/images/ImageGenerator2.png';
import ImageGenerator3 from '../../../../assets/images/ImageGenerator3.png';
import ImageGenerator4 from '../../../../assets/images/ImageGenerator4.png';
import ImageGenerator5 from '../../../../assets/images/ImageGenerator5.png';

function ThirdSection() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const list = [
        { text: 'hd image size' },
        { text: 'saved in history' },
        { text: '3 image ratio' },
    ]
    return (
        <>
            <Flex sx={secondSectionStyle}>
                <Box className="leftBox">
                    {isMobile &&
                        <Flex sx={promptMobileVersion} className="promptImage mobileVersion">
                            <Box className="textContainer">
                                <Text className="promptLabel">Prompt</Text>
                                <Text className="promptValue">3D Beer Bottle</Text>
                            </Box>
                            <Box className="imageContainer">
                                <Image src={Beer} />
                            </Box>
                        </Flex>
                    }
                    <TitleWithIcon icon={AiImageDocumentIcon} text="image creator" />
                    <Box className="headingContainer">
                        <Heading text="Visuals <br/>redefined, <br/>AI-style" />
                    </Box>
                    {!isMobile &&
                        <List list={list} />
                    }
                </Box>
                <Box className="rightBox">
                    {!isMobile &&
                        <Flex className="promptImage">
                            <Box className="textContainer">
                                <Text className="promptLabel">Prompt</Text>
                                <Text className="promptValue">3D Beer Bottle</Text>
                            </Box>
                            <Box className="imageContainer">
                                <Image src={Beer} />
                            </Box>
                        </Flex>
                    }
                    <Description text="Transform your ideas into stunning visuals <br/>with our AI Image Generation. Unleash a <br/>wave of creativity with AI-crafted images <br/>tailored to your vision." />
                    {!isMobile &&
                        <>
                            <Box className="buttonContainer">
                                <Button label={'GENERATE IMAGES'} onClick={() => console.log(123)} />
                            </Box>
                        </>
                    }
                </Box>
            </Flex>
            <Flex sx={imagesContainerStyle}>
                <Box className="imageItem first">
                    <Image src={ImageGenerator1} />
                </Box>
                <Box className="imageItem second">
                    <Image src={ImageGenerator2} />
                </Box>
                <Box className="imageItem third">
                    <Image src={ImageGenerator3} />
                </Box>
                <Box className="imageItem fourth">
                    <Image src={ImageGenerator4} />
                </Box>
                <Box className="imageItem fifth">
                    <Image src={ImageGenerator5} />
                </Box>
            </Flex>
            {isMobile &&
                <Flex sx={secondSectionStyle} className="mobileStructure">
                    <Box className="leftBox">
                        <List list={list} />
                    </Box>
                    <Box className="rightBox">
                        <Box className="buttonContainer">
                            <Button label={'GENERATE IMAGES'} onClick={() => console.log(123)} />
                        </Box>
                    </Box>
                </Flex>
            }
        </>
    )
}

const imagesContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-25px',
    marginRight: '-25px',
    '.imageItem': {
        flexGrow: 1,
        'img': {
            width: '100%'
        }
    },
    '@media all and (max-width:640px)': {
        '.imageItem': {
            '&.first': {
                display: 'none'
            },
            '&.second': {
                display: 'none'
            },
            '&.fifth': {
                marginRight: '-25px'
            }
        }
    }
}

const promptMobileVersion = {
    paddingLeft: '20px',
    position: 'relative',
    marginBottom: '40px',
    '.textContainer': {
        width: '125px',
        padding: '10px',
        borderRadius: '4px',
        background: '#252D35',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        '.promptLabel': {
            fontSize: '12px',
            lineHeight: '18px',
            color: '#98B3F8',
            textTransform: 'uppercase',
            letterSpacing: '1px'
        },
        '.promptValue': {
            fontSize: '14px',
            lineHeight: '18px',
            color: '#1E5DFF',
        }
    },
    '.imageContainer': {
        'img': {
            marginLeft: 'auto',
            marginRight: '-15px',
        }
    }
}

const secondSectionStyle = {
    '.leftBox': {
        flex: '0 0 50%',
        '.headingContainer': {
            marginBottom: '55px'
        }
    },
    
    '.rightBox': {
        flex: '0 0 50%',
        position: 'relative',
        top: '-120px',
        '.promptImage': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            marginBottom: '100px',
            '&.mobileVersion': {
                display: 'none'
            },
            '.textContainer': {
                position: 'relative',
                zIndex: 1,
                top: '-70px',
                '&:after': {
                    content: "''",
                    top: '14px',
                    position: 'absolute',
                    left: '100%',
                    height: '1px',
                    width: '250px',
                    background: '#EAF5FF'
                },
                '.promptLabel': {
                    fontSize: '12px',
                    lineHeight: '20px',
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    color: '#1B518F'
                },
                '.promptValue': {
                    color: '#1E5DFF'
                }
            },
            '.imageContainer': {
                marginLeft: 'auto',
                position: 'relative',
                zIndex: 2
            }
        },
        '.buttonContainer': {
            marginBottom: 0
        }
    },
    '&.mobileStructure': {
        display: 'none'
    },
    '@media all and (max-width: 800px)': {
        flexWrap: 'wrap',
        '.mobileVersion': {
            display: 'block'
        },
        '.leftBox': {
            flex: '0 0 100%',
            marginBottom: '30px',
            '.headingContainer': {
                marginBottom: 0
            }
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingTop: 0,
            top: 0,
            'promptImage': {
                display: 'none'
            },
            '.buttonContainer': {
                marginBottom: '30px'
            }
        },
        '&.mobileStructure': {
            marginBottom: 0,
            paddingTop: '40px',
            display: 'block'
        }
    },
    '@media all and (max-width: 420px)': {
        '.rightBox': {
            '.descriptionContainer': {
                'br': {
                    display: 'none'
                }
            }
        },
    }
}


export default ThirdSection;