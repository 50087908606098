function ThinCloseIcon() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M1.44176 1L11.4418 11" stroke="#CFD5E3" strokeLinecap="round"/>
            <path d="M11.3537 1.3538C11.549 1.15854 11.549 0.841954 11.3537 0.646692C11.1585 0.451429 10.8419 0.451429 10.6466 0.646692L11.3537 1.3538ZM6.35373 6.3538L11.3537 1.3538L10.6466 0.646692L5.64663 5.64669L6.35373 6.3538Z" fill="#CFD5E3"/>
            <path d="M1 11L4.125 7.875" stroke="#CFD5E3" strokeLinecap="round"/>
        </svg>

    );
}
  
export default ThinCloseIcon;