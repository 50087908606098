import React, { useState, useEffect } from 'react';
import { Box, Icon, Text, Input } from '@chakra-ui/react';
import GlobalMenu from '../../common/global-menu/GlobalMenu.js';
import { Link } from 'react-router-dom';
import RenameDocumentIcon from '../../../assets/images/RenameDocumentIcon.js';
import DeleteDocumentIcon from '../../../assets/images/DeleteDocumentIcon.js';

import FolderItemIcon from '../../../assets/images/FolderItemIcon.js';
import { useFolderContext } from '../../../contexts/FolderContext.js';

function FolderItem({ folder, sortType, onRename, onDelete }) {
    const { renameFolder, fetchFolders } = useFolderContext();
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(folder.name);

    const handleRename = () => {
        setIsEditing(true);
    }
    const handleDelete = () => {
        onDelete(folder.id);
    }

    const menuItems = [
        {
            label: 'Rename',
            icon: RenameDocumentIcon,
            onClick: handleRename
        },
        {
            label: 'Delete',
            icon: DeleteDocumentIcon,
            onClick: handleDelete
        }
    ];

    const handleBlur = async () => {
        if (name.trim() !== '') {
            if (name !== folder.name) {
                renameFolder(folder.id, name)
                    .then(() => {
                        fetchFolders(sortType);
                    });
            }
            setIsEditing(false);
        }
    }

    useEffect(() => {
        setName(folder.name);
    }, [folder.name]);

    return (
        <Box sx={folderItemContainerStyle}>
            <Box sx={folderItemStyle}>
                <Box className="iconMainContainer">
                    <Box className="iconItem">
                        <Icon as={FolderItemIcon} />
                    </Box>
                </Box>
                <Box className="rightContainer">
                    {isEditing ? (
                        <Input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <Link to={`/admin/my-documents/${folder.id}`}>
                            <Text style={folderNameStyle}>{folder.name}</Text>
                        </Link>
                    )}
                    <Text sx={numberOfDocsStyle}>{folder.projectCount} document(s)</Text>
                    <Box sx={globalMenuContainerStyle}>
                        <GlobalMenu menuItems={menuItems} />
                    </Box>

                    {/* <Flex sx={bottomDetailsContainerStyle}>
                        <Box className="leftBox">
                            <Icon as={ClockIcon}/>
                            <Text style={folderDateStyle}>{formatDate(folder.updatedAt)}</Text>
                        </Box>
                        <Box className="bottomBox">
                            <Box mr="20px">
                                <Button style={actionButtonStyle} color="#0D78F5" onClick={() => setIsEditing(true)}>Rename</Button>
                            </Box>
                            <Box>
                                <Button  style={actionButtonStyle} color="#FF1284" onClick={() => onDelete(folder.id)}>Delete</Button>
                            </Box>
                        </Box>
                    </Flex> */}
                </Box>
            </Box>
        </Box>
    );
}

const globalMenuContainerStyle = {
    position: 'absolute',
    right: '-12px',
    top: '3px'
}

const folderItemContainerStyle = {
    width: '33%',
    padding: '0 10px',
    marginBottom: '15px',
    '@media (max-width: 1200px)': {
        width: '33.33%',
        marginBottom: '15px',
        padding: '0 5px'
    },
    '@media (max-width: 680px)': {
        width: '50%',
        marginBottom: '10px',
    },
    '@media (max-width: 480px)': {
        width: '100%',
    },
}



const folderNameStyle = {
    color: '#1B518F',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: '5px'
}

const numberOfDocsStyle = {
    color: '#1B518F',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px',
    '@media (max-width: 1200px)': {
        marginBottom: '15px',
    }
}



const folderItemStyle = {
    background: '#fff',
    padding: '10px 15px',
    border: '1px solid #CFD5E3',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '.rightContainer': {
        position: 'relative',
        flexGrow: 1,
    },
    '.iconMainContainer': {
        paddingRight: '10px',
        '.iconItem': {
            width: '34px',
            height: '34px',
            borderRadius: '34px',
            border: '1px solid #CFD5E3',
            position: 'relative',
            transition: 'all 0.3s ease',
            'svg': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                'path': {
                    transition: 'all 0.3s ease',
                }
            }
        }
    },
    '&:hover': {
        background: '#fff',
        '.bottomBox': {
            opacity: 1,
            visibility: 'visible'
        },
        '.iconMainContainer': {
            '.iconItem': {
                background: '#1E5DFF',
                borderColor: 'transparent',
                'svg': {
                    'path': {
                        stroke: '#fff'
                    }
                }
            }
        },
    },
    '.bottomBox': {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease, visibility 0.3s ease'
    },
    '@media (max-width: 1200px)': {
        padding: '10px',
        '.bottomBox': {
            opacity: 1,
            visibility: 'visible'
        }
    }
}

export default FolderItem;
