import React, { useState, useEffect } from 'react';
import api from '../../../../utils/api.js';
import {
    Box,
    Text,
    Icon,
    Button,
} from "@chakra-ui/react";
import clipboardCopy from 'clipboard-copy';
import CopyIcon from '../../../../assets/images/CopyIcon.js';
import EditIcon from '../../../../assets/images/EditIcon.js';
import DeleteIcon from '../../../../assets/images/DeleteIcon.js';
import SaveIcon from '../../../../assets/images/SaveIcon.js';
import CancelIcon from '../../../../assets/images/CancelIcon.js';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function count_words(input_string) {
    return input_string.split(' ').length;
}

function count_characters(input_string) {
    return input_string.length;
}

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'code'],
        ['clean']
    ],
};

function ContentEditorItem({ content, documentDetails, onDelete }) {
    const [currentContent, setCurrentContent] = useState(content.content);
    const [newContent, setNewContent] = useState(currentContent);
    const [edit, setEdit] = useState(false);

    function handleUpdateContent() {
        api.post('/admin/my-documents/update-document-content', {
            documentId: documentDetails.id,
            generatedContentId: content.id,
            projectFields: documentDetails.projectFields,
            newContent: newContent
        })
            .then(response => {
                if (response.status === 200) {
                    setCurrentContent(newContent);
                    setEdit(false);
                } else {
                    console.error('Failed to update content:', response.data);
                }
            })
            .catch(error => {
                console.error('Error sending request:', error);
            });
    }

    useEffect(() => {
        setCurrentContent(content.content);
    }, [content.content]);

    const handleCopyClick = () => {
        clipboardCopy(newContent)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    return (
        <Box mb="20px" className="boxesContainer" sx={boxesContainerStyle}>
            <Box className={edit === true ? "contentMainBox editing" : "contentMainBox"}>
                {edit ? (
                    <Box sx={quillStyle}>
                        <ReactQuill
                            defaultValue={currentContent}
                            modules={modules}
                            placeholder="Enter content description"
                            onChange={(value) => setNewContent(value)}
                        />
                    </Box>
                ) : (
                    <Text sx={contentStyle} dangerouslySetInnerHTML={{ __html: currentContent }} />
                )}
                <Box className="buttonGroup" sx={buttonGroupStyle}>
                    {edit === true
                        ? <Box className="buttonsContainer saveAndClose">
                            <Button sx={contentButtonActionStyle} onClick={() => handleUpdateContent()} >
                                <Icon as={SaveIcon} />
                                <Text className="textLabel saveButton">Save</Text>
                            </Button>
                            <Button sx={contentButtonActionStyle} onClick={() => setEdit(false)} className="closeButton">
                                <Icon as={CancelIcon} />
                                <Text className="textLabel">Close</Text>
                            </Button>
                        </Box>
                        : <Box className="buttonsContainer">
                            <Button color="#1E5DFF" sx={contentButtonActionStyle} mr="20px" onClick={() => handleCopyClick()}>
                                <Icon as={CopyIcon} />
                            </Button>
                            <Button color="#1E5DFF" sx={contentButtonActionStyle} mr="20px" onClick={() => setEdit(!edit)}>
                                <Icon as={EditIcon} />
                            </Button>
                            <Button color="#FF1284" sx={contentButtonActionStyle} onClick={() => onDelete(content.id)}>
                                <Icon as={DeleteIcon} />
                            </Button>
                        </Box>
                    }
                    <Box className="wordsCoountContainer">
                        <Text sx={totalWordsStyle}>{count_words(currentContent)} Words</Text>
                        <Text sx={totalWordsStyle} color="#6D99BE" display="inline">( {count_characters(currentContent)} Characters)</Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const boxesContainerStyle = {
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto'
}

const quillStyle = {
    '.quill': {
        position: 'relative',
        '.ql-container': {
            border: '0 none'
        },
        '.ql-editor': {
            background: '#fff',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#1B518F',
        },
        '.ql-toolbar': {
            border: '0 none',
            background: '#fff'
        }
    }
}

const buttonGroupStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '10px',
    '.wordsCoountContainer ': {
        display: 'flex',
        flexDirection: 'row',
    },
    '.buttonsContainer': {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px',
        marginRight: '15px',
        'button': {
            marginLeft: 0,
            'svg': {
                width: '16px'
            },
            '&:hover': {
                background: 'transparent'
            }
        },
        '&.saveAndClose': {
            minWidth: '160px',
            justifyContent: 'left',
            padding: '15px',
            'button': {
                justifyContent: 'left',
                marginLeft: 0,
                marginRight: 0,
                '.textLabel': {
                    color: '#8599CD',
                    fontSize: '16px',
                    fontWeight: 700,
                    paddingLeft: '5px',
                    '&.saveButton': {
                        color: '#1E5DFF'
                    }
                }
            }
        }
    }
}

const contentStyle = {
    color: '#1B518F',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    'h1': {
        fontSize: '20px',
        lineHeight: '24px',
        color: '#6D99BE',
    },
    'h1 + br': {
        display: 'none'
    },
    'h2': {
        fontSize: '18px',
        lineHeight: '22px',
        color: '#6D99BE',
    },
    'h2 + br': {
        display: 'none'
    },
    'h3': {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#6D99BE',
    },
    'h3 + br': {
        display: 'none'
    },
    'ol': {
        paddingLeft: '17px'
    },
    'ul': {
        paddingLeft: '17px'
    },
    'li + br': {
        display: 'none'
    }
}

const contentButtonActionStyle = {
    padding: 0,
    background: 'transparent',
    height: 'auto',
    fontSize: '12px',
    lineheight: '15px',
    fontWeight: 500,
    minWidth: '1px',
    margin: '0 10px 0',
    '&:last-child': {
        margin: 0
    },
    '&.closeButton': {
        marginLeft: '15px !important'
    }
}

const totalWordsStyle = {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#1B518F',
    '@media (max-width: 640px)': {
        fontSize: '10px'
    }
}

export default ContentEditorItem;
