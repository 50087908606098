import React, { useState, useEffect } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import TitleWithIcon from '../../../common/frontend/title-with-icon/TitleWithIcon.js';
import Heading from '../../../common/frontend/heading/Heading.js';
import Description from '../../../common/frontend/description/Description.js';
import Button from '../../../common/frontend/button/Button.js';
import List from '../../../common/frontend/list/List.js';
import AiContentIcon from '../../../../assets/images/templates/AiContentIcon.js';
import CybertruckImg from '../../../../assets/images/homepage/Cybertruck.png';
import ContentTemplateImg from '../../../../assets/images/homepage/ContentTemplate.png';



function SecondSection() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const list = [
        { text: 'Extensive templates library' },
        { text: 'available in 80+ languages' },
        { text: 'Folder structure' },
    ]
    return (
        <Flex sx={secondSectionStyle}>
            <Box className="leftBox">
                {isMobile &&
                    <Box className="imageContianerMobile">
                        <Image src={CybertruckImg} />
                    </Box>
                }
                <TitleWithIcon icon={AiContentIcon} text="ai content" />
                <Box className="headingContainer">
                    <Heading text="AI-driven <br/>creativity on <br/>demand" />
                </Box>
                <Box className="firstImageContainer">
                    <Image src={ContentTemplateImg} />
                </Box>
            </Box>
            <Box className="rightBox">
                <Box className="imageContianer">
                    <Image src={CybertruckImg} />
                </Box>

                <Description text="Tap into ever-growing creativity with our AI <br/>Generator library. Effortlessly craft <br/>compelling content across diverse, <br/>expanding templates." />
                {isMobile &&
                    <Box className="firstImageContainerMobile">
                        <Image src={ContentTemplateImg} />
                    </Box>
                }
                {!isMobile &&
                    <Box className="buttonContainer">
                        <Button label={'CREATE WITH AI'} onClick={() => console.log(123)} />
                    </Box>
                }
                <List list={list} />
                {isMobile &&
                    <Box className="buttonContainerMobile">
                        <Button label={'CREATE WITH AI'} onClick={() => console.log(123)} />
                    </Box>
                }
            </Box>
        </Flex>
    )
}

const secondSectionStyle = {
    '.leftBox': {
        flex: '0 0 50%'
    },
    '.rightBox': {
        flex: '0 0 50%',
        paddingTop: '30px',
        '.buttonContainer': {
            marginBottom: '55px'
        }
    },
    '.headingContainer': {
        marginBottom: '70px',
    },
    '.imageContianer': {
        marginBottom: '60px',
        marginTop: '-90px'
    },
    '.imageContianerMobile': {
        display: 'none',
        marginRight: '-10px',
        marginBottom: '50px',
    },
    '.firstImageContainerMobile': {
        display: 'none'
    },
    '@media all and (max-width: 800px)': {
        flexWrap: 'wrap',
        '.imageContianerMobile': {
            display: 'block'
        },
        '.buttonContainerMobile': {
            marginTop: '30px',
            marginBottom: 0
        },
        '.headingContainer': {
            marginBottom: '10px',
        },
        '.leftBox': {
            flex: '0 0 100%',
            marginBottom: '20px'
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingTop: 0,
            '.buttonContainer': {
                marginBottom: '30px'
            }
        },
        '.firstImageContainer': {
            display: 'none'
        },
        '.firstImageContainerMobile': {
            display: 'block',
            marginLeft: '-15px',
            marginRight: '-30px',
            marginBottom: '30px',
        },
        '.imageContianer': {
            marginTop: 0,
            display: 'none'
        },
    },
    '@media all and (max-width: 420px)': {
        '.rightBox': {
            '.descriptionContainer': {
                'br': {
                    display: 'none'
                }
            }
        },
    }
}


export default SecondSection;