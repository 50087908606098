import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Box, Button as ChrakraButton } from '@chakra-ui/react';

function Button({ label, onClick }) {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/login');
    }
    return (
        <Box sx={style}>
            <ChrakraButton onClick={() => handleClick()}>{label}</ChrakraButton>
        </Box>
    )
}

const style = {
    'button': {
        height: '40px',
        lineHeight: '40px',
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '12px',
        color: '#1E5DFF',
        fontWeight: 700,
        border: '1px solid #1E5DFF',
        background: 'transparent',
        '&:hover': {
            background: '#1E5DFF',
            color: '#fff'
        }
    }
}


export default Button;