import React, { useEffect } from 'react';
import FoodChecker from '../../../../components/tools/food-checker/FoodChecker.js';
import { useAppContext } from '../../../../contexts/AppContext.js';
import Header from '../../../../layouts/Header/Header.js';

function FoodCheckerPage() {
    const { openMobileMenu, mobileMenuOpen } = useAppContext();

    useEffect(() => {
        if (window.innerWidth <= 1200 && mobileMenuOpen === true) {
            openMobileMenu(false);
        }
        if (window.innerWidth <= 1200) {
            document.documentElement.scrollTop = 0;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header page="foodChecker" title="Food Checker" />
            <FoodChecker />
        </>
    );
}

export default FoodCheckerPage; 
