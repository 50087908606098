import React from 'react';
import {
    Box,
    Flex,
    Button,
    Icon,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext.js';
import RightArrowFormButton from '../../assets/images/RightArrowFormButton.js';
import NoWordsRemaining from '../common/no-words-remaining/NoWordsRemaining.js';
import AttachImage from '../../assets/images/AttachImage.js';
import AiGalleryChatIcon from '../../assets/images/AiGalleryChat.js';
import DefaultCloseIcon from '../../assets/images/DefaultCloseIcon.js';

const SubmitButton = ({ handleAttachFile, showUploadedFiles, displayUploadedFiles, chat, activeState }) => {
    const { userDataAndPlans } = useUserContext();
    const location = useLocation();
    const checkForWords = () => {
        if (userDataAndPlans && userDataAndPlans.wordsRemaining > 0) {
            return true;
        }
        return false;
    }

    return (
        <Flex sx={buttonsContainerStyle}>
            {userDataAndPlans && userDataAndPlans.id &&
                <>
                    {checkForWords() === true
                        ? <>
                            {chat && !location.pathname.includes('dashboard') &&
                                <>
                                    <Box className="galleryButton attachButtoon buttonItem">
                                        <Button type="button" onClick={() => showUploadedFiles()}>
                                            {displayUploadedFiles === true
                                                ? <Icon as={DefaultCloseIcon} />
                                                : <Icon as={AiGalleryChatIcon} />
                                            }
                                        </Button>
                                    </Box>
                                    <Box className="attachButtoon buttonItem">
                                        <Button type="button" onClick={() => handleAttachFile()}>
                                            <Icon as={AttachImage} />
                                        </Button>
                                    </Box>
                                </>
                            }
                            <Box className="submitButton buttonItem">
                                <Button type="submit" className={activeState === true ? `active-state` : ``}>
                                    <Icon as={RightArrowFormButton} />
                                </Button>
                            </Box>
                        </>
                        : <NoWordsRemaining />
                    }
                </>
            }
        </Flex>
    );
};

const buttonsContainerStyle = {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    zIndex: 9,
    '.submitButton': {
        'button': {
            background: 'transparent',
            width: '34px',
            height: '34px',
            borderRadius: '10px',
            position: 'relative',
            padding: 0,
            minWidth: '1px',
            border: '2px solid #CFD5E3',
            transition: 'all 0.3s ease',
            'path': {
                transition: 'all 0.3s ease',
            },
            '&:hover': {
                borderColor: '#1E5DFF',
                background: '#1E5DFF',
                'path': {
                    stroke: '#fff'
                }
            },
            '&.active-state': {
                borderColor: '#1E5DFF',
                background: '#1E5DFF',
                'path': {
                    stroke: '#fff'
                }
            }
        }
    },
    '.attachButtoon': {
        'path': {
            transition: 'all 0.3s ease',
        },
        'button': {
            background: 'transparent',
            paddingLeft: 0
        },
        '&.galleryButton': {
            padding: 0
        }
    },
    '@media all and (max-width: 540px)': {
        '.submitButton': {
            'button': {
                width: '30px',
                height: '30px',
                'svg': {
                    width: '17px'
                }
            }
        },
        '.attachButtoon': {
            'button': {
                paddingRight: '5px'
            }
        },
        '.attachButtoon.galleryButton': {
            'button': {
                paddingRight: 0
            }
        }

    }
}
export default SubmitButton;