import React from 'react';
import {
    Flex,
    Box,
    Heading,
    Text,
} from '@chakra-ui/react';

function LowCredits() {
    return (
        <Flex sx={lowCreditsStyle}>
            <Box className="iconContainer">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path d="M7.8 7.8L11.2 4.4M11.2 4.4L7.8 1M11.2 4.4H7.8C4.04446 4.4 1 7.44446 1 11.2M28.2 28.2L24.8 31.6M24.8 31.6L28.2 35M24.8 31.6H28.2C31.9555 31.6 35 28.5555 35 24.8M14.9213 8.65C16.0537 4.25067 20.0471 1 24.8 1C30.4333 1 35 5.56669 35 11.2C35 15.9527 31.7494 19.9463 27.3502 21.0787M21.4 24.8C21.4 30.4333 16.8333 35 11.2 35C5.56669 35 1 30.4333 1 24.8C1 19.1667 5.56669 14.6 11.2 14.6C16.8333 14.6 21.4 19.1667 21.4 24.8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

            </Box>
            <Box className="textContainer">
                <Heading>Need more credits?</Heading>
                <Text>Running low on credits? No problem! Shoot us a message to request a top-up. credits@skelet.ai</Text>
                
                {/* <Button>Request more credits</Button> */}
            </Box>
        </Flex>
    )
}

const lowCreditsStyle = {
    background: '#1E5DFF',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    padding: '25px 20px 40px',
    marginTop: '40px', 
    '.iconContainer': {
        paddingRight: '15px',

    },
    '.textContainer': {
        'h2': {
            fontSize: '18px',
            lineHeight: '20px',
            color: '#fff',
            marginBottom: '10px',
        },
        'p': {
            fontSize: '14px',
            lineHeight: '16px',
            color: '#fff'
        },
        'button': {
            background: '#fff',
            fontSize: '16px',
            lineHeight: '40px',
            color: '#1E5DFF',
            fontWeight: 700,
            marginTop: '20px',
            borderRadius: '4px',
        }
    }
}


export default LowCredits;