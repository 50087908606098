import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import TikTokVideoIcon from '../../assets/images/templates/TikTokVideoScriptIcon.js';
import InstagramIcon from '../../assets/images/templates/InstagramCaptionIcon.js';
import TwitterIcon from '../../assets/images/templates/TweetGeneratorIcon.js';
import {Flex, Box, Text, Icon, List, ListItem, Button} from '@chakra-ui/react';

const FrontFooter = () => {
  const navigate = useNavigate();
  return (
    <Flex sx={footerContainer} className="front-footer"> 
          <Box sx={innerFooterContainerStyle}>
                <Box className="topContainer">
                    <Box className="box leftBox">
                        <Box display="flex" alignItems="left">
                            <Box as={RouterLink} to="/" mr="40px">
                                <svg width="37" height="65" viewBox="0 0 37 65" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.8049 4.77321L16.5967 0.899902L19.3043 4.77321L16.5967 8.59621L13.8049 4.77321ZM17.7737 11.154L30.8663 33.0096H35.9863L20.5897 7.1902L17.7737 11.154ZM12.0128 7.48277L28.0137 35.4871L27.9428 35.5237L28.0137 35.5645L19.3362 50.6386L8.54786 35.0961L12.0162 30.5766L14.848 34.1068L13.9802 35.2259L18.99 42.4435L20.8672 39.1825C18.7858 38.8903 17.1886 37.1876 17.1886 35.1308C17.1886 33.1829 18.621 31.5527 20.5406 31.1372L19.2283 28.8405H11.3676L7.82629 24.6713H16.8462L12.1009 16.3663L3.21517 32.758L0 29.4286L12.0128 7.48277ZM19.7629 64.8999L3.73028 41.0554L6.65599 37.6908L19.4742 56.7201L31.9634 35.0576H36.7908L19.7629 64.8999Z" fill="white"/>
                                </svg>
                            </Box>
                            <Box>
                                <Text style={footerTextStyle}>By harnessing the latest AI technology, we're enabling <br/>access to a source of endless creativity... and, now, <br/>staring is all you need.</Text>
                            </Box>
                        </Box>
                        
                    </Box>
                    <Box className="box rightBox">
                        <Box sx={socialIconsStyle}>
                            <Text className="socialIconsTitle">stay connected</Text>
                            <List>
                                <ListItem>
                                    <NavLink to="https://twitter.com" target="_blank">
                                        <Icon as={TwitterIcon}/>
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to="https://tiktok.ro" target="_blank">
                                        <Icon as={TikTokVideoIcon}/>
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to="https://instagram.com" target="_blank">
                                        <Icon as={InstagramIcon}/>
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
                <Box className="bottomContainer">
                    <Flex className="boxesContainer">
                        <Box className="box">
                            <Text className="copyright">Copyright ©2023 Staring.io. All rights reserved.</Text>
                        </Box>
                        <Box className="box rightBox">
                            <List sx={rightFooterHeader}>
                                <ListItem>
                                    <Button className="login_button" onClick={() => navigate('/login')}>Sign in</Button>
                                </ListItem>
                                <ListItem>
                                    <Button className="register_button" onClick={() => navigate('/register')}>Create an account</Button>
                                </ListItem>
                            </List>
                        </Box>
                    </Flex>
                </Box>
                <Box sx={temporarContainerStyle}>
                    <Text className="description">Content voice, and images generated by AI.</Text>
                    <Flex className="linksContainer">
                        <Box className="linkItem">
                            <RouterLink to="/terms-and-conditions">terms of service</RouterLink>
                        </Box>
                        <Box className="linkItem">
                            <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
                        </Box>
                    </Flex>
                    <Text className="copyright">skelet aI © 2024</Text>
                </Box>
          </Box>
    </Flex>
  )
};

const temporarContainerStyle = {
    flexDirection: 'column',
    textAlign: 'center',
    '.description': {
        fontSize: '14px',
        lineHeight: '16px',
        color: '#98B3F8',
        marginBottom: '10px'
    },
    '.linksContainer': {
        justifyContent: 'center',
        marginBottom: '20px',
        '.linkItem': {
            padding: '0 10px',
            position: 'relative',
            'a': {
                color: '#fff',
                fontSize: '12px',
                textTransform: 'uppercase',
                letterSpacing: '1px'
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                right: 0,
                top: '8px',
                bottom: '5px',
                width: '1px',
                background: '#fff'
            },
            '&:last-of-type': {
                '&:after': {
                    display: 'none'
                }
            }
        }
    },
    '.copyright': {
        color: '#98B3F8',
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '1px'
    }
}

const footerTextStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#6D99BE'
}

const socialIconsStyle = {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'right',
    '.socialIconsTitle': {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#6D99BE',
        marginBottom: '20px',
        textTransform: 'uppercase'
    },
    'ul': {
        display:'flex',
        flexDirection: 'row',
        margin: '0 -10px',
        'li': {
            padding: '0 10px',
            'a': {
                display: 'inline-block',
                borderRadius: '28px',
                background: '#2C588A',
                'svg':{
                    width: '28px',
                    height: '28px',
                    'circle': {
                        display: 'none'
                    }
                }
            }
        },
        
    },
    
}

const rightFooterHeader = {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    'li':{
        paddingLeft: '30px',
        'button':{
            fontSize: '16px',
            color:'#1E5DFF',
            fontWeight: 700,
            height: '40px',
            lineHeight: '40px',
            background: 'transparent',
        },
        '.register_button':{
            minWidth: '190px',
            border:'1px solid #1E5DFF'
        }
    }
}

const innerFooterContainerStyle = {
    maxWidth: '1200px',
    width: '100%', 
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.topContainer': {
        paddingBottom: '70px',
        marginBottom: '30px',
        borderBottom: '1px solid #1D4675',
        display:'none',
        flexDirection: 'row',
        alignItems: 'center',
        '.box': {
            width: '50%',
            display: 'flex'
        },
        '.rightBox':{
            justifyContent: 'right'
        }
    },
    '.bottomContainer': {
        display: 'none',
        width: '100%',
        '.boxesContainer': {
            width: '100%',
            '.box': {
                width: '50%',
                '.copyright': {
                    fontSize: '12px',
                    color: '#4C7DA6'
                }
            }
        }
    },
    '@media (max-width: 800px)': {
        '.topContainer': {
            flexDirection: 'column',
            flexWrap: 'wrap',
            paddingBottom: '40px',
            marginBottom: '20px',
            '.box': {
                width: '100%'
            },
            '.leftBox': {
                justifyContent: 'center',
                marginBottom: '30px'
            },
            '.rightBox':{
                justifyContent: 'center'  
            }
        },
        '.bottomContainer': {
            '.boxesContainer': {
                '.box': {
                    width: '100%',
                    '.copyright': {
                        textAlign: 'center'
                    }
                },
                '.rightBox': {
                    display: 'none'
                }
            }
        }
    }
}

const footerContainer = {
    paddingTop: '40px',
    paddingBottom: '40px',
    flexDirection: 'column',
    width: '100%',
    background: '#131E2B',
    '@media (max-width:800px)': {
        paddingTop: '40px',
        paddingBottom: '20px'
    }
}


export default FrontFooter;