import React, { useState, useEffect } from 'react';
import api from '../../utils/api.js';
import { useParams } from 'react-router-dom';

function ProjectDetails({ match }) {
  const { id } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    async function fetchProject() {
      api.get(`/admin/project/${id}`)
        .then(response => {
          setProject(response.data);
        })
        .catch(err => {
        });
    }

    fetchProject();

  }, [id]);
  return (
    <div>
      {project ? (
        <div>
          <h2>Project Name: {project.name}</h2>
        </div>
      ) : (
        <p>Loading folder details...</p>
      )}
    </div>
  );
}

export default ProjectDetails;
