import React, { useState, useEffect } from 'react';

import { Flex, Box, useToast } from '@chakra-ui/react';
import { useUserContext } from '../../contexts/UserContext.js';
import { useAppContext } from '../../contexts/AppContext.js';
import api from '../../utils/api.js';
import ChatForm from './chat-form/ChatForm.js';
import ChatMessages from './chat-messages/ChatMessages.js';

const Chat = ({ page }) => {
  const { userDataAndPlans, fetchUserData } = useUserContext();
  const {displayNoCreditsModal} = useAppContext();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const toast = useToast();

  const getLastMessages = async () => {
    if (userDataAndPlans && userDataAndPlans.lastChatId) {
      const response = await api.post('/admin/chat/get-last-messages', { chatId: userDataAndPlans.lastChatId });
      if (response.data.length > 0) {
        setMessages(response.data);
      }
    }
  }
  useEffect(() => {
    getLastMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataAndPlans.lastChatId]);

  useEffect(() => {
    const message = JSON.parse(localStorage.getItem('data'));
    localStorage.removeItem('data');
    if (message) {
      getLastMessages();
      submitForm(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (data) => {
    setLoading(true);
    const jwtToken = localStorage.token;
    if (data.attachment) {
      const formData = new FormData();
      formData.append('chatId', userDataAndPlans.lastChatId);
      formData.append('messageType', 'user');
      formData.append('dateCreated', new Date());
      formData.append('storage', userDataAndPlans.storage);
      formData.append('planType', userDataAndPlans.planType);
      formData.append('file', data.attachment);

      const response = await api.post(`/admin/chat/chat-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.error) {
        toast({
          description: response.data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return false;
      }

      const message = response.data;
      setMessages(prevMessages => [
        ...prevMessages,
        message
      ]);
      setLoading(false);
    } else {

      const queryData = {
        message: data.text,
        allMessages: JSON.stringify(messages),
        lastChatId: userDataAndPlans.lastChatId
      };

      const newMessage = {
        userId: userDataAndPlans.userId,
        message: data.text,
        messageType: 'user',
        dateCreated: new Date(),
      };
      setMessages((prevState) => [...prevState, newMessage]);

      const queryString = new URLSearchParams(queryData).toString();
      const eventSource = new EventSource(`https://skelet.ai:5000/api/admin/chat/chat-with-ai?${queryString}&token=${jwtToken}`);
      let currentMessage = {
        userId: userDataAndPlans.userId,
        message: '',
        messageType: 'ai',
        dateCreated: new Date(),
      };

      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        try {
          if (data.error) {
            
            displayNoCreditsModal(true);
            setLoading(false);
            eventSource.close(); 
            return false;
          } else if (data.message === "Stream done") {
            
            fetchUserData();
            setLoading(false);
            eventSource.close();
          } else {
            
            currentMessage.message += data.content;
            if (currentMessage.message.length === data.content.length) {
              setMessages(prevMessages => [...prevMessages, { ...currentMessage }]);
            } else {
              setMessages(prevMessages => [
                ...prevMessages.slice(0, -1),
                { ...currentMessage }
              ]);
            }
          }
        } catch (err) {
          console.error("Error parsing event data:", err);
        }
      };
      eventSource.addEventListener('end', function (event) {
        eventSource.close();
      });
    }
  };

  return (
    <Flex sx={chatContainerStyle}>
      <Box className="inner_container">
        {page !== 'dashboard' &&
          <Box className="message_container">
            <ChatMessages messages={messages} user={userDataAndPlans} />
          </Box>
        }

        <Box className="form_container">
          <ChatForm
            chatId={userDataAndPlans.lastChatId}
            onSubmit={(data) => submitForm(data)}
            page={page}
            loading={loading}
          />
        </Box>
      </Box>
    </Flex>
  );
};

const chatContainerStyle = {
  marginLeft: 'auto',
  marginRight: 'auto',
  flexDirection: 'column',
  position: 'relative',
  height: 'calc(100vh - 60px)',
  paddingTop: 0,
  paddingBottom: '0',
  '.inner_container': {
    height: '100%',
    position: 'relative',
    '.form_container': {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      zIndex: 99
    }
  },
  '@media all and (max-width: 850px)': {
    '.inner_container': {
      '.form_container': {
        position: 'fixed',
        left: 0,
        bottom: 0,
        right: 0,
        paddingLeft: '15px',
        paddingRight: '15px',
        background: '#fff'
      }
    }
  }
}
export default Chat;
