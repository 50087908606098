import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Image,
    Text,
} from "@chakra-ui/react";
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';
import Outofcredits from '../../../assets/images/Outofcredits.png';
import { useAppContext } from '../../../contexts/AppContext.js';
// import { useAppContext } from '../../../contexts/AppContext.js';

function NoCreditsModal() {
    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const {displayNoCreditsModal} = useAppContext();
    const { onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={onOpen} onClose={() => displayNoCreditsModal(false)} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="modalHeaderStyle">
                        <ModalCloseButton onClick={onClose}>
                            <CloseModalIcon />
                        </ModalCloseButton>
                    </ModalHeader>
                    <ModalBody>
                        <Box sx={bodyModalStyle}>
                            <Image src={Outofcredits} />
                            <Text className="heading">Your're out of credits</Text>
                            <Text className="grayText">You have used all your credits for this month</Text>
                            <Text className="getMoreCredits">Wait for next replenishment date or <br/>get more credits now</Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter sx={modalFooterStyle}>
                        <Button className="submitButton" onClick={() => navigate('/admin/my-account/edit-profile')}>
                            <Text className="createButtonLabelStyle">Buy more credits</Text>
                        </Button>
                        <Button variant="ghost" className="cancelButtonStyle" onClick={() => displayNoCreditsModal(false)}>Continue browsing</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const bodyModalStyle = {
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    'img': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px'
    },
    '.heading': {
        fontSize: '22px',
        lineHeight: '26px',
        color: '#FF1284',
        marginBottom: '5px',
        fontWeight: 600,
    },
    '.grayText': {
        fontSize: '16px',
        lineHeight: '18px',
        color: '#8599CD',
        marginBottom: '50px',
    },
    '.getMoreCredits': {
        fontSize: '16px',
        lineHeight: '25px',
        color: '#1B518F',
        fontWeight: 700,
        marginBottom: '20px',
    }
}

const modalFooterStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    'button': {
        height: '50px',
        lineHeight: '50px',
        fontSize: '16px',
        fontWeight: 700,
        width: '100%',
        borderRadius: '4px',
        '&.submitButton': {
            border: '1px solid transparent',
            background: '#1E5DFF',
            color: '#fff',
            marginBottom: '10px',
        },
        '&.cancelButtonStyle': {
            border: '1px solid #8599CD',
            background: 'transparent',
            color: '#8599CD',
        }
    }
}



export default NoCreditsModal;
