import React, { useEffect } from 'react';
import AiImages from '../../components/ai-images/AiImages.js';
import { useAppContext } from '../../contexts/AppContext.js';
import Header from '../../layouts/Header/Header.js';
function ImagesPage() {
    const { openMobileMenu, mobileMenuOpen } = useAppContext();

    useEffect(() => {
        if (window.innerWidth <= 1200 && mobileMenuOpen === true) {
            openMobileMenu(false);
        }
        if (window.innerWidth <= 1200) {
            document.documentElement.scrollTop = 0;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header page="aiImages" title="Generate AI Images" />
            <AiImages />
        </>
    );
}

export default ImagesPage;
