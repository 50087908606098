import React, { useState, useEffect } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import TitleWithIcon from '../../../common/frontend/title-with-icon/TitleWithIcon.js';
import Heading from '../../../common/frontend/heading/Heading.js';
import Description from '../../../common/frontend/description/Description.js';
import Button from '../../../common/frontend/button/Button.js';
import List from '../../../common/frontend/list/List.js';
import AiTtsDocumentIcon from '../../../../assets/images/templates/AiTtsDocumentIcon.js';
import SoundWaveImg from '../../../../assets/images/homepage/SoundWave.png';
import AudioWave from '../../../../assets/images/homepage/audio-wave.png';

function FourthSection() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const list = [
        { text: 'natural human tones' },
        { text: 'high definition sound model' },
        { text: 'mp3 download' },
        { text: 'available in 40+ languages' },
    ]
    return (
        <Flex sx={secondSectionStyle}>
            <Box className="leftBox">
                {isMobile &&
                    <Box className="audioWaveContainer">
                        <Image src={AudioWave} />
                    </Box>
                }
                <TitleWithIcon icon={AiTtsDocumentIcon} text="ai voice" />
                <Box className="headingConatiner">
                    <Heading text="Your words, <br/>AI voice" />
                </Box>
                <Box className="waveFormContainer">
                    <Image src={SoundWaveImg} />
                </Box>

            </Box>
            <Box className="rightBox">
                {!isMobile &&
                    <Box className="audioWaveContainer">
                        <Image src={AudioWave} />
                    </Box>
                }


                <Description text="Experience the power of Text-to-Speech as <br/>your words come to life with natural and <br/>expressive voices. Elevate your content with <br/>the perfect vocal touch, all at your command." />
                <Box className="buttonContainer">
                    <Button label={'PLAY YOUR WORDS'} onClick={() => console.log(123)} />
                </Box>

                <List list={list} />
            </Box>
        </Flex>
    )
}

const secondSectionStyle = {
    '.leftBox': {
        flex: '0 0 50%',
        '.audioWaveContainer': {
            display: 'none',
            marginLeft: '-100px',
            marginRight: '-100px'
        },
    },
    '.rightBox': {
        flex: '0 0 50%',
        '.audioWaveContainer': {
            marginBottom: '50px'
        },
        '.buttonContainer': {
            marginBottom: '55px'
        }
    },
    '.headingConatiner': {
        marginBottom: '70px'
    },
    '.waveFormContainer': {
        marginLeft: '-190px'
    },
    '@media all and (max-width: 1200px)': {
        '.waveFormContainer': {
            marginLeft: 0
        },
    },
    '@media all and (max-width: 800px)': {
        flexWrap: 'wrap',
        '.headingConatiner': {
            marginBottom: '20px'
        },
        '.leftBox': {
            flex: '0 0 100%',
            marginBottom: '20px',
            '.audioWaveContainer': {
                display: 'block'
            },
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingTop: 0,
            '.audioWaveContainer': {
                display: 'none'
            },
            '.buttonContainer': {
                marginBottom: '30px'
            }
        },
    },
    '@media all and (max-width: 420px)': {
        '.rightBox': {
            '.descriptionContainer': {
                'br': {
                    display: 'none'
                }
            }
        },
    }
}


export default FourthSection;