function FeaturesToBenefitsIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="21" fill="#F2F9FF"/>
            <path d="M34.125 7.875H7.875V34.125H34.125V7.875Z" fill="white" fillOpacity="0.01"/>
            <path fill-rule="evenodd" clipRule="evenodd" d="M31.9374 15.5313L20.9999 10.0625L10.0624 15.5313V26.4688L20.9999 31.9375L31.9374 26.4688V15.5313Z" stroke="#F50DC2" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M10.0624 15.5312L20.9999 21" stroke="#F50DC2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 31.9375V21" stroke="#F50DC2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.9375 15.5312L21 21" stroke="#F50DC2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.4688 12.7969L15.5313 18.2656" stroke="#F50DC2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
  }
  
export default FeaturesToBenefitsIcon;