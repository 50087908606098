function formatLinks(documentId, type) {
    if(type === 'tts'){
        return `/admin/text-to-speech/${documentId}`;
    }else if(type === 'AiImage'){
        return `/admin/ai-images/${documentId}`;
    }else if(type === 'folder'){
        return `/admin/my-documents/${documentId}`;
    }
    return `/admin/my-documents/document/${documentId}`;
}

export { formatLinks };