import React, {useEffect} from 'react';

import {Flex, Box, Icon, Text} from '@chakra-ui/react';
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';
import DocumentsTable from '../documents-table/DocumentsTable.js';
import { useDocumentContext } from '../../../contexts/DocumentContext.js';
import {Link} from 'react-router-dom';

function FolderDetails({folderDetils}) {
    const { fetchCountDocuments } = useDocumentContext();
    useEffect(() => {
        if(folderDetils && folderDetils.id){
            fetchCountDocuments(folderDetils.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderDetils])
    return (
        <Flex sx={folderDetailsStyle} flexDirection="column"> 
            {folderDetils && folderDetils.name &&
                <>
                    <Box display="flex" flexDirection="row" sx={headerPageStyle}>
                        <Box className="folderDetails">
                            <Box display="flex" alignItems="center" sx={svgStyle}>
                                <Text className="folderLabelStyle" as={Link} to="/admin/my-documents">Folders</Text>
                                <Icon as={RightArrowButtonIcon}/>
                                <Text className="foldersCountTitle">{folderDetils.name}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={documentsContainerStyle}>
                        <DocumentsTable projects={folderDetils.projects} folderDetails={folderDetils}/>
                    </Box>
                </>
            }
            
        </Flex>
    );
}

const folderDetailsStyle = {
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.simplebar-content': {
        overflowX: 'hidden',
    },
    '.simplebar-horizontal':{
        display: 'none'
    }
}

const documentsContainerStyle = {
    '@media (max-width: 1200px)': {
        paddingLeft: '15px',
        paddingRight: '15px',
        overflow: 'hidden'
    }
}

const headerPageStyle = {
    padding: '10px 0',
    marginBottom: '20px',
    '.folderLabelStyle': {
        color: '#1E5DFF',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 400,
        marginRight: '10px',
    },
    '.foldersCountTitle': {
        color: '#1B518F',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 700,
        marginRight: '10px',
        marginLeft: '10px'
    },
    '.totalProjectStyle': {
        color: '#9EC1DE',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px'
    },
    '@media (max-width: 1200px)': {
        padding: '10px 15px',
        marginBottom: '10px',
        '.foldersCountTitle': {
            fontSize: '14px'
        }
    }
}

const svgStyle = {
    'svg': {
        fill: '#9EC1DE !important',
        path: {
            fill: '#9EC1DE !important'
        }
    }
}

export default FolderDetails;
