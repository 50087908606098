import React from 'react';
import { Button, Text } from '@chakra-ui/react';


function RadioOption({ option, onClick, isActive}) {
    return (
        <Button sx={buttonStyle} onClick={() => onClick()}
            className={isActive ? 'active button-item' : 'button-item'}>
            <Text>{option.label}</Text>
        </Button>
    );
}

const buttonStyle = {
    height: '34px',
    lineHeight: '34px',
    minWidth: '75px',
    paddingLeft: '5px',
    paddingRight: '5px',
    textAlign: 'center',
    border: '0 none !important',
    'p': {
        fontSize: '14px',
        color: '#8599CD',
        textTransform: 'capitalize'
    },
    '&.active': {
        background: '#EAF5FF !important',
        'p': {
            fontWeight: 700
        }
    }
}



export default RadioOption;
