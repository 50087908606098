function FacebookAdsIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#F2F9FF"/>
            <path d="M13 8C13 5.23857 10.7614 3 8 3C5.23857 3 3 5.23857 3 8C3 10.4956 4.82844 12.5642 7.21875 12.9393V9.44531H5.94922V8H7.21875V6.89844C7.21875 5.64531 7.96521 4.95313 9.10732 4.95313C9.65438 4.95313 10.2266 5.05078 10.2266 5.05078V6.28125H9.59607C8.97496 6.28125 8.78125 6.66666 8.78125 7.06207V8H10.168L9.94629 9.44531H8.78125V12.9393C11.1716 12.5642 13 10.4956 13 8Z" fill="#1877F2"/>
            <path d="M9.94629 9.44531L10.168 8H8.78125V7.06207C8.78125 6.66666 8.97494 6.28125 9.59607 6.28125H10.2266V5.05078C10.2266 5.05078 9.65436 4.95312 9.10732 4.95312C7.96521 4.95312 7.21875 5.64531 7.21875 6.89844V8H5.94922V9.44531H7.21875V12.9393C7.4733 12.9792 7.73422 13 8 13C8.26578 13 8.52668 12.9792 8.78125 12.9393V9.44531H9.94629Z" fill="white"/>
        </svg>
    );
  }
  
export default FacebookAdsIcon;