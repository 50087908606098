function CreditCardIcon() {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.19318 11C1.53421 11 1 10.4404 1 9.75V2.25C1 1.55964 1.53421 1 2.19318 1H12.3068C12.9658 1 13.5 1.55964 13.5 2.25V9.75C13.5 10.4404 12.9658 11 12.3068 11H2.19318Z" stroke="#6D99BE" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 4.125H13.5" stroke="#6D99BE" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5 7.5625H7.25" stroke="#6D99BE" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}
  
export default CreditCardIcon;