import React, { useState, useEffect } from 'react';
import {
    Flex,
    Icon,
    Box,
    Checkbox,
    FormLabel,
    Text,
    Button,
    useToast
} from "@chakra-ui/react";
import api from '../../../utils/api.js';
import DocumentsTableItem from './document-tabe-item/DocumentTableItem.js';

import DeleteDocumentIcon from '../../../assets/images/DeleteDocumentIcon.js';
import DeleteConfirmationModal from '../../modals/delete-confirmation/DeleteConfirmationModal.js';
import { useDocumentContext } from '../../../contexts/DocumentContext.js';
import EmptyStateDocuments from './empty-state-documents/EmptyStateDocuments.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


function DocumentsTable({ projects: initialProjects, folderDetails }) {
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const toast = useToast();
    const { deleteDocument, fetchCountDocuments } = useDocumentContext();
    const [projects, setProjects] = useState(initialProjects);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAllDocsToggle = (e) => {
        if (e.target.checked) {
            setSelectedDocs(projects.map(p => p.id));
        } else {
            setSelectedDocs([]);
        }
    };

    const allDocsSelected = projects.length > 0 ? selectedDocs.length === projects.length : false;

    const handleDeleteModalOpen = (documentId) => {
        deleteDocument(documentId);
        setDocumentToDelete(documentId);
        setDeleteModalOpen(true);

    }

    const handleDelete = () => {
        if (documentToDelete) {
            setDeleteModalOpen(false);
            const updatedProjects = projects.filter(project => project.id !== documentToDelete);
            setProjects(updatedProjects);
            setDocumentToDelete(null);
        }
    }

    const handleDocumentRename = (updatedDocument) => {
        setProjects(prevDocuments =>
            prevDocuments.map(doc =>
                doc.id === updatedDocument.id ? updatedDocument : doc
            )
        );
    }

    const handleBulkDelete = async () => {
        try {
            const response = await api.post('/admin/project/delete-bulk-documents', {
                documentIds: selectedDocs
            });

            if (response.status === 200) {
                const updatedProjects = projects.filter(project => !selectedDocs.includes(project.id));
                setProjects(updatedProjects);
                fetchCountDocuments(folderDetails.id);
                setSelectedDocs([]);
                toast({
                    title: "Documents successfully deleted!",
                    description: "All your selected documents has been removed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                console.error("Error deleting documents:", response.data);
            }
        } catch (error) {
            console.error("Error deleting documents:", error);
        }
    }

    return (
        <>
            <Box sx={documentsTablePageStyle}>
                <Flex sx={topStructurePageStyle}>
                    <Box className="left">
                        <FormLabel className="selectAllContainer">
                            <Checkbox sx={checkboxStyle} isChecked={allDocsSelected} onChange={handleAllDocsToggle} />
                            <Text>Select All</Text>
                        </FormLabel>
                        {selectedDocs.length > 0 && (
                            <Button onClick={handleBulkDelete} sx={bulkActionsStyle}>
                                <Box display="flex" alignItems="center">
                                    <Icon as={DeleteDocumentIcon} />
                                    <Text style={bulkActionsApplyStyle}>Delete</Text>
                                </Box>
                            </Button>
                        )}
                    </Box>
                    <Box className="right">
                        {/* <Box sx={sortContainerStyle} className="actionContainer">
                            <Box className="iconContainer">
                                <Icon as={SortIcon} />
                                <Text>Sort</Text>
                            </Box>
                            <Box className="selectContainer">
                                <Select
                                    name="sortBy"
                                    styles={selectStyles}
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    options={sortOptions}
                                    onChange={(selectedOption) => {
                                        setSortType(selectedOption.value);
                                    }}
                                />
                            </Box>
                        </Box> */}
                    </Box>
                </Flex>
                {projects && projects.length > 0
                    ? <>
                        {isMobile
                            ? <Box>
                                {projects.map((project) => (
                                    <DocumentsTableItem
                                        key={project.id}
                                        documentItem={project}
                                        onRename={handleDocumentRename}
                                        onDelete={(documentId) => handleDeleteModalOpen(documentId)}
                                        selectedDocs={selectedDocs}
                                        setSelectedDocs={setSelectedDocs}
                                    />
                                ))}
                            </Box>
                            : <SimpleBar style={{ maxHeight: `calc(100vh - 180px` }}>
                                {projects.map((project) => (
                                    <DocumentsTableItem
                                        key={project.id}
                                        documentItem={project}
                                        onRename={handleDocumentRename}
                                        onDelete={(documentId) => handleDeleteModalOpen(documentId)}
                                        selectedDocs={selectedDocs}
                                        setSelectedDocs={setSelectedDocs}
                                    />
                                ))}
                            </SimpleBar>
                        }
                    </>
                    : <EmptyStateDocuments folderDetails={folderDetails} />
                }
            </Box>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={handleDelete}
                itemName="document"
            />
        </>
    );
}

const documentsTablePageStyle = {

}

const topStructurePageStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    '.left': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '.selectAllContainer': {
            position: 'relative',
            paddingLeft: '25px',
            marginRight: '5px',
            marginBottom: 0,
            'p': {
                color: '#8599CD',
                fontSize: '14px'
            }
        }
    }
}

const checkboxStyle = {
    position: 'absolute',
    left: '0',
    marginRight: '10px',
    width: '20px',
    height: '20px',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    '.chakra-checkbox__control': {
        width: '20px',
        height: '20px',
        _checked: {
            bg: '#1E5DFF',
            borderColor: '#1E5DFF',
            color: 'white',
        }
    },
    '@media (max-width: 1200px)': {
        width: '15px',
        height: '15px',
        '.chakra-checkbox__control': {
            width: '15px',
            height: '15px',
        }
    }
}

const bulkActionsStyle = {
    height: '34px',
    lineHeight: '34px',
    width: '95px',
    background: 'transparent',
    border: '2px solid #1E5DFF',
    'svg': {
        marginRight: '5px',
        'path': {
            stroke: '#1E5DFF'
        }
    }
}

const bulkActionsApplyStyle = {
    color: '#1E5DFF',
    fontWeight: 600,
    fontSize: '14px',
    marginRight: '10px'
}

export default DocumentsTable;
