import React, { useState, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Text,
    Heading
  } from '@chakra-ui/react';
import api from '../../../utils/api.js';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../contexts/UserContext.js';

function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const { userDataAndPlans } = useUserContext();

  useEffect(() => {
      fetchInvoices();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInvoices = async () => {
      try {
        const response = await api.post('/admin/payment/retrieve-invoices', {subscriptionId: userDataAndPlans.subscriptionId});
        const invoices = response.data;
        setInvoices(invoices.data);
        
    } catch (error) {
        console.error(error);
    }
  };

  return (
    <>
      {invoices && invoices.length > 0 &&
        <Box sx={invoicesContainerStyle}>
          <Box className="innerContainer">
            <Heading style={headingPageStyle} textAlign="center">Invoices</Heading>
            <Table variant="striped" colorScheme="#E1F2FF">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th>Amount</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoices.map((invoice) => (
                  <Tr key={invoice.id}>
                    <Td>{new Date(invoice.created * 1000).toLocaleDateString()}</Td>
                    <Td>{invoice.status === 'paid' 
                      ? <Text style={{color: 'green'}}>Paid</Text> 
                      : <Text style={{color: 'red'}}>Not Paid</Text>
                    }</Td>
                    <Td>${(invoice.amount_due / 100).toFixed(2)}</Td>
                    <Td>
                      <Link to={invoice.invoice_pdf} target="_blank">Download</Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      }
    </>
    
  );
}

const headingPageStyle = {
  fontSize: '26px',
  fontWeight: 700,
  lineHeight: '33px',
  marginBottom: '10px',
  color: '#1B518F'
}

const invoicesContainerStyle = {
  'table': {
    'tr': {
      'th': {
        fontSize: '14px',
        border: '0 none'
      },
      'td': {
        fontSize: '14px',
        border: '0 none',
        'a': {
          color: '#1E5DFF',
          fontWeight: 700
        }
      },
      '&:nth-child(2n+2)': {
        'td':{
          background: '#E1F2FF'
        }
      }
    }
  },
  '@media (max-width: 560px)': {
    overflow: 'auto',
    '.innerContainer': {
      width: '550px',
      overflow: 'hidden'
    }
  }
}

export default Invoices;
