// pages/ResetPasswordPage.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Box, Text, Heading, Button, FormControl, Icon, Input, useToast } from '@chakra-ui/react';
import RightArrowButtonIcon from '../assets/images/RightArrowButtonIcon.js';

function ResetPasswordPage() {
    const { register, handleSubmit } = useForm();
    const toast = useToast();
    const { token } = useParams();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        try {
            const response = await fetch("https://skelet.ai:5000/api/auth/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token, newPassword: data.password })
            });
            if (response.ok) {
                toast({
                    title: "Success",
                    description: "Password reset successful!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/login');
            } else {
                // handle error response
            }
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to reset password.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box sx={authBoxStyle}>
            <Text className="smallText">forgot password?</Text>
            <Heading className="pageHeading">Set new password</Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="handleSubmit">
                    <Input style={inputStyle} placeholder="New Password" type="password" {...register("password")} required />
                </FormControl>
                <Button sx={submitButtonStyle} type="submit">
                    <Box display="flex" alignItems="center">
                        <Text>Reset Password</Text>
                        <Icon as={RightArrowButtonIcon} />
                    </Box>
                </Button>
            </form>
            <Box className="bottomInformation">
                <Box className="authLinks">
                    <Link to="/login">Back to Login Page</Link>
                </Box>
            </Box>
        </Box>
    );
}


const inputStyle = {
    background: '#fff',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    height: '55px',
    lineHeight: '55px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
    color: '#6D99BE',
    marginBottom: '20px'
}

const submitButtonStyle = {
    background: '#1E5DFF',
    height: '55px',
    lineHeight: '55px',
    width: '100%',
    'p': {
        fontSize: '18px',
        lineHeight: '20px',
        color: '#fff',
        marginRight: '10px'
    }
}

const authBoxStyle = {
    maxWidth: '510px',
    padding: '40px 30px',
    background: '#293037',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    marginBottom: '40px',
    borderRadius: '4px',
    '.smallText': {
        color: '#98B3F8',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: '20px',
        textAlign: 'center',
    },
    '.pageHeading': {
        fontSize: '40px',
        lineHeight: '42px',
        marginBottom: '40px',
        color: '#fff',
        textAlign: 'center',
    },
    '.socialLogin': {
        background: 'transparent',
        height: '55px',
        lineHeight: '55px',
        border: '2px solid #1B518F',
        borderRadius: '4px',
        marginBottom: '30px',
        width: '100%',
        'p': {
            fontSize: '18px',
            fontWeight: 700,
            color: '#1B518F',
            marginLeft: '10px'
        }
    },
    '.normalLoginText': {
        textAlign: 'center',
        position: 'relative',
        justifyContent: 'center',
        marginBottom: '20px',
        'p': {
            fontSize: '12px',
            lineHeight: '14px',
            textTransform: 'uppercase',
            color: '#CFE0ED',
            background: '#F2F9FF',
            position: 'relative',
            zIndex: 2,
            display: 'inline-block',
            padding: '0 10px',
        },
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            height: '1px',
            background: '#CFE0ED',
            zIndex: 1
        }
    },
    '.bottomInformation': {
        paddingTop: '30px',
        '.forgotPasswordContainer': {
            paddingBottom: '20px',
            marginBottom: '20px',
            borderBottom: '1px solid #CFE0ED',
            textAlign: 'center',
            'a': {
                fontSize: '16px',
                color: '#1E5DFF',
                display: 'inline-block'
            }
        },
        '.authLinks': {
            textAlign: 'center',
            'p': {
                fontSize: '16px',
                color: '#98B3F8'
            },
            'a': {
                fontSize: '16px',
                color: '#98B3F8',
                fontWeight: 700
            }
        }
    },
    '@media all and (max-width: 580px)': {
        background: 'transparent',
        paddingLeft: '15px',
        paddingRight: '15px',
        '.normalLoginText': {
            'p': {
                background: '#121e2a'
            }
        }
    }
}

export default ResetPasswordPage;
