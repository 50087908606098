import React, { useEffect } from 'react';

import Select from 'react-select';
import { components } from "react-select";
import DropdownIcon from '../../assets/images/DropdownIcon.js';
import { Icon } from '@chakra-ui/react';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon as={DropdownIcon} sx={iconStyle} />
    </components.DropdownIndicator>
  );
};

const LanguageSelector = ({ label, onChange, defaultValue, setValue, user, ...rest }) => {
  // const { label, onChange, defaultValue, setValue, user, ...rest } = props;

  useEffect(() => {
    let defaultLang = 'English';
    if (user && user.defaultLanguage) {
      defaultLang = user.defaultLanguage;
    }
    setValue("language", defaultLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const avaiableLanguages = [
    { label: 'English', value: 'English' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'French', value: 'French' },
    { label: 'German', value: 'German' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Punjabi', value: 'Punjabi' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Bengali', value: 'Bengali' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Mandarin Chinese', value: 'Mandarin Chinese' },
    { label: 'Urdu', value: 'Urdu' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'Greek', value: 'Greek' },
  ]

  const languageOptions = avaiableLanguages.map(language => ({
    value: language.value,
    label: language.label
  }));




  return (
    <>
      <Select
        name="language"
        styles={selectStyles}
        isSearchable={true}
        components={{ DropdownIndicator }}
        options={languageOptions}
        defaultValue={{ label: user && user.defaultLanguage ? user.defaultLanguage : 'English', value: user && user.defaultLanguage ? user.defaultLanguage : 'English' }}
        onChange={selectedOption => {
          setValue("language", selectedOption);
        }}

      // value={defaultValue ? {label: defaultValue, value: defaultValue} : ''} // Adjust value prop to work with react-select

      />

    </>

  );
};

const iconStyle = {
  svg: {
    fill: '#1B518F'
  }
}

const selectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: '120px'
  }),
  control: (provided) => ({
    ...provided,
    padding: "0 10px 0px 10px",
    height: '31px',
    minHeight: '31px',
    border: '1px solid #CFD5E3',
    borderRadius: '4px',
    color: '#1B518F',
    fontSize: '14px',
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '9'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'relative',
    right: '-5px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0', // Adjust the padding values as desired
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9EC1DE',
    fontSize: '14px',
    fontWeight: 400
  }),
  // Add other styles or overrides if necessary
};


export default LanguageSelector;
