import React, { createContext, useContext, useState } from 'react';

import api from '../utils/api.js';
const TtsContext = createContext();

export const useTtsContext = () => {
    return useContext(TtsContext);
};

export const TtsProvider = ({ children }) => {
    const [ttsDetails, setTtsDetails] = useState();

    const fetchTtsDetails = async (documentId)  => {
        try {
            const response = await api.get(`/admin/text-to-speech/${documentId}`);
            setTtsDetails(response.data);
        } catch (error) {
            console.error("Error fetching the folder:", error);
        }
    }

    return (
        <TtsContext.Provider value={{ ttsDetails, fetchTtsDetails }}>
            {children}
        </TtsContext.Provider>
    );
}