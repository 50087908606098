import React, { useState, useEffect } from 'react';
import { useFolderContext } from '../../../contexts/FolderContext.js';
import { useAppContext } from '../../../contexts/AppContext.js';
import { useUserContext } from '../../../contexts/UserContext.js';
import { Flex, Box, Button, Icon, Heading, Text } from "@chakra-ui/react";
import { Link as NavLink } from 'react-router-dom';
import NoCreditsModal from '../../common/no-credits-modal/NoCreditsModal.js';
import CreateFolderModal from '../../folder/create-folder-modal/CreateFolderModal.js';
import AppLogo from '../../../assets/images/AppLogo.js';
import AiCHatIcon from '../../../assets/images/AiChatIcon.js';
import AiImageIcon from '../../../assets/images/AiImageIcon.js';
import AiContentIcon from '../../../assets/images/AiContentIcon.js';
import AiTextToSpeeach from '../../../assets/images/AiTextToSpeech.js';
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';
import OpenLinkIcon from '../../../assets/images/OpenLinkIcon.js';
import PlusThinIcon from '../../../assets/images/PlusThinIcon.js';

import MyProfileHeader from '../../header/my-profile-header/MyProfileHeader.js';

import { CloseIcon } from '@chakra-ui/icons'
import SidebarDefaultMenu from './sidebar-default-menu/SidebarDefaultMenu.js';

function Sidebar() {
  const { folders, defaultFolder } = useFolderContext();
  const { showNoCreditsModal, displayNoCreditsModal, mobileMenuOpen, openMobileMenu } = useAppContext();
  const { userDataAndPlans } = useUserContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    if (userDataAndPlans.wordsRemaining < 0) {
      displayNoCreditsModal(true);
    }
  }, [userDataAndPlans]);

  const hideSidebar = () => {
    const isSidebarOpen = document.documentElement.classList.contains('show-sidebar');
    if (isSidebarOpen) {
      document.documentElement.classList.remove('show-sidebar');
    }
  }

  return (
    <Box sx={sidebarMainStyle} className={mobileMenuOpen === true ? 'show-menu mainSidebarContainer' : 'mainSidebarContainer'} onMouseLeave={() => hideSidebar()}>
      {isMobile
        ? <Box className="topStructureMobile" display="flex" alignItems="center">
          <MyProfileHeader isMobile={true} />
          <Button onClick={() => openMobileMenu()} bg="transparent" p="0" className="closeButton">
            <Icon as={CloseIcon} />
          </Button>
        </Box>
        : <Box className="topContainer">
          <Box className="logoLink" as={NavLink} to="/admin/dashboard" display="block">
            <Box className="logoIcon">
              <AppLogo maxHeight="38px" />
              {/* <Text sx={temoporaryLogoStyle}>SKELET AI</Text> */}
            </Box>

            <Box className="backToHome">
              <Box className="iconContainer">
                <Icon as={CloseModalIcon} />
              </Box>
              <Text>Back to home</Text>
            </Box>
          </Box>
        </Box>
      }

      <Box sx={sidebarBlockStyle} mb="50px" className="secondContainerSidebar">
        <Heading className="blockHeading">Apps</Heading>
        <Box className="linkContainer">
          <NavLink to="/admin/chat">
            {/* {({ isActive, isPending }) => ( */}
            <Box sx={linkContainerStyle}>
              <Icon as={AiCHatIcon} className="icon" />
              <Text sx={buttonLabelStyle} className="linkLabel">AI Chat</Text>
            </Box>
            {/* )} */}
          </NavLink>
        </Box>
        <Box className="linkContainer">
          <NavLink to="/admin/new-project">
            {/* {({ isActive, isPending }) => ( */}
            <Box sx={linkContainerStyle}>
              <Icon as={AiContentIcon} className="icon" />
              <Text sx={buttonLabelStyle} className="linkLabel">AI Content</Text>
            </Box>
            {/* )} */}
          </NavLink>
        </Box>
        <Box className="linkContainer aiImageIcon">
          <NavLink to="/admin/ai-images">
            {/* {({ isActive, isPending }) => ( */}
            <Box sx={linkContainerStyle}>
              <Icon as={AiImageIcon} className="icon" />
              <Text sx={buttonLabelStyle} className="linkLabel">AI images</Text>
            </Box>
            {/* )} */}
          </NavLink>
        </Box>
        <Box className="linkContainer">
          <NavLink to="/admin/text-to-speech">
            {/* {({ isActive, isPending }) => ( */}
            <Box sx={linkContainerStyle}>
              <Icon as={AiTextToSpeeach} className="icon" />
              <Text sx={buttonLabelStyle} className="linkLabel">Text to Speech</Text>
            </Box>
            {/* )} */}
          </NavLink>
        </Box>
      </Box>
      <Box sx={sidebarBlockStyle} className="lastItem">
        <Heading className="blockHeading">
          <Flex w="100%" alignItems="center">
            <Text>Folders </Text>
            <Flex ml="auto">
              <Box>
                <Button className="viewAllDocs" as={NavLink} to="my-documents">
                  <Icon as={OpenLinkIcon} />
                </Button>
              </Box>
              <Box ml="10px">
                <CreateFolderModal buttonStyleProps={createNewFolderStyle} buttonLabel={<Icon as={PlusThinIcon} />} />
              </Box>
            </Flex>
          </Flex>

        </Heading>
        <SidebarDefaultMenu defaultFolderId={defaultFolder.id} initialName={defaultFolder.name} menu={folders} />

        {/* <SidebarMenu defaultFolderId={defaultFolder.id} initialName={defaultFolder.name} subMenu={folders}/> */}
      </Box>

      {/* <SimpleBar style={{maxHeight: 'calc(100vh - 140px)'}}> */}
      {/* <VStack align="start" spacing={4} pl="20px" pr="20px">
              <Box w="100%" mb="20px" sx={defaultFolderContainerStyle}>
                <HeadingTitleSidebar icon={WorkspaceLogo} title="Folders"  marginB="5px"/>
                
              </Box> 
              {isMobile && <RecentActivity isMobile={isMobile}/>}
              <Box w="100%">
                <HeadingTitleSidebar icon={HotIcon} title="Hot" marginB="15px"/>
                <HotTemplate />
              </Box>
              <Box w="100%" sx={allTemplatesContainerStyle}>
                <AllTemplatesSidebar style="component"/>
              </Box>
              
          </VStack> */}
      {/* </SimpleBar>
      <CreateButtonSidebar /> */}
      {showNoCreditsModal === true &&
        <NoCreditsModal />
      }
    </Box>
  );
}

const createNewFolderStyle = {
  border: '1px solid #133761',
  borderRadius: '4px',
  width: '34px',
  height: '34px',
  background: 'transparent',
  minWidth: '1px',
  padding: 0,
  marginRight: 0,
  '&:hover': {
    background: '#133761'
  }
}
const sidebarBlockStyle = {
  marginBottom: '20px',
  marginLeft: '-20px',
  marginRight: '-20px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '20px',
  borderBottom: '1px solid #133761',
  '&.lastItem': {
    marginBottom: 0,
    paddingBottom: 0,
    borderBottom: '0 none'
  },
  '.blockHeading': {
    color: '#8599CD',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.linkContainer': {
    textAlign: 'left',
    justifyContent: 'left',
    display: 'flex',
    marginBottom: '10px'
  },
  '.aiImageIcon': {
    'a': {
      marginLeft: '-1px'
    }
  },
  '.viewAllDocs': {
    width: '34px',
    height: '34px',
    borderRadius: '4px',
    position: 'relative',
    background: 'transparent',
    border: '1px solid #133761',
    '&:hover': {
      background: '#133761'
    },
    'svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      webKitTransform: 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    }

  },
  '@media all and (max-width: 1200px)': {
    marginLeft: '-15px',
    marginRight: '-15px',
    paddingLeft: '15px',
    paddingRight: '15px',
  }
}

const linkContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.linkLabel': {
    color: '#9EC1DE',
    fontWeight: 600,
    textTransform: 'uppercase'
  }
}

const buttonLabelStyle = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  marginLeft: '10px',
  color: '#fff'
}

const sidebarMainStyle = {
  width: '345px',
  height: '100vh',
  background: '#06172B',
  color: '#1B518F',
  padding: '20px',
  position: 'fixed',
  left: '-345px',
  top: 0,
  bottom: 0,
  zIndex: 999,
  '.topStructureMobile': {
    marginBottom: '10px',
    '.closeButton': {
      position: 'relative',
      right: '-20px',
      top: '-5px',
      'svg': {
        width: '25px',
        'path': {
          fill: '#fff'
        }
      }
    }
  },
  '.topContainer': {
    margin: '-20px',
    padding: '10px 20px',
    borderBottom: '1px solid #133761',
    marginBottom: '20px',
    '.backToHome': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '.iconContainer': {
        width: '34px',
        height: '34px',
        borderRadius: '4px',
        border: '1px solid #1E5DFF',
        position: 'relative',
        'svg': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          '.stroke': {
            stroke: '#1E5DFF',
          },
          '.fill': {
            fill: '#1E5DFF',
          }
        }
      },
      'p': {
        fontSize: '16px',
        lineHeight: '18px',
        color: '#fff',
        paddingLeft: '10px'
      }
    }
  },
  '.simplebar-content-wrapper': {
    overflowX: 'hidden',
  },
  '@media (max-width: 1200px)': {
    position: 'fixed',
    width: '100%',
    left: '-100%',
    top: 0,
    bottom: 0,
    zIndex: '9999',
    transition: 'all 0.3s ease',
    webkitTransition: 'all 0.3s ease',
    height: '100%',
    '&.show-menu': {
      left: 0
    }
  },
}

export default Sidebar;
