import React, { forwardRef } from 'react';
import Select from 'react-select';
import { Box, FormLabel, Icon } from '@chakra-ui/react';
import DropdownIcon from '../../assets/images/DropdownIcon.js';
import { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon as={DropdownIcon} sx={iconStyle}/>
    </components.DropdownIndicator>
  );
};
function SearchableSelect({ isSearchable = true, label, options, onChange, value, name, ...rest }, ref) {

  return (
    <Box position="relative">
      <FormLabel style={inputLabelStyle}>
        {label}
      </FormLabel>
      <Select
        name={name}
        styles={selectStyles}
        isSearchable={isSearchable}
        components={{ DropdownIndicator }}
        options={options}
        defaultValue={value}
        onChange={(selectedOption) => {
          // Propagate changes to RHF
          if (onChange) onChange(selectedOption.value);

          // Propagate changes to the outer component
          if (rest.onChange) rest.onChange(selectedOption);
        }}
        value={options.find(option => option.value === value)} // Adjust value prop to work with react-select
        ref={ref}
        {...rest}
      />
    </Box>
  );
}

const iconStyle = {
  svg: {
    fill: '#1B518F'
  }
}

const inputLabelStyle = {
  position: "absolute",
  top: "10px",
  left: "10px",
  px: "0",
  py: "0",
  bg: "transparent",
  zIndex: "1",
  textTransform: 'uppercase',
  color: '#1B518F',
  fontSize: '10px',
  lineHeight: '12px',
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    padding: "15px 10px 0px 10px",
    height: '55px',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    color: '#1B518F',
    fontSize: '14px',
    fontWeight: 600,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '9'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'relative',
    top: '-5px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0', // Adjust the padding values as desired
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9EC1DE',
    fontSize: '14px',
    fontWeight: 400
  }),
  // Add other styles or overrides if necessary
};

export default forwardRef(SearchableSelect);
