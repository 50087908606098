import React from 'react';
import { FormControl, FormLabel, Input, Box } from '@chakra-ui/react';

const InputDefault = React.forwardRef((props, ref) => {
    const { label, onChange, defaultValue, ...rest } = props;

    return (
        <Box>
            <FormControl position="relative">
                <FormLabel style={inputLabelStyle}>
                    {label}
                </FormLabel>
                <Input 
                    ref={ref} 
                    style={inputStyle} 
                    onChange={onChange} 
                    defaultValue={defaultValue} 
                    {...rest}
                />
            </FormControl>
        </Box>
    );
});

const inputLabelStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
    px: "0",
    py: "0",
    bg: "transparent",
    textTransform: 'uppercase',
    color: '#1B518F',
    fontSize: '10px',
    lineHeight: '12px',
    zIndex: 9
};

const inputStyle = {
    padding: "15px 10px 0px 10px",
    height: '55px',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    color: '#1B518F',
    fontSize: '14px',
    fontWeight: 600
};

export default InputDefault;
