import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function CustomHeading({ text }) {

    return (
        <Box sx={customHeadingStyle}>
            <Heading dangerouslySetInnerHTML={{ __html: text }}></Heading>
        </Box>
    )
}

const customHeadingStyle = {
    'h2': {
        fontSize: '64px',
        lineHeight: '70px',
        fontWeight: 700,
        backgroundClip: 'text',
        color: 'transparent',
        backgroundImage: 'linear-gradient(96.16deg, #1408A5 15%, #1E5DFF 54.84%, #DA00FE 90.64%)'
    },
    '@media all and (max-width: 640px)': {
        'h2': {
            fontSize: '40px',
            lineHeight: '44px',
        }
    }
}


export default CustomHeading;