import { PROMPTS } from './promptsConfig.js';

function getPrompts(template, extraFields, defaultLanguage){    
    console.log('extraFieldsextraFieldsextraFields', extraFields);
    const replaceData = (content) => {
        const constructedPrompt = content
        .replace(/{title}/g, extraFields.title)
        .replace(/{goal}/g, (extraFields.goal !== "") ? extraFields.goal : '')
        .replace(/{length}/g, (extraFields.length !== "") ? extraFields.length : '')
        .replace(/{tweetFormat}/g, (extraFields.tweetFormat !== "") ? extraFields.tweetFormat : '')
        .replace(/{wordCount}/g, (extraFields.wordCount !== "") ? extraFields.wordCount : '')
        .replace(/{words7}/g, (extraFields.wordCount !== "") ? extraFields.wordCount / 7 : '')
        
        .replace(/{newsletterType}/g, (extraFields.newsletterType !== "") ? extraFields.newsletterType : '')
        .replace(/{keyFeatures}/g, (extraFields.keyFeatures !== "") ? extraFields.keyFeatures : '')
        .replace(/{healthConditionsAndAllergies}/g, (extraFields.healthConditionsAndAllergies !== "") ? extraFields.healthConditionsAndAllergies : '')
        .replace(/{activityLevel}/g, (extraFields.activityLevel !== "") ? extraFields.activityLevel : '')
        .replace(/{age}/g, (extraFields.age !== "") ? extraFields.age : '')
        .replace(/{duration}/g, (extraFields.duration !== "") ? extraFields.duration : '')
        .replace(/{weight}/g, (extraFields.weight !== "") ? extraFields.weight : '')
        .replace(/{height}/g, (extraFields.height !== "") ? extraFields.height : '')
        .replace(/{location}/g, (extraFields.location !== "") ? extraFields.location : '')
        .replace(/{currentIncome}/g, (extraFields.currentIncome !== "") ? extraFields.currentIncome : '')
        .replace(/{monthlyExpenses}/g, (extraFields.monthlyExpenses !== "") ? extraFields.monthlyExpenses : '')
        .replace(/{currentDebt}/g, (extraFields.currentDebt !== "") ? extraFields.currentDebt : '')
        .replace(/{currentSavings}/g, (extraFields.currentSavings !== "") ? extraFields.currentSavings : '')
        .replace(/{financialGoals}/g, (extraFields.financialGoals !== "") ? extraFields.financialGoals : '')
        .replace(/{riskTolerance}/g, (extraFields.riskTolerance !== "") ? extraFields.riskTolerance : '')
        .replace(/{investmentPreferences}/g, (extraFields.investmentPreferences !== "") ? extraFields.investmentPreferences : '')
        .replace(/{financialConcerns}/g, (extraFields.financialConcerns !== "") ? extraFields.financialConcerns : '')
        .replace(/{creditScore}/g, (extraFields.creditScore !== "") ? extraFields.creditScore : '')
        .replace(/{keyword}/g, (extraFields.keyword !== "") ? extraFields.keyword : '')
        
        .replace(/{genre}/g, (extraFields.genre !== "") ? extraFields.genre : '')
        .replace(/{gender}/g, (extraFields.gender !== "") ? extraFields.gender : '')
        .replace(/{tone}/g, (extraFields.tone !== "") ? extraFields.tone : 'Friendly')
        .replace(/{numberOfScenes}/g, (extraFields.numberOfScenes !== "") ? extraFields.numberOfScenes : '3')
        .replace(/{targetAudience}/g, (extraFields.targetAudience !== "") ? extraFields.targetAudience : 'general');

        return constructedPrompt;
    }

    const systemPromptTemplate = replaceData(PROMPTS[template].system);
    const userPromptTemplate = replaceData(PROMPTS[template].user);
    const language = (defaultLanguage.value) ? defaultLanguage.value : defaultLanguage;


    return [
        {role: 'system', content: systemPromptTemplate},
        {role: 'user', content: userPromptTemplate + ` The output should be in this language: ${language}, Also should be unique, don't make something similar with the other responses if any.`}
    ];
        
        
}
export default getPrompts;