import React, { createContext, useContext, useState } from 'react';

import api from '../utils/api.js';
import { useProjectContext } from './ProjectContext.js';
const DocumentContext = createContext();

export const useDocumentContext = () => {
    return useContext(DocumentContext);
};

export const DocumentProvider = ({ children }) => {
    const { projects, setProjects } = useProjectContext();
    const [countDocuments, setCountDocuments] = useState(0);
    
    const renameDocument = async (documentId, newName) => {
        try {
            const response = await api.put(`/admin/my-documents/${documentId}/rename`, { name: newName });
            
            // Update the folders list in the state after renaming
            setProjects(prevDocuments => prevDocuments.map(document => 
                document.id === documentId ? { ...document, name: newName } : document
            ));
            
            return response.data;
        } catch (error) {
            console.error("Error renaming the folder:", error);
        }
    }
    const deleteDocument = async (documentId) => {
        try {
            await api.post(`/admin/my-documents/${documentId}/delete`);
            // After deleting, remove the folder from the state.
            const updatedProjects = projects.filter(project => project.id !== documentId);
            setProjects(updatedProjects);
        } catch (error) {
            console.error("Failed to delete folder:", error);
        }
    };

    const fetchCountDocuments = async (documentId) => {
        try {
            const response = await api.post(`/admin/my-documents/${documentId}/count-documents`);
            console.log(response.data);
            // After deleting, remove the folder from the state.
            // const updatedProjects = projects.filter(project => project.id !== documentId);
            setCountDocuments(response.data.count);
        } catch (error) {
            console.error('Failed to count documents', error);
        }
    }

    return (
        <DocumentContext.Provider value={{ renameDocument, deleteDocument, countDocuments, fetchCountDocuments }}>
            {children}
        </DocumentContext.Provider> 
    );
}