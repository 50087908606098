import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, Icon } from '@chakra-ui/react'
import RightArrowButtonIcon from '../../assets/images/RightArrowButtonIcon.js';

const SuccessPayment = (props) => {
    const navigate = useNavigate();
    return (
        <Box sx={paymentContainerStyle}>
            <svg width="60" height="50" viewBox="0 0 60 50" fill="none">
                <path d="M13.0591 37.7056H18.7061" stroke="#1E5DFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                <path d="M25.7646 37.7056H37.0588" stroke="#1E5DFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                <path d="M1.76465 15.1177H34.2352" stroke="#1E5DFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                <path d="M58.2352 22.2612V36.6047C58.2352 46.5153 55.7223 49 45.6988 49H14.3011C4.27759 49 1.76465 46.5153 1.76465 36.6047V13.3953C1.76465 3.48471 4.27759 1 14.3011 1H34.2352" stroke="#1E5DFF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
                <path d="M42.7061 8.05882L46.9413 12.2941L58.2355 1" stroke="#1E5DFF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
            </svg>

            <Heading className="headingStyle">Payment successfull.</Heading>
            <Text className="textStyle">Thank you, enjoy and create!</Text>
            <Button onClick={() => navigate('/admin/dashboard')}>
                <Box display="flex" alignItems="center">
                    <Text className="buttonTextStye">Start creating</Text>
                    <Icon as={RightArrowButtonIcon} />
                </Box>
            </Button>
        </Box>  
    );
};

const paymentContainerStyle = {
    maxWidth: '940px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
    textAlign: 'center',
    marginTop: '80px',
    paddingTop: '90px',
    paddingBottom: '90px',
    'svg': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '20px',
    },
    '.headingStyle': {
        fontSize: '26px',
        lineHeight: '30px',
        color: '#1B518F',
        marginBottom: '10px',
    },
    '.textStyle': {
        color: '#1B518F',
        fontSize: '14px',
        lineHeight: '16px',
        marginBottom: '40px'
    },
    'button': {
        height: '55px',
        lineHeight: '55px',
        border: '1px solid #1E5DFF',
        borderRadius: '4px',
        background: 'transparent',
        '.buttonTextStye': {
            fontSize: '14px',
            color: '#1E5DFF',
            fontWeight: 600,
            marginRight: '10px'
        },
        'svg': {
            margin: 0,
            'path': {
                fill: '#1E5DFF'
            }
        }
    },
    '@media (max-width: 1200px)': {
        marginTop: '1px',
        padding: '20px'
    }
}

export default SuccessPayment;
