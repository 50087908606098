import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

function SpinnerLoader({ subMenu, clickHandle }) {

    return (
        <Box sx={spinnerContainerStyle}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </Box>
    )
}

const spinnerContainerStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(246, 251, 255, 0.5)',
    zIndex: 9,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}


export default SpinnerLoader;