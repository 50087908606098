import React from 'react';
import {
    Box, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, AccordionIcon,
} from "@chakra-ui/react";
import { useAppContext } from '../../../../contexts/AppContext.js';
import TemplateMenuItems from '../template-menu-items/TemplateMenuItems.js';

function AllTemplatesSidebar({style}) {
    const {availableContentTemplates} = useAppContext();
    return (
        <>
            {availableContentTemplates.categories && (
                <Accordion allowMultiple defaultIndex={[0]} sx={style === 'page' ? allTemplatesPageStyle : ''}>
                    {availableContentTemplates.categories.map(category => (
                        <AccordionItem key={category.name} border="0" className={style === 'page' ? 'pageStyle': 'componentStyle'}>
                            {({ isExpanded }) => (
                                <>
                                    <AccordionButton border="0" p="0" className={isExpanded ? 'expanded' : ''} sx={buttonContainerStyle}>
                                        <Box flex="1" textAlign="left" style={categoryNameStyle} className="categoryName">
                                            {category.name}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel p="0">
                                        <Box>
                                            <TemplateMenuItems menuItems={category.templates}/>
                                        </Box>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
            )}
        </>
        
    );
}

const allTemplatesPageStyle = {
    '.pageStyle': {
        'button':{
            background: '#fff',
            padding: '10px',
            borderRadius: '4px',
            '.categoryName': {
                fontSize: '14px !important',
                fontWeight: 500
            }
        },
    }
}

const buttonContainerStyle = {
    '.categoryName': {
        color:'#0D78F5'
    },
    'svg': {
        'path': {
            fill: '#0D78F5'
        }
    },
    '&:hover':{
        background: 'transparent'
    },
    '&.expanded': {
        '.categoryName': {
            color: '#9EC1DE'
        },
        'svg': {
            'path':{
                fill: '#9EC1DE'
            }
        }
    },
}

const categoryNameStyle = {
    fontSize: '11px',
    lineHeight:'12px',
    border:0,
    textTransform:'uppercase'
}

export default AllTemplatesSidebar;
