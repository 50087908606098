import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Text,
    Icon,
} from "@chakra-ui/react";
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';

function DefaultModal({ buttonStyleProps, modalTitle, buttonLabel, onSubmit, formFields, isOpen, setIsOpen }) {

    const {  onClose } = useDisclosure();

    

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => setIsOpen()} >
                <ModalOverlay />
                <ModalContent sx={createFolderModalStyle}>
                    <ModalHeader className="modalHeaderStyle">
                        <Box display="flex" alignItems="center">
                            <Text className="newFolderHeadingStyle">{modalTitle}</Text>
                        </Box>
                        <ModalCloseButton sx={closeButtonStyle} onClick={onClose}>
                            <CloseModalIcon className="iconContainer" />
                        </ModalCloseButton>
                    </ModalHeader>

                    <ModalBody style={bodyStyle}>
                        {formFields}
                    </ModalBody>
                    <ModalFooter sx={footerStyle}>
                        <Button className="submitButton" onClick={() => onSubmit()}>
                            <Text className="createButtonLabelStyle">Submit</Text>
                            <Icon as={RightArrowButtonIcon} />
                        </Button>
                        <Button variant="ghost" className="cancelButtonStyle" onClick={() => setIsOpen()}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const createFolderModalStyle = {
    width: '530px',
    '.modalHeaderStyle': {
        position: "relative",
        padding: "30px 20px",
        borderBottom: "1px solid #E1F2FF",
        marginBottom: "40px",
        '.newFolderHeadingStyle': {
            fontSize: '22px',
            lineHeight: '24px',
            color: '#1B518F',
            marginLeft: '10px'
        }
    },
    '@media (max-width: 560px)': {
        width: '95%',
        '.modalHeaderStyle': {
            padding: "15px 20px",
            marginBottom: "20px",
            '.newFolderHeadingStyle': {
                fontSize: '16px',
                lineHeight: '20px',
            }
        },
    }
}

const footerStyle = {
    flexDirection: 'column',
    '.submitButton': {
        height: '55px',
        lineHeight: '55px',
        display: 'flex',
        alignItems: 'center',
        background: '#1E5DFF',
        borderRadius: '4px',
        width: '100%',
        marginBottom: '20px',
        '.createButtonLabelStyle': {
            fontSize: '18px',
            fontWeight: 700,
            color: '#fff',
            marginRight: '10px',

        }
    },
    '.cancelButtonStyle': {
        fontSize: '18px',
        height: '55px',
        lineHeight: '55px',
        color: '#1E5DFF',
        background: '#F6FBFF',
        borderRadius: '4px',
        width: '100%'
    },
    '@media (max-width: 1200px)': {
        '.submitButton': {
            height: '40px',
            lineHeight: '40px',
            fontSize: '16px'
        },
        '.cancelButtonStyle': {
            height: '40px',
            lineHeight: '40px',
            fontSize: '16px'
        },
    }
}

const bodyStyle = {
    padding: '0 20px 0 20px',

}

const closeButtonStyle = {
    width: '34px',
    height: '34px',
    borderRadius: '34px',
    border: '1px solid #6D99BE',
    position: 'absolute',
    right: '20px',
    top: '50%',
    marginTop: '-12px',
    'svg': {
        margin: 0,
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginTop: '-6px',
        marginLeft: '-6px'
    }
}

export default DefaultModal;
