import React from 'react';

import { Link, Grid, Box } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import { TEMPLATE_ICONS } from '../../../../assets/images/templates/TemplatesImage.js';

function TemplateMenuItems(props) {
    const {menuItems} = props;
    return (  
        <>
            {menuItems && (
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={4} mb="40px">
                    {menuItems.map(template => {
                        const SvgIcon = TEMPLATE_ICONS[template.type] || null; 
                        return (
                            <Box key={template.type} alignItems="center">
                            
                                <Box sx={innerContainerStyle}>
                                    <Box className="iconContainer">
                                        {SvgIcon}
                                    </Box>
                                    <Link as={RouterLink} to={`/admin/new-project?type=${template.type}`} style={hotTemplateLinkStyle}>{template.name}</Link>
                                </Box>
                            
                            </Box>
                        );
                    })}
                </Grid>
            )}
        </>
    );
}
const innerContainerStyle = {
    background:'#fff',
    border: '1px solid #CFD5E3',
    display: 'flex',
    flexDirection: 'row', 
    padding:'5px 15px',
    borderRadius: '4px',
    position:'relative',
    cursor: 'pointer',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    '.iconContainer': {
        marginRight: '10px',
        svg: {
        width: '42px',
        height: '42px',
        }
    },
    '&:hover': {
        background: '#CFD5E3'
    }
}

const hotTemplateLinkStyle = {
    fontSize:'14px',
    fontWeight:'600',
    lineHeight:'16px',
    color:'#1B518F',
    textDecoration: 'none'
}
export default TemplateMenuItems;
