import React from 'react';
import api from '../../../utils/api.js';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Icon,
  useToast
} from "@chakra-ui/react";
import { useFolderContext } from '../../../contexts/FolderContext.js';
import { useAppContext } from '../../../contexts/AppContext.js';
import InputDefault from '../../form/InputDefault.js';
import { useForm } from "react-hook-form";
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';

function CreateFolderModal({ buttonStyleProps, buttonLabel }) {
  const { mobileMenuOpen, openMobileMenu } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm();
  const { addFolder, changeDefaultFolder, fetchDocumentsByDefaultFolder } = useFolderContext();
  const toast = useToast();
  
  const onSubmit = async (data) => {
    try {
      const response = await api.post('https://skelet.ai:5000/api/admin/folder/create', data);
      const newFolder = response.data;
      addFolder(newFolder);
      await changeDefaultFolder(newFolder.id);
      fetchDocumentsByDefaultFolder();
      toast({
        title: "Folder has been created.",
        description: `Your workspace is set to folder: ${newFolder.name}.`,
        status: "success",
        duration: 6000,
        isClosable: true,
    });
      onClose();
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleOnClick = () => {
    onOpen();
    if (window.innerWidth <= 1200 && mobileMenuOpen === true) {
      openMobileMenu(false);
    }
  }

  return (
    <>
      <Button onClick={() => handleOnClick()} sx={buttonStyleProps} className="openCreateFolderModal">{buttonLabel}</Button>

      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent sx={createFolderModalStyle}>
          <ModalHeader className="modalHeaderStyle">
            <Box display="flex" alignItems="center">
              <svg width="20" height="17" viewBox="0 0 20 17" fill="none">
                <path d="M18.6538 15.5227L11.0385 3.72727M11.0385 3.72727L9.30769 1H7.57692H2.38462C1.62308 1 1 1.61364 1 2.36364V14.6364C1 15.3864 1.62308 16 2.38462 16H17.6154C18.3769 16 19 15.3864 19 14.6364V5.09091C19 4.34091 18.3769 3.72727 17.6154 3.72727H11.0385Z" stroke="#1B518F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <Text className="newFolderHeadingStyle">Create a New Folder</Text>
            </Box>
            <ModalCloseButton sx={closeButtonStyle}>
              <CloseModalIcon className="iconContainer" />
            </ModalCloseButton>
          </ModalHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody style={bodyStyle}>
              <Text style={headingStyle}>Keep your documents organized</Text>
              <InputDefault label="Name your folder" placeholder="Folder name" {...register("folderName", { required: true })} />
            </ModalBody>

            <ModalFooter sx={footerStyle}>
              <Button type="submit" className="submitButton">
                <Text className="createButtonLabelStyle">Create folder</Text>
                <Icon as={RightArrowButtonIcon} />
              </Button>
              <Button variant="ghost" className="cancelButtonStyle" onClick={onClose}>Cancel</Button>

            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

const createFolderModalStyle = {
  width: '530px',
  '.modalHeaderStyle': {
    position: "relative",
    padding: "30px 20px",
    borderBottom: "1px solid #E1F2FF",
    marginBottom: "40px",
    '.newFolderHeadingStyle': {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#1B518F',
      marginLeft: '10px'
    }
  },
  '@media (max-width: 560px)': {
    width: '95%',
    '.modalHeaderStyle': {
      padding: "15px 20px",
      marginBottom: "20px",
      '.newFolderHeadingStyle': {
        fontSize: '16px',
        lineHeight: '20px',
      }
    },
  }
}

const footerStyle = {
  flexDirection: 'column',
  '.submitButton': {
    height: '55px',
    lineHeight: '55px',
    display: 'flex',
    alignItems: 'center',
    background: '#1E5DFF',
    borderRadius: '4px',
    width: '100%',
    marginBottom: '20px',
    '.createButtonLabelStyle': {
      fontSize: '18px',
      fontWeight: 700,
      color: '#fff',
      marginRight: '10px',

    }
  },
  '.cancelButtonStyle': {
    fontSize: '18px',
    height: '55px',
    lineHeight: '55px',
    color: '#1E5DFF',
    background: '#F6FBFF',
    borderRadius: '4px',
    width: '100%'
  },
  '@media (max-width: 1200px)': {
    '.submitButton': {
      height: '40px',
      lineHeight: '40px',
      fontSize: '16px'
    },
    '.cancelButtonStyle': {
      height: '40px',
      lineHeight: '40px',
      fontSize: '16px'
    },
  }
}

const bodyStyle = {
  padding: '0 20px 0 20px',

}

const closeButtonStyle = {

  width: '34px',
  height: '34px',
  borderRadius: '34px',
  border: '1px solid #6D99BE',
  position: 'absolute',
  right: '20px',
  top: '50%',
  marginTop: '-12px',
  'svg': {
    margin: 0,
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: '-6px',
    marginLeft: '-6px'
  }
}

const headingStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  color: '#1B518F',
  marginBottom: '20px'
}

export default CreateFolderModal;
