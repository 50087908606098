import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

function MyAccountNavigation({ match }) {

    return (
        <Box sx={customHeaderStyle}>
            <Box>
                <NavLink to="/admin/my-account/edit-profile">
                {({ isActive, isPending }) => (
                    <Box sx={isActive ? activeLinkContainerStyle : linkContainerStyle}>
                        <Text className="linkLabel">My profile</Text>
                    </Box>
                )}
                </NavLink>
            </Box>
            <Box ml="20px">
                <NavLink to="/admin/my-account/billing" ml="20px">
                {({ isActive, isPending }) => (
                    <Box sx={isActive ? activeLinkContainerStyle : linkContainerStyle}>
                        <Text className="linkLabel">Subscriptions & billing</Text>
                    </Box>
                )}
                </NavLink>
            </Box>
        </Box>
    );
}
const linkContainerStyle = {
    color: '#1E5DFF',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    position: 'relative',    
}

const activeLinkContainerStyle = {
    ...linkContainerStyle,
    '.linkLabel': {
      color: '#1B518F',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '-20px', 
      height: '3px',
      background: '#1B518F',
      borderRadius: '5px',
    }
    
  }

const customHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '20px 40px',
  color: '#E1F2FF',
  borderBottom: '1px solid #E1F2FF',
  '@media (max-width: 1200px)': {
    justifyContent: 'center'
  },
}


export default MyAccountNavigation;
