function ClockIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="#9EC1DE" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 3.3999V6.9999L9.4 8.1999" stroke="#9EC1DE" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
  }
  
  export default ClockIcon;