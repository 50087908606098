import React, { useState, useEffect } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';

import TitleWithIcon from '../../../common/frontend/title-with-icon/TitleWithIcon.js';
import Heading from '../../../common/frontend/heading/Heading.js';
import Description from '../../../common/frontend/description/Description.js';
import Button from '../../../common/frontend/button/Button.js';
import List from '../../../common/frontend/list/List.js';
import AiChatWhiteBgIcon from '../../../../assets/images/templates/AiChatWhiteBgIcon.js';
import FirstImage from '../../../../assets/images/homepage/FirstSectionImg1.png';
import SecondImage from '../../../../assets/images/homepage/FirstSectionImg2.png';

function FirstSection() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const list = [
        { text: 'Chat GPT4' },
        { text: 'image recongnition' },
        // { text: 'internet access' },
        { text: 'available in 80+ languages' },
        { text: 'image retention' },
    ]



    return (
        <Flex sx={firstSectionStyle}>
            <Box className="leftBox">
                <Box className="mobileTopImage">
                    <Image src={FirstImage} />
                </Box>
                <TitleWithIcon icon={AiChatWhiteBgIcon} text="Conversational ai" />
                <Box className="headingContainer">
                    <Heading text="Conversational <br/>brilliance <br/>unleashed." />
                </Box>
                {!isMobile &&
                    <Image src={SecondImage} />
                }

            </Box>
            <Box className="rightBox">
                <Box className="firstImageContainer">
                    <Image src={FirstImage} />
                </Box>
                <Description text="Your AI-powered chat assistant, ready to <br/>engage, assist, and make every interaction <br/>meaningful. Conversations that feel human, <br/>powered by the efficiency of AI." />
                {isMobile &&
                    <Box className="chatImageMobile">
                        <Image src={SecondImage} />
                    </Box>
                }
                {!isMobile &&
                    <Box className="buttonContainer">
                        <Button label={'START A CONVERSATION'} />
                    </Box>
                }

                <List list={list} />
                {isMobile &&
                    <Box className="buttonContainer" mt="25px">
                        <Button label={'START A CONVERSATION'} />
                    </Box>
                }
            </Box>
        </Flex>
    )
}

const firstSectionStyle = {
    '.leftBox': {
        flex: '0 0 50%'
    },
    '.rightBox': {
        flex: '0 0 50%',
        paddingTop: '30px',
        '.chatImageMobile': {

        },
        '.buttonContainer': {
            marginBottom: '55px'
        }
    },
    '.headingContainer': {
        marginBottom: '100px',
    },
    '.firstImageContainer': {
        marginBottom: '80px'
    },
    '.mobileTopImage': {
        display: 'none',
        marginBottom: '40px',
    },
    '@media all and (max-width: 800px)': {
        flexWrap: 'wrap',
        '.mobileTopImage': {
            display: 'block'
        },
        '.headingContainer': {
            marginBottom: '20px',
        },
        '.firstImageContainer': {
            marginBottom: '20px',
            display: 'none',
        },
        '.leftBox': {
            flex: '0 0 100%',
            marginBottom: '20px'
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingTop: 0,
            '.buttonContainer': {
                marginBottom: '30px'
            }
        },
    },
    '@media all and (max-width: 420px)': {
        '.rightBox': {
            '.descriptionContainer': {
                'br': {
                    display: 'none'
                }
            }
        },
    }
}


export default FirstSection;