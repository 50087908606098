import React from 'react';
import { Flex } from '@chakra-ui/react';
import DetailsItem from './DetailsItem/DetailsItem.js';

function Details({details}) {
    return (
        <Flex sx={groupStyle}>
            {details && details.allTts.length > 0 &&
                <>
                    {details.allTts.map((item, key) => (
                        <DetailsItem key={key} itemDetails={item}/>
                    ))}
                </>
            }
        </Flex>
    );
}

const groupStyle = {
    paddingTop: '40px',
    flexDirection: 'column',
}

export default Details;
