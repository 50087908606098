import React from 'react';
import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import CheckGreenIcon from '../../../../assets/images/CheckGreenIcon.js';

function List({ list }) {
    return (
        <Flex sx={listContainerStyle}>
            {list && list.length &&
                <>
                    {list.map((item, key) => (
                        <Box key={key} className="listItem">
                            <Icon as={CheckGreenIcon} />
                            <Text>{item.text}</Text>
                        </Box>
                    ))}
                </>
            }
        </Flex>
    )
}

const listContainerStyle = {
    flexDirection: 'column',
    '.listItem': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        'svg': {
            marginRight: '10px',   
        },
        'p': {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: '#98B3F8'
        },
        '&:last-child': {
            marginBottom: 0
        }
    }
}


export default List;