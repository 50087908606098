import React, { useState } from 'react';
import api from '../../../../utils/api.js';
import InputDefault from '../../../../components/form/InputDefault.js';
import LockIcon from '../../../../assets/images/LockIcon.js';
import { Box, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useToast, useDisclosure } from "@chakra-ui/react";

function ChangePassword() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();

  const onSubmit = async (password) => {
    try {
      const response = await api.post('/admin/my-account/profile/update-password', { password });
      if (response.data.success) {
        toast({
          title: "Password Updated",
          description: "Your password has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: "Failed to update password.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "Password does not match",
        description: 'Please check your passwords.',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onSubmit(newPassword);
  };

  return (
    <>
      <Button onClick={onOpen} sx={changePasswordStyle}>
        <Icon as={LockIcon} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="20px">
              <InputDefault label="New Password" type="password" placeholder="Set a new Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
            </Box>
            <Box>
              <InputDefault label="Confirm Password" type="password" placeholder="Confirm the new password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleSubmit} sx={updatePasswordButtonStyle}>Update Password</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const updatePasswordButtonStyle = {
  background: '#1E5DFF',
  height: '40px',
  lineHeight: '40px',
  textAlign: 'center',
  color: '#fff',
  width: '100%'
}

const changePasswordStyle = {
  background: 'transparent',
  color: '#1E5DFF',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 500,
  padding: 0,
  borderRadius: '4px',
  width: '34px',
  height: '34px',
  border: '1px solid #CFD5E3',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: '#CFD5E3'
  }
}
export default ChangePassword;