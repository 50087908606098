import React, { useState, useEffect } from 'react';
import api from '../../../../utils/api.js';
import { Box } from "@chakra-ui/react";
import TemplateMenuItems from '../template-menu-items/TemplateMenuItems.js';


function HotTemplate() {
    const [hotTemplates, setHotTemplates] = useState([]);

    useEffect(() => {
        const fetchHotTemplates = async () => {
            try {
                const response = await api.post('/admin/content-templates/get-hot-templates');
                setHotTemplates(response.data);
            } catch (error) {
                console.error('Failed to fetch hot templates:', error);
            }
        };

        fetchHotTemplates();
    }, []);

    return (
        <Box mb="20px">
            <TemplateMenuItems menuItems={hotTemplates}/>
        </Box>
    );
}

export default HotTemplate;
