import React, {useState} from 'react';
import {
  Box,
  Heading,
  Grid,
  Text,
  Button, 
  Icon
} from "@chakra-ui/react";
import {TEMPLATE_ICONS} from '../../../assets/images/templates/TemplatesImage.js';
import HotTemplates from '../../dashboard/sidebar/hot-templates/HotTemplate.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useAppContext } from '../../../contexts/AppContext.js';
import { useNavigate } from 'react-router-dom';
import ArrowLineDown from '../../../assets/images/ArrowLineDown.js';


function DesktopLibrary() {
  const [templatesVisible, setTemplatesVisible] = useState(false);
  const {availableContentTemplates} = useAppContext();
  const navigate = useNavigate();
  // const allTemplates = categories.flatMap(category => availableContentTemplates[category]);

  const handleBoxClick = (template) => {
    navigate(`/admin/new-project?type=${template}`);
  };

  return (
    <Box style={tabsContainerStyle}>
      <Heading style={headingStyle}>Trending</Heading>
      <HotTemplates />
      {templatesVisible === false &&
        <Box>
          <Button onClick={() => setTemplatesVisible(true)} sx={viewMoreButtonStyle}>
            <Text>View all</Text>
            <Icon as={ArrowLineDown} />
          </Button>
        </Box>
      }
      {availableContentTemplates.categories && templatesVisible === true && (
        <Box style={tabsContainerStyle}>
          {availableContentTemplates.categories && (
              <SimpleBar style={{maxHeight: 'calc(100vh - 500px)'}}>
              {availableContentTemplates.categories.map((category, key) => {
                    return (
                        <Box kru={key}>
                            <Heading style={headingStyle}>{category.name}</Heading>
                            <Grid templateColumns="repeat(3, 1fr)" gap={4} mb="40px">
                                {category.templates.map((template, keyTemplate) => {
                                    const SvgIcon = TEMPLATE_ICONS[template.type] || null;
                                    return (
                                        <Box key={keyTemplate} sx={templateBoxStyle} onClick={() => handleBoxClick(template.type)}>
                                            <Box className="iconContainer">
                                              {SvgIcon}
                                            </Box>
                                            <Text style={templateNameStyle}>{template.name}</Text>
                                            {/* <Badge colorScheme="red" style={badgeStyle}>Hot</Badge> */}
                                            {/* <Text style={templateDescriptionStyle}>{template.description}</Text> */}
                                        </Box>
                                    )
                                })}
                            </Grid>
                        </Box>
                    )
                })}
              </SimpleBar>
          )}
        </Box>
      )}
    </Box>
  );
}

const viewMoreButtonStyle = {
  height: '40px',
  lineHeight: '40px',
  fontSize: '16px',
  color: '#98B3F8',
  width: '100%',
  background: 'transparent',
  border: '1px solid #98B3F8',
  'svg': {
    marginLeft: '10px',
    'path': {
      fill: '#98B3F8'
    }
  }
}

const tabsContainerStyle = {
  flexGrow: '1',
}

const headingStyle = {
  fontSize: '18px',
  lineHeihgt: '20px',
  fontWeight: 700,
  color:'#1B518F',
  marginBottom:'20px'
}
const templateBoxStyle = {
  background:'#fff',
  border: '1px solid #CFD5E3',
  display: 'flex',
  flexDirection: 'row', 
  padding:'5px 15px',
  borderRadius: '4px',
  position:'relative',
  cursor: 'pointer',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  '.iconContainer': {
    marginRight: '10px',
    svg: {
      width: '42px',
      height: '42px',
    }
  },
  '&:hover': {
    background: '#CFD5E3'
  }
}
const templateNameStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600, 
  color:'#1B518F',
}


export default DesktopLibrary;
