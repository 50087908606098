function HorizontallyDotsIcon() {
    return (
        <svg width="15" height="3" viewBox="0 0 15 3" fill="none">
            <path d="M8.94231 1.44246C8.94231 0.645892 8.29657 0.000148914 7.5 0.000148949C6.70344 0.000148983 6.05769 0.645892 6.05769 1.44246C6.05769 2.23902 6.70344 2.88477 7.5 2.88477C8.29657 2.88477 8.94231 2.23902 8.94231 1.44246Z" fill="#98B3F8"/>
            <path d="M15 1.44246C15 0.645892 14.3543 0.000148914 13.5577 0.000148949C12.7611 0.000148983 12.1154 0.645892 12.1154 1.44246C12.1154 2.23902 12.7611 2.88477 13.5577 2.88477C14.3543 2.88477 15 2.23902 15 1.44246Z" fill="#98B3F8"/>
            <path d="M2.88462 1.44246C2.88462 0.645892 2.23887 0.000148914 1.44231 0.000148949C0.645743 0.000148983 -9.78643e-08 0.645892 -6.30453e-08 1.44246C-2.82263e-08 2.23902 0.645743 2.88477 1.44231 2.88477C2.23887 2.88477 2.88462 2.23902 2.88462 1.44246Z" fill="#98B3F8"/>
        </svg>
    );
}

export default HorizontallyDotsIcon;