function DefaultCloseIcon() {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path d="M1.67709 1L17.0001 16.323" stroke="#1B518F" strokeLinecap="round"/>
            <path d="M16.6768 1.35317C16.872 1.1579 16.872 0.841321 16.6768 0.646059C16.4815 0.450797 16.1649 0.450797 15.9696 0.646059L16.6768 1.35317ZM9.01524 9.01469L16.6768 1.35317L15.9696 0.646059L8.30813 8.30758L9.01524 9.01469Z" fill="#1B518F"/>
            <path d="M1 16.3232L5.78845 11.5348" stroke="#1B518F" strokeLinecap="round"/>
        </svg>


    );
}
  
export default DefaultCloseIcon;