const prices = {
    free: {
        monthly: {
            price: 0,
            credits: 5000
        },
        yearly: {
            price: 0,
            credits: 5000
        }
    },
    premium: {
        monthly: {
            price: 19,
            credits: 100000
        },
        yearly: {
            price: 17,
            credits: 1200000
        }
    },
    unlimited: {
        price: '49',
        credits: 'unlimited'
    },
};

export default prices;