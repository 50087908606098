import React, {useState} from 'react';

import api from '../../../../utils/api.js';
import SpinnerLoader from '../../../common/spinner/Spinner.js';
import {useAiImageContext} from '../../../../contexts/AiImageContext.js';
import {useUserContext} from '../../../../contexts/UserContext.js';
import { Flex, Box, Text, Button, useToast } from '@chakra-ui/react';
import FileItem from './FileItem/FileItem.js';

function DetailsItem({ detailsItem }) {
    const toast = useToast();
    const { fetchImageDocumentDetails } = useAiImageContext();
    const {fetchUserData, userDataAndPlans} = useUserContext();
    const [loading, setLoading] = useState(false);
    const handleRegenerate = async () => {
        setLoading(true);
        const data = {
            generateInstructions: detailsItem.prompt,
            size: detailsItem.size,
            quality: detailsItem.quality,
            projectId: detailsItem.projectId,
            style: detailsItem.style,
            aiImageDocumentId: detailsItem.id,
            storage: userDataAndPlans.storage,
            planType: userDataAndPlans.planType
        }
        const response = await api.post(`/admin/ai-images/generate`, data);
        if(response.data.error){
            toast({
                description: response.data.error,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return false;
        }
        setLoading(false);
        fetchImageDocumentDetails(detailsItem.projectId);
        fetchUserData();
    }

    const checkForWords = () => {
        if (userDataAndPlans && userDataAndPlans.wordsRemaining > 0) {
            return true;
        }
        return false;
    }

    return (
        <Box sx={detailsItemStyle}>
            <Box className="containerInner">
                <Flex className="topStructure">
                    <Box className="promptContainer">
                        <Text className="prompt">{detailsItem.prompt}</Text>
                    </Box>
                    {checkForWords() === true &&
                        <Box className="actionButtons">
                            <Button onClick={() => handleRegenerate()}>Regenerate</Button>
                        </Box>
                    }
                </Flex>
                
                {detailsItem.files && detailsItem.files.length > 0 &&
                    <Flex className="imagesContainer">
                        {detailsItem.files.map((file_item, file_key) => (
                            <FileItem key={file_key} file={file_item}/>
                        ))}
                    </Flex>
                }

                {loading === true &&
                    <SpinnerLoader />
                }

            </Box>
        </Box>
    );
}

const detailsItemStyle = {
    marginBottom: '20px',
    position: 'relative',
    '.containerInner': {
        border: '1px solid #CFD5E3',
        borderRadius: '4px',
        padding: '20px',
        '.topStructure': {
            '.prompt': {
                fontSize: '14px',
                fontWeight: 700,
                color: '#1B518F',
                marginBottom: '10px'
            },
            '.actionButtons': {
                marginLeft: 'auto',
                'button': {
                    background: '#F2F9FF',
                    color: '#1E5DFF'
                }
            }
        },
        '.imagesContainer': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: '-10px',
            marginRight: '-10px',
            '.imageItem': {
                width: '50%',
                padding: '0 10px'
            }
        }
    },
    '&:last-child': {
        marginBottom: 0
    },
    '@media all and (max-width:1200px)': {
        '.containerInner': {
            '.topStructure': {
                flexDirection: 'column',
                marginBottom: '10px',
                '.prompt': {
                    fontSize: '14px',
                    fontWeight: 400
                },
                '.actionButtons': {
                    width: '100%',
                    'button': {
                        width: '100%'
                    }
                }
            }
        }
    }
}
export default DetailsItem;
