import React from 'react';
import {Link} from 'react-router-dom';
import { Flex, Box, Icon, Text, Menu, MenuButton, MenuList, MenuItem, useMenu } from '@chakra-ui/react';
import { useFolderContext } from '../../../../contexts/FolderContext.js';
import DropdownIcon from '../../../../assets/images/DropdownIcon.js';
import FolderItemIcon from '../../../../assets/images/FolderItemIcon.js';
import { TEMPLATE_ICONS } from '../../../../assets/images/templates/TemplatesImage.js';
import { formatLinks } from '../../../../utils/getLinks.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css'

function limitString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}
function SidebarDefaultMenu({ defaultFolderId, initialName, menu }) {
    const {menuDocuments, changeDefaultFolder, fetchDocumentsByDefaultFolder} = useFolderContext();
    const foldersMenu = useMenu();
    const menuItems = [];

    const getIcon = (type) => {
        return TEMPLATE_ICONS[type]
    }

    const handleChangeDefaultFolder = async (id) => {
        await changeDefaultFolder(id);
        fetchDocumentsByDefaultFolder();
    } 

    if (menu && menu.length > 1) {
        menu.forEach(folder => {
            if (folder.id !== defaultFolderId) {
                const folderItem = {
                    id: folder.id,
                    label: folder.name,
                    icon: '',
                    onClick: () => console.log(folder.name)
                };
                menuItems.push(folderItem);
            }
        });
    }

    return (
        <Flex sx={mainMenuContainerStyle}>
            <Box className="menuContainerStyle">
                <Menu isOpen={foldersMenu.isOpen} onClose={foldersMenu.onClose} onOpen={foldersMenu.onOpen}>
                    <MenuButton w="100%">
                        <Flex sx={menuButtonStyle} className="menuButtonStyle">
                            <Box className="leftIcon">
                                <Icon as={FolderItemIcon} />
                            </Box>
                            <Box className="middleIcon">
                                <Text>{initialName}</Text>
                            </Box>
                            <Box className="righticon">
                                <Icon as={DropdownIcon} />
                            </Box>
                        </Flex>
                    </MenuButton>
                    {menuItems && menuItems.length > 0 
                        ? <MenuList sx={menuContainerStyle}>
                            {menuItems.map((menuItem, key) => (
                                <MenuItem sx={menuItemStyle} display="flex" key={key} onClick={() => { handleChangeDefaultFolder(menuItem.id) }}>
                                    <Icon as={FolderItemIcon} />
                                    <Text style={folderNameStyle}>{limitString(menuItem.label, 20)}</Text>
                                </MenuItem>
                            ))}
                        </MenuList>
                        : <MenuList sx={menuContainerStyle}>
                            <Text className="emptyState">It looks like there's nothing here yet. <br/>Create your first folder clicking on + button.</Text>
                        </MenuList>
                    }
                </Menu>
            </Box>
            {menuDocuments && menuDocuments.length > 0 &&
                <SimpleBar style={{maxHeight: 'calc(100vh - 370px)'}}>
                <Box className="documentsList">
                    {menuDocuments.map((item, key) => (
                        <Box className={`documentItem ${item.templateType !== 'AiImage' && item.templateType !== 'tts' ? 'contentTemplate' : item.templateType+'Template'}`} key={key}>
                            <Link to={formatLinks(item.id, item.templateType)}>
                                {getIcon(item.templateType)}
                                <Text>{limitString(item.name, 32)}</Text>
                            </Link>
                            
                        </Box>
                    ))}
                </Box>
                </SimpleBar>
            }
        </Flex>
    )
}
const menuButtonStyle = {
    background: '#1B518F',
    borderRadius: '4px',
    border: '1px solid #1B518F',
    position: 'relative',
    padding:'7px 10px',
    alignItems: 'center',
    
    '.leftIcon': {
        paddingRight: '10px',
        'svg': {
            'path': {
                stroke: '#8599CD'
            }
        }
    },
    '.middleIcon': {
        'p':{
            fontSize: '16px',
            fontWeight: 600,
            color: '#fff'
        }
    },
    '.righticon': {
        position: 'absolute',
        right: '15px',
        top: '15px',
        'svg': {
            'path': {
                fill: '#fff'
            }
        }
    }
}

const mainMenuContainerStyle = {
    flexDirection: 'column',
    '.menuContainerStyle' :{
        marginBottom: '10px',
        marginLeft: '-10px',
        marginRight: '-10px'
    },
    '.documentsList': {
        paddingBottom: '70px',
        '.documentItem': {
            'a': {
                display: 'flex',
                flexDirection: 'row',
                padding: '10px  0',
                'svg': {
                    marginRight: '10px',
                    width: '18px',
                    height: '18px'
                },
                'p': {
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#1B518F'
                }
            },
            '&.contentTemplate': {
                'a': {
                    'svg':{
                        'circle': {
                            fill: '#EAF5FF !important'
                        }
                    }
                }
            }
        }
    }
}   

const menuContainerStyle = {
    border: '0 none',
    boxShadow: '0px 0px 16px 0px #0000001A',
    borderRadius: '6px',
    maxHeight: '270px',
    overflow: 'auto',
    '.emptyState': {
        fontSize: '14px',
        padding: '10px 20px'
    }
    
}

const menuItemStyle = {
    borderBottom: '1px solid #E1F2FF',
    'button': {
        height: 'auto',
        background: 'transparent',
        padding: 0,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#1B518F'
    }
}

const folderNameStyle = {
    color: '#1B518F',
    fontSize: '14px',
    marginLeft: '10px'
}

export default SidebarDefaultMenu;