import React, { useState, useEffect } from "react";
import { Flex, Box, Heading } from '@chakra-ui/react';
import api from '../../utils/api.js';
import RecentActivityItem from './recent-activity-item/RecentActivityItem.js';


function RecentActivity({ isMobile }) {
  const [recentActivity, setRecentActivity] = useState([]);
  const getRecentActivity = async () => {
    const response = await api.post("/admin/recent-activity", { limit: isMobile ? 3 : 3 });
    setRecentActivity(response.data);
  }
  useEffect(() => {
    getRecentActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {recentActivity.length > 0 &&
        <>
          <Heading style={boxTitle}>Resume your work</Heading>
          <Box sx={boxesContainerStyle} className={isMobile === true ? 'mobile-design' : 'desktop-design'}>
            <Flex className="recentItemsContainer">
              {recentActivity.map((item, key) => (
                <RecentActivityItem details={item} key={key} />
              ))}
            </Flex>
          </Box>
        </>
      }
    </>
  );
}

const boxTitle = {
    color: '#1B518F',
    fontSize: '22px',
    fontWeight: 700,
    marginBottom: '5px'
}

const boxesContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  '.recentItemsContainer': {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
  },
  '.headingStyle': {
    fontSize: '16px',
    fontWeight: 700,
    color: '#1B518F',
    marginBottom: '10px',

  },
  '&.desktop-design': {
    marginLeft: '-10px',
    marginRight: '-15px',
  },
  '&.mobile-design': {
    width: '100%',
    padding: '0',
    '.headingStyle': {
      display: 'none'
    }
  }
}


export default RecentActivity;