function OpenLinkIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M15 8V14.4555C15.0004 14.5272 14.9866 14.5981 14.9594 14.6644C14.9321 14.7306 14.892 14.7908 14.8414 14.8414C14.7908 14.892 14.7306 14.9321 14.6644 14.9594C14.5981 14.9866 14.5272 15.0004 14.4555 15H1.54445C1.47284 15.0004 1.40187 14.9866 1.33563 14.9594C1.2694 14.9321 1.20922 14.892 1.15859 14.8414C1.10795 14.7908 1.06786 14.7306 1.04064 14.6644C1.01342 14.5981 0.999612 14.5272 1.00001 14.4555V1.54445C0.999612 1.47284 1.01342 1.40187 1.04064 1.33563C1.06786 1.2694 1.10795 1.20922 1.15859 1.15859C1.20922 1.10795 1.2694 1.06786 1.33563 1.04064C1.40187 1.01342 1.47284 0.999612 1.54445 1.00001H8" stroke="#1E5DFF"/>
            <path d="M10.4888 1H14.9999V5.66068" stroke="#1E5DFF"/>
            <path d="M4.20703 12.1043L14.7639 1.09277" stroke="#1E5DFF"/>
        </svg>
    );
  }
  
export default OpenLinkIcon;