import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Flex, Box, Heading, Text, Icon } from '@chakra-ui/react';
import RecentActivity from '../recent-activity/RecentActivity.js';
import Chat from '../chat/Chat.js';

import AiChatSupportIcon from '../../assets/images/AiChatSupportIcon.js';
import AiImageIcon from '../../assets/images/AiImageIcon.js';
import AiContentIcon from '../../assets/images/AiContentIcon.js';
import AiTextToSpeeach from '../../assets/images/AiTextToSpeech.js';

function Dashboard() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex sx={dashboardPageStyle}>
      <Box className="dashboardBox firstBox">
        <RecentActivity isMobile={isMobile} />
      </Box>
      <Box className="dashboardBox secondBox">
        <Heading className="boxHeading">Getting started</Heading>
        <Box className="secondBoxContainer">
          <Box className="linkContainer">
            <Link to="/admin/new-project">
              <Box className="linkContainerStyle">
                <Box className="iconContainer">
                  <Icon as={AiContentIcon} className="icon" />
                </Box>
                <Box className="textContainer">
                  <Heading className="boxHeading">Create content</Heading>
                  <Text className="linkLabel">AI craft: Content on-demand</Text>
                </Box>
              </Box>
            </Link>
          </Box>
          <Box className="linkContainer aiImageIcon">
            <Link to="/admin/ai-images">
              <Box className="linkContainerStyle">
                <Box className="iconContainer">
                  <Icon as={AiImageIcon} className="icon" />
                </Box>
                <Box className="textContainer">
                  <Heading className="boxHeading">Generate images</Heading>
                  <Text className="linkLabel">Visualize Ideas: AI Image Gen</Text>
                </Box>
              </Box>
            </Link>
          </Box>
          <Box className="linkContainer">
            <Link to="/admin/text-to-speech">
              <Box className="linkContainerStyle">
                <Box className="iconContainer">
                  <Icon as={AiTextToSpeeach} className="icon" />
                </Box>
                <Box className="textContainer">
                  <Heading className="boxHeading">Text to Speech</Heading>
                  <Text className="linkLabel">Voice your ideas</Text>
                </Box>
              </Box>
            </Link>
          </Box>
          <Box className="linkContainer">
            <Link to="/admin/tools/food-checker">
              <Box className="linkContainerStyle">
                <Box className="iconContainer">
                  <Icon as={AiChatSupportIcon} className="icon" />
                </Box>
                <Box className="textContainer">
                  <Heading className="boxHeading">Food Checker</Heading>
                  <Text className="linkLabel">Check what you eat</Text>
                </Box>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box className="dashboardBox thirdBox">
        <Heading className="boxHeading">Got questions?</Heading>
        <Chat page="dashboard" />
      </Box>
    </Flex>
  );
}

const dashboardPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: '850px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '50px',
  height: 'calc(100vh - 57px)',
  'h2': {
    color: '#1B518F',
    fontSize: '22px',
    fontWeight: 700,
    marginBottom: '5px'
  },
  '.firstBox': {
    position: 'relative',
    zIndex: 9,
    marginBottom: '50px',
  },
  '.secondBox': {
    position: 'relative',
    zIndex: 9,
    '.secondBoxContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: '-10px',
      marginRight: '-10px',
      '.linkContainer': {
        flex: '0 0 50%',
        padding: '10px',
        '.linkContainerStyle': {
          border: '1px solid #CFD5E3',
          padding: '15px',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'row',
          '.iconContainer': {
            width: '34px',
            height: '34px',
            background: '#1E5DFF',
            borderRadius: '34px',
            lineHeight: '34px',
            position: 'relative',
            'svg': {
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '24px',
              marginTop: '-12px',
              marginLeft: '-12px',
              '.stroke': {
                stroke: '#fff'
              },
              '.fill': {
                fill: '#fff'
              }
            }
          },
          '.textContainer': {
            marginLeft: '15px',
            'h2': {
              fontSize: '16px',
              fontWeight: 600,
              color: '#1B518F',
              marginBottom: '5px'
            },
            'p': {
              fontSize: '14px',
              color: '#1B518F'
            },

          }
        },
        '&:nth-child(2)': {
          '.iconContainer': {
            background: '#F50DC2',
          }
        },
        '&:nth-child(3)': {
          '.iconContainer': {
            background: '#FF6712',
          }
        },
        '&:nth-child(4)': {
          '.iconContainer': {
            background: '#19D02B',
          }
        }
      }
    },
  },
  '.thirdBox': {
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%'
  },
  '@media all and (max-width: 1200px)': {
    paddingTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '80px',
    '.thirdBox': {
      '.boxHeading': {
        display: 'none'
      }
    },
    '.firstBox': {
      marginBottom: '25px'
    },
    '.secondBox': {
      '.secondBoxContainer': {
        '.linkContainer': {
          flex: '0 0 100%',
          paddingBottom: '0'
        }
      }
    }
  },
  '@media all and (max-width:850px)': {
    height: 'auto',
  }
}

export default Dashboard;
