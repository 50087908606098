import React, { useEffect } from 'react';
import { Icon } from '@chakra-ui/react';
import Select from 'react-select';
import { components } from "react-select";
import DropdownIcon from '../../../assets/images/DropdownIcon.js';

const availableWritingTones = [
  {
    label: '😊 Friendly',
    value: 'Friendly'
  },
  {
    label: '💎 Luxury',
    value: 'Luxury'
  },
  {
    label: '😌 Relaxed',
    value: 'Relaxed'
  },
  {
    label: '💼 Professional',
    value: 'Professional'
  },
  {
    label: '💪 Bold',
    value: 'Bold'
  },
  {
    label: '🚀 Adventurous',
    value: 'Adventurous'
  },
  {
    label: '😜 Witty',
    value: 'Witty'
  },
  {
    label: '👉🏻 Persuasive',
    value: 'Persuasive'
  },
  {
    label: '🤗 Empathic',
    value: 'Empathic'
  }
];

const findLabelAndValue = (searchValue) => {
  const foundTone = availableWritingTones.find(tone => tone.value === searchValue);

  if (foundTone) {
    return { label: foundTone.label, value: foundTone.value };
  } else {
    return { label: 'Not found', value: null };
  }
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon as={DropdownIcon} sx={iconStyle} />
    </components.DropdownIndicator>
  );
};
const ContentWritingTone = ({ onChange, defaultValue, register, setValue, ...rest }) => {
  const result = findLabelAndValue(defaultValue);
  useEffect(() => {
    if (defaultValue) {
      setValue("tone", defaultValue);
    }
  }, [defaultValue, setValue]);

  const toneOptions = availableWritingTones.map(tone => ({
    value: tone.value,
    label: tone.label
  }));
  return (
    <Select
      name={"tone"}
      styles={selectStyles}
      isSearchable={true}
      components={{ DropdownIndicator }}
      options={toneOptions}
      defaultValue={defaultValue ? result : findLabelAndValue('Friendly')}
      onChange={selectedOption => {
        setValue("tone", selectedOption);
      }}
    />
  );
}

const iconStyle = {
  svg: {
    fill: '#1B518F'
  }
}

const selectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: '120px'
  }),
  control: (provided) => ({
    ...provided,
    padding: "0 10px 0px 10px",
    height: '31px',
    minHeight: '31px',
    border: '1px solid #CFD5E3',
    borderRadius: '4px',
    color: '#1B518F',
    fontSize: '14px',
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '9'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'relative',
    right: '-5px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9EC1DE',
    fontSize: '14px',
    fontWeight: 400
  }),
};

export default ContentWritingTone;
