import React, { useState, useEffect } from 'react';
import { Flex, Box, Icon, Text } from '@chakra-ui/react';
import SortIcon from '../../assets/images/SortIcon.js';
import DropdownIcon from '../../assets/images/DropdownIcon.js';
import Select, { components } from 'react-select';
import SlimPlusIcon from '../../assets/images/SlimPlusIcon.js';

import FolderItem from './folder-item/FolderItem.js';
import DeleteConfirmationModal from '../modals/delete-confirmation/DeleteConfirmationModal.js';
import { useFolderContext } from '../../contexts/FolderContext.js';
import CreateFolderModal from '../folder/create-folder-modal/CreateFolderModal.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon as={DropdownIcon} sx={iconStyle} />
        </components.DropdownIndicator>
    );
};

function MyDocuments() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);


    const { deleteFolder, fetchFolders, folders, totalDocuments } = useFolderContext();
    const [sortType, setSortType] = useState('');
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState(null);

    const sortOptions = [
        { value: 'updatedAt', label: 'Date Updated' },
        { value: 'createdAt', label: 'Date Created' },
        { value: 'name', label: 'Name' }
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDeleteModalOpen = (folderId) => {
        setFolderToDelete(folderId);
        setDeleteModalOpen(true);
    }

    const handleDelete = () => {
        if (folderToDelete) {
            deleteFolder(folderToDelete);
            setDeleteModalOpen(false);
            setFolderToDelete(null);
            fetchFolders(sortType);
        }
    }

    useEffect(() => {
        fetchFolders(sortType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchFolders(sortType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);
    return (
        <>
            <Flex sx={foldersPageStyle} flexDirection="column">
                <Box sx={headerPageStyle}>
                    <Box className="pageTitleContainer">
                        {/* <Text className="foldersCountTitle">{Object.keys(folders).length} Folders</Text> */}
                        <Text className="foldersCountTitle">Folders</Text>
                        <Text className="totalProjectStyle">{totalDocuments} documents</Text>
                    </Box>
                    <Flex className="actionsFoldersPage">
                        <Box className="actionContainer">
                            <CreateFolderModal buttonStyleProps={createNewFolderStyle} buttonLabel={<Icon as={SlimPlusIcon} />} />
                        </Box>
                        <Box sx={sortContainerStyle} className="actionContainer">
                            <Box className="iconContainer">
                                <Icon as={SortIcon} />
                                <Text>Sort</Text>
                            </Box>
                            <Box className="selectContainer">
                                <Select
                                    name="sortBy"
                                    styles={selectStyles}
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    options={sortOptions}
                                    onChange={(selectedOption) => {
                                        setSortType(selectedOption.value);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {isMobile
                    ? <Box sx={outerContainerStyle}>
                        <Flex sx={foldersContainerStyle}>
                            {folders.map((folder, index) => (
                                <FolderItem key={index} folder={folder} onDelete={(folderId) => handleDeleteModalOpen(folderId)} sortType={sortType} />
                            ))}
                        </Flex>
                    </Box>
                    : <Box sx={outerContainerStyle}>
                        <SimpleBar style={{ maxHeight: `calc(100vh - ${isMobile ? '145px' : '200px'})` }}>
                            <Flex sx={foldersContainerStyle}>
                                {folders.map((folder, index) => (
                                    <FolderItem key={index} folder={folder} onDelete={(folderId) => handleDeleteModalOpen(folderId)} sortType={sortType} />
                                ))}
                            </Flex>
                        </SimpleBar>
                    </Box>
                }

            </Flex>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={handleDelete}
                itemName="folder"
            />
        </>

    );
}

const iconStyle = {
    svg: {
        fill: '#1B518F'
    }
}

const foldersPageStyle = {
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.pageTitleContainer': {
        marginBottom: '10px',
    },
    '.actionsFoldersPage': {
        margin: '0 -10px',
        '.actionContainer': {
            flex: '0 0 50%',
            padding: '0 10px'
        }
    }
}

const outerContainerStyle = {
    margin: '0 -10px',
    '@media (max-width: 1200px)': {
        margin: 0,
        overflow: 'hidden'
    }
}

const sortContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    '.iconContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '10px',
        'svg': {
            marginRight: '5px',
        },
        'p': {
            color: '#8599CD',
            fontSize: '14px',
            textTransform: 'underline'
        }
    },
    '@media (max-width: 1200px)': {
        display: 'none'
    }
}

const foldersContainerStyle = {
    flexWrap: 'wrap',
    '@media (max-width: 1200px)': {
        paddingLeft: '15px',
        paddingRight: '15px',
        margin: 0
    }
}

const createNewFolderStyle = {
    border: '1px solid #CFD5E3',
    borderRadius: '4px',
    width: '34px',
    height: '34px',
    background: 'transparent',
    minWidth: '1px',
    padding: 0,
    marginRight: '10px'
}

const headerPageStyle = {
    paddingTop: '50px',
    marginBottom: '20px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '.foldersCountTitle': {
        color: '#1B518F',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 700,
        marginRight: '10px'
    },
    '.totalProjectStyle': {
        color: '#9EC1DE',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        display: 'none',
    },
    '@media screen and (max-width: 1200px)': {
        padding: '10px 20px',
        '.foldersCountTitle': {
            fontSize: '20px',
            lineHeight: '24px'
        },
        '.totalProjectStyle': {
            fontSize: '12px'
        },
        'button': {
            width: '35px !important',
            height: '35px !important',
            'svg': {
                width: '17px'
            }
        }
    }
}

const selectStyles = {
    container: (provided) => ({
        ...provided,
        minWidth: '140px',
    }),
    control: (provided) => ({
        ...provided,
        padding: "5px 10px",
        border: '1px solid #CFE0ED',
        borderRadius: '4px',
        color: '#1B518F',
        fontSize: '14px',
        fontWeight: 600,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: '9'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        position: 'relative',
        top: '0',
        right: '-5px'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0', // Adjust the padding values as desired
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#9EC1DE',
        fontSize: '14px',
        fontWeight: 400
    }),
    // Add other styles or overrides if necessary
};



export default MyDocuments;
