function DownloadIcon() {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
            <path d="M8 9.75L8 1" stroke="#1B518F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.66699 7.22217L7.93266 9.48783C7.96999 9.52517 8.03066 9.52517 8.06799 9.48783L10.3337 7.22217" stroke="#1B518F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 8.77783V9.55561V11.8889C1 12.7481 1.69645 13.4445 2.55556 13.4445H13.4444C14.3036 13.4445 15 12.7481 15 11.8889V9.55561V8.77783" stroke="#1B518F" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}
  
export default DownloadIcon;