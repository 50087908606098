function AiChatWhiteBgIcon() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
            <circle cx="17" cy="17" r="16.5" fill="#EAF5FF" stroke="white"/>
            <line x1="14.8352" y1="7.48446" x2="5.57208" y2="18.4318" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="15.6758" y1="10.8555" x2="8.93901" y2="18.7442" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="19.8818" y1="10.018" x2="12.3029" y2="18.7488" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="28.2771" y1="18.705" x2="18.8108" y2="10.0807" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="23.8743" y1="18.5118" x2="17.1374" y2="12.6171" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="24.6806" y1="23.5303" x2="15.4174" y2="14.2672" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="20.1582" y1="18.6974" x2="5.00031" y2="18.6974" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="25.2109" y1="23.75" x2="9.21094" y2="23.75" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="22.6846" y1="21.2237" x2="11.7372" y2="21.2237" stroke="#1E5DFF" strokeWidth="1.5"/>
        </svg>

    );
}
  
export default AiChatWhiteBgIcon;