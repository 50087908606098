import React from 'react';
import {
    Flex,
    Box,
    Heading,
    Text,
  } from '@chakra-ui/react';
import PaymentStripeForm from '../PaymentStripeForm.js';
function PaymentPlans({planType, userDataAndPlans}) {
    const paymentPlans = [
        {
            type: 'free',
            name: 'Free',
            description: 'Experience the power of Staring',
            monthlyPrice: '0',
            yearlyPrice: '0',
            words: '5000',
            enoughFor: 'Enough for up to 50 twitter posts',
        },
        {
            type: 'premium',
            name: 'Premium',
            description: 'Experience the power of Staring',
            monthlyPrice: '12',
            yearlyPrice: '24',
            words: '20000',
            enoughFor: 'Enough for up to 50 twitter posts',
        },
        {
            type: 'unlimited',
            name: 'Unlimited',
            description: 'Go beyond Staring, go unlimited',
            monthlyPrice: '49',
            yearlyPrice: '69',
            words: 'unlimited',
            enoughFor: 'Enough for anyting you can imagine',
        },
    ]
    return (
        <Flex sx={plansContainerStyle}>
            {paymentPlans.map((item, key) => (
                <Box key={key} sx={paymentPlanContainer}>
                    <Box  className={item.type === 'free' ? "freePlan boxItem" : "premiumPlan boxItem"} border={item.type === userDataAndPlans.planType && userDataAndPlans.planCycleType === planType ? '1px solid #1E5DFF' : ''}>
                        <Heading className="planTitle">{item.name}</Heading>
                        <Text className="planDescription">{item.description}</Text>
                        {planType === 'monthly'
                            ? <Text className="planPrice">${item.monthlyPrice}.<sup>{item.type === 'free' ? '00': '99'}</sup><span>/ month</span></Text>
                            : <Text className="planPrice">${item.yearlyPrice}.<sup>{item.type === 'free' ? '00': '99'}</sup><span>/ year</span></Text>
                        }
                        <Box className="planBenefits"> 
                            <Text className="benefit">{item.words} Words / Month</Text>
                            <Text className="enough">Enough for up to 50 twitter posts</Text>
                        </Box>
                        {(
                            (userDataAndPlans.planType === 'premium' && item.type === 'premium' && userDataAndPlans.planCycleType === 'monthly' && planType === 'monthly') || 
                            (userDataAndPlans.planType === 'premium' && item.type === 'premium' && userDataAndPlans.planCycleType === 'yearly' && planType === 'yearly') || 
                            (userDataAndPlans.planType === 'unlimited' && item.type === 'unlimited' && userDataAndPlans.planCycleType === 'monthly' && planType === 'monthly') ||
                            (userDataAndPlans.planType === 'unlimited' && item.type === 'unlimited' && userDataAndPlans.planCycleType === 'yearly' && planType === 'yearly') ||
                            (userDataAndPlans.planType === 'free' && item.type === 'free')
                            ) ? (
                            <Box>
                                <Text className="upgradeButton">Selected</Text>
                            </Box>
                            ) : (
                            <Box>
                                <PaymentStripeForm planType={planType} planName={item.type} />
                            </Box>
                        )}
                    </Box>
                </Box>
            ))}
        </Flex>
    )
}   
const plansContainerStyle = {
    margin: '0 -10px',
    '@media (max-width: 800px)':{
        flexWrap: 'wrap'
    }
}
const paymentPlanContainer = {
    flex: '0 0 33.33%',
    padding: '0 10px',
    '.boxItem': {
        padding: '15px',
        borderRadius: '4px',
    },
    '.planTitle': {
        color: '#1B518F',
        fontSize: '22px',
        lineHeight: '22px',
        fontWeight: 700,
        marginBottom: '10px'
    },
    '.planDescription': {
        color: '#6D99BE',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        marginBottom: '20px'
    },
    '.planPrice': {
        color: '#1B518F',
        fontSize: '26px',
        fontWeight: 700,
        marginBottom: '20px',
        'span': {
            fontWeight: 400,
            fontSize: '14px',
        }
    },
    '.planBenefits': {
        background: '#F6FBFF',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '50px',
        '.benefit': {
            color: '#1B518F',
            fontSize: '14px',
            fontWeight: 700
        },
        '.enough': {    
            color: '#6D99BE',
            fontSize: '12px'
        }   

    },
    '.upgradeButton': {
        height: '50px',
        lineHeight: '50px',
        background: '#F6FBFF',
        color: '#1E5DFF',
        fontSize: '16px',
        fontWeight: 700,
        borderRadius: '4px',
        display: 'block',
        width: '100%'
    },
    '.freePlan': {
        '.planTitle': {
            color: '#6D99BE',
        },
        '.planPrice': {
            color: '#6D99BE',
        },
        '.planBenefits': {
            background: 'transparent',
            border: '1px solid #F2F9FF'
        }

    },
    '@media (max-width:800px)': {
        flexWrap: 'wrap',
        flexDirection: 'colum',
        flex: '0 0 100%'
    }
}
export default PaymentPlans;