import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../utils/api.js';

const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
    // const [notifications, setNotifications] = useState([]);
    const [availableContentTemplates, setAvailableContentTemplates] = useState([]);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showNoCreditsModal, setShowNoCreditsModal] = useState(false);

    const openMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    const fetchContentTemplates = async () => {
        try {
            const response = await api.post('/admin/content-templates/get-available-templates');
            setAvailableContentTemplates(response.data);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    };

    const displayNoCreditsModal = (value) => {
        setShowNoCreditsModal(value);
    }

    /* --- Notifications --- */
    // const fetchNotifications = async () => {
    //     try {

    //         const response = await api.post(`/admin/notifications/get-latest`);
    //         setNotifications(response.data);
    //     } catch (error) {
    //         console.error("Error fetching folders:", error);
    //     }
    // }

    useEffect(() => {
        fetchContentTemplates();
    }, []);


    const value = {
        mobileMenuOpen,
        openMobileMenu,
        availableContentTemplates,
        // fetchNotifications,
        // notifications,
        showNoCreditsModal,
        displayNoCreditsModal
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
