import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import ChatMessageItem from './chate-message-item/ChatMessageItem.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css'
const ChatMessages = ({ messages, user }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const simpleBarRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (simpleBarRef.current) {
      simpleBarRef.current.getScrollElement().scrollTop = simpleBarRef.current.getScrollElement().scrollHeight;
    } else {
      const scrollingElement = document.getElementById('mainMessagesContainer');
      if (scrollingElement) {
        document.documentElement.scrollTop = scrollingElement.scrollHeight;
      }
    }
  }, [messages]);

  return (
    <Flex sx={messagesContainer}>
      {messages && messages.length > 0 &&
        <Box id="mainMessagesContainer" className="inner_conatiner">
          {isMobile
            ? <Box>
              {messages.map((item, key) => (
                <ChatMessageItem key={key} message={item} user={user} />
              ))}
            </Box>
            : <SimpleBar style={{ overflowX: 'hidden', maxHeight: window.innerWidth <= 1200 ? 'auto' : 'calc(100vh - 160px)' }} ref={simpleBarRef}>
              {messages.map((item, key) => (
                <ChatMessageItem key={key} message={item} user={user} />
              ))}
            </SimpleBar>
          }

        </Box>
      }
    </Flex>
  );
};

const messagesContainer = {
  maxWidth: '100vw',
  overflow: 'hidden',
  '.inner_conatiner': {
    width: '100%',
    '.simplebar-scrollbar.simplebar-visible.simplebar-vertical': {
      width: 0
    }
  },
  '@media all and (max-width: 1200px)': {
    paddingBottom: '80px'
  }
}

export default ChatMessages;
