import React from 'react';
import {
    Flex,
    Box,
    Text,
    Image,
    Button,
    Icon
} from "@chakra-ui/react";
import {useNavigate} from 'react-router-dom';
import { useFolderContext } from '../../../../contexts/FolderContext.js';
import EmptyStateDocumentsIcon from '../../../../assets/images/EmptyStateDocumentsIcon.png';

import AiContentIcon from '../../../../assets/images/AiContentIcon.js';
import AiImageIcon from '../../../../assets/images/AiContentIcon.js';
import AiTextToSpeeach from '../../../../assets/images/AiContentIcon.js';

function EmptyStateDocuments({folderDetails}) {
    const {changeDefaultFolder} = useFolderContext();
    const navigate = useNavigate();

    const handleButtonClick = (type) => {
        changeDefaultFolder(folderDetails.id);
        if(type === 'content'){
            navigate("/admin/new-project");
        }else if(type === 'image'){
            navigate("/admin/ai-images");
        }else{
            navigate("/admin/text-to-speech");
        }
    }
    return (
        <Box textAlign="center">
            <Image src={EmptyStateDocumentsIcon} ml="auto" mr="auto" mb="20px" mt="20px"/>
            <Box>
                <Text style={headingStyle}>It Looks Empty</Text>
                <Text style={descriptionStyle}>Currently, there are no documents in this folder.</Text>
                
                <Flex sx={linksContainerStyle}> 
                    <Box className="linkItem itemContent">
                        <Button onClick={() => handleButtonClick('content')}>
                            <Box className="containerInner">
                                <Box className="iconContainer">
                                    <Icon as={AiContentIcon} />
                                </Box>
                                <Text>Create content</Text>
                            </Box>
                        </Button>
                    </Box>
                    <Box className="linkItem itemImage">
                        <Button onClick={() => handleButtonClick('image')}>
                            <Box className="containerInner">
                                <Box className="iconContainer">
                                    <Icon as={AiImageIcon} />
                                </Box>
                                <Text>Generate images</Text>
                            </Box>
                        </Button>
                    </Box>
                    <Box className="linkItem itemTts">
                        <Button onClick={() => handleButtonClick('tts')}>
                            <Box className="containerInner">
                                <Box className="iconContainer">
                                    <Icon as={AiTextToSpeeach} />
                                </Box>
                                <Text>Text to speech</Text>
                            </Box>
                        </Button>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );  
}

const linksContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '0 -10px',
    '.linkItem': {
        flex: '0 0 33.33%',
        padding: '0 10px',
        'button': {
            padding: '10px',
            height: 'auto',
            display: 'block',
            width: '100%',
            background: 'transparent',
            border: '1px solid #CFD5E3',
            borderRadius: '4px',
            '.containerInner': {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                'p': {
                    marginLeft: '10px'
                }
            },
            '.iconContainer': {
                width: '24px',
                heihgt: '24px',
                borderRadius: '24px',
                background: '#1E5DFF',
                'svg': {
                    width: '17px',
                    margin: '0 auto',
                    'path': {
                        stroke: '#fff'
                    },
                    'line': {
                        stroke: '#fff'
                    },
                    'circle': {
                        stroke: '#fff'
                    }
                }
            }
        },
        '&.itemImage': {
            '.iconContainer': {
                background: '#F50DC2',
            }
        },
        '&.itemTts': {
            '.iconContainer': {
                background: '#FF6712',
            }
        }
    },
    '@media all and (max-width: 640px)': {
        flexDirection: 'column',
        '.linkItem': {
            flex: '0 0 100%',
            marginBottom: '10px',
            width: '100%',
            'button': {
                width: '100%'
            }
        }
    }
}

const descriptionStyle = {
    fontSize: '14px',
    color: '#6D99BE',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '25px'
}
const headingStyle = {
    fontSize: '22px',
    lineHeight: '24px',
    color: '#1B518F',
    textAlign: 'center',
    marginBottom: '10px',
    fontWeight: 600
}
export default EmptyStateDocuments;