import React, { createContext, useContext, useState } from 'react';
import api from '../utils/api.js';

const ProjectContext = createContext();

export const useProjectContext = () => {
    return useContext(ProjectContext);
};

export const ProjectProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);

    const addProject = (newProject) => {
        setProjects(prevProjects => [...prevProjects, newProject]);
    };

    const deleteProject = async (projectId) => {
        try {
            await api.post(`/admin/project/${projectId}/delete`);
            const updatedProjects = projects.filter(project => project.id !== projectId);
            setProjects(updatedProjects);
        } catch (error) {
            console.error("Failed to delete project:", error);
        }
    }

    const renameProject = async (projectId, newName) => {
        try {
            const response = await api.put(`/admin/project/${projectId}/rename`, { name: newName });
            
            setProjects(prevProjects => prevProjects.map(project => 
                project.id === projectId ? { ...project, name: newName } : project
            ));

            return response.data;
        } catch (error) {
            console.error("Error renaming the project:", error);
        }
    }

    const value = {
        projects,
        setProjects,
        addProject,
        deleteProject,
        renameProject,
    }

    return (
        <ProjectContext.Provider value={value}>
            {children}
        </ProjectContext.Provider> 
    );
}