import React from 'react';
import { Flex, Box, Icon } from '@chakra-ui/react';

import Select from 'react-select';
import { components } from "react-select";
import DropdownIcon from '../../../../assets/images/DropdownIcon.js';
import CustomRadioButtons from '../CustomRadioButtons/CustomRadioButtons.js';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon as={DropdownIcon} sx={iconStyle} />
        </components.DropdownIndicator>
    );
};
function OptionsBoxes({ extraOptions, setValue }) {
    return (
        <Flex sx={mainBoxStyle}>
            {extraOptions &&
                <Box className="buttonsContainer">
                    {extraOptions.map((item, key) => (
                        <Box key={key} className={false === true ? 'buttonItem' : 'buttonItem'}>
                            <Box className="inputContainer">
                                {item.elementType === 'select' &&
                                    <Flex sx={selectContainerStyle}>
                                        {item.icon &&
                                            <Box className="iconContainer">
                                                <Icon as={item.icon} />
                                            </Box>
                                        }
                                        <Box className="selectContainer">
                                            <Select
                                                name={item.name}
                                                styles={selectStyles}
                                                isSearchable={true}
                                                components={{ DropdownIndicator }}
                                                options={item.options}
                                                defaultValue={item.defaultValue ? { label: item.defaultValue, value: item.defaultValue } : { label: item.defaultValue, value: item.defaultValue }}
                                                onChange={selectedOption => {
                                                    setValue(item.name, selectedOption.value);
                                                }}
                                            />
                                        </Box>
                                    </Flex>
                                }

                                {item.elementType === 'customRadio' &&
                                    <CustomRadioButtons options={item.options} defaultValue={item.defaultValue} name={item.name} setValue={setValue} />
                                }

                                {/* <InputDefault label="Keyword" placeholder="ai benefits" {...register('keyword')} defaultValue={fields ? fields.keyword : ''} />
                                <Button onClick={() => setKeywords(false)}><Icon as={CloseModalIcon} /></Button> */}
                            </Box>
                        </Box>
                    ))}
                </Box>
            }

        </Flex>
    );
}

const selectContainerStyle = {
    border: '1px solid #CFD5E3',
    height: '34px',
    lineHeight: '34px',
    borderRadius: '4px',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    '.iconContainer': {
        paddingRight: '5px',
    }
}

const mainBoxStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '.textareaContainer': {
        'textarea': {
            minHeight: '100px',
            fontSize: '16px',
            color: '#8599CD',
            padding: '20px',
            border: '0 none',
            resize: 'none',
            boxShadow: 'none'
        }
    },
    '.extraFieldsContainer': {
        background: '#fff',
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        left: 0,
        width: '550px',
        '.input-item': {
            // display: 'none',
            '.inputContainer': {
                position: 'relative',
                'button': {
                    position: 'absolute',
                    top: '5px',
                    right: '10px',
                    height: '40px',
                    lineHeight: '40px',
                    width: '40px',
                    background: 'transparent',
                    border: '1px solid #CFD5E3',
                    borderRadius: '4px',
                    padding: 0,
                    transition: 'all 0.3s ease',
                    zIndex: 1,
                    'svg': {
                        width: '10px',
                        path: {
                            fill: '#A4C5E0',
                            '&.stroke': {
                                stroke: '#A4C5E0'
                            }

                        }
                    },
                    '&:hover': {
                        background: '#CFD5E3'
                    }
                }
            },
            '&.active': {
                display: 'block'
            }
        }
    },
    '.buttonsContainer': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '.buttonItem': {
            marginRight: '10px',
            marginBottom: '10px',
            '&:last-child': {
                marginRight: 0
            },
            'button': {
                height: '31px',
                lineHeight: '31px',
                paddingLeft: '10px',
                paddingRight: '10px',
                border: '1px solid #CFD5E3',
                fontSize: '14px',
                color: '#1B518F',
                borderRadius: '4px',
                background: 'transparent',
                transition: 'all 0.3s ease',
                fontWeight: 400,
                '&:hover': {
                    background: '#CFD5E3'
                }
            },
            '&.hasValue': {
                'button': {
                    borderColor: '#1E5DFF',
                    color: '#1B518F',
                    fontWeight: 700
                }
            }
        }
    }

}

const iconStyle = {
    svg: {
        fill: '#1B518F'
    }
}

const selectStyles = {
    container: (provided) => ({
        ...provided,
        marginTop: '-5px'
    }),
    control: (provided) => ({
        ...provided,
        padding: 0,
        height: '31px',
        minHeight: '31px',
        border: '0 none',
        borderRadius: '4px',
        color: '#1B518F',
        fontSize: '14px',
        fontWeight: 400,
        position: 'relative',
        boxShadow: 'none !important',
        outline: '0 none !important',
        background: 'transparent'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: '9',
        minWidth: '120px',
        left: '-10px',
    }),
    menuList: (provided) => ({
        ...provided,
        fontSize: '14px',
        paddingTop: 0,
        marginTop: 0
    }),
    option: (provided) => ({
        ...provided,
        paddingTop: '3px',
        paddingBottom: '3px',
        lineHeight: '25px',
        textTransform: 'capitalize'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorContainer: (provided) => ({
        ...provided,
        position: 'absolute',
        right: '-5px',
        top: '3px'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        position: 'relative',
        right: '-5px'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0',
        textTransform: 'capitalize'
    }),
    input: (provided) => ({
        ...provided,
        margin: 0
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#9EC1DE',
        fontSize: '14px',
        fontWeight: 400
    }),
};

export default OptionsBoxes;
