function BrainstormIdeasIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="21" fill="#F2F9FF"/>
            <path d="M18.7646 32H23.9411" stroke="#6D99BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill-rule="evenodd" clipRule="evenodd" d="M21.3528 14.5294C24.5691 14.5294 27.1764 17.1367 27.1764 20.3529C27.1764 22.6388 25.8593 24.6171 23.9426 25.5704L23.9411 26.8235C23.9411 28.253 22.7823 29.4118 21.3528 29.4118C19.9234 29.4118 18.7646 28.253 18.7646 26.8235L18.7643 25.571C16.8469 24.618 15.5293 22.6394 15.5293 20.3529C15.5293 17.1367 18.1366 14.5294 21.3528 14.5294Z" stroke="#FF6712" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.353 11.2941V10" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.8235 13.8824L29.1176 12.5882" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.8823 13.8824L13.5882 12.5882" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.8235 25.5294L29.1176 26.8235" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.8823 25.5294L13.5882 26.8235" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.2941 19.0588H11" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.706 19.0588H30.4119" stroke="#FFA012" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
  }
  
export default BrainstormIdeasIcon; 