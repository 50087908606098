import React from 'react';
import { useLocation } from 'react-router-dom';

import MyProfileHeader from '../../../components/header/my-profile-header/MyProfileHeader.js'
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';
import HorizontallyDotsIcon from '../../../assets/images/HorizontallyDotsIcon.js';
import { Flex, Box, Button, Text, Icon } from '@chakra-ui/react';
import Search from '../../../components/search/Search.js';
import ChatHeader from '../../../components/chat/chat-header/ChatHeader.js';
import ChangePassword from '../../../pages/admin/MyAccount/change-password/ChangePassword.js';

import DocumentName from '../../../components/common/document-name/DocumentName.js';
const DesktopHeader = ({ title, showSearch, documentDetails, refreshState }) => {
  const location = useLocation();
  const handleMouseOver = () => {
    document.documentElement.classList.add('show-sidebar');
  }

  const ReturnMyAccountHeader = () => {
    return (
      <Box sx={myAccountStyle}>
        <Text>My Account</Text>
        {/* <Button onClick={() }>Sign out</Button> */}
      </Box>
    )
  }

  const ReturnTopComponent = () => {
    if (location.pathname.includes('dashboard') || (location.pathname.includes('my-documents') && !location.pathname.includes('my-documents/document/'))) {
      return <Search />;
    } else if (location.pathname.includes('chat')) {
      return <ChatHeader />;
    }
    return <DocumentName title={title} documentDetails={documentDetails} refreshState={refreshState} triggerButtonIcon={HorizontallyDotsIcon} />;
  }

  return (
    <Flex sx={headerContainerStyle} className="mainDesktopHeaderContainer">
      <Box className="closeButtonContainer">
        <Button
          className="closeButton"
          onMouseOver={handleMouseOver}
        >
          <Icon as={CloseModalIcon} />
        </Button>
      </Box>
      <Box className="searchContainer">
        {location.pathname.includes('my-account')
          ? <ReturnMyAccountHeader />
          : <ReturnTopComponent />
        }
      </Box>
      <Box className="rightBox">
        <Box display="flex" justifyContent="right">
          {/* <Notification /> */}
          {location.pathname.includes('my-account')
            ? <Box>
              <ChangePassword />
            </Box>
            : <MyProfileHeader />
          }
        </Box>
      </Box>
    </Flex>
  )
};

const myAccountStyle = {
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px 0 15px',
  'p': {
    fontSize: '18px',
    lineHeight: '20px',
    color: '#1B518F'
  }
}

const headerContainerStyle = {
  background: '#fff',
  padding: '5px 15px',
  position: 'relative',
  borderBottom: '1px solid #CFD5E3',
  zIndex: 10,
  '.closeButtonContainer': {
    position: 'absolute',
    left: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
    '.closeButton': {
      padding: 0,
      width: '34px',
      height: '34px',
      border: '1px solid #D6D6D6',
      borderRadius: '4px',
      background: 'transparent'
    },
  },
  '.searchContainer': {
    maxWidth: '850px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.rightBox': {
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
  }
}

export default DesktopHeader;