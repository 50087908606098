import React, { useState, useEffect } from 'react';
import { Box, Flex, FormControl, Button, Text, Icon } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import CustomOption from '../form/OptionCustom.js';
import Select, { components } from 'react-select';
import api from '../../utils/api.js';
import getPrompts from './prompts/getPrompts.js';
import PromptSettings from './prompt-settings/PromptSettings.js';
import RightArrowButtonIcon from '../../assets/images/RightArrowButtonIcon.js';
import DropdownIcon from '../../assets/images/DropdownIcon.js';
import Library from '../../components/library/Library.js';

import Header from '../../layouts/Header/Header.js';
import { TEMPLATE_ICONS } from '../../assets/images/templates/TemplatesImage.js';
import SpinnerLoader from '../common/spinner/Spinner.js';

import NoWordsRemaining from '../common/no-words-remaining/NoWordsRemaining.js';
import ContentEditor from '../../components/generate-content/content-editors/ContentEditor.js';

import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext.js';
import { useAppContext } from '../../contexts/AppContext.js';

import { useFolderContext } from '../../contexts/FolderContext.js';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon as={DropdownIcon} sx={iconStyle} />
        </components.DropdownIndicator>
    );
};

function GenerateContent() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const [triggerRefresh, setTriggerRefresh] = useState();
    const [loading, setLoading] = useState(false);
    const { availableContentTemplates, openMobileMenu, mobileMenuOpen, } = useAppContext();
    const { fetchDocumentsByDefaultFolder } = useFolderContext();
    const { userDataAndPlans, fetchUserData } = useUserContext();
    const { register, handleSubmit, setValue, getValues, control, watch } = useForm();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const { documentId } = useParams();
    const [docId, setDocId] = useState(documentId);
    const navigate = useNavigate();
    // const isOpen = true;


    // const handleClose = () => {
    // if (window.history.length > 2) {
    //     navigate(-1);
    // } else {
    //     navigate('/admin/dashboard');
    // }
    // navigate('/admin/dashboard');
    // };

    const [template, setTemplate] = useState(type);

    // const [tone, setTone] = useState("");
    // const [targetAudience, setTargetAudience] = useState("");
    // const [TemplateComponent, setTemplateComponent] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const checkForWords = () => {
        if (userDataAndPlans && userDataAndPlans.wordsRemaining > 0) {
            return true;
        }
        return false;
    }

    const refreshState = () => {
        setTriggerRefresh(!triggerRefresh)
    }

    const [projectData, setProjectData] = useState({
        template: type,
        generatedContent: [],
        document: {}
    });

    const IconComponent = template ? TEMPLATE_ICONS[template] : projectData.template ? TEMPLATE_ICONS[projectData.template] : TEMPLATE_ICONS['DefaultTemplate'];

    async function getDocument() {
        try {
            const response = await api.get(`/admin/my-documents/document/${docId}`);
            setProjectData(() => ({
                template: response.data.document.templateType,
                document: response.data.document,
                generatedContent: response.data.generatedContent
            }));
            // setDocument(response.data);
            // setTemplate(response.data.templateType);

        } catch (error) {
            console.error('Error fetching new content:', error);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile === true && mobileMenuOpen === true) {
            openMobileMenu(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDocId(documentId);
    }, [documentId]);

    useEffect(() => {
        if (docId) {
            getDocument();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docId]);

    useEffect(() => {
        if (docId) {
            getDocument();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerRefresh]);

    useEffect(() => {
        setTemplate(type);
        const option = groupedOptions.flatMap(group => group.options).find(opt => opt.value === type);
        setSelectedOption(option);
        setValue('type', type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const getTemplateDetails = (templateType) => {
        let foundTemplate = null;
        if (availableContentTemplates && availableContentTemplates.categories) {
            availableContentTemplates.categories.forEach(category => {
                category.templates.forEach(template => {
                    if (template.type === templateType) {
                        foundTemplate = {
                            name: template.name,
                            type: template.type,
                            description: template.description
                        };
                    }
                });
            });
        }
        return foundTemplate;
    };

    const templateDetails = getTemplateDetails(projectData.template);

    const groupedOptions = availableContentTemplates.categories ? availableContentTemplates.categories.map(category => ({
        label: category.name,
        options: category.templates.map(template => ({
            value: template.type,
            label: template.name,
            description: template.description
        }))
    })) : [];

    const changeTemplate = async (selectedTemplate) => {
        setTemplate(selectedTemplate);

        // if (selectedTemplate) {
        //     const module = await import(`./templates/${selectedTemplate}.js`);
        //     setTemplateComponent(() => module.default);
        // } else {
        //     setTemplateComponent(null);
        // }
        const option = groupedOptions.flatMap(group => group.options).find(opt => opt.value === selectedTemplate);
        setSelectedOption(option);
        setValue('type', selectedTemplate);
    }

    const handleTemplateChange = (selectedTemplate) => {
        changeTemplate(selectedTemplate);
    }

    const handleTemplateChangeNewDoc = async (selectedTemplate) => {
        setDocId(null);
        const newUrl = `/admin/new-project`;
        window.history.pushState({}, '', newUrl);
        changeTemplate(selectedTemplate);
        setProjectData({
            template: selectedTemplate,
            generatedContent: [],
            document: {}
        });
    }

    useEffect(() => {
        if (projectData.template) {
            handleTemplateChange(projectData.template);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData.template]);

    const addInitialObject = (type, content, id) => {
        const initialObject = {
            projectId: type || 'default-id',
            content: content,
            id: id,
            dateGenerated: new Date(),
        };
        setProjectData(prevState => ({
            ...prevState,
            generatedContent: [initialObject, ...prevState.generatedContent],
        }));
    };

    const updateGeneratedContent = (projectId, content, id) => {
        setProjectData(prevState => ({
            ...prevState,
            generatedContent: prevState.generatedContent.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        content: item.content + content, // Append new content
                        dateGenerated: new Date(),
                    };
                } else {
                    return item;
                }
            }),
        }));
    };

    const handleSubmitUpdate = async (data) => {
        setLoading(true);
        const constructedPrompt = getPrompts(projectData.template, data, data.language);
        // console.log('constructedPromptconstructedPromptconstructedPrompt', constructedPrompt);
        // return false;
        const queryData = {
            prompt: JSON.stringify(constructedPrompt),
            projectFields: JSON.stringify(data),
            generatedContent: JSON.stringify(projectData.generatedContent),
            documentId: docId,
        };

        const queryString = new URLSearchParams(queryData).toString();
        const jwtToken = localStorage.token;
        const eventSource = new EventSource(`https://skelet.ai:5000/api/new-content/update?${queryString}&token=${jwtToken}`);
        let trigger = true;
        eventSource.onmessage = function (event) {
            try {

                const data = JSON.parse(event.data);
                if (data.message === "Stream done") {
                    eventSource.close();
                    fetchUserData();
                    setLoading(false);
                } else {
                    const { projectId, content, id } = data;
                    if (trigger === true) {
                        addInitialObject(projectId, content, id);
                        trigger = false;
                    } else {
                        updateGeneratedContent(projectId, content, id)
                    }
                }
            } catch (err) {
                console.error("Error parsing event data:", err);
            }
        };

        eventSource.addEventListener('end', function (event) {
            eventSource.close();
        });
    };


    const handleSubmitInsert = async (data) => {
        setLoading(true);
        const constructedPrompt = getPrompts(template, data, data.language);
        // console.log('constructedPromptconstructedPromptconstructedPrompt', constructedPrompt, data);
        // return false;
        const queryData = {
            prompt: JSON.stringify(constructedPrompt),
            title: (data.title ? data.title : 'Untitled'),
            templateType: data.type,
            projectFields: JSON.stringify(data)
        };

        const queryString = new URLSearchParams(queryData).toString();
        const jwtToken = localStorage.token;
        const eventSource = new EventSource(`https://skelet.ai:5000/api/new-content/insert?${queryString}&token=${jwtToken}`);

        eventSource.onmessage = function (event) {
            try {
                const data = JSON.parse(event.data);

                if (data.message === "Stream done") {
                    fetchUserData();

                    // navigate(`/admin/my-documents/document/${data.projectId}`);
                    const newUrl = `/admin/my-documents/document/${data.projectId}`;
                    setDocId(data.projectId);
                    window.history.pushState({}, '', newUrl);
                    fetchDocumentsByDefaultFolder();
                    eventSource.close();
                    setLoading(false);
                } else {
                    setProjectData(prevState => {
                        if (!prevState.generatedContent.length) {
                            return {
                                ...prevState,
                                template: template,
                                generatedContent: [{
                                    projectId: data.projectId || 'default-id',
                                    content: data.content,
                                    dateGenerated: new Date()
                                }]
                            };
                        } else {
                            // If the array already has an object, update its content
                            const updatedContentObj = {
                                ...prevState.generatedContent[0],
                                content: prevState.generatedContent[0].content + data.content
                            };

                            return {
                                ...prevState,
                                generatedContent: [updatedContentObj]
                            };
                        }
                    });
                }
            } catch (err) {
                console.error("Error parsing event data:", err);
            }
        };

        eventSource.addEventListener('end', function (event) {
            eventSource.close();
            const data = JSON.parse(event.data);
            fetchUserData();
            navigate(`/admin/my-documents/document/${data.projectId}`);
        });
    }

    const onDeleteGeneratedContent = (generatedContentId) => {
        setProjectData(prevState => ({
            ...prevState,
            generatedContent: prevState.generatedContent.filter(item => item.id !== generatedContentId)
        }));
    }
    const onSubmit = (data) => {
        if (docId) {
            handleSubmitUpdate(data);
        } else {
            handleSubmitInsert(data);
        }
    }

    const findTemplateByType = (type) => {
        for (let category of groupedOptions) {
            const template = category.options.find(option => option.value === type);
            if (template) return template;
        }
        return null;
    };

    return (
        <>
            <Header page="document-details" title={projectData?.document?.name} documentDetails={projectData} refreshState={refreshState} />
            <Flex sx={generateContentContainerStyle}>
                <Flex className="boxesContainer">
                    <Box className="topBox">
                        <FormControl sx={formContainerStyle} as="form" onSubmit={handleSubmit(onSubmit)} height="100%">
                            <Box display="flex" flexDirection="column" height="100%">
                                {template !== null &&
                                    <Box style={inputsContainerStyle}>
                                        <PromptSettings control={control} defaultValues={projectData.document} register={register} setValue={setValue} getValues={getValues} user={userDataAndPlans} watch={watch}/>
                                    </Box>
                                }
                                <Box sx={bottomStructureStyle} className={template !== null ? "bottomStructureStyle template-selected" : "bottomStructureStyle"}>
                                    {/* {userDataAndPlans && userDataAndPlans.wordsRemaining > 0 && */}
                                        <Flex className="generateBoxesContainer">
                                            <Box display="flex" alignItems="center" maxWidth="505px" width="100%">
                                                <Box minWidth="40px" sx={svgHeaderStyle}>
                                                    {IconComponent}
                                                </Box>
                                                <Box style={{ minWidth: '230px' }}>
                                                    {/* {templateDetails && */}
                                                    <Select
                                                        styles={selectStyles}
                                                        // defaultMenuIsOpen={true}
                                                        // menuIsOpen={true}
                                                        // onMenuClose={() => {}}
                                                        autoFocus={false}
                                                        components={{ DropdownIndicator, Option: CustomOption }}
                                                        options={groupedOptions}
                                                        placeholder="Please select a template"
                                                        defaultValue={templateDetails ? { label: templateDetails.name, value: templateDetails.type } : selectedOption}
                                                        value={findTemplateByType(selectedOption?.value || templateDetails?.type)}
                                                        onChange={(selectedOption) => docId ? handleTemplateChangeNewDoc(selectedOption.value) : handleTemplateChange(selectedOption.value)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="buttonsContainer">
                                                {checkForWords() === true
                                                    ? <Button mt={4} type="submit" style={formSubmitButtonStyle} isDisabled={template !== null ? false : true}>
                                                        <Box display="flex" alignItems="center">
                                                            {docId
                                                                ? <Text mr="10px">Regenerate</Text>
                                                                : <Text mr="10px">Generate</Text>
                                                            }
                                                            <Icon as={RightArrowButtonIcon} />
                                                        </Box>
                                                    </Button>
                                                    : <NoWordsRemaining />
                                                }
                                            </Box>
                                        </Flex>
                                    {/* } */}
                                </Box>
                            </Box>
                        </FormControl>
                        {loading === true &&
                            <SpinnerLoader />
                        }
                    </Box>
                    <Box flex="2" className="bottomBox">
                        {template === null &&
                            <Box className="libraryContainer">
                                <Library />
                            </Box>
                        }
                        <Box style={rightBoxContentStyle}>
                            {projectData.generatedContent && projectData.generatedContent.length > 0
                                && <ContentEditor isMobile={isMobile} generatedContent={projectData.generatedContent} document={projectData.document} onDeleteGeneratedContent={(generatedContentId) => onDeleteGeneratedContent(generatedContentId)} />
                            }
                        </Box>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
}

const formContainerStyle = {
    maxWidth: '840px',
    marginLeft: 'auto',
    marginRight: 'auto'
}

const inputsContainerStyle = {
    border: '1px solid #CFD5E3',
    borderBottom: '0 none',
    padding: '10px 10px 0',
    borderRadius: '4px'
}

const generateContentContainerStyle = {
    padding: '60px 0 0',
    background: '#fff',
    '.boxesContainer': {
        flexDirection: "column",
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: "calc(100vh - 62px)",
        '.topBox': {
            width: "100%",
            marginBottom: '30px',
            position: 'relative'
        },
        '.bottomBox': {
            '.libraryContainer': {
                maxWidth: '840px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },
    '@media (max-width: 1200px)': {
        paddingTop: '30px',
        '.boxesContainer': {
            flexDirection: 'column',
            height: "auto",
            '.topBox': {
                paddingLeft: '15px',
                paddingRight: '15px',
            },
            '.bottomBox': {
                paddingLeft: '15px',
                paddingRight: '15px',
                overflow: 'hidden',
            },
            '.leftBox': {
                width: '100%',
                maxWidth: '100%'
            },
            '.rightBox': {
                width: '100%',
                height: 'auto',
                paddingTop: '20px',
                paddingBottom: '20px'
            }
        }
    },
    '@media (max-width: 640px)': {
        '.chakra-modal__header': {
            paddingRight: '60px'
        }
    }
}


const svgHeaderStyle = {
    'svg': {
        width: '34px',
        height: '34px'
    }
}

const formSubmitButtonStyle = {
    height: '40px',
    lineHeihgt: '40px',
    background: '#0D78F5',
    color: '#fff',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 700,
    textAlign: 'center',
    width: '190px',
    margin: 0
}


const bottomStructureStyle = {
    border: '1px solid #1E5DFF',
    padding: '10px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    boxShadow: '0px 4px 20px 0px #1E5DFF1A',
    '.generateBoxesContainer': {
        '.buttonsContainer': {
            marginLeft: 'auto'
        }
    },
    '&.template-selected': {
        border: '1px solid #CFD5E3'
    },
    '@media all and (max-width: 640px)': {
        '.generateBoxesContainer': {
            flexDirection: 'column',
            '.buttonsContainer': {
                width: '100%',
                marginTop: '5px',
                'button': {
                    width: '100% !important'
                }
            }
        }
    }
}

const rightBoxContentStyle = {
    textAlign: 'center'
}

const iconStyle = {
    svg: {
        fill: '#1B518F'
    }
}

const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        background: 'white', // or any other color you want
        zIndex: 10,
        boxShadow: '0px 0px 16px 0px #0000001A',
        border: '0 none',
        borderRadius: '6px'
    }),
    control: (provided) => ({
        ...provided,
        border: '0 none !important',
        borderRadius: '0',
        width: '100%',
        background: '#fff',
        boxShadow: 'none !important'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        position: 'relative',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0', // Adjust the padding values as desired
        color: '#1B518F',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#1B518F',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#1B518F',
        fontSize: '18px',
        fontWeight: 600
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '16px',
        padding: '10px',
        color: state.isSelected === true ? '#1E5DFF' : '#1B518F',
        background: state.isFocused ? '#E1F2FF' : '#fff',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        color: '#9EC1DE',
        fontSize: '11px',
        // ... other styles
    }),
    // Add other styles or overrides if necessary
};

export default GenerateContent;
