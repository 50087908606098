import React, { useState, useRef, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Spinner, useToast } from '@chakra-ui/react';
import { useAiImageContext } from '../../contexts/AiImageContext.js';
import { useFolderContext } from '../../contexts/FolderContext.js';
import { useUserContext } from '../../contexts/UserContext.js';
import { useAppContext } from '../../contexts/AppContext.js';
import DefaultForm from '../form/DefaultForm/DefaultForm.js';
import SquareIcon from '../../assets/images/SquareIcon.js';
import api from '../../utils/api.js';

const AiImages = ({ page, projectId, imageDocumentDetails }) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [projectData, setProjectData] = useState({ 
        size: '1024x1024',
        style: 'natural',
        quality: 'standard'
    })
    const { fetchDocumentsByDefaultFolder } = useFolderContext();
    const formRef = useRef(null);
    const navigate = useNavigate();
    const { fetchImageDocumentDetails } = useAiImageContext();
    const {fetchUserData, userDataAndPlans} = useUserContext();
    const {displayNoCreditsModal} = useAppContext();
    const {
        handleSubmit,
        register,
        setValue,
        watch,
    } = useForm();

    useEffect(() => {
        const lastPrompt = imageDocumentDetails?.allPrompts?.[0];
        if(lastPrompt){
            setProjectData((prevState) => ({
                ...prevState,
                size: lastPrompt.size,
                style: lastPrompt.style,
                quality: lastPrompt.quality
              }));
        }
    }, [imageDocumentDetails]);

    const submitForm = async (data) => {
        setLoading(true);
        data.projectId = projectId;
        data.planType = userDataAndPlans.planType;
        data.storage = userDataAndPlans.storage;
        const response = await api.post(`/admin/ai-images/generate`, data);
        if(response.data.error){
            toast({
                description: response.data.error,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return false;
        }else if(response.data.errorCredits){
            displayNoCreditsModal(true);
            setLoading(false);
            return false;
        }
        if (projectId) {
            fetchImageDocumentDetails(projectId);
            setLoading(false);
        } else {
            fetchDocumentsByDefaultFolder();
            navigate(`/admin/ai-images/${response.data.returnData.projectId}`);
        }
        fetchUserData();
    };

    const handleKeyPress = () => {

    }

    const extraOptions = [
        {
            label: 'Size',
            name: 'size',
            elementType: 'select',
            icon: SquareIcon,
            defaultValue: '1024x1024',
            options: [
                {
                    label: '1024x1024',
                    value: '1024x1024'
                },
                {
                    label: '1792x1024',
                    value: '1792x1024'
                },
                {
                    label: '1024x1792',
                    value: '1024x1792'
                }
            ]
        },
        {
            label: 'Style',
            name: 'style',
            elementType: 'select',
            defaultValue: 'natural',
            options: [
                {
                    label: 'Natural',
                    value: 'natural'
                },
                {
                    label: 'Vivid',
                    value: 'vivid'
                }
            ]
        },
        {
            label: 'Quality',
            name: 'quality',
            elementType: 'customRadio',
            defaultValue: 'standard',
            options: [
                {
                    label: 'standard',
                    value: 'standard'
                },
                {
                    label: 'HD',
                    value: 'hd'
                }
            ]
        },
        {
            label: 'Result(s)',
            name: 'results',
            elementType: 'number',
            options: []
        }
    ];

    return (
        <Flex sx={mainContainerStyle}>
            <Box className="formContainer">
                <form ref={formRef} onSubmit={handleSubmit(submitForm)}>
                    <DefaultForm
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        handleKeyPress={() => handleKeyPress()}
                        handleAttachFile={() => null}
                        extraOptions={extraOptions}
                        page="ai-images"
                    />
                </form>
                {loading === true &&
                    <Box className="spinnerContainer">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Box>
                }
            </Box>
        </Flex>
    );
};

const mainContainerStyle = {
    paddingTop: '60px',
    height: '100%',
    '.formContainer': {
        width: '100%',
        maxWidth: '850px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        '.spinnerContainer': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(246, 251, 255, 0.5)',
            zIndex: 9,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    '@media all and (max-width: 1200px)': {
        paddingTop: '30px',
        paddingLeft: '15px',
        paddingRight: '15px'
    }
}

export default AiImages;
