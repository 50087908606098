import React from 'react';

import {
    Flex,
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Icon,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStripeForm from '../PaymentStripeForm.js';
import LowCredits from './low-credits/LowCredits.js';
import Invoices from '../invoices/Invoices.js';
import StarsIcon from '../../../assets/images/StarsIcon.js';

const stripePromise = loadStripe('pk_live_51OUtXIHkjUWvsyoxJbEIv7USBhwQ3n21qAMGNTdwsoKsoTRUNN7Jhacwc1PtYyl1ARfcthkvalBguwnYCV7BCiqO00P7fvhlAt');


function PaymentPlansUpdated({ userDataAndPlans }) {

    return (
        <>
            {userDataAndPlans.planType === 'premium' && userDataAndPlans.wordsRemaining === 5000 &&
                <LowCredits />
            }
            {userDataAndPlans.planType === 'free' && 
                <Flex sx={plansContainerStyle}>
                    <Box className="leftBox">
                        <Box sx={leftBoxTitleStyle}>
                            <Box className="iconContainer">
                                <Icon as={StarsIcon} />
                            </Box>
                            <Box className="textContainer">
                                <Text className="title">Upgrade your account</Text>
                                <Text className="description">Choose <b>premium</b> and get <b>100K credits/month</b></Text>
                            </Box>
                        </Box>
                        <Box sx={creditsContainer}>
                            <Text className="creditsNumber">100.000 credits</Text>
                            <Text className="description">Could be used for one of the above</Text>
                        </Box>
                        <Flex sx={creditsDetails}>
                            <Box className="detailsItem">
                                <Text className="upTo">Up to</Text>
                                <Text className="upToWhat">100 Blog Posts</Text>
                                <Text className="upToDetails">2500 characters each</Text>
                            </Box>
                            <Box className="detailsItem">
                                <Text className="upTo">Up to</Text>
                                <Text className="upToWhat">1K  Tweets</Text>
                                <Text className="upToDetails">2-3 rows of text</Text>
                            </Box>
                            <Box className="detailsItem">
                                <Text className="upTo">Up to</Text>
                                <Text className="upToWhat">100 Audio min.</Text>
                                <Text className="upToDetails">High definition audio</Text>
                            </Box>
                            <Box className="detailsItem">
                                <Text className="upTo">Up to</Text>
                                <Text className="upToWhat">90 Images</Text>
                                <Text className="upToDetails">Standard quality</Text>
                            </Box>

                        </Flex>
                    </Box>
                    <Box className="rightBox">
                        <Box className="rightBoxInner">
                            <Box className="innerDetails">
                                <Elements stripe={stripePromise}>
                                    <Tabs>
                                        <TabList style={tabListStyle}>
                                            <Tab sx={tabButtonStyle}>Monthly</Tab>
                                            <Tab sx={tabButtonStyle}>Yearly <Text className="yearlyPercentDiscountStyle">-10%</Text></Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel style={tabPanelStyle}>
                                                <Text className="price">$19.99 <span>/month*</span></Text>
                                                <Box className="planDetails">
                                                    <Box className="planDetailsItem topTwo">
                                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none">
                                                            <path d="M5.28 1.24847C2.80488 1.98608 1 4.28198 1 7C1 9.718 2.80488 12.0139 5.28 12.7515M18.12 7C18.12 10.3137 15.4373 13 12.128 13C8.8187 13 6.136 10.3137 6.136 7C6.136 3.68629 8.8187 1 12.128 1C15.4373 1 18.12 3.68629 18.12 7Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>100.000 Credits / mo.</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem topTwo">
                                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                                            <path d="M1 8.00001C1 7.57044 1.34823 7.22223 1.77778 7.22223H14.2222C14.6518 7.22223 15 7.57044 15 8.00001V11.1111C15 11.9703 14.3036 12.6667 13.4444 12.6667H2.55556C1.69645 12.6667 1 11.9703 1 11.1111V8.00001Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15 8L13.7151 2.21811C13.557 1.50638 12.9257 1 12.1967 1H3.80338C3.07429 1 2.44303 1.50638 2.28487 2.21811L1 8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M3.33301 10.3333V10.3411" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M5.66699 10.3333V10.3411" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>1 GB Storage</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem bottomTwo">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M1.79729 12.5557C2.74828 14.3539 4.64721 15 8 15C12.6328 15 14.4897 13.7664 14.9055 9.96436M1.79729 12.5557C1.22996 11.4829 1 10.0002 1 8C1 2.64706 2.64706 1 8 1C13.3529 1 15 2.64706 15 8C15 8.72018 14.9702 9.37332 14.9055 9.96436M1.79729 12.5557L4.36477 9.98816C5.00799 9.34499 6.05085 9.34499 6.69405 9.98816L6.83536 10.1295C7.47854 10.7727 8.52146 10.7727 9.16464 10.1295L10.953 8.34111C11.5962 7.69793 12.6391 7.69793 13.2823 8.34111L14.9055 9.96436M6.922 5.40259C6.922 6.24176 6.24094 6.92282 5.40176 6.92282C4.56341 6.92282 3.88235 6.24176 3.88235 5.40259C3.88235 4.56341 4.56341 3.88235 5.40176 3.88235C6.24094 3.88235 6.922 4.56341 6.922 5.40259Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>Access HD Images</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem bottomTwo">
                                                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none">
                                                            <path d="M9.27914 9.24178C10.1173 9.24178 10.7967 9.92125 10.7967 10.7594V11.1477C10.7967 11.7511 10.581 12.3347 10.1886 12.7932C9.12953 14.0305 7.51869 16.6745 5.39612 16.6745C3.27324 16.6745 1.66313 14.0303 0.606171 12.7924C0.214942 12.3343 0 11.7516 0 11.1492V10.7594C0 9.92125 0.679436 9.24178 1.51757 9.24178H9.27914ZM9.27914 10.254H1.51757C1.23847 10.254 1.01222 10.4803 1.01222 10.7594V11.1492C1.01222 11.5106 1.14118 11.8603 1.37592 12.1352C2.22167 13.1256 3.54827 15.4544 5.39612 15.4544C7.24397 15.4544 8.57173 13.1255 9.41964 12.135C9.65508 11.8599 9.78444 11.5098 9.78444 11.1477V10.7594C9.78444 10.4803 9.55818 10.254 9.27914 10.254ZM12.1806 0.0667742C12.4234 -0.0719067 12.7325 0.0123977 12.8712 0.255088C13.6072 1.54295 14 3.00254 14 4.51814C14 6.03884 13.6045 7.50312 12.8638 8.79404C12.7247 9.0365 12.4154 9.12031 12.1729 8.98116C11.9305 8.84209 11.8467 8.53275 11.9859 8.29036C12.6391 7.15168 12.9878 5.86092 12.9878 4.51814C12.9878 3.17987 12.6415 1.89322 11.9924 0.757304C11.8537 0.514621 11.938 0.205462 12.1806 0.0667742ZM5.39612 1.14719C7.25956 1.14719 8.7702 2.65781 8.7702 4.52126C8.7702 6.38469 7.25956 7.89532 5.39612 7.89532C3.53267 7.89532 2.02205 6.38469 2.02205 4.52126C2.02205 2.65781 3.53267 1.14719 5.39612 1.14719ZM9.84214 1.41361C10.0851 1.27545 10.3941 1.36042 10.5323 1.6034C11.0334 2.48468 11.3007 3.48254 11.3007 4.51814C11.3007 5.5561 11.0321 6.55616 10.5288 7.43881C10.3904 7.68168 10.0813 7.7663 9.83849 7.62783C9.5957 7.48936 9.51107 7.18029 9.64955 6.93749C10.0662 6.20654 10.2885 5.37911 10.2885 4.51814C10.2885 3.65913 10.0673 2.8335 9.65231 2.10374C9.51418 1.86075 9.59914 1.55177 9.84214 1.41361ZM5.39612 2.15941C4.0917 2.15941 3.03427 3.21684 3.03427 4.52126C3.03427 5.82567 4.0917 6.8831 5.39612 6.8831C6.70054 6.8831 7.75798 5.82567 7.75798 4.52126C7.75798 3.21684 6.70054 2.15941 5.39612 2.15941Z" fill="white"/>
                                                        </svg>
                                                        <Text>HD Voice Model</Text>
                                                    </Box>
                                                </Box>
                                                <PaymentStripeForm planType="monthly" planName="premium" />
                                                <Text className="bottomPlansText">*All plans are subscription based.</Text>
                                            </TabPanel>
                                            <TabPanel style={tabPanelStyle}>
                                                <Text className="price">$17.99 <span>/month*</span></Text>
                                                <Box className="planDetails">
                                                    <Box className="planDetailsItem topTwo">
                                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none">
                                                            <path d="M5.28 1.24847C2.80488 1.98608 1 4.28198 1 7C1 9.718 2.80488 12.0139 5.28 12.7515M18.12 7C18.12 10.3137 15.4373 13 12.128 13C8.8187 13 6.136 10.3137 6.136 7C6.136 3.68629 8.8187 1 12.128 1C15.4373 1 18.12 3.68629 18.12 7Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>100.000 Credits / mo.</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem topTwo">
                                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                                            <path d="M1 8.00001C1 7.57044 1.34823 7.22223 1.77778 7.22223H14.2222C14.6518 7.22223 15 7.57044 15 8.00001V11.1111C15 11.9703 14.3036 12.6667 13.4444 12.6667H2.55556C1.69645 12.6667 1 11.9703 1 11.1111V8.00001Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15 8L13.7151 2.21811C13.557 1.50638 12.9257 1 12.1967 1H3.80338C3.07429 1 2.44303 1.50638 2.28487 2.21811L1 8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M3.33301 10.3333V10.3411" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M5.66699 10.3333V10.3411" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>1 GB Storage</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem bottomTwo">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M1.79729 12.5557C2.74828 14.3539 4.64721 15 8 15C12.6328 15 14.4897 13.7664 14.9055 9.96436M1.79729 12.5557C1.22996 11.4829 1 10.0002 1 8C1 2.64706 2.64706 1 8 1C13.3529 1 15 2.64706 15 8C15 8.72018 14.9702 9.37332 14.9055 9.96436M1.79729 12.5557L4.36477 9.98816C5.00799 9.34499 6.05085 9.34499 6.69405 9.98816L6.83536 10.1295C7.47854 10.7727 8.52146 10.7727 9.16464 10.1295L10.953 8.34111C11.5962 7.69793 12.6391 7.69793 13.2823 8.34111L14.9055 9.96436M6.922 5.40259C6.922 6.24176 6.24094 6.92282 5.40176 6.92282C4.56341 6.92282 3.88235 6.24176 3.88235 5.40259C3.88235 4.56341 4.56341 3.88235 5.40176 3.88235C6.24094 3.88235 6.922 4.56341 6.922 5.40259Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <Text>Access HD Images</Text>
                                                    </Box>
                                                    <Box className="planDetailsItem bottomTwo">
                                                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none">
                                                            <path d="M9.27914 9.24178C10.1173 9.24178 10.7967 9.92125 10.7967 10.7594V11.1477C10.7967 11.7511 10.581 12.3347 10.1886 12.7932C9.12953 14.0305 7.51869 16.6745 5.39612 16.6745C3.27324 16.6745 1.66313 14.0303 0.606171 12.7924C0.214942 12.3343 0 11.7516 0 11.1492V10.7594C0 9.92125 0.679436 9.24178 1.51757 9.24178H9.27914ZM9.27914 10.254H1.51757C1.23847 10.254 1.01222 10.4803 1.01222 10.7594V11.1492C1.01222 11.5106 1.14118 11.8603 1.37592 12.1352C2.22167 13.1256 3.54827 15.4544 5.39612 15.4544C7.24397 15.4544 8.57173 13.1255 9.41964 12.135C9.65508 11.8599 9.78444 11.5098 9.78444 11.1477V10.7594C9.78444 10.4803 9.55818 10.254 9.27914 10.254ZM12.1806 0.0667742C12.4234 -0.0719067 12.7325 0.0123977 12.8712 0.255088C13.6072 1.54295 14 3.00254 14 4.51814C14 6.03884 13.6045 7.50312 12.8638 8.79404C12.7247 9.0365 12.4154 9.12031 12.1729 8.98116C11.9305 8.84209 11.8467 8.53275 11.9859 8.29036C12.6391 7.15168 12.9878 5.86092 12.9878 4.51814C12.9878 3.17987 12.6415 1.89322 11.9924 0.757304C11.8537 0.514621 11.938 0.205462 12.1806 0.0667742ZM5.39612 1.14719C7.25956 1.14719 8.7702 2.65781 8.7702 4.52126C8.7702 6.38469 7.25956 7.89532 5.39612 7.89532C3.53267 7.89532 2.02205 6.38469 2.02205 4.52126C2.02205 2.65781 3.53267 1.14719 5.39612 1.14719ZM9.84214 1.41361C10.0851 1.27545 10.3941 1.36042 10.5323 1.6034C11.0334 2.48468 11.3007 3.48254 11.3007 4.51814C11.3007 5.5561 11.0321 6.55616 10.5288 7.43881C10.3904 7.68168 10.0813 7.7663 9.83849 7.62783C9.5957 7.48936 9.51107 7.18029 9.64955 6.93749C10.0662 6.20654 10.2885 5.37911 10.2885 4.51814C10.2885 3.65913 10.0673 2.8335 9.65231 2.10374C9.51418 1.86075 9.59914 1.55177 9.84214 1.41361ZM5.39612 2.15941C4.0917 2.15941 3.03427 3.21684 3.03427 4.52126C3.03427 5.82567 4.0917 6.8831 5.39612 6.8831C6.70054 6.8831 7.75798 5.82567 7.75798 4.52126C7.75798 3.21684 6.70054 2.15941 5.39612 2.15941Z" fill="white"/>
                                                        </svg>
                                                        <Text>HD Voice Model</Text>
                                                    </Box>
                                                </Box>
                                                <PaymentStripeForm planType="yearly" planName="premium" />
                                                <Text className="bottomPlansText">*All plans are subscription based.</Text>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Elements>
                            </Box>
                        </Box>
                    </Box>
                </Flex>
            }
            {stripePromise && (
                <Box sx={invoiceMainContainerStyle}>
                    <Invoices stripePromise={stripePromise} />
                </Box>
            )}
        </>
    )
}

const plansContainerStyle = {
    background: '#F6FBFF',
    padding: '30px 0 0',
    position: 'relative',
    '.leftBox': {
        position: 'relative',
        zIndex: 2,
        flex: '0 0 50%',
        paddingRight: '15px'
    },
    '.rightBox': {
        position: 'relative',
        zIndex: 2,
        flex: '0 0 50%',
        paddingLeft: '15px',
        '.rightBoxInner': {
            background: '#1E5DFF',
            padding: '25px 0',
            borderRadius: '4px',
            '.innerDetails': {
                maxWidth: '210px',
                marginLeft: 'auto',
                marginRight: 'auto',
                '.price': {
                    fontSize: '34px',
                    lineHeight: '40px',
                    color: '#fff',
                    marginBottom: '15px',
                    'span': {
                        fontSize: '60%',
                        position: 'relative',
                    }
                },
                '.planDetails': {
                    marginBottom: '25px',
                    '.planDetailsItem': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '5px',
                        'p': {
                            paddingLeft: '10px',
                            fontSize: '14px',
                            color: '#fff'
                        },
                        '&.topTwo': {
                            fontWeight: 700
                        },
                        '&:nth-of-type(2)': {
                            marginBottom: '15px'
                        }
                    }
                },
                '.bottomPlansText': {
                    color: '#fff',
                    fontSize: '14px',
                    lineHeight: '16px',
                    paddingTop: '15px',
                    textAlign: 'center',
                }
            }
            
        }
    },
    '&:before': {
        content: "''",
        left: '-1000%',
        right: '-1000%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        background: '#F6FBFF',
        zIndex: 1
    },
    '@media all and (max-width: 1200px)': {
        flexWrap: 'wrap',
        overflow: 'hidden',
        '.leftBox': {
            flex: '0 0 100%',
            paddingLeft: '15px'
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingLeft: 0
        },
    },
    
}


const creditsDetails = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 -10px',
    '.detailsItem': {
        flex: '0 0 50%',
        padding: '0 10px',
        marginBottom: '35px',
        '.upTo': {
            fontSize: '10px',
            lineHeight: '14px',
            color: '#8599CD'
        },
        '.upToWhat': {
            fontSize: '16px',
            lineHeight: '22px',
            color: '#1B518F',
            fontWeight: 600
        },
        '.upToDetails': {
            fontSize: '14px',
            lineHeight: '20px',
            color: '#8599CD'
        }
    }

}

const creditsContainer = {
    marginBottom: '30px',
    '.creditsNumber': {
        fontSize: '34px',
        lineHeight: '38px',
        color: '#1E5DFF',
        fontWeight: 600
    },
    '.description': {
        fontSize: '14px',
        lineHeight: '18px',
        color: '#1B518F'
    }
}

const leftBoxTitleStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '30px',
    '.iconContainer': {
        marginRight: '10px'
    },
    '.textContainer': {
        '.title': {
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '22px',
            color: '#1B518F'
        },
        '.description': {
            fontSize: '14px',
            lineHeight: '16px',
            color: '#1B518F'
        }
    }
}



const invoiceMainContainerStyle = {
    paddingTop: '50px'
}

const tabPanelStyle = {
    padding: 0
}

const tabListStyle = {
    border: '0 none',
    justifyContent: 'center',
    marginBottom: '40px'
}

const tabButtonStyle = {
    height: '40px',
    width: '120px',
    lineHeight: '40px',
    textAlign: 'center',
    border: '2px solid #1E5DFF',
    fontSize: '12px',
    color: '#fff',
    borderRadius: '4px',
    margin: 0,
    padding: 0,
    fontWeight: 700,
    background: '#376FFF',
    textTransform: 'uppercase',
    '.yearlyPercentDiscountStyle': {
        height: '21px',
        width: '38px',
        lineHeight: '21px',
        border: '1px solid #FF1284',
        color: '#FF1284',
        borderRadius: '21px',
        fontSize: '9px',
        fontWeight: 700,
        marginLeft: '5px',
        display: 'none',
    },
    '&[aria-selected=true]': {
        background: '#1B518F',
        color: '#fff',
        borderColor: 'transparent',
        '.yearlyPercentDiscountStyle': {
            borderColor: '#fff',
            color: '#fff'
        }
    },
    '&:first-of-type': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },
    '&:last-of-type': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    },
}
export default PaymentPlansUpdated;