import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTtsContext } from '../../contexts/TtsContext.js';
import { useAppContext } from '../../contexts/AppContext.js';
import { useFolderContext } from '../../contexts/FolderContext.js';
import { useUserContext } from '../../contexts/UserContext.js';
import { Flex, Box, Spinner, useToast } from '@chakra-ui/react';
import VoiceIcon from '../../assets/images/VoiceIcon.js';
import DefaultForm from '../form/DefaultForm/DefaultForm.js';
import SpeedIcon from '../../assets/images/SpeedIcon.js';
import Details from './Details/Details.js';
import api from '../../utils/api.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const TextToSpeech = ({ page, details }) => {
  const toast = useToast();
  const { fetchUserData, userDataAndPlans } = useUserContext();
  const isMobile = window.innerWidth <= 1200;
  const { displayNoCreditsModal, mobileMenuOpen, openMobileMenu } = useAppContext();

  const { fetchDocumentsByDefaultFolder } = useFolderContext();
  const { fetchTtsDetails } = useTtsContext();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const {
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    if (isMobile === true && mobileMenuOpen === true) {
      openMobileMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (data) => {
    setLoading(true);
    try {
      data.projectId = details?.document.id;
      data.storage = userDataAndPlans.storage;
      data.planType = userDataAndPlans.planType;
      const response = await api.post(`/admin/text-to-speech/generate`, data);
      if (response.data.error) {
        toast({
          description: response.data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return false;
      }else if(response.data.errorCredits){
        displayNoCreditsModal(true);
        setLoading(false);
        return false;
      }
      if (data.projectId) {
        setLoading(false);
        fetchTtsDetails(data.projectId);
      } else {
        navigate(`/admin/text-to-speech/${response.data.projectId}`);
        fetchDocumentsByDefaultFolder();
      }
      fetchUserData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPress = () => {

  }

  const extraOptions = [
    {
      label: 'Voice',
      name: 'voice',
      icon: VoiceIcon,
      elementType: 'select',
      defaultValue: 'alloy',
      options: [
        {
          label: 'alloy',
          value: 'alloy'
        },
        {
          label: 'echo',
          value: 'echo'
        },
        {
          label: 'fable',
          value: 'fable'
        },
        {
          label: 'onyx',
          value: 'onyx'
        },
        {
          label: 'nova',
          value: 'nova'
        },
        {
          label: 'shimmer',
          value: 'shimmer'
        }
      ]
    },
    {
      label: 'Speed',
      name: 'speed',
      icon: SpeedIcon,
      elementType: 'select',
      defaultValue: '1.0',
      options: [
        {
          label: '0.25',
          value: '0.25'
        },
        {
          label: '0.5',
          value: '0.5'
        },
        {
          label: '1.0',
          value: '1.0'
        },
        {
          label: '2.0',
          value: '2.0'
        },
        {
          label: '3.0',
          value: '3.0'
        },
        {
          label: '4.0',
          value: '4.0'
        }
      ]
    },
    //   {
    //     label: 'Format',
    //     name: 'format',
    //     elementType: 'select',
    //     options: [
    //         {
    //             label: 'flac',
    //             value: 'flac'
    //         },
    //         {
    //           label: 'mp3',
    //           value: 'mp3'
    //         },
    //         {
    //           label: 'mp4',
    //           value: 'mp4'
    //         },
    //         {
    //           label: 'mpeg',
    //           value: 'mpeg'
    //         },
    //         {
    //           label: 'mpga',
    //           value: 'mpga'
    //         },
    //         {
    //           label: 'm4a',
    //           value: 'm4a'
    //         },
    //         {
    //           label: 'ogg',
    //           value: 'ogg'
    //         },
    //         {
    //           label: 'wav',
    //           value: 'wav'
    //         },
    //         {
    //           label: 'webm',
    //           value: 'webm'
    //         }
    //     ]
    // },
  ];

  return (
    <Flex sx={mainContainerStyle}>
      <Box className="formContainer">
        <form ref={formRef} onSubmit={handleSubmit(submitForm)}>
          <DefaultForm
            register={register}
            setValue={setValue}
            watch={watch}
            handleKeyPress={() => handleKeyPress()}
            handleAttachFile={() => null}
            extraOptions={extraOptions}
            page="tts"
          />
        </form>
        {loading === true &&
          <Box className="spinnerContainer">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Box>
        }

        {page === 'details' &&
          <>
            {isMobile === true
              ? <Details details={details} />
              : <SimpleBar style={{ maxHeight: 'calc(100vh - 190px)' }}>
                <Details details={details} />
              </SimpleBar>
            }
          </>
        }
      </Box>
    </Flex>
  );
};

const mainContainerStyle = {
  paddingTop: '60px',
  '.formContainer': {
    width: '100%',
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '.spinnerContainer': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(246, 251, 255, 0.5)',
      zIndex: 9,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  '@media all and (max-width: 1200px)': {
    paddingTop: '30px',
    '.formContainer': {
      paddingLeft: '15px',
      paddingRight: '15px'
    }
  }
}

export default TextToSpeech;