function DownloadImageIcon() {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
            <path d="M13 16L13 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 11.6665L12.884 15.5505C12.948 15.6145 13.052 15.6145 13.116 15.5505L17 11.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 14.3335V15.6668V19.6668C1 21.1396 2.19391 22.3335 3.66667 22.3335H22.3333C23.8061 22.3335 25 21.1396 25 19.6668V15.6668V14.3335" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}
  
export default DownloadImageIcon;