import React, { createContext, useContext, useState, useEffect } from 'react';
import { useToast} from "@chakra-ui/react";
import api from '../utils/api.js';

const FolderContext = createContext();

export const useFolderContext = () => {
    return useContext(FolderContext);
};

export const FolderProvider = ({ children }) => {
    const [folders, setFolders] = useState([]);
    const [menuDocuments, setMenuDocuments] = useState([]);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [defaultFolder, setDefaultFolder] = useState([]);
    const toast = useToast();

    useEffect(() => {
        fetchDefaultFolder();
        fetchDocumentsByDefaultFolder();
        fetchFolders();
    }, []);

    const addFolder = (newFolder) => {
        setFolders(prevFolders => [...prevFolders, newFolder]);
    };

    const fetchDefaultFolder = async () => { 
        try {
            const response = await api.post('/admin/folder/get-default-folder');
            setDefaultFolder(response.data);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    };

    const fetchDocumentsByDefaultFolder = async () => {
        try {
            const response = await api.post('/admin/folder/get-documents-by-default-folder');
            setMenuDocuments(response.data);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    }
    

    const changeDefaultFolder = async (defaultFolderId) => {
        try {
            await api.post('/admin/folder/change-default-folder', {defaultFolderId: defaultFolderId});
            fetchDefaultFolder();
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    };

    const fetchFolders = async (sortType) => {
        try {
            const body = { sortOption: sortType ? sortType : 'updatedAt' };
            const response = await api.post(`/admin/my-documents`, body);
            setFolders(response.data);
            const total = response.data.reduce((acc, folder) => acc + (folder.projectCount || 0), 0);
            setTotalDocuments(total);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    }

    // const fetchOnlyFolders = async (sortType) => {
    //     try {
    //         const body = { sortOption: sortType ? sortType : 'updatedAt' };
    //         const response = await api.post(`/admin/my-documents/only-folders`, body);
    //         setFolders(response.data);
    //     } catch (error) {
    //         console.error("Error fetching folders:", error);
    //     }
    // }

    const deleteFolder = async (folderId) => {
        try {
            await api.post(`/admin/folder/${folderId}/delete`);
            const updatedFolders = folders.filter(folder => folder.id !== folderId);
            setFolders(updatedFolders);
        } catch (error) {
            toast({
                title: "This folder is in use.",
                description: 'Please select other folder from sidebar and try again.',
                status: "error",
                duration: 6000,
                isClosable: true,
            });
            console.error("Failed to delete folder:", error);
        }
    };

    const renameFolder = async (folderId, newName) => {
        try {
            const response = await api.post(`/admin/folder/${folderId}/rename`, { name: newName });
            
            // Update the folders list in the state after renaming
            setFolders(prevFolders => prevFolders.map(folder => 
                folder.id === folderId ? { ...folder, name: newName } : folder
            ));
            
            return response.data;
        } catch (error) {
            console.error("Error renaming the folder:", error);
        }
    }

    const value = {
        folders,
        addFolder,
        deleteFolder,
        fetchFolders,
        renameFolder,
        defaultFolder,
        totalDocuments,
        changeDefaultFolder,
        fetchDefaultFolder,
        menuDocuments,
        fetchDocumentsByDefaultFolder
    };

    return (
        <FolderContext.Provider value={value}>
            {children}
        </FolderContext.Provider> 
    );
}