function AiTextToSpeeach() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <line className="stroke" x1="11.4209" y1="21.1308" x2="11.4209" y2="4.76673" stroke="#1E5DFF"/>
            <line className="stroke" x1="20.502" y1="16.7256" x2="20.502" y2="10.3822" stroke="#1E5DFF"/>
            <line className="stroke" x1="16.4316" y1="19.1926" x2="16.4316" y2="7.91529" stroke="#1E5DFF"/>
            <circle className="fill" cx="15.739" cy="21.821" r="1.17898" transform="rotate(180 15.739 21.821)" fill="#1E5DFF"/>
            <circle className="fill" cx="11.3855" cy="21.821" r="1.17898" transform="rotate(180 11.3855 21.821)" fill="#1E5DFF"/>
            <circle className="fill" cx="7.03294" cy="21.821" r="1.17898" transform="rotate(180 7.03294 21.821)" fill="#1E5DFF"/>
            <line className="stroke" y1="-0.5" x2="6.34347" y2="-0.5" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 2 16.7256)" stroke="#1E5DFF"/>
            <line className="stroke" y1="-0.5" x2="11.2773" y2="-0.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 6.06934 19.1926)" stroke="#1E5DFF"/>
            <path className="fill" d="M5.25451 6.13149C5.25451 4.50531 5.9005 2.94573 7.05039 1.79584C8.20028 0.645957 9.75986 -4.26399e-05 11.386 -4.24977e-05C13.0122 -4.23555e-05 14.5718 0.645957 15.7217 1.79584C16.8716 2.94573 17.5176 4.50531 17.5176 6.13149L16.5839 6.13149C16.5839 4.75293 16.0363 3.43083 15.0615 2.45603C14.0867 1.48124 12.7646 0.93361 11.386 0.933609C10.0075 0.933609 8.68538 1.48124 7.71058 2.45603C6.73579 3.43083 6.18816 4.75293 6.18816 6.13149L5.25451 6.13149Z" fill="#1E5DFF"/>
        </svg>

    );
}
  
export default AiTextToSpeeach;