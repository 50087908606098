import React, {useState, useRef, useEffect} from 'react';
import {
    Flex,
    Box,
    Input,
    Checkbox
} from "@chakra-ui/react";
import {formatLinks} from '../../../../utils/getLinks.js';
import {Link} from 'react-router-dom';
import ClockIcon from '../../../../assets/images/ClockIcon.js';
import { formatDateAndTime } from '../../../../utils/formatDate.js';
import { useDocumentContext } from '../../../../contexts/DocumentContext.js';
import { TEMPLATE_ICONS } from '../../../../assets/images/templates/TemplatesImage.js';
import CustomTooltip from '../../../common/custom-tooltip/CustomTooltip.js';
import GlobalMenu from '../../../common/global-menu/GlobalMenu.js';
import RenameDocumentIcon from '../../../../assets/images/RenameDocumentIcon.js';
import DeleteDocumentIcon from '../../../../assets/images/DeleteDocumentIcon.js';
import FileIcon from '../../../../assets/images/FileIcon.js';

function DocumentsTableItem({ documentItem, onRename, onDelete, selectedDocs, setSelectedDocs }) {
    const [rename, setRename] = useState(false);
    const [title, setTitle] = useState("");
    const inputRef = useRef(null);
    const { renameDocument } = useDocumentContext();

    const icon = TEMPLATE_ICONS[documentItem.templateType]; 

    const isChecked = selectedDocs.includes(documentItem.id);
    
    const menuItems = [
        {
            label: 'Rename document',
            icon: RenameDocumentIcon,
            onClick: () => setRename(true)
        },
        {
            label: 'Delete document',
            icon: DeleteDocumentIcon,
            onClick: () => onDelete(documentItem.id)
        }
    ]

    const handleCheckboxChange = () => {
        if (isChecked) {
            setSelectedDocs(prev => prev.filter(id => id !== documentItem.id));
        } else {
            setSelectedDocs(prev => [...prev, documentItem.id]);
        }
    };
  
    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                if (title !== documentItem.name && title.trim() !== "") {
                    // Save the data
                    renameDocument(documentItem.id, title)
                        .then(() => {
                            const updatedDocument = { ...documentItem, name: title };
                            onRename(updatedDocument);  // Call the callback after successful update
                            setRename(false);
                        });
                }else{
                    setRename(false);
                }
            }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, documentItem.name, renameDocument, documentItem.id]);;

    useEffect(() => {
        if (rename && inputRef.current) {
            inputRef.current.focus();
        }
    }, [rename]);
    return (
        <Flex sx={rowStyle} bg={isChecked ? '#EAF5FF' : 'rgba(255, 255, 255, 1)'}>
            <Box sx={documentTitleStyle}>
                <Checkbox sx={checkboxStyleRow} isChecked={isChecked} onChange={handleCheckboxChange}/>
                <Box className="titleContainer">
                    {icon}
                    {rename === false 
                        ? <Link to={formatLinks(`${documentItem.id}`, documentItem.templateType)} style={{display: 'block'}}>{documentItem.name.length > 55 ? documentItem.name.substring(0, 55) + "..." : documentItem.name}</Link>
                        : <Input ref={inputRef} defaultValue={documentItem.name} onChange={(e) => setTitle(e.target.value)} style={editInputStyle}/>
                    }
                </Box>
                
            </Box>
            <Box className="iconColumn">
                <CustomTooltip label={formatDateAndTime(documentItem.updatedAt)} CustomIcon={ClockIcon}/>
            </Box>
            <Box  className="iconColumn">
                <CustomTooltip label={documentItem.templateType} CustomIcon={FileIcon} />
            </Box>
            <Flex justifyContent="right" flexGrow="1" className="actionButtons">
                <GlobalMenu menuItems={menuItems}/>
            </Flex>
        </Flex>
    );
}

const rowStyle = {
    border: '1px solid rgba(13, 120, 245, 0.2)',
    borderRadius: '4px',
    marginBottom: '10px',
    height:'40px',
    lineHeight: '40px',
    padding: '0 15px',
    transition: 'all 0.3s ease',
    alignItems: "center",
    position: 'relative',
    '.iconColumn' :{ 
        marginRight: '10px'
    },
    '.actionButtons': {
        display: 'none',
        position:'absolute',
        right: '10px',
        top: '5px'
    },
    '&:hover': {
        background: '#fff',
        '.actionButtons': {
            display: 'flex'
        }
    },
    
    '@media (max-width: 1200px)': {
        padding: '0 10px',
        '.templateType': {
            display: 'none'
        },
        '.countDocuments': {
            display: 'none'
        },
        '.updatedAt': {
            display: 'none'
        },
        '.iconColumn': {
            display: 'none'
        },
        '.actionButtons': {
            opacity: 1,
            right: 0,
            top: '2px',
            display: 'flex'
        }
    }
}

const checkboxStyle = {
    position: 'relative',
    bottom: '-10px',
    marginRight: '20px',
    width: '20px',
    height: '20px',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    '.chakra-checkbox__control': {
        width: '20px',
        height: '20px',
        _checked: {
            bg: '#1E5DFF',
            borderColor: '#1E5DFF',
            color: 'white',
        }
    } 
}

const checkboxStyleRow = {
    ...checkboxStyle,
    position: 'absolute',
    left:0,
    top: '10px',
    '@media (max-width: 1200px)': {
        width: '15px',
        height: '15px',
        '.chakra-checkbox__control': {
            width: '15px',
            height: '15px'
        }
    }
}

const editInputStyle = {
    height:'34px',
    border: '0 none'
}

const documentTitleStyle = {
    color: '#1B518F',
    fontSize: '14px',
    lineHeihgt: '16px',
    fontWeight: 600,
    position:'relative',
    paddingLeft: '40px',
    marginRight: '20px',
    '.titleContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        'svg': {
            width: '22px',
            height: '22px',
            marginRight: '5px',
        },
    },
    'a':{
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    '&:hover': {
        color: '#1E5DFF'
    },
    '@media (max-width:1200px)':{
        width: '100%',
        paddingRight: '25px',
        paddingLeft: '25px',
    }
}

export default DocumentsTableItem;
