// MainLayout.js
import React, { useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../components/dashboard/sidebar/Sidebar.js'
import DashboardPage from '../pages/admin/DashboardPage.js';
import NewProjectPage from '../pages/admin/NewProjectPage.js';
import FolderDetails from '../pages/admin/FolderDetails.js';
import DocumentDetailsPage from '../pages/admin/DocumentDetailsPage.js';
import ProjectDetails from '../pages/admin/ProjectDetails.js';
import Profile from '../pages/admin/MyAccount/Profile.js';
import Billing from '../pages/admin/MyAccount/Billing.js';
import PaymentPage from '../pages/admin/MyAccount/payment/PaymentPage.js';
import SuccessPayment from '../components/payment-stripe/SuccessPayment.js';
import LibraryPage from '../pages/admin/LibraryPage.js';
import ChatPage from '../pages/admin/ChatPage.js';
import AiImages from '../pages/admin/AiImagesPage.js';
import AiImagesDetails from '../pages/admin/AiImagesDetailsPage.js';
import TextToSpeechPage from '../pages/admin/TextToSpeech.js';
import TextToSpeechDetailsPage from '../pages/admin/TextToSpeechDetailsPage.js';
import FoodCheckerPage from '../pages/admin/tools/food-checker/index.js';

import MyDocumentsPage from '../pages/admin/MyDocumentsPage.js';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const getPageClass = () => {
    const match = currentPath.match(/\/admin\/([^/]+)/);

    return match ? match[1] : '';
  };
  useEffect(() => {
    document.documentElement.classList.remove('front-layout');
    document.documentElement.classList.add('dashboard-layout');
  }, []);

  useEffect(() => {
    const pageClass = getPageClass();

    if (pageClass) {
      document.documentElement.classList.add(`page-${pageClass}`);
    }
    document.documentElement.classList.remove(`show-sidebar`);
    return () => {
      if (pageClass) {
        document.documentElement.classList.remove(`page-${pageClass}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  return (
    <Flex height='100%'>
      <Sidebar height="100%" />
      <Box sx={rightBoxStyle}>
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/library" element={<LibraryPage />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/ai-images" element={<AiImages />} />
          <Route path="/ai-images/:id" element={<AiImagesDetails />} />
          <Route path="/my-documents" element={<MyDocumentsPage />} />
          <Route path="/my-documents/:id" element={<FolderDetails />} />
          <Route path="/my-documents/document/:documentId" element={<DocumentDetailsPage />} />
          <Route path="/new-project" element={<NewProjectPage />} />
          <Route path="/project/:id" element={<ProjectDetails />} />

          <Route path="/text-to-speech" element={<TextToSpeechPage />} />
          <Route path="/text-to-speech/:id" element={<TextToSpeechDetailsPage />} />


          <Route path="/my-account/payment" element={<PaymentPage />} />
          <Route path="/my-account/edit-profile" element={<Profile />} />
          <Route path="/my-account/billing" element={<Billing />} />
          <Route path="/my-account/success-payment" element={<SuccessPayment />} />
          <Route path="/tools/food-checker" element={<FoodCheckerPage />} />
        </Routes>
      </Box>
    </Flex>
  );
};

const rightBoxStyle = {
  flexGrow: 1,
  maxWidth: '100%',
  background: '#fff',
  height: '100%'
}

export default MainLayout;