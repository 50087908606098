function DeleteDocumentIcon() {
    return (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" >
            <path d="M5.47703 2.57895C5.80216 1.65906 6.67948 1 7.71069 1C8.7419 1 9.61924 1.65906 9.94435 2.57895" stroke="#8599CD" strokeLinecap="round"/>
            <path d="M14.4211 4.15796H1" stroke="#8599CD" strokeLinecap="round"/>
            <path d="M13.1052 6.13159L12.742 11.5783C12.6023 13.6742 12.5324 14.7223 11.8495 15.3611C11.1666 16 10.1163 16 8.01566 16H7.40516C5.30449 16 4.25417 16 3.57127 15.3611C2.88837 14.7223 2.81851 13.6742 2.67877 11.5783L2.31566 6.13159" stroke="#8599CD" strokeLinecap="round"/>
            <path d="M5.73685 8.10522L6.13158 12.0526" stroke="#8599CD" strokeLinecap="round"/>
            <path d="M9.68421 8.10522L9.28947 12.0526" stroke="#8599CD" strokeLinecap="round"/>
        </svg>
    );
  }
  
  export default DeleteDocumentIcon;