import React, { useState } from 'react';
import { Box } from "@chakra-ui/react";
import api from '../../../utils/api.js';

import 'react-quill/dist/quill.snow.css';
import ContentEditorItem from './content-editor-item/ContentEditorItem.js';
import DeleteConfirmationModal from '../../modals/delete-confirmation/DeleteConfirmationModal.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css'

function ContentEditor(props) {
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const handleDeleteModalOpen = (documentId) => {
        setDocumentToDelete(documentId);
        setDeleteModalOpen(true);
    }
    const handleDelete = () => {
        if (documentToDelete) {
            try {
                api.post('/admin/my-documents/delete-document-content', {
                    documentId: props.document.id,
                    generatedContentId: documentToDelete
                });

                props.onDeleteGeneratedContent(documentToDelete);
                setDeleteModalOpen(false);

            } catch (error) {
                console.error("Failed to fetch folders and projects:", error);
            }
        }
    }

    const ContentBoxes = () => {
        return (
            <Box sx={boxesStyle}>
                {props.generatedContent.map((content, index) => (
                    <ContentEditorItem
                        key={index}
                        content={content}
                        documentDetails={props.document}
                        onDelete={(documentId) => handleDeleteModalOpen(documentId)}
                    />
                ))}
            </Box>
        )
    }

    return (
        <Box>
            {props.generatedContent && props.generatedContent.length > 0 &&
                <>
                    {props.isMobile === true
                        ? <Box>
                            <ContentBoxes />
                        </Box>
                        : <Box><SimpleBar style={{ maxHeight: window.innerWidth <= 1200 ? '' : 'calc(100vh - 380px)' }}>
                            <ContentBoxes />
                        </SimpleBar>
                        </Box>
                    }
                </>
            }
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={handleDelete}
                itemName="document"
            />
        </Box>
    );
}

const boxesStyle = {
    '.boxesContainer': {
        height: 'auto !important',
        position: 'relative',
        '.contentMainBox': {
            borderRadius: '4px',
            marginBottom: '10px',
            paddingTop: '30px',
            paddingBottom: '30px',
            textAlign: 'left',
            position: 'relative',
        },
        '&:nth-child(odd)': {
            background: '#F6FBFF',
            '&:before': {
                content: "''",
                position: 'absolute',
                background: '#F6FBFF',
                left: '-1000%',
                right: '-1000%',
                top: 0,
                bottom: 0
            }
        },
        '&:nth-child(even)': {
            background: '#fff'
        },
    },
    '@media (max-width: 1200px)': {
        '.boxesContainer': {
            width: '100%'
        }
    },
    '.simplebar-scrollbar.simplebar-visible.simplebar-vertical': {
        width: 0
    }

}
export default ContentEditor;
