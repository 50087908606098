function AiChatSupportIcon() {
    return (
        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line className="stroke" x1="10.4473" y1="-2.18557e-08" x2="10.4473" y2="5.52632" stroke="#1E5DFF"/>
            <line className="stroke" x1="19.2891" y1="8.84229" x2="19.2891" y2="19.8949" stroke="#1E5DFF"/>
            <line className="stroke" x1="1.60498" y1="8.84229" x2="1.60498" y2="19.8949" stroke="#1E5DFF"/>
            <circle className="stroke" cx="10.5002" cy="6.07879" r="1.15789" fill="#1E5DFF" stroke="#1E5DFF"/>
            <circle className="stroke" cx="1.65789" cy="20.4474" r="1.15789" fill="#1E5DFF" stroke="#1E5DFF"/>
            <circle className="stroke" cx="10.5002" cy="20.4474" r="1.15789" fill="#1E5DFF" stroke="#1E5DFF"/>
            <circle className="stroke" cx="19.342" cy="20.4474" r="1.15789" fill="#1E5DFF" stroke="#1E5DFF"/>
            <path className="stroke" d="M13.2629 3.31602H19.8945L10.4997 14.3687L1.10498 3.31602H7.73656" stroke="#1E5DFF"/>
            <path className="stroke" d="M19.3424 8.84218L10.5003 18.7896L1.6582 8.84218" stroke="#1E5DFF"/>
        </svg>
    );
}
  
export default AiChatSupportIcon;