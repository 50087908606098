import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';  // <-- Import useParams
import api from '../../utils/api.js';
import Header from '../../layouts/Header/Header.js';
import FolderDetails from '../../components/folder/folder-details/FolderDetails.js';

function FolderDetailsPage() {
    const [folder, setFolder] = useState(null);
    const { id } = useParams();
      const refreshState = () => {

      }
    useEffect(() => { 
      async function fetchFolder() { 
        try {
          const response = await api.post(`/admin/my-documents/${id}`);
          setFolder(response.data);
        } catch (error) {
          console.error("Error fetching the folder:", error);
        }
      }
  
      fetchFolder();
    }, [id]); 
    return (
      <>
        <Header page="ai-image-details" documentDetails={{document: {id: folder?.id, name: folder?.name}}} refreshState={refreshState}/>
        <FolderDetails folderDetils={folder}/>
      </>
      
    );
  }

export default FolderDetailsPage;
