import React, { useEffect } from 'react';
import Chat from '../../components/chat/Chat.js';
import Header from '../../layouts/Header/Header.js';
import { useAppContext } from '../../contexts/AppContext.js';

function ChatPage() {
    const { openMobileMenu, mobileMenuOpen } = useAppContext();
    useEffect(() => {
        if (window.innerWidth <= 1200 && mobileMenuOpen === true) {
            openMobileMenu(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Header page="chat" />
            <Chat />
        </>
    );
}

export default ChatPage;
