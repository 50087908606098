import React, { useEffect } from "react";

import { Flex, Box } from '@chakra-ui/react';

import HeroSection from "../components/homepage/updated/hero-section/HeroSection.js";
import FirstSection from '../components/homepage/updated/first-section/FirstSection.js';
import SecondSection from "../components/homepage/updated/second-section/SecondSection.js";
import ThirdSection from "../components/homepage/updated/third-section/ThirdSection.js";
import FourthSection from '../components/homepage/updated/fourth-section/FourthSection.js';
import FifthSection from '../components/homepage/updated/fifth-section/FifthSection.js';
import SixthSection from '../components/homepage/updated/sixth-section/SixthSection.js';

function HomepageContainer({setGeneratedContent}) { 
//   const [textGenerated, setTextGenerated] = useState("");

//   const updateGeneratedText = (text) => {
//     setTextGenerated((prevText) => {
//         return prevText + text
//     })
//     setGeneratedContent(true);
//   }

  useEffect(() => {
    document.documentElement.classList.remove('auth-template');
    const token = localStorage.getItem("token");
    if(token){
        window.location.href = "/admin/dashboard";
    }
  }, []);

  return (
    <Flex sx={homepageContainerStyle}>
        <Box>
            <HeroSection />
        </Box>
        <Box className="container">
            <FirstSection />
        </Box>
        <Box className="container">
            <SecondSection />
        </Box>
        <Box className="aiImageContainer">
            <Box className="container">
                <ThirdSection />
            </Box>
            
        </Box>
        <Box className="container">
            <FourthSection />
        </Box>
        <Box className="container">
            <FifthSection />
        </Box>
        <Box className="container">
            <SixthSection />
        </Box>
        
    </Flex>
  );
}

const homepageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    background: '#131E2A',
    '.container': {
        maxWidth: '1230px',
        width: '100%',
        padding: '70px 15px',
        margin: '0 auto'
    },
    '.aiImageContainer': {
        '.container': {
            paddingBottom: 0
        }
    },
    '@media all and (max-width: 640px)': {
        '.container': {
            paddingTop: '30px',
            paddingBottom: '30px'
        }
    }
}

export default HomepageContainer;