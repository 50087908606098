function AiCHatIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <line className="stroke" x1="9.64439" y1="2.32297" x2="0.381231" y2="13.2703" stroke="#1E5DFF"/>
            <line className="stroke" x1="10.4857" y1="5.69311" x2="3.74889" y2="13.5819" stroke="#1E5DFF"/>
            <line className="stroke" x1="14.693" y1="4.85407" x2="7.11411" y2="13.585" stroke="#1E5DFF"/>
            <line className="stroke" x1="23.4455" y1="13.5202" x2="13.9792" y2="4.89593" stroke="#1E5DFF"/>
            <line className="stroke" x1="19.0389" y1="13.3236" x2="12.3021" y2="7.42891" stroke="#1E5DFF"/>
            <line className="stroke" x1="19.8574" y1="18.3536" x2="10.5942" y2="9.0904" stroke="#1E5DFF"/>
            <line className="stroke" x1="15.1582" y1="13.4474" x2="0.000308037" y2="13.4474" stroke="#1E5DFF"/>
            <line className="stroke" x1="20.2109" y1="18.5" x2="4.21094" y2="18.5" stroke="#1E5DFF"/>
            <line className="stroke" x1="17.6846" y1="15.9737" x2="6.7372" y2="15.9737" stroke="#1E5DFF"/>
        </svg>

    );
}
  
export default AiCHatIcon;