import React from "react";
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import CustomTooltip from '../../../common/custom-tooltip/CustomTooltip.js';
import HeroImageBg from '../../../../assets/images/homepage/HeroImageBg.png';
import AiChatDocumentIcon from '../../../../assets/images/templates/AiChatDocumentIcon.js';
import AiContentIcon from '../../../../assets/images/templates/AiContentIcon.js';
import AiImageDocumentIcon from '../../../../assets/images/templates/AiImageDocumentIcon.js';
import AiTtsDocumentIcon from '../../../../assets/images/templates/AiTtsDocumentIcon.js';

function HeroSection() {
    return (
        <Flex sx={heroSectionStyle}>
            <Box className="contentContainer">
                <Text className="firstText">Unified Creativity</Text>
                <h1>Limitless<br />Possibilities</h1>
                <Text className="lastText">Empower possibilities in one action: AI-driven content generator, <br />images, text-to-speech, and dynamic conversations.</Text>
                <Box sx={iconsContainerStyle}>
                    <Box className="iconItem aiChatIcon">
                        <CustomTooltip label={'Ai Chat'} CustomIcon={AiChatDocumentIcon} />
                    </Box>
                    <Box className="iconItem aiContentIcon">
                        <CustomTooltip label={'Ai Content Generator'} CustomIcon={AiContentIcon} />
                    </Box>
                    <Box className="iconItem aiImageIcon">
                        <CustomTooltip label={'Ai Image Generator'} CustomIcon={AiImageDocumentIcon} />
                    </Box>
                    <Box className="iconItem aiTtsIcon">
                        <CustomTooltip label={'Ai Text to Speech'} CustomIcon={AiTtsDocumentIcon} />
                    </Box>
                </Box>
            </Box>
            <Box className="imageContainer">
                <motion.div
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 3, repeat: Infinity }}
                >
                    <Image src={HeroImageBg} />
                </motion.div>
            </Box>
        </Flex>
    );
}

const iconsContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '.iconItem': {
        padding: '0 5px',
        'svg': {
            width: '34px',
            height: '34px',
        },
        '&.aiChatIcon': {
            'svg': {
                'circle': {
                    fill: "#fff"
                },
                'line': {
                    stroke: '#1E5DFF'
                }
            }
        }
    }

}

const heroSectionStyle = {
    background: '#131E2B',
    paddingTop: '150px',
    flexDirection: 'column',
    overflow: 'hidden',
    '.contentContainer': {
        maxWidth: '560px',
        paddingLeft: '15px',
        paddingRight: '15px',
        textAlign: 'center',
        color: '#fff',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '-140px',
        '.firstText': {
            fontSize: '22px',
            lineHeight: '28px',
            fontWeight: 700,
            marginBottom: '20px',
            color: '#98B3F8'
        },
        'h1': {
            fontSize: '84px',
            lineHeight: '88px',
            fontWeight: 700,
            marginBottom: '40px',
            backgroundClip: 'text',
            backgroundImage: 'linear-gradient(96.16deg, #1408A5 15%, #1E5DFF 54.84%, #DA00FE 90.64%)'
        },
        '.lastText': {
            fontSize: '18px',
            lineHeight: '28px',
            marginBottom: '30px'
        }
    },
    'img': {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '@media all and (max-width: 1200px)': {
        '.contentContainer': {
            marginBottom: 0
        }
    },
    '@media all and (max-width: 640px)': {
        '.contentContainer': {
            '.firstText': {
                fontSize: '18px',
                lineHeight: '22px',
                fontWeight: 700,
                marginBottom: '10px'
            },
            'h1': {
                fontSize: '54px',
                lineHeight: '56px',
                fontWeight: 700,
                marginBottom: '30px'
            },
            '.lastText': {
                fontSize: '16px',
                lineHeight: '24px',
                marginBottom: '20px',
                'br': {
                    display: 'none'
                }
            }
        },
        '.imageContainer': {
            marginLeft: '-300px',
            marginRight: '-300px',
        }
    },
}


export default HeroSection;


