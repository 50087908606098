import React from 'react';
import { Flex, Box, Text, Image, Button, Icon, Avatar } from '@chakra-ui/react';
import AIChatMessageIcon from '../../../../assets/images/AIChatMessageIcon.js';
// import { CopyIcon } from '@chakra-ui/icons';
import CopyIcon from '../../../../assets/images/CopyIcon.js';
import clipboardCopy from 'clipboard-copy';
const ChatMessageItem = ({ message, user }) => {
    const amazonURL = process.env.REACT_APP_AMAZON_S3_URL;
    const handleCopyClick = (fileId) => {
        clipboardCopy(fileId)
            .then(() => { 
                console.log('File name copied to clipboard');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    return (
        <Flex sx={itemMessageStyle}>
            <Box className={`item_message type_${message.messageType}`}>
                <Box className="mainMessageContainer">
                    <Box className="iconContainer">
                        {message.messageType === 'ai' && user
                            ? <Box className="chatIcon"><Icon as={AIChatMessageIcon} /></Box>
                            : <Avatar name={user.lastname.charAt(0).toUpperCase()} w="34px" h="34px" bgColor="#1E5DFF" />
                        }
                    </Box>
                    <Box className="textContainer">
                        <Text className="authorName">{message.messageType === 'ai' ? `Skelet AI` : user.firstname}</Text>
                        {message.fileId
                            ? <Box className="main_file_container">
                                <Box className="file_image">
                                    <Image src={`${amazonURL}${message?.file?.filePath}`} />
                                </Box>
                                <Text>Use this name in chat to ask questions about the image:
                                    <Button onClick={() => handleCopyClick(`#photo-${message?.file?.id}`)}>#picture-{message?.file?.id} <CopyIcon /></Button>
                                </Text>
                            </Box>
                            : <Text dangerouslySetInnerHTML={{ __html: message.message }}></Text>
                        }
                    </Box>
                </Box>
            </Box>
        </Flex>

    );
};


const itemMessageStyle = {
    maxWidth: '860px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.item_message': {
        padding: '20px',
        background: '#fff',
        borderRadius: '4px',
        marginBottom: '10px',
        width: '100%',
        '.mainMessageContainer': {
            display: 'flex',
            flexDirection: 'row',
            '.iconContainer': {
                '.chatIcon': {
                    'svg': {
                        borderRadius: '50px',
                        border: '1px solid #CFD5E3',
                        'circle': {
                            fill: 'transparent',   
                        },
                        'line': {
                            stroke: '#1E5DFF'
                        }
                    }
                }
            },
            '.textContainer': {
                paddingLeft: '20px',
                '.authorName': {
                    fontWeight: 700
                },
                'p': {
                    color: '#1B518F',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    'pre': {
                        width: '100%',
                        overflow: 'auto',
                        float: 'left',
                        minWidth: '100%'
                    }
                },
                'button': {
                    background: '#EAF5FF',
                    fontSize: '16px',
                    fontWeight: '400px',
                    color:'#1B518F',
                    marginLeft: '5px',
                    'svg': {
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        'path': {
                            stroke: '#1B518F'
                        }
                    }
                }
            }
        },
        '&.type_ai': {
            background: '#F6FBFF',
            color: '#fff',
            position: 'relative',
            '&:before': {
                content: "''",
                position: 'absolute',
                left: '-1000px',
                right: '-1000px',
                zIndex: '-1',
                background: '#F6FBFF',
                top: 0,
                bottom: 0,
            },
        },
        '.file_image': {
            maxWidth: '200px',
            marginBottom: '15px'
        }
    },
    '@media all and (max-width:580px)': {
        '.item_message': {
            padding: '10px',
            '.mainMessageContainer': {
                '.iconContainer': {
                    display: 'none',
                    '.chatIcon': {
                        'svg': {

                        }
                    }
                },
                '.textContainer': {
                    paddingLeft: '5px',
                    'p': {
                        fontSize: '15px'
                    }
                }
            }
        }
    }
}

export default ChatMessageItem;
