import React, {useState, useEffect} from 'react';
import RadioOption from './RadioOption/RadioOptions.js';
import {Flex, Box} from '@chakra-ui/react';


function CustomRadioButtons({options, setValue, name, defaultValue}) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleButtonClick = (index) => {
      setActiveIndex(index);
      setValue(name, options[index].value);
    };

    useEffect(() => {
        const selectedIndex = options.findIndex(item => item.value === defaultValue);
        setActiveIndex(selectedIndex);
        if(defaultValue) {
            setValue(name, defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={mainBoxStyle}>
            {options && options.map.length > 0 &&
                <Flex className="optionsContainer">
                    {options.map((item, key) => (
                        <RadioOption 
                            key={key} 
                            option={item}
                            isActive={activeIndex === key}
                            onClick={() => handleButtonClick(key)}
                        />
                    ))}
                </Flex>
            }
        </Box>
    );
} 

const mainBoxStyle = {
    '.optionsContainer': {
        border: '1px solid #CFE0ED',
        borderRadius: '4px',
    }
}

export default CustomRadioButtons;
