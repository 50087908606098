import React from 'react';
import Header from '../../layouts/Header/Header.js';
import Dashboard from '../../components/dashboard/Dashboard.js';

const DashboardPage = () => {
    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    // useEffect(() => {
    //     const handleResize = () => {
    //       setIsMobile(window.innerWidth <= 1200);
    //     };
    
    //     window.addEventListener('resize', handleResize);
    
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    return (  
        <>
            <Header page="dashboard"/>
            <Dashboard />
        </>
    );
}

export default DashboardPage;
