import React, { useState } from 'react';

import { Box, Image, Button, Icon } from '@chakra-ui/react';
import PreviewImageIcon from '../../../../../assets/images/PreviewImageIcon.js';
import DownloadImageIcon from '../../../../../assets/images/DownloadImageIcon.js';
// import FsLightbox from "fslightbox-react";

function FileItem({ file }) {
    const [toggler, setToggler] = useState(false);
    const amazonURL = process.env.REACT_APP_AMAZON_S3_URL;
    const handleDownload = () => {
        const fileUrl = `${amazonURL}${file.filePath}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = "_blank";
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
console.log(`${amazonURL}${file?.filePath}`);
    return (
        <>
            <Box sx={imageItemStyle}>
                <Box className="imageContainer">
                    <Image src={`${amazonURL}${file.filePath}`} />
                    <Box className="topActions">
                        {/* <Button onClick={() => setToggler(!toggler)}>
                            <Icon as={PreviewImageIcon} />
                        </Button> */}
                        <Button onClick={() => handleDownload()}>
                            <Icon as={DownloadImageIcon} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            {/* <FsLightbox
                toggler={toggler}
                sources={[`${amazonURL}${file?.filePath}`]}
                types={['image']}
            /> */}
        </>
    );
}

const imageItemStyle = {
    width: '50%',
    padding: '0 10px',
    marginBottom: '20px',
    '.imageContainer': {
        position: 'relative',
        '.topActions': {
            position: 'absolute',
            top: '15px',
            right: '15px',
            opacity: 0,
            zIndex: 2,
            'button': {
                background: 'transparent',
                padding: 0,
                'svg': {
                    width: '24px'
                }
            }
        },
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
            transition: 'all 0.3s ease',
            zIndex: 1,
            opacity: 0
        },
    },
    
    '&:hover': {
        '.imageContainer': {
            '.topActions': {
                opacity: 1
            },
            '&:after': {
                opacity: 1
            }
        },
    },

    '@media all and (max-width: 640px)': {
        width: '100%',
        marginBottom: '10px'
    }
}
export default FileItem;
