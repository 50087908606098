import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAiImageContext } from '../../contexts/AiImageContext.js';
import { useAppContext } from '../../contexts/AppContext.js';
import Header from '../../layouts/Header/Header.js';
import Details from '../../components/ai-images/Details/AiImageDetails.js';

function AiImagesDetailsPage() {
    const {openMobileMenu, mobileMenuOpen} = useAppContext();

    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const { documentDetails, fetchImageDocumentDetails } = useAiImageContext();
    const refreshState = () => {
        setTriggerRefresh(!triggerRefresh);
    }
    const { id } = useParams();


    useEffect(() => {
        fetchImageDocumentDetails(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id,triggerRefresh]);
    
    useEffect(() => {
        if(window.innerWidth <= 1200 && mobileMenuOpen === true){
            openMobileMenu(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    return (
        <>
            <Header page="ai-image-details" documentDetails={documentDetails} refreshState={refreshState}/>
            <Details documentDetails={documentDetails} />
        </>

    );
}

export default AiImagesDetailsPage;
