import React from 'react';
import Library from '../../components/library/Library.js';

function NewProjectPage() {
    return (
        <>
            <Library />
        </>
    );
  }

export default NewProjectPage;
