import React from 'react';
import {
  Box,
  Button,
  Image,
  useToast
} from '@chakra-ui/react';
import CopyIcon from '../../../../assets/images/CopyIcon.js';
import clipboardCopy from 'clipboard-copy';



const ImageItem = ({imageItem}) => {
    const toast = useToast();
    const amazonURL = process.env.REACT_APP_AMAZON_S3_URL;
    const handleCopyClick = (fileId) => {
        clipboardCopy(fileId)
            .then(() => {
                console.log('File name copied to clipboard');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
        
            toast({
                description: "Image copied to clipboard",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
    }
  return (
    <Box sx={imageItemStyle}>
        <Box className="innerContainer">
            <Box className="imageContainer" onClick={() => handleCopyClick(`#photo-${imageItem.id}`)}>
                <Image src={`${amazonURL}${imageItem.filePath}`} />
            </Box>
            <Box className="textContainer">
                <Button onClick={() => handleCopyClick(`#photo-${imageItem.id}`)}>#picture-{imageItem.id} <CopyIcon /></Button>
            </Box>
        </Box>
        
    </Box>
  );
};

const imageItemStyle = {
    flex: '0 0 25%',
    padding: '0 5px',
    marginBottom: '10px',
    '.imageContainer': {
        marginBottom: '10px',
        'img': {
            height: '115px',
            objectFit: 'cover',
            borderRadius: '4px',
        },
    },
    '.textContainer': {
        'button': {
            fontSize: '14px',
            color: '#1B518F',
            background: 'transparent',
            padding: 0,
            'svg': {
                marginLeft: '5px',
                width: '14px',
                'path': {
                    stroke: '#1B518F'
                }
            }
        }
    },
    '@media all and (max-width: 540px)': {
        flex: '0 0 33%',
        '.imageContainer': {
            marginBottom: 0,
            'img': {
                height: '75px'
            }
        }
    }
}


export default ImageItem;