import React, { useState, useEffect, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../../../utils/api.js';
import { useFolderContext } from '../../../contexts/FolderContext.js';
import { Flex, Box, Text, Input, Button, Icon, useToast } from '@chakra-ui/react';
import GlobalMenu from '../global-menu/GlobalMenu.js';
import DeleteConfirmationModal from '../../modals/delete-confirmation/DeleteConfirmationModal.js';
import RenameDocumentIcon from '../../../assets/images/RenameDocumentIcon.js';
import DeleteDocumentIcon from '../../../assets/images/DeleteDocumentIcon.js';
import CheckSaveIcon from '../../../assets/images/CheckSaveIcon.js';
import ThinCloseIcon from '../../../assets/images/ThinCloseIcon.js';
import { useDocumentContext } from '../../../contexts/DocumentContext.js'

function limitString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}
const DocumentName = ({ title, documentDetails, refreshState, triggerButtonIcon }) => {
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { defaultFolder, fetchDocumentsByDefaultFolder } = useFolderContext();
    const { renameDocument } = useDocumentContext();
    const inputRef = useRef(null);
    const toast = useToast();

    useEffect(() => {
        if (edit && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }
    }, [edit]);

    const handdleRename = async (newName) => {
        if (newName !== "") {
            if (newName !== documentDetails.document.name) {
                await renameDocument(documentDetails.document.id, newName);
                refreshState();
                fetchDocumentsByDefaultFolder();
            }
            setEdit(false);
        } else {
            toast({
                description: 'Name cannot be empty',
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }

    const handdleDelete = async () => {
        try {
            const response = await api.post('/admin/delete-document', {
                documentId: documentDetails.document.id
            });
            if(response.data.success === true){
                fetchDocumentsByDefaultFolder();
                toast({
                    description: 'Project deleted successfully.',
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/admin/dashboard');
            }
            console.log(response);
            
        } catch (error) {
            console.error("Error deleting documents:", error);
        }
    }
    const showEditInput = () => {
        setEdit(true);
    }
    

    const showConfirmModal = () => {
        setIsDeleteModalOpen(true);
    }

    const menuItems = [
        {
            label: `Rename document`,
            icon: RenameDocumentIcon,
            onClick: showEditInput
        },
        {
            label: `Delete document`,
            icon: DeleteDocumentIcon,
            onClick: showConfirmModal
        }
    ];
    // console.log('documentDetails.document', documentDetails?.document);
    return (
        <Flex sx={newDocumentHeaderStyle}>
            <Box className="documentTitleContainer">
                <Box className="innerContainer">
                    <Flex>
                        {documentDetails && documentDetails.document.id &&
                            <Box>
                                <GlobalMenu menuItems={menuItems} triggerButtonIcon={triggerButtonIcon} />
                            </Box>
                        }
                        <Box>
                            {edit === false
                                ? <Text className="documentName">
                                    {documentDetails?.document.name ? limitString(documentDetails?.document.name, 35) : 'Untitled document'}
                                </Text>
                                : <Box className="inputsContainer">
                                    <Button className="buttonItem closeButton" onClick={() => setEdit(false)}>
                                        <Icon as={ThinCloseIcon} />
                                    </Button>
                                    <Input ref={inputRef} defaultValue={documentDetails?.document.name} className="inputValue" onBlur={(e) => handdleRename(e.target.value)} />
                                    <Button className="buttonItem saveButton" onClick={() => handdleRename(inputRef.current.value)}>
                                        <Icon as={CheckSaveIcon} />
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Flex>
                    <Text className="defaultFolder">{defaultFolder.name}</Text>
                </Box>
            </Box>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onDelete={() => handdleDelete()}
                itemName="project"
            />
        </Flex>
    );
}
const newDocumentHeaderStyle = {
    marginTop: '-5px',
    marginBottom: '-5px',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    '.documentTitleContainer': {
        height: '55px',
        textAlign: 'center',
        '.documentName': {
            fontSize: '18px',
            color: '#1B518F',
        },
        '.inputValue': {
            fontSize: '18px',
            color: '#8599CD',
            textAlign: 'center',
            border: '1px solid #CFD5E3',
            borderRadius: '4px',
            paddingLeft: '25px',
            paddingRight: '25px',
            marginTop: '3px',
            height: '34px'
        },
        '.defaultFolder': {
            fontSize: '12px',
            lineHeight: '14px',
            color: '#8599CD',
            fontWeight: 500
        }
    },
    '.inputsContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        '.buttonItem': {
            padding: 0,
            background: 'transparent',
            position: 'absolute',
            left: '-5px',
            top: 0,
            zIndex: 9,
            '&.saveButton': {
                left: 'auto',
                right: '-5px'
            }
        }
    }
}
export default DocumentName;
