import React from 'react';
import axios from 'axios';
import { FormControl, Input, Button, Box, Text, Icon, useToast  } from '@chakra-ui/react';
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';

const Register = () => {
    const { register, handleSubmit } = useForm();
    const toast = useToast();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        try {
            const response = await axios.post('https://skelet.ai:5000/api/auth/register', data);
            
            if (response.data.user) {
                
                toast({
                    title: "Registration successful",
                    description: "Please login to continue",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                navigate('/login');
            } else {
                // Handle registration errors here
                toast({
                    title: "Registration failed",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            console.log('Response from server:', response.data);
        } catch (error) {
            // Handle server or network errors here
            toast({
                title: "Error",
                // description: "There was an error during registration.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <Input style={inputStyle} placeholder='First Name' {...register("firstname", { required: true })} />

                <Input style={inputStyle} placeholder='Last Name' {...register("lastname", { required: true })} />

                <Input style={inputStyle} placeholder='Email' {...register("email", { required: true })} />

                <Input style={inputStyle} type="password" placeholder='Password' {...register("password", { required: true })} />
                <Input style={inputStyle} type="password" placeholder='Confirm Password' {...register("confirm_password", { required: true })} />
            </FormControl>
            <Button type="submit" sx={submitButtonStyle}>
                <Box display="flex" alignItems="center">
                        <Text>Register</Text>
                        <Icon as={RightArrowButtonIcon} />
                    </Box>
            </Button>
        </form>
    );
}

const inputStyle = {
    background: '#fff',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    height: '55px',
    lineHeight: '55px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
    color: '#6D99BE',
    marginBottom: '20px'
}

const submitButtonStyle = {
    background: '#1E5DFF',
    height: '55px',
    lineHeight: '55px',
    width: '100%',
    'p': {
        fontSize: '18px',
        lineHeight: '20px',
        color: '#fff',
        marginRight: '10px'
    }
}

export default Register;
