import React from 'react';
import { Box } from "@chakra-ui/react";

import AllTemplatesSidebar from '../../dashboard/sidebar/all-templates-sidebar/AllTemplatesSidebar.js';


function MobileLibrary() {

  return (
    <Box style={tabsContainerStyle}>
        
        <AllTemplatesSidebar style="page"/>
    </Box>
  );
}
const tabsContainerStyle = {
    padding: '15px 0'
}

export default MobileLibrary;
