function CloseModalIcon() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path className="stroke" d="M1.42285 1L10.9997 10.5768" stroke="#1B518F" strokeWidth="2" strokeLinecap="round"/>
            <path d="M11.2841 1.70709C11.6746 1.31656 11.6746 0.683398 11.2841 0.292873C10.8936 -0.0976509 10.2604 -0.0976509 9.86988 0.292873L11.2841 1.70709ZM6.49568 6.4955L11.2841 1.70709L9.86988 0.292873L5.08147 5.08128L6.49568 6.4955Z" fill="#1B518F"/>
            <path className="stroke" d="M1 10.5768L3.99276 7.58409" stroke="#1B518F" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
  }
  
  export default CloseModalIcon;