function CancelIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="12" stroke="#8599CD" strokeWidth="2"/>
            <path d="M9.35352 9L17.3535 17" stroke="#8599CD" strokeWidth="2" strokeLinecap="round"/>
            <path d="M17.7071 9.70711C18.0976 9.31658 18.0976 8.68342 17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289L17.7071 9.70711ZM13.7071 13.7071L17.7071 9.70711L16.2929 8.29289L12.2929 12.2929L13.7071 13.7071Z" fill="#8599CD"/>
            <path d="M9 17L11.5 14.5" stroke="#8599CD" strokeWidth="2" strokeLinecap="round"/>
        </svg>

    );
}
  
export default CancelIcon;