function DefaultTemplateIcon() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="17" fill="#1E5DFF"/>
            <line x1="17" y1="11.3479" x2="17" y2="24.8262" stroke="white" strokeWidth="2"/>
            <line x1="26" y1="13" x2="7" y2="13" stroke="white" strokeWidth="2"/>
            <line x1="6.73926" y1="17" x2="6.73926" y2="12" stroke="white" strokeWidth="2"/>
            <line x1="25.8696" y1="17" x2="25.8696" y2="12" stroke="white" strokeWidth="2"/>
            <circle cx="26.4346" cy="20" r="1.08696" fill="white"/>
            <circle cx="7.08696" cy="20" r="1.08696" fill="white"/>
            <path d="M17.6919 6.5L16.8258 5L15.9598 6.5L13.5124 10.7391L12.6463 12.2391H14.3784H19.2733H21.0054L20.1393 10.7391L17.6919 6.5Z" stroke="white" strokeWidth="2"/>
            <path d="M15.8705 28.7313L16.5525 29.3674L17.2346 28.7313L19.682 26.4487L21.5384 24.7174L19 24.7174L14.1051 24.7174L11.5667 24.7174L13.423 26.4487L15.8705 28.7313Z" stroke="white" strokeWidth="2"/>
        </svg>
    );
}
  
export default DefaultTemplateIcon;

