function AIChatMessageIcon() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
            <circle cx="17" cy="17" r="17" fill="#1E5DFF"/>
            <line x1="14.8357" y1="7.48446" x2="5.57257" y2="18.4318" stroke="white" strokeWidth="1.5"/>
            <line x1="15.6758" y1="10.8555" x2="8.93901" y2="18.7442" stroke="white" strokeWidth="1.5"/>
            <line x1="19.8823" y1="10.018" x2="12.3034" y2="18.7489" stroke="white" strokeWidth="1.5"/>
            <line x1="28.2771" y1="18.705" x2="18.8108" y2="10.0808" stroke="white" strokeWidth="1.5"/>
            <line x1="23.8748" y1="18.5118" x2="17.1379" y2="12.6171" stroke="white" strokeWidth="1.5"/>
            <line x1="24.6801" y1="23.5303" x2="15.417" y2="14.2672" stroke="white" strokeWidth="1.5"/>
            <line x1="20.1577" y1="18.6974" x2="4.99982" y2="18.6974" stroke="white" strokeWidth="1.5"/>
            <line x1="25.2104" y1="23.75" x2="9.21045" y2="23.75" stroke="white" strokeWidth="1.5"/>
            <line x1="22.6841" y1="21.2236" x2="11.7367" y2="21.2236" stroke="white" strokeWidth="1.5"/>
        </svg>
    );
}
   
export default AIChatMessageIcon;