import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import AttachImage from '../../../assets/images/AttachImage.js';
import api from '../../../utils/api.js';
import { Flex, Box, Button, Text, useToast, Spinner, Progress } from '@chakra-ui/react';

// import RecentActivity from '../recent-activity/RecentActivity.js';


function FoodChecker() {
    const {
        handleSubmit,
        // register,
        // control, 
        setValue,
        // watch,
    } = useForm();
    const toast = useToast();
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState('blue')
    const [productScore, setProductScore] = useState(0)
    const [productInformation, setProductInformation] = useState("")
    //   const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    // useEffect(() => {
    //     const handleResize = () => {
    //       setIsMobile(window.innerWidth <= 1200);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    useEffect(() => {
        // Extract the health score from the message
        console.log(productInformation)
        if (productInformation.success) {
            const scoreMatch = productInformation.message.match(/(\d+)\/10/);

            if (scoreMatch) {
                const score = parseInt(scoreMatch[1], 10); // Convert extracted score to a number
                setProductScore(score)
                // Determine the color based on the score
                if (score <= 3) {
                    setColor('red');
                } else if (score >= 4 && score <= 7) {
                    setColor('orange');
                } else if (score > 7) {
                    setColor('green');
                }
            }
        }
    }, [productInformation]);

    const submitForm = async (data) => {

        if (data.attachment) {
            setLoading(true)
            const formData = new FormData();
            formData.append('file', data.attachment);

            const response = await api.post(`/admin/tools/food-checker/check-product`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.error) {
                toast({
                    description: response.data.error,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setLoading(false);
                return false;
            }

            // const message = response.data;
            // console.log(message)
            setProductInformation(response.data.content)

            // setMessages(prevMessages => [
            //   ...prevMessages,
            //   message
            // ]);
            setLoading(false);
        }
    }

    const handleAttachFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.heic,.jpg,.png,.gif,.jpeg';
        document.body.appendChild(fileInput);

        fileInput.addEventListener('change', (e) => {
            const file = e.target.files[0];
            setValue('attachment', file);
            handleSubmit(submitForm)();
            document.body.removeChild(fileInput);
        });
        fileInput.click();
    };

    return (
        <Flex>
            {loading &&
                <Box className="spinnerConainter" sx={spinnerConainter}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                    <Text>Analyzing the product...</Text>
                </Box>
            }
            <form onSubmit={handleSubmit(submitForm)} encType="multipart/form-data" style={{ width: '100%' }}>
                <Box className="buttonContainer" sx={buttonContainer}>
                    <Button onClick={() => handleAttachFile()}>
                        <AttachImage />
                        <Text>Upload product informational label</Text>
                    </Button>
                </Box>

                {productInformation.success
                    ? <Box className="productInformation" sx={productInformationStyle}>
                        <Box className="progressContainer">
                            <Progress value={productScore + '0'} colorScheme={color} />
                            <Text>Score: {productScore}/10</Text>
                        </Box>
                        <Text dangerouslySetInnerHTML={{ __html: productInformation.message }}></Text>
                    </Box>
                    : <Box className="productInformation" sx={productInformationStyle}>
                        <Text style={{ color: 'red' }}>{productInformation.message}</Text>
                    </Box>
                }
            </form>
        </Flex>
    );
}

const productInformationStyle = {
    maxWidth: '540px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px',
    '.progressContainer': {
        marginBottom: '20px',
        'p': {
            fontSize: '12px',
            paddingTop: '5px',
            textAlign: 'center',
            fontWeight: 700
        }
    },
    'p': {
        fontSize: '14px'
    },
    'h1': {
        fontSize: '18px',
        fontWeight: 700,
        marginBottom: '5px'
    },
    'h2': {
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '10px',
        paddingTop: '10px'
    },
    'ul': {
        marginBottom: '5px',
        paddingLeft: '16px'
    }
}

const spinnerConainter = {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    background: 'rgba(255,255,255,0.5)',
    flexDirection: 'column',
    'p': {
        marginTop: '10px'
    }
}

const buttonContainer = {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '30px',
    width: '100%',
    justifyContent: 'center',
    'button': {
        background: 'rgb(13, 120, 245)',
        color: '#fff',
        'svg': {
            marginRight: '10px'
        }
    },
    'input[type="file"]': {
        position: 'fixed',
        left: '-50px',
        top: '-50px',
        opacity: 0,
        zIndex: -1
    }
}

export default FoodChecker;
