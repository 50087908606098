import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';

function TitleWithIcon({ icon, text }) {

    return (
        <Box sx={style}>
            {icon &&
                <Icon as={icon} />
            }
            
            <Text>{text}</Text>
        </Box>
    )
}

const style = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    'svg': {
        paddingRight: '15px',
        width: '42px',
        height: '42px',
    },
    'p':{
        fontSize: '12px',
        lineHeight: '14px',
        color: '#fff',
        fontWeight: 700,
        textTransform: 'uppercase'
        
    },
    '@media all and (max-width: 640px)': {
        'p': {
            fontSize: '10px',
            lineHeight: '14px'
        }
    }
}


export default TitleWithIcon;