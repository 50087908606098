import React from 'react';
import { components } from 'react-select';
import { TEMPLATE_ICONS } from '../../assets/images/templates/TemplatesImage.js';
import { Box, Text,  } from '@chakra-ui/react';


const OptionCustom = (props) => {
    const icon = TEMPLATE_ICONS[props.value];
    return (
        <components.Option {...props}>
            <Box display="flex" alignItems="center">
                    <Box sx={iconStyle}>
                        {icon}
                    </Box>
                <Text style={optionTextLabel}>{props.label}</Text>
            </Box>
        </components.Option>
    );
};
const iconStyle = {
    minWidth: '20px',
    marginRight: '5px'
}
const optionTextLabel = {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: '#1B518F'
}
export default OptionCustom;
