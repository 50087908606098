import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea } from '@chakra-ui/react';

import OptionsBoxes from './OptionsBoxes/OptionsBoxes.js';
import SubmitButton from '../SubmitButton.js';
import LeftIcon from '../LeftIcon.js';

function DefaultForm({ register, setValue, handleAttachFile, handleKeyPress, extraOptions, watch, page }) {
    const [showForm, setShowForm] = useState(false);
    const textareaRef = useRef(null);
    const textareaValue = watch('generateInstructions');

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'inherit';
            const computed = window.getComputedStyle(textarea);
            const height = textarea.scrollHeight + parseInt(computed.borderTopWidth) + parseInt(computed.borderBottomWidth);
            textarea.style.height = `${height}px`;
        }
    }, [textareaValue]);

    return (
        <Box sx={defaultFormStyle}>
            <Box className={showForm === true ? 'inner_container show_full_form' : 'inner_container'}>
                <Box className="textareaContainer">
                    <LeftIcon />
                    <Textarea
                        onClick={() => setShowForm(true)}
                        {...register('generateInstructions', { required: true })}
                        onKeyPress={handleKeyPress}
                        placeholder={page === 'tts' ? "Enter your text" : "Enter image description"}
                        onChange={(e) => setValue('generateInstructions', e.target.value)}
                        ref={textareaRef}
                    />
                    <SubmitButton handleAttachFile={() => handleAttachFile()} />
                </Box>
                {extraOptions &&
                    <Box className="generatorOptions">
                        <OptionsBoxes extraOptions={extraOptions} setValue={setValue} />
                    </Box>
                }
            </Box>
        </Box>
    );
}

const defaultFormStyle = {
    maxWidth: '860px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '.inner_container': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: '#fff',
        borderRadius: '4px',
        border: '1px solid #1E5DFF',
        boxShadow: '0px 4px 20px 0px #1E5DFF1A',
        '.textareaContainer': {
            position: 'relative',
            paddingLeft: '50px',
            paddingRight: '45px',
            'textarea': {
                minHeight: '1px !important',
                border: '0 none',
                height: '50px',
                resize: 'none',
                fontSize: '16px',
                color: '#1B518F',
                paddingTop: '16px',
                paddingLeft: '5px',
                paddingBottom: 0,
                lineHeight: '20px',
                boxShadow: 'none',
            },

            '.buttonsContainer': {
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)'
            }
        },
        '.generatorOptions': {
            display: 'none',
            padding: '10px 10px 0',
        },
        '&.show_full_form': {
            border: '1px solid #CFD5E3',
            boxShadow: '0 none',
            '.generatorOptions': {
                display: 'block'
            },
        }
    }
}

export default DefaultForm;
