import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTtsContext } from '../../contexts/TtsContext.js';
import Header from '../../layouts/Header/Header.js';
import TextToSpeech from '../../components/text-to-speech/TextToSpeech.js';

function TextToSpeechDetailsPage() {
    const {ttsDetails, fetchTtsDetails} = useTtsContext();
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const { id } = useParams();
    const refreshState = () => {
        setTriggerRefresh(!triggerRefresh);
    }
    useEffect(() => {
        fetchTtsDetails(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, triggerRefresh]);

    return (
        <>
            <Header page="ai-image-details" documentDetails={ttsDetails} refreshState={refreshState}/>
            <TextToSpeech page="details" details={ttsDetails}/>
        </>

    );
}

export default TextToSpeechDetailsPage;
