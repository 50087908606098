import React from "react";

import { Flex, Box, Text, Heading } from '@chakra-ui/react';

function PrivacyPolicy() {

    return (
        <Flex sx={contentPageStyle}>
            <Box className="container">
                <Heading>Privacy Policy</Heading>
                <Box className="textContent">
                    <Heading>GENERAL PRIVACY TERMS</Heading>
                    <Text>
                        In addition to our Terms of Service, Skelet respects your privacy and is committed to protecting it. This Privacy Policy (the "Policy") explains the types of information collected by Skelet when you use the Platform (as defined in Terms of Service) that references this Policy, how we collect, use, share and store such information collected and also explains the rationale for collection of such information, the privacy rights and choices you have regarding your information submitted to us when you use the Services.
                        For ease of reference, use of the terms "Skelet", "we", "us, and/or "our" refer to Unacademy Inc.– a company incorporated under the laws of Delaware, located in the United States of America and all of its affiliates which have license to host the Platform and offer Services. Similarly, use of the terms "you", "yours" and/or "User(s)" refer to all users of the Platform (as more particularly defined under our Terms of Service).
                        The Services are governed by this Policy, Terms of Service, and any other rules, policies or guidelines published on the Platform as applicable to you. Please read this Policy carefully prior to accessing our Platform and using the Services. By accessing and using the Platform and providing your Personal Information (defined below), or by otherwise signalling your agreement when the option is presented to you, you consent to the collection, use, disclosure, sharing and storing of information described in this Policy, Terms of Service and any other rules, policies or guidelines published on the Platform as applicable to you (collectively referred to as the "Platform Terms"), and Skelet disclaims all the liabilities arising therefrom. If you have inadvertently submitted any Personal Information to Skelet prior to reading this Policy statements set out herein, or you do not agree with the way your Personal Information is collected, stored, or used, then you may access, modify and/or delete your Personal Information in accordance with this Policy (refer to the sections about Your Choices and Your Rights).
                        If any information you have provided or uploaded on the Platform violates the Platform Terms, Skelet may be required to delete such information upon informing you of the same and revoke your access to the Platform if required.
                        Capitalized terms used but not defined in this Policy can be found in our Terms of Service.
                        If you have any questions about this Policy, please contact us privacy@skelet.ai.<br />
                        <b>APPLICABILITY </b>
                        This Policy applies to all Users of the Platform and the information pertaining to the Users that have been collected through the Platform.
                        <br />
                        <b>ACCESS </b>
                        To allow you to access and view the Platform, you are required to create an account and register on our Platform. To create an account, you are required to provide certain Personal Information as may be required during the time of registration. Skelet may, in future, include other optional requests for information from you to help Skelet to customize the Platform to deliver personalized information to you. Skelet may keep records of telephone calls or emails received from or made by you for making enquiries, feedback, or other purposes for the purpose of rendering Services effectively and efficiently.
                        <b>USE OF THE PLATFORM/SERVICES BY CHILDREN </b>
                        As stated in our Terms of Service, the Platform is intended for access and use only by Users (as defined in our Terms of Service).
                        We do not knowingly collect personally identifiable information from a child. If you are a parent or guardian and you are aware that your child has provided us with any such Personal Information, please contact us privacy@skelet.ai. If we become aware that we have collected Personal Information from a child, we will take steps to remove that information from our servers.
                        Whenever we refer to a "child" or "children" in this Policy, we mean a child under the age of 18 years of age.
                        <br />
                        <b>CONTROLLERS </b>
                        Skelet is the controller of Personal Information that it collects and processes in connection with the use of the Platform and the provision of the Services on the Platform. The kind of Personal Information we collect in connection with such use is detailed below.
                        <br />
                        <b>PERSONAL INFORMATION </b>
                        "Personal Information" shall mean information which identifies a User which shall include first and last name, email address and/or phone number and any other information categorized as ‘personal data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) and / or the California Consumer Privacy Act (CCPA) as amended and in context of this Policy or other equivalent / similar legislations ("Data Protection Laws"), provided at the time of accessing the Platform or at the time of registration .
                        "Sensitive Personal Information" shall include (i) passwords and financial data (except the truncated last four digits of credit/debit card), (ii) health data, (iii) official identifier (such as biometric data, aadhar number, social security number, driver’s license, passport, etc.,), (iv) information about sexual life, sexual identifier, race, ethnicity, political or religious belief or affiliation, (v) account details and passwords, or (vi) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Data Protection Laws and in context of this Policy or other equivalent / similar legislations.
                        We request you to not provide Skelet with any Personal Information unless specifically requested by us. In the event you share with Skelet any Personal Information without us having specifically requested for the same, then we bear no liability in respect of such Personal Information provided by you.
                        <br />
                        <b>INFORMATION WE COLLECT </b>
                        We only collect information about you if we have a reason to do so — for example, to provide our Services on the Platform, to communicate with you, or to make our Services better.
                        We collect this information from the following sources:<br />
                        Information we collect from You:<br />
                        Basic account information: In order to access the Platform and in order to avail the Services offered by the Platform, you will need to create an account and register with us.  We ask for basic information which may include your name, an email address, and phone/mobile number.
                        Information when you communicate with us: When you write to us with a question or to ask for help, we will keep that correspondence, and the email address, for future reference; this may also include any phone/ mobile numbers if you have provided us the same as part of your communication either in writing (emails included), over a phone call or otherwise. When you browse pages on our Platform, we will track that for statistical purposes which may be to improve the Platform and the Services.
                        Information we collect automatically:<br />
                        Device and Log information: When you access our Platform, we collect information that web browsers, mobile devices, and servers typically make available, including the browser type, IP address, unique device identifiers, language preference, referring site, the date and time of access, operating system, and mobile network information. We collect log information when you use our Platform — for example, when you create or make changes to your account information on the Platform.
                        Usage information: We collect information about your usage of our Platform. We also collect information about what happens when you use our Platform (e.g., page views, support document searches, features enabled for your account, interactions with other parts of our Services) along with information about your Supported Device. We use this information to provide our Platform to you, get insights on how people use our Platform so that we can make our Platform better, and understand and make predictions about User retention.
                        Location information: We may determine the approximate location of your Supported Device from your Internet Protocol (IP) address. We may collect and use this information to calculate how many people visit from certain geographic regions or to improve our Platform Services.
                        Information from cookies & other technologies: We may collect information about you through the use of cookies and other similar technologies. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyse our Services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Platform and/or the Services offered therein. Examples of cookies we use:
                        Session Cookies: We use Session Cookies to operate our Service.
                        Preference Cookies: We use Preference Cookies to remember your preferences and various settings.
                        Security Cookies: We use Security Cookies for security purposes.
                        Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.<br />
                        Information we collect from other sources:<br />
                        We might receive and collect information about you from other sources in the course of their services to us or from a publicly available sources, as permitted by law, which we may combine with other information we receive from or about you. For example, we may receive information about you from a social media site or a Google service if you connect to the Services through that site or if you use the Google sign-in.
                        <b>BASIS FOR COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION: </b><br />
                        Basis for collection:<br />
                        We collect and process your Personal Information based on the following legal parameters depending upon the nature of Personal Information and the purposes for which it is processed:
                        Consent: We rely on your consent to process your Personal Information in certain situations. If we require your consent to collect and process certain Personal Information, as per the requirements under the applicable Data Protection Laws, your consent is sought at the time of collection of your Personal Information and such processing will be performed where consent is secured.
                        Compliance with a legal obligation: Your Personal Information may be processed by us, to the extent that such processing is necessary to comply with a legal obligation.
                        Processing of your Personal Information:
                        We may process your Personal Information in connection with any of the purposes and uses set out in this Policy on one or more of the following legal grounds:
                        Because it is necessary to perform the Services you have requested or to comply with your instructions or other contractual obligations between you and us.
                        To comply with our legal obligations as well as to keep records of our compliance processes.
                        Because our legitimate interests, or those of a third-party recipient of your Personal Information, make the processing necessary, provided those interests are not overridden by your interests or fundamental rights and freedoms.
                        Because you have chosen to publish or display your Personal Information on a public area of the Platform, such as a comment area.
                        Because it is necessary to protect your vital interests.
                        Because it is necessary in the public interest; or
                        Because you have expressly given us your consent to process your Personal Information in a particular manner.
                        We do not use Personal Information for making any automated decisions affecting or creating profiles other than what is described in this Policy.
                        Where the processing of your Personal Information is based on your consent, you have the right to withdraw your consent at any point in time in accordance with this Policy. Please note that should the withdrawal of consent result in us not being able to continue offering our Services to you, we reserve the right to withdraw or cease from offering our Services to you upon your consent withdrawal. You may withdraw consent by contacting us with a written request to the contact details provided in the ‘Grievances’ section below. Upon receipt of your request to withdraw your consent, the consequences of withdrawal may be communicated to you. Upon your agreement to the same, your request for withdrawal will be processed.
                        <br />
                        <b>HOW WE USE and SHARE THE INFORMATION COLLECTED </b>
                        We use/process your information in the following manner:
                        To provide Services on our Platform:  We use your information as collected by us to allow you to access the Platform and the Services offered therein, including without limitation to set-up and maintain your account, provide customer service, fulfil purchases through the Platform, verify User information and to resolve any glitches with our Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.
                        To improve our Platform and maintain safety: We use your information to improve and customize the Platform and Services offered by us, including providing automatic updates to newer versions of our Platform and creating new features based on the Platform usage analysis. Further, we also use your information to prevent, detect, investigate, and take measures against criminal activity, fraud, misuse of or damage to our Platform or network, and other threats and violations to Skelet’s or a third party's rights and property, or the safety of Skelet, its users, or others.  The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.
                        To market our Platform and communicate with You: We will use your information to develop a more targeted marketing of our Platform, to communicate with you about our offers, new products, services or even receive your feedback on the Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.
                        To establish, exercise, or defend legal claims: We may process any Personal Information identified in this Policy when necessary for establishing, exercising, or defending legal claims, whether in court, administrative, or other proceedings.  The legal basis for this processing is our legitimate interest in the protection and assertion of our legal rights, your legal rights, and the legal rights of others.
                        To manage risk and obtain professional advice: We may process any of the Personal Information identified in this Policy to manage risk or obtain professional advice.  The legal basis for this processing is our legitimate interest in the proper protection of our business and Platform.
                        Consent: We may otherwise use your information with your consent or at your direction.
                        To Better Understand Our Users: We may use information we gather to determine which areas of the Services are most frequently visited to understand how to enhance the Platform Services.
                        We share the information collected as per terms of this Policy only in the manner specified hereinbelow. We do not sell or otherwise disclose Personal Information we collect about you for monetary or other valuable consideration. Further, only authorized representatives of Skelet and on a need-to-know basis use any information received from you and as consented by you. In the event of any identified unauthorized use or disclosure of information or upon your complaint as stated under the ‘Grievances’ section below, we will investigate any such complaint and take the appropriate action as per the applicable Data Protection Laws.
                        Affiliates and Subsidiaries: We may disclose information about you to our affiliates, subsidiaries and other businesses under the same control and ownership, and their respective officers, directors, employees, accountants, attorneys, or agents, who need the information to help us provide the Services or process the information on our behalf. We require our affiliate, subsidiaries and other businesses under the same control and ownership to follow this Privacy Policy for any Personal Information that we share with them; you will always have an option to opt out of receiving some or all of such promotional communications from Skelet or its affiliates through the unsubscribe link in the email or such other option that may be communicated to you.
                        Third-party vendors/service providers including integrated services on the Platform: We may share information about you with third-party vendors or service providers (including consultants, payment processors, and other service providers and integrated services) who need the information to provide their support services to us or you, or to provide their services to you on our behalf either directly or through the Platform. These services may include providing customer support, performing business and sales analysis, supporting our website functionality, facilitating payment processing, and supporting contests, surveys, and other features offered on our Platform. Such third-party vendors are not allowed to use the information for any purpose other than what it was provided for, and they are required to process and use the information in accordance with this Privacy Policy.
                        Legal Disclosures: We may disclose information about you in response to a court order, or other governmental request. Without limitation to the foregoing, we reserve the right to disclose such information where we believe in good faith that such disclosure is necessary to:
                        comply with applicable laws, regulations, court orders, government, and law enforcement agencies’ requests.
                        protect and defend Skelet's or a third party's rights and property, or the safety of Skelet, our users, our employees, or others; or
                        prevent, detect, investigate, and take measures against criminal activity, fraud and misuse or unauthorized use of our Platform and/or to enforce our Terms of Service or other agreements or policies.
                        To the extent permitted by law, we will attempt to give you prior notice before disclosing your information in response to such a request.
                        Business transfers: In the event Skelet undergoes any merger, acquisition, or sale of company assets, in part or in full, with another company, or in the unlikely event that Skelet goes out of business or enters bankruptcy, user information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.
                        Advertising and Analytics Partners: We may share usage data with third-party advertisers, advertisement networks, and analytics providers through cookies and other similar technologies.
                        With Your Consent: We may share and disclose information with your consent or at your direction.
                        Your information may be shared for reasons not described in this Policy; however, we will seek your consent before we do the same or share information upon your direction.
                        <br />
                        <b>CROSS-BORDER DATA TRANSFER </b>
                        Your information including any Personal Information may be stored, processed, and transferred in and to the Amazon Web Service (AWS) servers and databases located in USA. Skelet may also store, process, and transfer information in and to servers in other countries depending on the location of its affiliates and service providers.
                        Please note that these countries may have differing (and potentially less stringent) privacy laws and that Personal Information can become subject to the laws and disclosure requirements of such countries, including disclosure to governmental bodies, regulatory agencies, and private persons, as a result of applicable governmental or regulatory inquiry, court order or other similar process.
                        If you use our Platform from outside India, including in the USA, EU, EEA, and UK, your information may be transferred to, stored, and processed in India. By accessing our Platform or otherwise giving us information, you consent to the transfer of information to India and other countries outside your country of residence. If you are located in the EU and applicable law specifies relevant legal grounds for processing personal data, the legal grounds for our processing activities are to perform our contract(s) with you; to meet our legal obligations; and for our legitimate business purposes, including to improve our operation and Services and to detect and prevent fraud.
                        We rely on legal bases to transfer information outside the EU, EEA and UK, and any Personal Information that we transfer will be protected in accordance with this Policy as well as with adequate protections in place in compliance with applicable Data Protection Laws and regulations.
                        DURATION FOR WHICH YOUR INFORMATION IS STORED ON THE PLATFORM
                        Mostly, when you delete any of the information provided by you or when you delete your account, on the Platform if applicable, or when you send a request to us through email to delete the Personal Information provided by you at the time of registration, the same will be deleted from our servers too. However, in certain cases, we will retain your information for as long as it is required for us to retain for the purposes stated hereinabove, including for the purpose of complying with legal obligation or business compliances.
                        Note: If you wish to exercise any of your rights (as specified in ‘Your Rights’ section below) to access, modify and delete any or all information stored about you, then you may do so by using the options provided within the Platform. You can always write to us privacy@skelet.ai for any clarifications needed.
                        <br />
                        <b>YOUR CHOICES </b>
                        Limit the information You provide: You always have an option to choose the information you provide to us, including the option to update or delete your information. However, please note that lack of certain information may not allow you the access to the Platform or any of its features, in part or in full.
                        Limit the communications You receive from us: Further, you will also have the option to choose what kind of communication you would like to receive from us and whether you would like to receive such communication at all or not. However, there may be certain communications that are required for legal or security purposes, including changes to various legal agreements, that you may not be able to limit.
                        Reject Cookies and other similar technologies: You may reject or remove cookies from your web browser; you will always have the option to change the default settings on your web browser if the same is set to ‘accept cookies’. However, please note that some Services offered on the Platform may not function or be available to you, when the cookies are rejected, removed, or disabled.
                        <br />
                        <b>YOUR RIGHTS: </b>
                        In general, all Users have the rights specified herein this section. However, depending on where you are situated, you may have certain specific rights in respect of your Personal Information accorded by the laws of the country you are situated in. To understand Your rights, please refer to the Country Specific Additional Terms below.
                        If you are a User, you may exercise any of these rights by using the options provided to you within the Platform upon your login. If, however, you are facing any issues or require any clarifications, you can always write to us at the address noted in the ‘Grievances’ section below, and we will address your concerns to the extent required by the applicable law.
                        Right to Confirmation and Access: You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information.
                        Right to Correction: You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.
                        Right to be Forgotten: You have the right to restrict or prevent the continuing disclosure of your Personal Information under certain circumstances.
                        Right to Erasure: If you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your account on the Platform permanently, and the same will not be retrievable.
                        Remember, you are entitled to exercise your rights as stated above only with respect to your information, including Personal Information, and not that of other Users. Further, when we receive any requests or queries over email or physically to the address specified in the ‘Grievances’ section below, then, as per the applicable Data Protection Laws, we may need to ask you a few additional information to verify your identity in association with the Platform and the request received.
                        <br />
                        <b>INFORMATION SECURITY </b>
                        We work to protect the security of your information during transmission by using Transport Layer Security (TLS) or Secure Sockets Layer (SSL) software (depending on your browser/Supported Device), which encrypts information you input in addition to maintaining security of your information as required under applicable laws.
                        We maintain electronic, and procedural safeguards in connection with the collection, storage, and disclosure of Personal Information (including Sensitive Personal Information). Our security procedures mean that we may occasionally request proof of identity before we disclose Personal Information to you that belongs to you.
                        However, no form or method of data storage or transmission system is fully secure, and we cannot guarantee that security provided by such system(s) is absolute and that your information will not be accessed, disclosed, or destroyed in the event of a breach of any of our security measures.
                        It is important for you to protect your account against unauthorized access to or use of your password and to your computer and if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner, you must immediately change your password or inform us, so that we are able to help you stop or prevent such unauthorized access. Be sure to sign off when you finish using a shared computer.
                        All KYC information which may be collected in accordance with this Policy are fully encrypted and cannot be accessed by any person other than the designated authority in Skelet.
                        We try and ensure that the third parties who provide services to us under appropriate contracts take appropriate security measures to protect Personal Information in line with our policies.
                        <br />
                        <b>PROMOTIONAL COMMUNICATIONS </b>
                        You will always have the option to opt out of receiving some or all of our promotional communications through the setting provided within the Platform upon your login, by using the unsubscribe link in any email communications sent to you or by emailinghelp@skelet.ai.
                        If you opt out of promotional communications, we may still send you transactional communications, such as service announcements, administrative and legal notices, and information about your account, without offering you the opportunity to opt out of these communications. If you no longer wish to use our Platform or receive any communications from us (except for those that are legally required), then you may delete your account by using the option enabled within the Platform or by writing to us privacy@skelet.ai.
                        Please note that opting out of promotional email communications only affects future communications from us. If we have already provided your information to a third party (as stated in this Policy) before you changed your preferences or updated your information, you may have to change your preferences directly with that third party.
                        We do not sell your Personal Information to third parties, and we do not use your name or name of your company in marketing statements without your consent.
                        <br />
                        <b>INTEREST-BASED ADS </b>
                        On unaffiliated sites, Skelet may display interest-based advertising using information you make available to us when you interact with our Platform and Services. Interest-based ads, also sometimes referred to as personalised or targeted ads, are displayed to you based on information from activities such as registering with our Platform, visiting sites that contain Skelet content or ads. In providing interest-based ads, we follow applicable laws, as well as the Code for Self-Regulation in Advertising by the Advertising Standards Council of India and the Self-Regulatory Principles for Online Behavioural Advertising developed by the Digital Advertising Alliance (a coalition of marketing, online advertising, and consumer advocacy organizations) and other rules and guidelines issued by the Federal Trade Commission in respect of digital advertising.
                        We do not provide any Personal Information to advertisers or to third party sites that display our interest-based ads. However, advertisers and other third-parties (including the ad networks, ad-serving companies, and other service providers they may use) may assume that users who interact with or click on a personalised ad or content are part of the group that the ad or content is directed towards. Also, some third-parties may provide us information about you (such as the sites where you have been shown ads or demographic information) from offline and online sources that we may use to provide you more relevant and useful advertising.
                        Advertisers or ad companies working on their behalf sometimes use technology to serve the ads that appear on our sites directly to your browser. They automatically receive your IP address when this happens. They may also use cookies to measure the effectiveness of their ads and to personalise ad content. We do not have access to or control over cookies or other features that advertisers and third-party sites may use, and the information practices of these advertisers and third-party websites are not covered by our Policy. Please contact them directly for more information about their privacy practices.
                        <br />
                        <b>MODIFICATION TO PRIVACY POLICY </b>
                        Our business changes constantly and our Policy may change from time to time. We may, at our discretion (unless required by applicable laws to mandatorily do so), email periodic reminders of our notices and conditions, unless you have instructed us not to, but we encourage you to check our Platform frequently to see the recent changes. Unless stated otherwise, our current Policy applies to all information that we have about you and your account. We stand behind the promises we make, however, and will not materially change our policies and practices making them less protective of customer information collected in the past without your consent.
                        <br />
                        <b>GRIEVANCES </b>
                        If you have any questions about this Policy, wish to exercise your rights, concerns about privacy or grievances at Platform, please register your complaint with a thorough description via email privacy@skelet.
                    </Text>
                    <Heading>COUNTRY SPECIFIC ADDITIONAL PRIVACY TERMS</Heading>
                    <Text>
                        TERMS APPLICABLE IF YOU ARE AN INDIAN RESIDENT<br />
                        Your Rights: If you are located in India, you may have the following rights under the Personal Digital Personal Data Protection Bill (DPDPB) when it becomes a legislation.  All requests can be made by using the option provided to you within the Platform upon your login. You may also write to us as stated in the "Grievances" section above, and we will address you concerns to the extent required by law.
                        <br/>Right to Confirmation and Access: You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information.
                        <br/>Right to Correction: You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.
                        <br/>Right to Grievance Redressal: You have the right to register your grievance with us concerning the manner in which your Personal Information is being handled by writing to our Grievance Officer.
                        <br/>Right to Nominate: You may nominate another person to act on your behalf, in the event of your death or incapacity, exercise your rights.
                        <br/>Right to Erasure: If you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your account on the Platform permanently, and the same will not be retrievable.
                        <br/>TERMS APPLICABLE IF YOU ARE A RESIDENT OF UNITED KINGDOM (UK), A EUROPEAN UNION (EU) COUNTRY OR EUROPEAN ECONOMIC AREA (EEA)
                        Your Rights: If you are located in the United Kingdom (UK) or European Union (EU) or European Economic Area (EEA), you have the following rights under the UK and EU General Data Protection Regulation (GDPR) respectively.  All requests should be sent to the address noted in the "Grievances" section above, and we will fulfil requests to the extent required by applicable law.
                        <br/>Right to access Your Personal Information: You have the right to receive confirmation as to whether or not Personal Information concerning you is being processed and, where that is the case, access to the Personal Information can be sought.
                        <br/>Right to Rectification: Our goal is to keep your Personal Information accurate, current and complete.  Please contact us if you believe your information is inaccurate or incomplete.
                        <br/>Right to Erasure: In some cases, you have a legal right to request that we erase your Personal Information.
                        <br/>Right to object to Processing: You have the right to object to our processing of your Personal Information under certain conditions.
                        <br/>Right to restrict Processing: You have the right to request that we restrict the processing of your Personal Information, under certain conditions.
                        <br/>Right to Data Portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                        <br/>Right to make a complaint to a government supervisory authority: If you believe we have not processed your Personal Information in accordance with applicable provisions of the GDPR, we encourage you to contact us privacy@skelet.ai.  You also have the right to make a GDPR complaint to the relevant Supervisory Authority or seek a remedy through the courts. A list of Supervisory Authorities is available at:https://edpb.europa.eu/about-edpb/board/members_en. If you need further assistance regarding your rights, please contact us using the contact information provided below, and we will consider your request in accordance with applicable law. You can identify the supervising authority of your concern by visiting https://edpb.europa.eu/about-edpb/board/members_en.
                        <br/>Right to not be subject to automated decision-making, including profiling: You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal or similarly significant effects concerning you.
                        <br/>We collect and process Personal Information about you only where we have a legal rationale to do so. Specific legal rationale applied for the same will depend on the type of Personal Information collected and the context in which the same is being processed, including the Services involved.
                        <br/>TERMS APPLICABLE IF YOU ARE A CALIFORNIA STATE RESIDENT<br/>
                        <br/>If you are a California state resident, then you have the following rights to the extent, and in the manner, set out in the CCPA:
                        <br/>The right to access the Personal Information that we hold on you.
                        <br/>The right to know what Personal Information we intend on collecting from them before the point of collection.
                        <br/>The right to opt in or out of marketing, analytics, and other similar activities.
                        <br/>The right to equal services without discrimination; and
                        <br/>The right to request deletion of Personal Information.
                        <br/>The above rights, the manner in which you can exercise the same and the category of and the manner in which we collect your information, are detailed below.
                        <br/>CCPA NOTICE AT COLLECTION:<br/>
                        <br/>For purposes of the CCPA, in collecting the information described above, we collect the categories of Personal Information listed below from you:
                        Identifiers: We may collect your name, email address, mobile number, username, unique personal identifier, and Internet Protocol (IP) address.   We use Identifiers as set forth in the "How We Use and Share the Information Collected" section of this Policy, like your name, contact information, and device and online identifiers.
                        Characteristics of Personal Information described in the California Customer Records statute: We may collect your name, email address, username, unique personal identifier, and gender. We use Categories of Personal Information described in the California Consumer Records statute as set forth in the ‘How We Use and Share the Information Collected’ section of this Policy.
                        Internet or other electronic network activity information: We collect cookies as described in our ‘Information we collect’ section above; we will automatically receive information from your browser and your device, which includes the date and time of your visit to the Platform as well as your location, Internet Protocol (IP) address, domain server, browser type, access time, and data about which pages you visit on the Platform.  We use Internet or other electronic network activity information as set forth in the "How We Use and Share the Information Collected" section of this Policy.
                        Geolocation data: We may collect your IP address.   We use Geolocation Data as set forth in the "How We Use and Share the Information Collected" section of this Policy.  (Such as your location based on your IP address);
                        Audio, electronic, visual or similar information: We may collect your profile picture or other audio, or visual information uploaded as content to the Platform. We use audio, electronic, visual or similar information as set forth in the "How We Use and Share the Information Collected" section of this Policy.
                        Inferences: We may make inferences based upon the Personal Information collected (such as likelihood of retention or attrition).  We use Inference information as set forth in the "How We Use and Share the Information Collected" section of this Policy.
                        <br/><br/>CCPA DATA PRACTICES DURING THE LAST 12 MONTHS:
                        Personal Information collected: As described in this Policy, we have collected the categories of Personal Information listed below during the preceding 12 months:
                        <br/>Identifiers
                        <br/>Characteristics of Personal Information described in the California Customer Records statute
                        <br/>Internet or other electronic network activity information
                        <br/>Geolocation data
                        <br/>Commercial information
                        <br/>Audio, electronic, visual, thermal, olfactory, or similar information
                        <br/>Inferences<br/>
                        <br/>Categories of sources: We have collected the Personal Information identified in this Policy from you and our payment processors.
                        <br/>Business and commercial purposes for collecting: We have collected the categories of Personal Information listed above for the following purposes:
                        <br/>Operate the Platform;
                        <br/>Provide our Services to you;
                        <br/>Honour our Terms of Service and contracts;
                        <br/>Ensure the privacy and security of our Platform and Services;
                        <br/>Manage our relationships with you;
                        <br/>Communicate with you;
                        <br/>Analyze use of the Platform and our Services;
                        <br/>Enhance your experience;
                        <br/>Track visits to the Platform;
                        <br/>Provide you with a more personal and interactive experience on the Platform; and
                        <br/>Usage analytics purposes.
                        <br/>Personal Information sold: We have not sold categories of Personal Information during the preceding 12 months.
                        <br/><br/>Personal Information disclosed for a business purpose: We have disclosed for a business purpose the categories of Personal Information listed below during the preceding 12 months:
                        <br/>Identifiers
                        <br/>Characteristics of Personal Information described in the California Customer Records statute
                        <br/>Internet or other electronic network activity information
                        <br/>Geolocation data
                        <br/>Commercial information
                        <br/>Audio, electronic, visual, thermal, olfactory, or similar information
                        <br/>Inferences
                        <br/>We have disclosed each category of Personal Information to the following categories of third parties: (1) corporate parents, subsidiaries, and affiliates; (2) advisors (accountants, attorneys); (3) service providers (data analytics, data storage, mailing, marketing, website and platform administration, technical support); and (4) operating systems and platforms.
                        <br/><br/>CONSUMER RIGHTS AND REQUESTS UNDER THE CCPA
                        <br/>The CCPA gives consumers the right to request that we (1) disclose what Personal Information we collect, use, disclose, and sell, and (2) delete certain Personal Information that we have collected or maintained. you may submit these requests to us as described below, and we honour these rights where they apply.
                        If a request is submitted in a manner that is not one of the designated methods for submission, or if the request is deficient in some manner unrelated to our verification process, we will either (1) treat the request as if it had been submitted in accordance with the designated manner, or (2) provide you with specific directions on how to submit the request or remedy any deficiencies with the request, as applicable.
                        Request to Know: As a California resident, you have the right to request: (1) the specific pieces of Personal Information we have collected about you; (2) the categories of Personal Information we have collected about you; (3) the categories of sources from which the Personal Information is collected; (4) the categories of Personal Information about you that we have sold and the categories of third parties to whom the Personal Information was sold; (5) the categories of Personal Information about you that we disclosed for a business purpose and the categories of third parties to whom the Personal Information was disclosed for a business purpose; (6) the business or commercial purpose for collecting, disclosing, or selling Personal Information; and (7) the categories of third parties with whom we share Personal Information. Our response will cover the 12-month period preceding our receipt of a verifiable request.
                        Request to Delete: As a California resident, you have a right to request the erasure/deletion of certain Personal Information collected or maintained by us. As described herein, we will delete your Personal Information from our records and direct any service providers (as defined under applicable law) to delete your Personal Information from their records. However, we are not required to honour a deletion request if an exemption applies under the law.
                        <br/><br/>Submitting a Request:
                        <br/>If we cannot verify the identity of the person making a request for categories of Personal Information, we may deny the request. If the request is denied in whole or in part for this reason, we will provide a copy of, or direct you to, our Privacy Policy.
                        <br/>If we cannot verify the identity of the person making the request for specific pieces of Personal Information, we are prohibited from disclosing any specific pieces of Personal Information to the requestor. However, if denied in whole or in part for this reason, we will evaluate the request as if it is seeking the disclosure of categories of Personal Information about the consumer.
                        <br/>If we cannot verify the identity of the person making a request to delete, we may deny the request.  If there is no reasonable method by which we can verify the identity of the requestor to the degree of certainty required, we will state this in our response and explain why we have no reasonable method by which we can verify the identity of the requestor.
                        <br/>Authorized Agents: Authorized agents may submit requests via the methods identified in this Policy. If you use an authorized agent to submit a request to know or a request to delete, we may require you to: (1) provide the authorized agent with signed permission to do so; (2) verify your identity directly with us; and (3) directly confirm with us that you provided the authorized agent permission to submit the request. However, we will not require these actions if you have provided the authorized agent with power of attorney pursuant to the California Probate Code.
                        <br/>Excessive Requests: If requests from a User are manifestly unfounded or excessive, in particular because of their repetitive character, we may either (1) charge a reasonable fee, or (2) refuse to act on the request and notify the User of the reason for refusing the request. If we charge a fee, the amount will be based upon the administrative costs of providing the information or communication or taking the action requested.
                        <br/>CCPA Non-Discrimination: You have the right not to receive discriminatory treatment by us due to your exercise of the rights provided by the CCPA. We do not offer financial incentives and price or service differences, and we do not discriminate against Users/consumers for exercising their rights under the CCPA.

                    </Text>
                </Box>
            </Box>
        </Flex>
    );
}

const contentPageStyle = {
    background: '#131E2A',
    paddingTop: '100px',
    '.container': {
        maxWidth: '860px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0 15px',
        'h2': {
            color: '#fff',
            fontSize: '42px',
            lineHeight: '52px',
            textAlign: 'center',
            marginBottom: '20px'
        },
        '.textContent': {
            background: '#293037',
            padding: '40px',
            'h2': {
                paddingTop: '20px',
                fontSize: '21px',
                marginBottom: '10px'
            },
            'p': {
                color: '#fff',
                fontSize: '16px',
                lineHeight: '28px'
            }
        }
    },
    '@media all and (max-width: 640px)': {
        paddingTop: '50px',
        '.container': {
            'h2': {
                fontSize: '38px'
            },
            '.textContent': {
                fontSize: '20px'
            }
        }
    }
}

export default PrivacyPolicy;