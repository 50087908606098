import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import AiImages from '../AiImages.js';
import DetailsItem from './DetailsItem/DetailsItem.js';
import SimpleBar from 'simplebar-react'; 
import 'simplebar/dist/simplebar.min.css';

function AiImageDetails({ documentDetails }) {
  return (
    <Flex sx={aiImagesDetailsPageStyle}>
      <Box className="topStructureContainer">
        <AiImages page="ai-image-details" projectId={documentDetails?.document?.id} imageDocumentDetails={documentDetails}/>
      </Box>
      {documentDetails && documentDetails.allPrompts.length > 0 &&
        <SimpleBar style={{maxHeight: 'calc(100vh - 240px)'}}>
          <Box className="bottomStructureContainer">
            {documentDetails.allPrompts.map((item, key) => (
              <DetailsItem key={key} detailsItem={item}/>
            ))}
          </Box>
        </SimpleBar>
      }
    </Flex>
  );
}
const aiImagesDetailsPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  '.topStructureContainer': {
    marginBottom: '40px',
  },
  '.bottomStructureContainer': {
    marginBottom: '40px',
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.imagesContainer': {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '-10px',
      marginRight: '-10px',
      '.imageItem': {
        width: '25%',
        padding: '0 10px'
      }
    }
  },
  '@media all and (max-width: 1200px)': {
    '.bottomStructureContainer': {
      paddingLeft: '15px',
      paddingRight: '15px'
    }
  }
}
export default AiImageDetails;
