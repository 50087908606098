import React from "react";
import { Flex, Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import Heading from '../../../common/frontend/heading/Heading.js';
import Description from '../../../common/frontend/description/Description.js';

function SixthSection() {
    const boxes = [
        {
            question: 'How do I use Skelet\'s AI content generator?',
            answer: 'It\'s incredibly easy! Just enter the text you want into the designated area and click "Generate". You\'ll quickly receive content created by AI, which you can then use or modify as needed.'
        },
        {
            question: 'How do I create an image with Skelet\'s AI image generator?',
            answer: 'Enter your text into the provided space and hit the generate button. In moments, your AI-generated image will be displayed, all set for you to download and share.'
        },
        {
            question: 'Can I use these AI images for commercial purposes?',
            answer: 'Our images are designed to cater to various business requirements, be it for marketing, brand identity, or other commercial purposes. However, it\'s important to ensure compliance with the applicable local regulations.'
        },
        {
            question: 'Is multi-language support available?',
            answer: 'Yes, we offer support for multi-language. Also the text-to-speech can generate in the following languages: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.'
        },
        {
            question: 'Can I upgrade from a monthly to an annual plan?',
            answer: 'Yes, you have the flexibility to switch from a monthly to an annual subscription whenever you choose via your Subscriptions page. Upon making the change, the annual plan becomes effective right away. We will prorate and adjust your existing monthly plan accordingly, and it will be discontinued following the conclusion of the present billing period.'
        },
        {
            question: 'What payment methods can I use?',
            answer: 'Payments can be made using both debit and credit cards. All payments are powered by Stripe and 256-bit encrypted. Skelet doesn’t store or share your card, or personal payment information. All payments are managed by Stripe.'
        },
        {
            question: 'Will my unused credits be carried forward to the next month?',
            answer: 'Currently your limits will be valid for that particular month only. If unused, they are not carried forward to the next month.'
        },
        
    ];
    return (

        <Flex sx={sixthSectionStyle}>
            <Box className="leftBox">
                <Box className="headingContainer">
                    <Heading text="Questions, <br/>Answered" />
                </Box>
                <Description text="We've addressed the most <br/>frequently asked questions to guide <br/>you through Skelet AI" />
                <Text className="textLeftBottom">If you have additional inquiries, feel free to <br />send your questions to help@skelet.ai, <br />and our team will be happy to assist you.</Text>
            </Box>
            <Box className="rightBox">
                <Flex sx={boxesContainerStyle}>
                    <Accordion allowMultiple defaultIndex={[0]} w="100%">
                        {boxes.map((item, key) => (
                            <AccordionItem key={key} border="0" mb="20px">
                                {({ isExpanded }) => (
                                    <Box className={isExpanded ? 'expandedItem itemContainer' : 'itemContainer'}>
                                        <AccordionButton border="0" p="0" className={isExpanded ? 'expanded' : ''} sx={buttonContainerStyle}>
                                            <AccordionIcon className="accordionIcon" />
                                            <Box display="flex" alignItems="center">
                                                <Text>{item.question}</Text>
                                            </Box>
                                        </AccordionButton>
                                        <AccordionPanel className="answerContainer">
                                            <Text>{item.answer}</Text>
                                        </AccordionPanel>
                                    </Box>
                                )}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Flex>
            </Box>
        </Flex>
    );
}

const sixthSectionStyle = {
    flexWrap: 'wrap',
    '.headingContainer': {
        marginBottom: '70px',
    },
    '.leftBox': {
        flex: '0 0 30%',
        '.textLeftBottom': {
            fontSize: '16px',
            color: '#8599CD'
        }
    },
    '.rightBox': {
        flex: '0 0 70%',
        paddingTop: '150px',
        '.buttonContainer': {
            marginBottom: '55px'
        }
    },
    '@media all and (max-width: 800px)': {
        flexWrap: 'wrap',
        '.headingContainer': {
            marginBottom: '20px',
        },
        '.leftBox': {
            flex: '0 0 100%',
            marginBottom: '20px'
        },
        '.rightBox': {
            flex: '0 0 100%',
            paddingTop: 0,
            '.buttonContainer': {
                marginBottom: '30px'
            }
        },
    },
    '@media all and (max-width: 420px)': {
        '.rightBox': {
            '.descriptionContainer': {
                'br': {
                    display: 'none'
                }
            }
        },
    }
}

const buttonContainerStyle = {

}
const boxesContainerStyle = {
    width: '100%',
    flexWrap: 'wrap',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',

    'button': {
        height: '63px',
        lineHeight: '63px',
        fontSize: '18px',
        color: '#1B518F',
        fontWeight: 700,
        padding: '20px',
        'p': {
            color: '#1E5DFF',
            fontSize: '18px',
            lineHeight: '22px',
            textAlign: 'left',
            fontWeight: 500
        },
        '.accordionIcon': {
            marginRight: '10px',
            width: '22px',
            height: '22px',
            borderRadius: '36px',
            'path': {
                fill: '#1E5DFF'
            }
        },
    },
    '.answerContainer': {
        padding: '0 55px 20px',
        'p': {
            fontSize: '16px',
            lineHeight: '22px',
            color: '#1B518F'
        }
    },
    '.itemContainer': {
        background: '#293037',
        '&.expandedItem': {
            'button': {
                'p': {
                    color: '#fff',
                    fontWeight: 700
                }
            },
            'p': {
                color: '#fff'
            }
        },
    },
    '@media (max-width: 800px)': {
        paddingLeft: 0,
        paddingRight: 0,
        'button': {
            fontSize: '14px',
            paddingLeft: '15px',
            paddingRight: '15px',
            textAlign: 'left',
            lineHeight: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            '.accordionIcon': {
                width: '25px',
                height: '25px'
            }
        },
        '.answerContainer': {
            padding: '20px',
            'p': {
                fontSize: '14px'
            }
        }
    }
}



export default SixthSection;


