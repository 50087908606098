import React, {useState, useEffect} from 'react';
import {Box} from '@chakra-ui/react';
import DesktopLibrary from './DesktopLibrary/DesktopLibrary.js';
import MobileLibrary from './MobileLibrary/MobileLibrary.js';
// import RecentActivity from '../recent-activity/RecentActivity.js';


function Library() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1200);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <>
      {isMobile === false
        ? <Box display="flex" flexWrap="wrap">
            <DesktopLibrary />
            {/* <RecentActivity isMobile={isMobile}/> */}
          </Box>
        : <MobileLibrary />
      }
    </>
  );
}

export default Library;
