function AiCHatFormIcon() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
            <circle cx="17" cy="17" r="17" fill="#EAF5FF"/>
            <line x1="14.8357" y1="7.48446" x2="5.57257" y2="18.4318" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="15.6756" y1="10.8555" x2="8.93876" y2="18.7442" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="19.8821" y1="10.018" x2="12.3031" y2="18.7489" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="28.2769" y1="18.705" x2="18.8106" y2="10.0808" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="23.8745" y1="18.5117" x2="17.1377" y2="12.617" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="24.6801" y1="23.5303" x2="15.417" y2="14.2672" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="20.158" y1="18.6973" x2="5.00006" y2="18.6973" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="25.2104" y1="23.75" x2="9.21045" y2="23.75" stroke="#1E5DFF" strokeWidth="1.5"/>
            <line x1="22.6843" y1="21.2236" x2="11.737" y2="21.2236" stroke="#1E5DFF" strokeWidth="1.5"/>
        </svg>

    );
}
  
export default AiCHatFormIcon;