import axios from 'axios';

// Create an axios instance
const api = axios.create({
  baseURL: 'https://skelet.ai:5000/api/', // Replace with your API base URL
  timeout: 50000,
  headers: { 
    'Content-Type': 'application/json',
  },
});

// This interceptor runs before each request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // If the error is related to token expiration or invalid token, remove it from localStorage
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem("token");
        if (window.location.pathname.includes("admin")) {
          window.location.href = "/";
        }
        
    }
    return Promise.reject(error);
});

export default api;
