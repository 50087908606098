import React, { createContext, useContext, useState } from 'react';

import api from '../utils/api.js';
const AiImageContext = createContext();

export const useAiImageContext = () => {
    return useContext(AiImageContext);
};

export const AiImageProvider = ({ children }) => {
    const [documentDetails, setDocumentDetails] = useState();

    const fetchImageDocumentDetails = async (documentId) => {
        try {
            const response = await api.get(`/admin/ai-images/${documentId}`);
            setDocumentDetails(response.data);
        } catch (error) {
            console.error('Failed to count documents', error);
        }
    }

    return (
        <AiImageContext.Provider value={{ documentDetails, fetchImageDocumentDetails }}>
            {children}
        </AiImageContext.Provider>
    );
}