function TikTokVideoScriptIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#F2F9FF"/>
            <path d="M11.9102 6.3123V7.54818C11.8438 7.54818 11.764 7.56147 11.6843 7.56147C11.0863 7.56147 10.5282 7.33555 10.1029 6.97675V9.60798C10.1029 10.1395 9.93015 10.6445 9.62451 11.0432C9.19926 11.6146 8.50823 12 7.72417 12C6.87368 12 6.12949 11.5482 5.71753 10.8837C6.14278 11.2824 6.71421 11.5349 7.33879 11.5349C8.10956 11.5349 8.80059 11.1628 9.22584 10.5781C9.51819 10.1794 9.70424 9.68771 9.70424 9.14286V6.49834C10.1295 6.87044 10.6743 7.08306 11.2856 7.08306C11.3654 7.08306 11.4318 7.08306 11.5115 7.06977V6.27243C11.6312 6.29901 11.7375 6.3123 11.8571 6.3123H11.9102Z" fill="#FF004F"/>
            <path d="M8.05654 7.22925V8.59802C7.96351 8.57144 7.8572 8.55815 7.76418 8.55815C7.17946 8.55815 6.70106 9.04984 6.70106 9.64785C6.70106 9.78074 6.72763 9.90034 6.7675 10.0199C6.50172 9.82061 6.31567 9.50167 6.31567 9.14287C6.31567 8.54486 6.79408 8.05317 7.3788 8.05317C7.48511 8.05317 7.57813 8.06646 7.67116 8.09303V7.21596C7.69773 7.21596 7.72431 7.21596 7.75089 7.21596C7.8572 7.21596 7.96351 7.21596 8.05654 7.22925Z" fill="#FF004F"/>
            <path d="M10.3023 5.32891C10.0631 5.11629 9.89039 4.82393 9.79736 4.51828H10.1163C10.1163 4.58473 10.1163 4.63788 10.1163 4.70433C10.1429 4.91695 10.2093 5.12958 10.3023 5.32891Z" fill="#FF004F"/>
            <path d="M11.5249 6.28571V7.08305C11.4584 7.09634 11.3787 7.09634 11.299 7.09634C10.701 7.09634 10.1428 6.87042 9.71759 6.51162V9.14285C9.71759 9.67441 9.54483 10.1794 9.23918 10.5781C8.80064 11.1628 8.1229 11.5349 7.35214 11.5349C6.72755 11.5349 6.15612 11.2824 5.73087 10.8837C5.50496 10.5116 5.37207 10.0864 5.37207 9.62125C5.37207 8.33222 6.39533 7.28238 7.67107 7.24252V8.11959C7.57805 8.09302 7.47174 8.07973 7.37872 8.07973C6.794 8.07973 6.31559 8.57142 6.31559 9.16943C6.31559 9.52823 6.48835 9.86046 6.76742 10.0465C6.9136 10.4585 7.31227 10.7641 7.7641 10.7641C8.34881 10.7641 8.82722 10.2724 8.82722 9.67441V4.51826H9.79732C9.89034 4.8372 10.0631 5.11627 10.3023 5.3289C10.5415 5.8073 10.9933 6.16611 11.5249 6.28571Z" fill="black"/>
            <path d="M7.6711 6.76413V7.21595C6.39535 7.25582 5.37209 8.30566 5.37209 9.59469C5.37209 10.0598 5.50498 10.4851 5.7309 10.8572C5.27907 10.4186 5 9.80732 5 9.11629C5 7.80067 6.06312 6.73755 7.36545 6.73755C7.47176 6.73755 7.57807 6.75084 7.6711 6.76413Z" fill="#00F7EF"/>
            <path d="M9.79723 4.51827H8.82713V9.64784C8.82713 10.2458 8.34873 10.7375 7.76401 10.7375C7.2989 10.7375 6.91351 10.4452 6.76733 10.0199C6.94009 10.1395 7.15272 10.2193 7.37863 10.2193C7.96335 10.2193 8.44175 9.74086 8.44175 9.14286V4H9.73079V4.02658C9.73079 4.07973 9.73079 4.13289 9.74408 4.18605C9.74408 4.29236 9.77066 4.41196 9.79723 4.51827Z" fill="#00F7EF"/>
            <path d="M11.5249 5.79402V6.27242C10.9934 6.16611 10.5416 5.80731 10.2891 5.3289C10.6213 5.63455 11.0465 5.80731 11.5249 5.79402Z" fill="#00F7EF"/>
        </svg>

    );
  }
  
export default TikTokVideoScriptIcon;