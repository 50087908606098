import React, {useEffect} from 'react';
import Header from '../../layouts/Header/Header.js';
import TextToSpeech from '../../components/text-to-speech/TextToSpeech.js';

function TextToSpeechPage() {
    useEffect(() => {
        if (window.innerWidth <= 1200) {
            document.documentElement.scrollTop = 0;
        }
    });
    return (
        <>
            <Header page="textToSpeech" title="Untitled Document"/>
            <TextToSpeech page="generate"/>
        </>
    );
  }

export default TextToSpeechPage;
