import React, { useState } from 'react';
// import { useWatch } from "react-hook-form"
import InputDefault from '../../form/InputDefault.js';
import ContentWritingTone from '../content-writing-tone/ContentWritingTone.js';
import LanguageSelector from '../../form/LanguageSelector.js';
import CloseModalIcon from '../../../assets/images/CloseModalIcon.js';

import { Box, Flex, Button, Icon, Text, Textarea } from "@chakra-ui/react";

const PromptSettings = ({ defaultValues, register, user, setValue, getValues, ref, watch }) => {
    const fields = defaultValues.projectFields ? JSON.parse(defaultValues.projectFields) : null;
    const [keywords, setKeywords] = useState(false);
    const [goals, setGoals] = useState(false);
    const [targetAudience, setTargetAudience] = useState(false);
    const keywordsText = watch('keyword');
    const goalsText = watch('goal');
    const targetAudienceText = watch('targetAudience');

    return (
        <Flex sx={mainBoxStyle}>
            <Box className="textareaContainer">
                <Textarea placeholder="Provide more details"
                    {...register('title', { required: true })}
                    defaultValue={fields ? fields.title : ''}
                />
            </Box>
            <Box className="extraFieldsContainer">
                <Box className={keywords === true ? 'input-item active' : 'input-item'}>
                    <Box className="inputContainer">
                        <InputDefault label="Keyword" placeholder="Enter keywords separated by comma" {...register('keyword')} defaultValue={fields ? fields.keyword : ''} />
                        <Button onClick={() => setKeywords(false)} className={keywordsText?.length > 0 ? `active` : ``}>
                            {keywordsText?.length > 0
                                ? <Text className="okTextStyle">Ok</Text>
                                : <Icon as={CloseModalIcon} />
                            }
                        </Button>
                    </Box>
                </Box>
                <Box className={goals === true ? 'input-item active' : 'input-item'}>
                    <Box className="inputContainer">
                        <InputDefault label="Goals" placeholder="Signups, get more clients, etc" {...register('goal')} defaultValue={fields ? fields.goal : ''} />
                        <Button onClick={() => setGoals(false)} className={goalsText?.length > 0 ? `active` : ``}>
                            {goalsText?.length > 0
                                ? <Text className="okTextStyle">Ok</Text>
                                : <Icon as={CloseModalIcon} />
                            }
                        </Button>
                    </Box>
                </Box>
                <Box className={targetAudience === true ? 'input-item active' : 'input-item'}>
                    <Box className="inputContainer">
                        <InputDefault label="Target Audience" placeholder="General" {...register('targetAudience')} defaultValue={fields ? fields.targetAudience : ''} />
                        <Button onClick={() => setTargetAudience(false)} className={targetAudienceText?.length > 0 ? `active` : ``}>
                            {targetAudienceText?.length > 0
                                ? <Text className="okTextStyle">Ok</Text>
                                : <Icon as={CloseModalIcon} />
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box className="buttonsContainer">
                <Box className={getValues('keyword') !== "" && getValues('keyword') !== undefined ? "buttonItem hasValue" : "buttonItem"}>
                    <Button onClick={() => setKeywords(true)}>Keywords</Button>
                </Box>
                <Box className={getValues('goal') !== "" && getValues('goal') !== undefined ? "buttonItem hasValue" : "buttonItem"}>
                    <Button onClick={() => setGoals(true)}>Goals</Button>
                </Box>
                <Box className={getValues('targetAudience') !== "" && getValues('targetAudience') !== undefined ? "buttonItem hasValue" : "buttonItem"}>
                    <Button onClick={() => setTargetAudience(true)}>Target Audience</Button>
                </Box>
                <Box className="buttonItem">
                    <ContentWritingTone {...register('tone')} setValue={setValue} defaultValue={fields ? fields.tone : ''} />
                </Box>
                <Box className="buttonItem">
                    <LanguageSelector {...register('language', { required: false })} user={user} ref={ref} setValue={setValue} />
                </Box>
            </Box>
        </Flex>
    );
}

const mainBoxStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '.textareaContainer': {
        marginBottom: '10px',
        'textarea': {
            minHeight: '100px',
            fontSize: '16px',
            color: '#8599CD',
            padding: '20px',
            border: '0 none',
            resize: 'none',
            boxShadow: 'none'
        }
    },
    '.extraFieldsContainer': {
        background: '#fff',
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        left: 0,
        width: '550px',
        '.input-item': {
            display: 'none',
            '.inputContainer': {
                position: 'relative',
                'button': {
                    position: 'absolute',
                    top: '5px',
                    right: '10px',
                    height: '40px',
                    lineHeight: '40px',
                    width: '40px',
                    background: 'transparent',
                    border: '1px solid #CFD5E3',
                    borderRadius: '4px',
                    padding: 0,
                    transition: 'all 0.3s ease',
                    zIndex: 1,
                    '.okTextStyle': {
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        color: '#FFFFFF',
                        fontWeight: 700
                    },
                    'svg': {
                        width: '10px',
                        path: {
                            fill: '#A4C5E0',
                            '&.stroke': {
                                stroke: '#A4C5E0'
                            }

                        }
                    },
                    '&:hover': {
                        background: '#CFD5E3'
                    },
                    '&.active': {
                        background: '#1E5DFF',
                        borderColor: '#1E5DFF'
                    }
                }
            },
            '&.active': {
                display: 'block'
            }
        }
    },
    '.buttonsContainer': {
        display: 'flex',
        flexDirection: 'row',
        '.buttonItem': {
            marginRight: '10px',
            marginBottom: '10px',
            '&:last-child': {
                marginRight: 0
            },
            'button': {
                height: '31px',
                lineHeight: '31px',
                paddingLeft: '10px',
                paddingRight: '10px',
                border: '1px solid #CFD5E3',
                fontSize: '14px',
                color: '#1B518F',
                borderRadius: '4px',
                background: 'transparent',
                transition: 'all 0.3s ease',
                fontWeight: 400,
                '&:hover': {
                    background: '#CFD5E3'
                }
            },
            '&.hasValue': {
                'button': {
                    borderColor: '#1E5DFF',
                    color: '#1B518F',
                    fontWeight: 700
                }
            }
        }
    },
    '@media all and (max-width: 640px)': {
        '.buttonsContainer': {
            flexWrap: 'wrap'
        },
        '.extraFieldsContainer': {
            width: '100%',
        }
    }
}

export default PromptSettings;
