import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function Description({  text }) {

    return (
        <Box sx={style} className="descriptionContainer">
            <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
        </Box>
    )
}

const style = {
    marginBottom: '40px',
    'p': {
        fontSize: '16px',
        lineHeight: '22px',
        color: '#fff',
        fontWeight: 700
    }
}

export default Description;