import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../utils/api.js';
import {
    Box,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Text,
    Link,
    Icon, 
    Button,
} from "@chakra-ui/react";
import SearchIcon from '../../assets/images/SearchIcon.js';

import CloseModalIcon from '../../assets/images/CloseModalIcon.js';
import FolderIcon from '../../assets/images/WorkspaceLogo.js';
import DocumentIcon from '../../assets/images/DocumentIcon.js';
import { TEMPLATE_ICONS } from '../../assets/images/templates/TemplatesImage.js';
import {formatLinks} from '../../utils/getLinks.js';
import { Link as RouterLink } from 'react-router-dom';
function limitString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}
function Search() {
    const { register, watch, setValue } = useForm();
    const [results, setResults] = useState([]);
    const searchQuery = watch("search", "");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);
    const inputRef = React.useRef(null);

    const getIcon = (type) => {
        console.log(type);
        return TEMPLATE_ICONS[type]
    }

    const hasResults = results.contentTemplates?.length > 0 || results.folders?.length > 0 || results.projects?.length > 0;

    const handleSearch = async () => {
        try {
            const response = await api.post('/admin/search', { query: searchQuery });
            setResults(response.data);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchQuery);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            handleSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    const handleCloseSearch = () => {
        setResults([]);
        setValue('search', '');
    }

    return (
        <Box sx={mainSearchContainerStyle}>
            <Popover isOpen={!!debouncedSearchTerm}>
                <PopoverTrigger>
                    <Box style={searchContainerStyle}>
                        <Box>
                            {searchQuery !== ""
                                ? <Button onClick={handleCloseSearch} sx={closeSeachButtonStyle}>
                                    <Icon as={CloseModalIcon} />
                                </Button>
                                : <Icon as={SearchIcon} />
                            }
                        </Box>
                        <Input
                            ref={inputRef}
                            name="search"
                            placeholder="Find anything......"
                            {...register("search")}
                            style={searchInputStyle}
                        />
                    </Box>
                    
                </PopoverTrigger>
                <PopoverContent sx={popoverContentStyle}>
                    <PopoverBody className="popoverBody">
                        {hasResults ? (
                            <>
                                {/* {results.contentTemplates && results.contentTemplates.length > 0 && (
                                    <Box style={boxSerachItemStyle}>
                                        <Box sx={headingBoxStyle}>
                                            <Icon as={LibraryIcon} className="icon"/>
                                            <Text className="textLabel">Library</Text>
                                        </Box>
                                        <TemplateMenuItems menuItems={results.contentTemplates} />
                                    </Box>
                                )} */}

                                {results.folders && results.folders.length > 0 && (
                                    <Box style={boxSerachItemStyle}>
                                        <Box sx={headingBoxStyle}>
                                            <Icon as={FolderIcon} className="icon" />
                                            <Text className="textLabel">Folders</Text>
                                        </Box>
                                        {results.folders.map((item , key) => (
                                            <Box sx={linkItemStyle} key={key}>
                                                <Link key={item.id} as={RouterLink} to={`${formatLinks(item.id, 'folder')}`}>
                                                    <Text>{limitString(item.name, 80)}</Text>
                                                </Link>
                                            </Box>
                                        ))}
                                    </Box>
                                )}

                                {results.projects && results.projects.length > 0 && (
                                    <Box style={boxSerachItemStyle}>
                                        <Box sx={headingBoxStyle}>
                                            <Icon as={DocumentIcon} className="icon" />
                                            <Text className="textLabel">Documents</Text>
                                        </Box>
                                        {results.projects.map((item, key) => (
                                            <Box sx={linkItemStyle} key={key}>
                                                <Link key={item.id} as={RouterLink} to={`${formatLinks(item.id, item.templateType)}`}>
                                                    {getIcon(item.templateType)}
                                                    <Text>{limitString(item.name, 80)}</Text>
                                                </Link>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </>
                        ) : (
                            searchQuery !== "" ? <Text p="20px">No results found.</Text> : null
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

const mainSearchContainerStyle = {
    width: '100%',
    border: '1px solid #CFD5E3',
    borderRadius: '4px',
    position: 'relative',
    '.chakra-popover__popper':{
        transform: 'none !important',
        position: 'relative !important'
    }
}

const linkItemStyle = {
    'a': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        'svg': {
            marginRight: '5px',
            width: '23px',
            height: '23px',
            'circle': {
                width: '11px',
                height: '11px'
            }
        },
        'p': {
            color: '#1B518F',
            fontSize: '14px',
            padding: '7px 10px',
            fontWeight: 600,
            textAlign: 'left',
        }
    }
}

const boxSerachItemStyle = {
    padding: '20px',
    borderBottom: '1px solid #E1F2FF'
}

const headingBoxStyle = {
    display: 'flex',
    alignItems: "center",
    marginBottom: '10px',
    '.textLabel': {
        fontSize: '11px',
        lineHeight: '12px',
        color: '#6D99BE',
        fontWeight: 700,
        marginLeft: '10px',
        textTransform: 'uppercase'
    },
    'svg': {
        position: 'relative',
        width: '10px',
        top: '-2px',
        'path': {
            stroke: '#6D99BE'
        }
    }
}

const popoverContentStyle = {
    width: '320px',
    boxShadow: '0px 0px 16px 0px #0000001A',
    borderRadius: '6px',
    border: '0 none',
    outline: 'none !important',
    position: 'absolute',
    transform: 'none', 
    top: '100%',
    left:0,
    '.popoverBody': {
        padding: '0',
        outline: 'none !important',
    }
}   

const closeSeachButtonStyle = {
    background: 'transparent !important',
    padding: 0,
    minWidth: '1px',
    'svg path':{
        fill: '#A4C5E0',
        stroke: '#A4C5E0'
    }
}

const searchContainerStyle = {
    display: "flex", 
    alignItems: "center", 
    height: "45px",
    width: '100%',
    borderLeft: "1px solid #E1F2FF",
    borderRight: "1px solid #E1F2FF", 
    paddingLeft: "20px",
}

const searchInputStyle = {
    border: '0 none',
    borderRadius: '0',
    height: '100%',
    fontSize: '14px',
    lineHeight: '#6D99BE',
    outline: 'none',
    boxShadow: 'none',
    color: '#6D99BE',
}

export default Search;
