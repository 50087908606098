// FrontLayout.js
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import FrontHeader from './Header/FrontHeader.js';
import FrontFooter from './Footer/FrontFooter.js';
import HomepageContainer from "../pages/Homepage.js";
import RegisterPage from '../pages/RegisterPage.js';
import LoginPage from "../pages/LoginPage.js";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.js";
import ResetPasswordPage from "../pages/ResetPasswordPage.js";
import TermsAndConditions from "../pages/front/terms-and-conditions.js";
import PrivacyPolicy from "../pages/front/privacy-policy.js";
// import PreviewImage from '../assets/images/homepage/preview.png';

const FrontLayout = ({ children }) => {
  const [generatedContent, setGeneratedContent] = useState(false);
  // const [userIp, setUserIp] = useState(null);
  // const allowedIps = ['188.26.8.188', '188.26.8.116', '86.121.55.29'];

  // useEffect(() => {
  //   const fetchUserIp = async () => {
  //     try {
  //       const response = await axios.get("https://skelet.ai:5000/api/auth/get-ip/");
  //       setUserIp(response.data.ip);
  //     } catch (error) {
  //       console.error('Error fetching user IP:', error);
  //     }
  //   };

  //   fetchUserIp();
  // }, []);
  // const isIpAllowed = allowedIps.includes(userIp);

  useEffect(() => {
    document.documentElement.classList.add('front-layout');
    document.documentElement.classList.remove('dashboard-layout');
  }, []);

  const onLoginSuccessful = () => { }

  return (
    <Flex flexWrap="wrap">
      <FrontHeader generatedContent={generatedContent} />
      <Box flex="1" height="100%">
        <Routes>
          <Route path="/" element={<HomepageContainer />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="login" element={<LoginPage onLoginSuccessful={onLoginSuccessful} />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />

          <Route index element={<HomepageContainer setGeneratedContent={() => setGeneratedContent(true)} />} />
        </Routes>
        <Outlet />
      </Box>
      <FrontFooter />
    </Flex>
  );
};

// const previewContainer = {
//   width: '100%',
//   background: '#131E2A',
//   '.innerContainer': {
//     maxWidth: '600px',
//     marginLeft: 'auto',
//     marginRight: 'auto'
//   }
// }

export default FrontLayout;