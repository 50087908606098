import React, {useEffect} from 'react';
import GenerateContent from '../../components/generate-content/GenerateContent.js';

function NewProjectPage() {
    useEffect(() => {
        if (window.innerWidth <= 1200) {
            document.documentElement.scrollTop = 0;
        }
    });
    return (
        <>
            <GenerateContent />
        </>
    );
}

export default NewProjectPage;
