function AiContentIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <line className="stroke" x1="11.5" y1="6.34784" x2="11.5" y2="19.8261" stroke="#1E5DFF"/>
            <line className="stroke" x1="21" y1="7.5" x2="2" y2="7.5" stroke="#1E5DFF"/>
            <line className="stroke" x1="2.23926" y1="12" x2="2.23926" y2="7" stroke="#1E5DFF"/>
            <line className="stroke" x1="21.3691" y1="12" x2="21.3691" y2="7" stroke="#1E5DFF"/>
            <circle className="fill" cx="21.4346" cy="15" r="1.08696" fill="#1E5DFF"/>
            <circle className="fill" cx="2.08696" cy="15" r="1.08696" fill="#1E5DFF"/>
            <path className="stroke" d="M12.2591 1.75L11.8261 1L11.3931 1.75L8.94561 5.98913L8.5126 6.73913H9.37862H14.2736H15.1396L14.7066 5.98913L12.2591 1.75Z" stroke="#1B518F"/>
            <path className="stroke" d="M11.2115 23.3657L11.5525 23.6837L11.8936 23.3657L14.341 21.083L15.2692 20.2174L14 20.2174L9.10507 20.2174L7.83588 20.2174L8.76405 21.083L11.2115 23.3657Z" stroke="#1B518F"/>
        </svg>

    );
}
  
export default AiContentIcon;