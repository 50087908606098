import React, {useEffect} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import prices from '../../../utils/prices.js';
import Invoices from '../../../components/payment-stripe/invoices/Invoices.js';
import {
  Box,
  Heading,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Text,
  Progress,
} from '@chakra-ui/react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { useUserContext } from '../../../contexts/UserContext.js';
import { useAppContext } from '../../../contexts/AppContext.js';
import {formatDate} from '../../../utils/formatDate.js';
import PaymentPlans from '../../../components/payment-stripe/payment-plans/PaymentPlans.js';
import MyAccountNavigation from './MyAccountNavigation/MyAccountNavigation.js';
const stripePromise = loadStripe('pk_test_51D8QVoCtBGqXvw5Cj6d36oOYmlZUBFR6jrcbiNR8Gk5NttjtuH9MnXezUZ6dFURvrnCXcKGNsMGWocCEoUOQgTSG00C7leZv5Y');

function calculatePercentage(current, total) { 
  if (total === 0) return 0;  // Handle division by zero
  return Math.round((1 - (current / total)) * 100);
}

function Billing() {
    const { userDataAndPlans } = useUserContext();
    const planType = userDataAndPlans.planType;
    const progressValue = (userDataAndPlans.wordsRemaining / prices[planType]?.credits) * 100;
    const color = progressValue <= 30 ? "#FF1284" : "#0D78F5";
    const {mobileMenuOpen, openMobileMenu} = useAppContext();
    
    useEffect(() => {
      if(mobileMenuOpen === true && window.innerWidth < 1200){
        openMobileMenu(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <MyAccountNavigation />
            <SimpleBar style={{maxHeight: 'calc(100vh - 160px)'}}>
              <Box sx={mainContainerStyle}>
                  {userDataAndPlans &&
                    <>
                      <Box sx={firstBoxStyle}>
                        <Heading style={headingPageStyle}>My Subscription</Heading>
                        <Text style={subtitleStyle}>You are currently on the monthly <Text style={planTypeStyle}>{userDataAndPlans.planType}</Text> plan.</Text>
                        {userDataAndPlans.planType !== 'unlimited' && (
                          <>
                            <Text style={generatedWordsStyle}>Words Generated: <Text sx={wordsAmountStyle}><span>{Math.max(0, prices[planType]?.credits - userDataAndPlans.wordsRemaining)}</span> of {prices[planType]?.credits}</Text> <Text style={percentWordsRemaining}>{calculatePercentage(userDataAndPlans.wordsRemaining, prices[planType]?.credits)}%</Text></Text>
                            <Progress  
                                value={progressValue} 
                                colorScheme={'#6D99BE'}
                                sx={{
                                    "> div": {
                                        bg: color,  // This sets the background color of the track
                                    },
                                    background:"#6D99BE",
                                    borderRadius: "120px",
                                    height: "5px"
                                }}
                                mb="10px"
                            />
                          </>
                        )}
                        <Text style={resetTextStyle}>Words usage resets on {formatDate(userDataAndPlans.renewalDate)}</Text>
                      </Box>
                      <Box sx={secondBoxStyle} textAlign="center">
                        <Heading sx={headingPageStyle}  textAlign="center">Upgrade your account</Heading>
                        <Text sx={subtitleStyle}>Get more words per month by upgrading your subscription!</Text>
                        <Elements stripe={stripePromise}>
                            <Tabs>
                                <TabList style={tabListStyle}>
                                    <Tab sx={tabButtonStyle}>Monthly</Tab>
                                    <Tab sx={tabButtonStyle}>Yearly <Text className="yearlyPercentDiscountStyle">-30%</Text></Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel style={tabPanelStyle}>
                                        <PaymentPlans planType="monthly" userDataAndPlans={userDataAndPlans}/>
                                    </TabPanel>
                                    <TabPanel style={tabPanelStyle}>
                                      <PaymentPlans planType="yearly" userDataAndPlans={userDataAndPlans}/>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                            {stripePromise && (
                              <Box sx={invoiceMainContainerStyle}>
                                <Invoices stripePromise={stripePromise}/> 
                              </Box>
                            )}
                          </Elements>
                        </Box>
                      </>   
                  }
              </Box>
            </SimpleBar>
        </>
    );
}

const invoiceMainContainerStyle = {
  paddingTop: '50px'
}

const tabPanelStyle = {
  padding: 0
}

const tabButtonStyle = {
  height: '40px',
  width: '120px',
  lineHeight: '40px',
  textAlign: 'center',
  border: '2px solid #1E5DFF',
  fontSize: '14px',
  borderRadius: '4px',
  margin:0,
  padding:0,
  color: '#1E5DFF',
  fontWeight: 700,
  '.yearlyPercentDiscountStyle':{
    height: '21px',
    width: '38px',
    lineHeight: '21px',
    border: '1px solid #FF1284',
    color: '#FF1284',
    borderRadius: '21px',
    fontSize: '9px',
    fontWeight: 700,
    marginLeft: '5px'
  },
  '&[aria-selected=true]': {
    background:'#1E5DFF',
    color: '#fff',
    borderColor: 'transparent',
    '.yearlyPercentDiscountStyle': {
      borderColor: '#fff',
      color:'#fff'
    }
  },
  '&:first-of-type': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '&:last-of-type': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
}

const tabListStyle = {
  border: '0 none',
  justifyContent: 'center',
  marginBottom: '40px'
}

const secondBoxStyle = {
  background: '#fff',
  padding: '40px',
  '@media (max-width: 1200px)': {
    marginTop: 0,
    padding: '20px'
  }
}

const resetTextStyle = {
  color: '#6D99BE',
  fontSize: '14px',
  lineHeight: '15px'
}

const wordsAmountStyle = {
  marginLeft: '30px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  display: 'inline-block',
  'span': {
    fontWeight: 700
  }
}

const generatedWordsStyle = {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '18px',
  color: '#1B518F',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px'
}

const mainContainerStyle = {
  maxWidth: '940px',
  boxShadow: 'none',
  padding: '0 0 50px 0',
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "50px",
  '@media (max-width: 1200px)': {
    marginTop: 0
  }
}

const firstBoxStyle = {
  background: '#fff',
  borderRadius: '4px',
  padding: '40px',
  marginBottom: '30px',
  '@media (max-width: 1200px)': {
    padding: '20px'
  }
}

const subtitleStyle = {
  fontSize: '16px',
  lineHeight: '20px',
  color: '#1B518F',
  marginBottom: '30px'
}

const planTypeStyle = {
  fontSize: '12px',
  fontWeight: 700,
  color: '#1E5DFF',
  textTransform: 'uppercase',
  background: '#F6FBFF',
  height: '30px',
  lineHeight: '30px',
  paddingLeft: '10px',
  paddingRight: '10px',
  textAlign: 'center',
  display: 'inline-block',
  borderRadius: '4px'
}

const percentWordsRemaining = {
  marginLeft: 'auto'
}

const headingPageStyle = {
  fontSize: '26px',
  fontWeight: 700,
  lineHeight: '33px',
  marginBottom: '10px',
  color: '#1B518F'
}
export default Billing;

