import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Icon,
  Flex,
  Text,
  useToast,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import api from '../../../utils/api.js';
import SearchableSelect from '../../../components/form/SearchableSelect.js';
import InputDefault from '../../form/InputDefault.js';
import { useUserContext } from '../../../contexts/UserContext.js';
import { useAppContext } from '../../../contexts/AppContext.js';
import EditIcon from '../../../assets/images/EditIcon.js';
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';

function ProfileForm({ match }) {
  const [data, setData] = useState();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const toast = useToast();
  const { userDataAndPlans, fetchUserData } = useUserContext();
  const { mobileMenuOpen, openMobileMenu } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen === true && window.innerWidth < 1200) {
      openMobileMenu(false);
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (formData) => {
    try {
      const response = await api.post(`/admin/my-account/profile/update-profile`, formData);

      if (response.data.success) {
        toast({
          title: "Profile updated",
          description: "Your profile has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsModalOpen(false);
        fetchUserData();
      } else {
        toast({
          title: "Update failed",
          description: response.data.error.message || "An error occurred while updating your profile.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        title: "Update failed",
        description: "An unexpected error occurred. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const avaiableLanguages = [
    {label: 'English', value: 'English'},
    {label: 'Romanian', value: 'Romanian'},
    {label: 'Spanish', value: 'Spanish'},
    {label: 'French', value: 'French'},
    {label: 'German', value: 'German'},
    {label: 'Russian', value: 'Russian'},
    {label: 'Chinese', value: 'Chinese'},
    {label: 'Korean', value: 'Korean'},
    {label: 'Turkish', value: 'Turkish'},
    {label: 'Punjabi', value: 'Punjabi'},
    {label: 'Hindi', value: 'Hindi'},
    {label: 'Bengali', value: 'Bengali'},
    {label: 'Italian', value: 'Italian'},
    {label: 'Arabic', value: 'Arabic'},
    {label: 'Portuguese', value: 'Portuguese'},
    {label: 'Japanese', value: 'Japanese'},
    {label: 'Mandarin Chinese', value: 'Mandarin Chinese'},
    {label: 'Urdu', value: 'Urdu'},
    {label: 'Vietnamese', value: 'Vietnamese'},
    {label: 'Dutch', value: 'Dutch'},
    {label: 'Greek', value: 'Greek'},
  ]

  const languageOptions = avaiableLanguages.map(language => ({
      value: language.value,
      label: language.label
  }));
  
  useEffect(() => {
    async function getUserData() {
      try {
        const response = await api.post(`/admin/my-account/profile`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching the folder:", error);
      }
    }

    getUserData();
  }, []); 

  return (
    <Box>
      {userDataAndPlans.id &&
          <>
          <Button sx={editButton} onClick={openModal}>
            <Icon as={EditIcon} />
            <Text>Edit</Text>
          </Button>
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Profile</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex sx={mainFormBoxContainer}>
                  <Box className="rightBox">
                    <FormControl isInvalid={errors.firstName} mb="10px">
                      <InputDefault label="Firstname" {...register('firstName', { required: true })} name="firstName" placeholder="First Name" defaultValue={userDataAndPlans.firstname}/>
                    </FormControl>

                    <FormControl isInvalid={errors.lastName} mb="10px">
                      <InputDefault label="Lastname" {...register('lastName', { required: true })} placeholder="Last Name"  defaultValue={userDataAndPlans.lastname}/>
                    </FormControl>

                    <FormControl isInvalid={errors.email} mb="10px">
                      <InputDefault label="Email" {...register('email', { required: true })} placeholder="Email"  defaultValue={userDataAndPlans.email}/>
                    </FormControl>
                    <Text style={subtitleStyle}>Settings</Text>
                    <SearchableSelect
                      label="Language"
                      options={languageOptions}
                      value={{label: userDataAndPlans.defaultLanguage, value: userDataAndPlans.defaultLanguage}}
                      {...register('language', { required: false })}
                      name="language"
                      onChange={selectedOption => {
                        setValue("language", selectedOption);
                      }}
                    />
                    <Text style={disclaimerStyle}>All new content will be generated in the selected language</Text>
                    
                    <Button mt={4}  style={updateButtonStyle} type="submit">
                      <Box display="flex" alignItems="center">
                        <Text mr="10px">Update</Text>
                        <Icon as={RightArrowButtonIcon}></Icon>
                      </Box>
                    </Button>
                  </Box>
                </Flex>
              </form>
              </ModalBody>
            </ModalContent>
          </Modal>
          </>
      }
    </Box>
  );
}

const editButton = {
    background: 'transparent',
    padding: 0,
    height: 'auto',
    paddingLeft: '10px',
    'svg': {
      width: '15px',
      marginRight: '5px',
      'path': {
        stroke: '#1E5DFF'
      }
    },
    'p': {
      fontSize: '14px',
      color: '#1E5DFF'
    }
}

const updateButtonStyle = {
  background: '#1E5DFF',
  height: '55px',
  lineHeight: '55px',
  textAlign: 'center',
  borderRadius: '4px',
  color: '#FFFFFF',
  fontSize: '18px',
  fontWeight: 700,
  width: '100%'
}
const subtitleStyle = {
  color:'#1B518F',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 500,
  marginBottom: '10px'
}
const disclaimerStyle = {
  color: '#6D99BE',
  fontSize: '14px',
  lineHeight: '16px',
  marginTop: "10px"
}


const mainFormBoxContainer = {
  '.leftBox': {
    minWidth: "180px"
  },
  '.rightBox': {
    width: '100%'
  },
  '@media (max-width: 1200px)': {
    padding: '20px',
    marginTop: 0
  },
  '@media (max-width: 580px)': {
    '.leftBox': {
      display: 'none'
    },
    '.rightBox': {
      maxWidth: '100%'
    }
  }
}

export default ProfileForm;