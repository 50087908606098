import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Button, FormControl, Icon, Input, useToast, Box, Text } from "@chakra-ui/react";
import RightArrowButtonIcon from '../../../assets/images/RightArrowButtonIcon.js';

const LoginForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const response = await axios.post("https://skelet.ai:5000/api/auth/login", data);

            if (response.status !== 200) {
                toast({
                    title: "Login error",
                    description: response.data.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                localStorage.setItem("token", response.data.token);
                toast({
                    title: "Logged in",
                    description: "You have successfully logged in.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                window.location.href = "/admin/dashboard";
            }
        } catch (error) {
            toast({
                title: "Login error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="email" isInvalid={errors.email} mb={4}>
                <Input
                    style={inputStyle}
                    placeholder="Email"
                    type="email"
                    {...register("email", { required: "Email is required" })}
                />
            </FormControl>
            <FormControl id="password" isInvalid={errors.password} mb={4}>
                <Input
                    style={inputStyle}
                    placeholder="Password"
                    type="password"
                    {...register("password", { required: "Password is required" })}
                />
            </FormControl>
            <Button sx={submitButtonStyle} type="submit">
                <Box display="flex" alignItems="center">
                    <Text>Sign in</Text>
                    <Icon as={RightArrowButtonIcon} />
                </Box>
            </Button>
        </form>
    );
}

const inputStyle = {
    background: '#fff',
    border: '1px solid #CFE0ED',
    borderRadius: '4px',
    height: '55px',
    lineHeight: '55px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
    color: '#6D99BE'
}

const submitButtonStyle = {
    background: '#1E5DFF',
    height: '55px',
    lineHeight: '55px',
    width: '100%',
    'p': {
        fontSize: '18px',
        lineHeight: '20px',
        color: '#fff',
        marginRight: '10px'
    }
}

export default LoginForm;
