import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../utils/api.js';

const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [userDataAndPlans, setUserDataAndPlans] = useState([]);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await api.post('/admin/get-user-data');
            setUserDataAndPlans(response.data);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    }

    return (
        <UserContext.Provider value={{ userDataAndPlans, fetchUserData }}>
            {children}
        </UserContext.Provider> 
    );
}