import React from 'react';
import { Box, Button, Text, Icon } from '@chakra-ui/react';
import AudioPlayer from 'react-h5-audio-player';
import VoiceIcon from '../../../../assets/images/VoiceIcon.js';
import SpeedIcon from '../../../../assets/images/SpeedIcon.js';
import PlayAudioIcon from '../../../../assets/images/PlayAudioIcon.js';
import DownloadIcon from '../../../../assets/images/DownloadIcon.js';


function DetailsItem({ itemDetails }) {
    const amazonURL = process.env.REACT_APP_AMAZON_S3_URL;
    const handleDownload = () => {
        const fileUrl = `${amazonURL}${itemDetails.files[0].filePath}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = "_blank";
        link.download = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Box sx={ttsDetailsItemStyle}>
            <Box className="promptContainer">
                <Text>{itemDetails.prompt}</Text>
            </Box>
            <Box className="audioPlayerContainer">
                <Box className="mainAudioPlayerContainer">
                    <AudioPlayer
                        showJumpControls={false}
                        customIcons={
                            {play: <Icon as={PlayAudioIcon} />}
                        }
                        src={`${amazonURL}${itemDetails.files[0].filePath}`}
                    />
                </Box>
                <Box className="downloadButton">
                    <Button onClick={() => handleDownload()}>
                        <Icon as={DownloadIcon} />
                    </Button>
                </Box>
                
            </Box>
            <Box className="ttsDetails">
                <Box className="tssDetailsItem">
                    <Icon as={VoiceIcon} />
                    <Text>{itemDetails.voice}</Text>
                </Box>
                <Box className="tssDetailsItem">
                    <Icon as={SpeedIcon} />
                    <Text>{itemDetails.speed}</Text>
                </Box>
            </Box>
        </Box>
    );
}

const ttsDetailsItemStyle = {
    padding: '10px 15px',
    border: '1px solid #CFD5E3',
    marginBottom: '20px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    '.promptContainer': {
        marginBottom: '-5px',
        'p': {
            fontSize: '16px',
            color: '#1B518F'
        }
    },
    '.ttsDetails': {
        display: 'flex',
        flexDirection: 'row',
        '.tssDetailsItem': {
            borderRadius: '4px',
            marginRight: '10px',
            padding: '5px 10px',
            border: '1px solid #CFD5E3',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            'p':{
                fontSize: '14px',
                color: '#1B518F',
                textTransform: 'capitalize'
            },
            'svg': {
                marginRight: '5px'
            }
        }
    },
    '.audioPlayerContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '.mainAudioPlayerContainer': {
            width: 'calc(100% - 50px)'
        },
        '.rhap_main': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            '.rhap_progress-section': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                '.rhap_current-time': {
                    fontSize: '14px',
                },
                '.rhap_total-time': {
                    fontSize: '14px',
                },
                '.rhap_progress-container': {
                    flexGrow: 1,
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    '.rhap_progress-bar': {
                        height: '5px',
                        position: 'relative',
                        background: '#98B3F8',
                        '.rhap_progress-filled': {
                            background: '#1E5DFF',
                            height: '100%',
                            position: 'absolute',
                            borderRadius: '5px',
                        },
                        '.rhap_download-progress': {
                            
                            height: '100%',
                            borderRadius: '5px',
                        }
                    }
                    
                }
            },
            '.rhap_controls-section': {
                marginLeft: '10px',
                '.rhap_additional-controls': {
                    display: 'none'
                },
                '.rhap_volume-controls': {
                    display: 'none'
                }
            }
        }
        
        
    },
    '.downloadButton': {
        'button':{
            background: 'transparent',
            padding: 0
        }
    }
    
}






export default DetailsItem;
