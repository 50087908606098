function SortIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M1 4H7.75" stroke="#8599CD" stroke-linecap="round"/>
            <path opacity="0.7" d="M2.5 7.75H7.75" stroke="#8599CD" strokeLinecap="round"/>
            <path opacity="0.4" d="M4 11.5H7.75" stroke="#8599CD" strokeLinecap="round"/>
            <path d="M10.75 13V1L13 4" stroke="#8599CD" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}
  
export default SortIcon;