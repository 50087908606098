import React from 'react';
import api from '../../utils/api.js';
import { useStripe } from '@stripe/react-stripe-js';
// import {stripe} from 'stripe';

const PaymentStripeForm = (props) => {
    const stripe = useStripe();

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             Make an API request to your server to retrieve the upcoming invoice
    //             const response = await api.post('/admin/payment/retrieve-upcoming-invoice');
           
    //             if (response.ok) {
    //                 const upcomingInvoice = await response.json();
    //                 const upcomingInvoiceId = upcomingInvoice.id;
    //                 console.log('Upcoming Invoice ID:', upcomingInvoiceId);
    //                 setUpcomingInvoiceId(upcomingInvoiceId);
    //             } else {
    //                 console.error('Failed to retrieve upcoming invoice:', response.statusText);
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     })();
    // }, []);
    const createSubscription = async () => {
        const response = await api.post('/admin/payment/create-checkout-session', {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
            },
            planType: props.planType,  // send userId to your server
            planName: props.planName,  // send userId to your server
        });
        const session = response.data;
        if (!stripe || !session.id) {
            console.error("Stripe.js has not loaded yet.");
            return;
        } 

        // Redirect to the Checkout page
        if(session.updated === false){
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                console.log('[error]', result.error.message);
            }
        }

        
        
        if(session.updated === true){
            window.location.reload();
        }
    }

    const cancelSubscription = async () => {
        await api.post('/admin/payment/cancel-subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        window.location.reload();
        
    }
    
    const handleCheckout = (event) => {
        event.preventDefault();
        if(props.planName === 'free') {
            cancelSubscription();
        } else {
            createSubscription();
        }
    };

    return (
        <form onSubmit={handleCheckout}>
            <button type="submit" style={upgradeButtonStyle}>
                Upgrade Now
            </button>
        </form>
    );
};

const upgradeButtonStyle = {
    height: '50px',
    lineHeight: '50px',
    background: '#fff',
    color: '#1E5DFF',
    fontSize: '16px',
    fontWeight: 700,
    borderRadius: '4px',
    display: 'block',
    width: '100%'
}

export default PaymentStripeForm;
