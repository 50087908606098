import React, {useState, useEffect} from 'react';


import DesktopHeader from './DesktopHeader/DesktopHeader.js';
import MobileHeader from './MobileHeader/MobileHeader.js';

const Header = ({ page, title, documentDetails, refreshState }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile === false
        ? <DesktopHeader page={page} title={title} documentDetails={documentDetails} refreshState={refreshState}/>
        : <MobileHeader page={page} title={title}/>
      }
    </>
    
  )
};
export default Header;