// MainLayout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import AppLogo from '../../assets/images/AppLogo.js';
import {Flex, Box, List, ListItem, Button} from '@chakra-ui/react';

const FrontHeader = ({ generatedContent}) => {

  const navigate = useNavigate();
  return (
    <Flex sx={headerContainer} className="front-header"> 
          <Box sx={innerHaderContainerStyle}>
                <Box className={`left_box ${generatedContent === true ? 'hasTextGenerated' : ''}`}>
                    <Box as={RouterLink} to="/">
                        <AppLogo />
                    </Box>
                </Box>
                <Box className="right_box">
                    <List sx={rightMenuHeader}>
                        <ListItem className={generatedContent === true ? 'hasTextGenerated' : ''}>
                            <Button className="login_button" onClick={() => navigate('/login')}>Sign in</Button>
                        </ListItem>
                        <ListItem className={generatedContent === true ? 'hasTextGenerated' : ''}>
                            <Button className="register_button" onClick={() => navigate('/register')}>get started</Button>
                        </ListItem>
                    </List>
                </Box>
          </Box>
    </Flex>
  )
};


const rightMenuHeader = {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    'li':{
        paddingLeft: '20px',
        'button':{
            fontSize: '12px',
            color:'#1E5DFF',
            letterSpacing: '1px',
            fontWeight: 700,
            height: '40px',
            lineHeight: '40px',
            background: 'transparent',
            borderRadius: '4px',
            textTransform: 'uppercase',
            border:'1px solid #1E5DFF'
        },
        '.login_button': {
            color: '#fff',
            '&:hover':{ 
                background: '#1E5DFF'
            }
        },
        '.register_button':{
            background: '#1E5DFF',
            color: '#fff',
            '&:hover': {
                background: '#fff',
                color: '#1E5DFF'
            }
        },
        '&.hasTextGenerated': {
            'button': {
                color: '#1E5DFF',
                borderColor: '#1E5DFF'
            }
        }
    },
    '@media (max-width: 1200px)': {
        'li': {
            paddingLeft: '10px',
            'button': {
                fontSize: '10px',
                color: '#fff',
                height: '30px',
                lineHeight: '30px',
                
            },
            '.register_button':{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '1px'
            },
            '&:first-child':{
                paddingLeft: 0,
                'button': {
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }
            }
        }
    }
}

const headerContainer = {
    width: '100%',
    paddingTop:'30px',
    paddingBottom:'30px',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 9,
    '@media all and (max-width: 800px)': {
        paddingTop: '20px'
    }
}
const innerHaderContainerStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 20px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    '.left_box':{
        width: '50%',
        'svg': {
            width: 'auto',
            height: '50px'
        },
        '&.hasTextGenerated': {
            'svg': {
                'path': {
                    fill: '#1E5DFF'
                }, 
            },
        }
    },
    '.right_box': {
        width: '50%'
    },
    '@media (max-width: 540px)': {
        '.left_box': {
            width: '30%',
            'svg': {
                width: '100%'
            } 
        },
        '.right_box': {
            width: '70%'
        }
    }
}

export default FrontHeader;