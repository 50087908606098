function YoutubeVideoScriptIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#F2F9FF"/>
            <path d="M11.9175 6.29475C11.9175 6.29475 11.8395 5.7065 11.5997 5.4475C11.2955 5.108 10.9547 5.106 10.7985 5.08675C9.67925 5 8.0005 5 8.0005 5H7.99675C7.99675 5 6.318 5 5.199 5.08675C5.04275 5.106 4.70225 5.108 4.39775 5.4475C4.15825 5.7065 4.08 6.29475 4.08 6.29475C4.08 6.29475 4 6.985 4 7.67525V8.32225C4 9.013 4.08 9.703 4.08 9.703C4.08 9.703 4.158 10.291 4.39775 10.5495C4.70225 10.889 5.1015 10.8787 5.2795 10.9142C5.91925 10.9797 7.99875 11 7.99875 11C7.99875 11 9.67925 10.997 10.7985 10.9113C10.9547 10.8913 11.2955 10.8892 11.5997 10.5497C11.8392 10.2912 11.9175 9.70325 11.9175 9.70325C11.9175 9.70325 11.9975 9.013 11.9975 8.3225V7.6755C11.9975 6.985 11.9175 6.29475 11.9175 6.29475Z" fill="#E02F2F"/>
            <path d="M7 6.5V9.5L9.5 8L7 6.5Z" fill="white"/>
        </svg>

    );
  }
  
export default YoutubeVideoScriptIcon;