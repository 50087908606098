function EmailNewsletterIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="21" fill="#F2F9FF"/>
            <path d="M21.7272 23.3667L12.059 17.214C11.5591 16.8835 11.2091 16.3698 11.0843 15.7837C10.9594 15.1976 11.0698 14.586 11.3915 14.0804C11.7132 13.5749 12.2206 13.2159 12.8045 13.0808C13.3883 12.9457 14.0018 13.0453 14.5129 13.3581L21.606 17.8717L27.9746 13.4135C28.4715 13.0768 29.0809 12.9491 29.6712 13.0579C30.2615 13.1667 30.7852 13.5033 31.1294 13.9951C31.4736 14.4868 31.6105 15.0942 31.5106 15.6861C31.4107 16.2779 31.082 16.8067 30.5955 17.1583L21.7272 23.3667Z" fill="#EA4435"/>
            <path d="M30.0471 29H26.9996V15.2862C26.9996 14.68 27.2404 14.0986 27.669 13.67C28.0977 13.2413 28.679 13.0005 29.2852 13.0005C29.8914 13.0005 30.4728 13.2413 30.9014 13.67C31.33 14.0986 31.5708 14.68 31.5708 15.2862V27.4762C31.5708 27.8804 31.4103 28.2679 31.1245 28.5537C30.8388 28.8395 30.4512 29 30.0471 29Z" fill="#00AC47"/>
            <path d="M31.5374 14.9547C31.5307 14.909 31.5312 14.8623 31.5217 14.8166C31.5071 14.7467 31.4799 14.6821 31.459 14.6146C31.4388 14.5373 31.4144 14.4612 31.386 14.3865C31.3708 14.3504 31.3473 14.3186 31.33 14.2834C31.2826 14.1818 31.2278 14.0839 31.1659 13.9904C31.1354 13.9462 31.0973 13.9085 31.0637 13.8666C31.0059 13.7904 30.9434 13.7179 30.8766 13.6495C30.826 13.6002 30.7682 13.559 30.7131 13.5146C30.6546 13.4644 30.5936 13.4172 30.5303 13.3731C30.4699 13.3336 30.4037 13.3032 30.3398 13.2696C30.2725 13.2344 30.2065 13.1962 30.1364 13.1681C30.0687 13.1409 29.9964 13.1235 29.9257 13.1027C29.8549 13.0819 29.7845 13.057 29.7118 13.0435C29.6217 13.029 29.5309 13.0199 29.4398 13.0161C29.382 13.012 29.3247 13.0019 29.2666 13.0024C29.1588 13.0055 29.0514 13.0163 28.9452 13.0347C28.9023 13.0411 28.8591 13.0406 28.8165 13.0494C28.6727 13.0934 28.5294 13.1386 28.3864 13.1851C28.3472 13.2018 28.3128 13.2267 28.2748 13.2456C27.8879 13.4262 27.5616 13.7149 27.3353 14.0769C27.109 14.4389 26.9923 14.8588 26.9995 15.2856V19.6756L30.5956 17.1581C30.9487 16.9211 31.2256 16.5868 31.3927 16.1958C31.5598 15.8047 31.6102 15.3737 31.5374 14.9547Z" fill="#FFBA00"/>
            <path d="M13.2856 13C13.8918 13 14.4732 13.2408 14.9018 13.6694C15.3305 14.0981 15.5713 14.6794 15.5713 15.2856V28.9995H12.5238C12.1196 28.9995 11.7321 28.8389 11.4463 28.5532C11.1605 28.2674 11 27.8798 11 27.4757V15.2856C11 14.6794 11.2408 14.0981 11.6694 13.6694C12.0981 13.2408 12.6794 13 13.2856 13Z" fill="#4285F4"/>
            <path d="M11.0335 14.9549C11.0402 14.9092 11.0397 14.8625 11.0492 14.8169C11.0638 14.7469 11.091 14.6823 11.1119 14.6148C11.1322 14.5376 11.1565 14.4615 11.1849 14.3869C11.2001 14.3507 11.2236 14.3189 11.241 14.2837C11.2884 14.1821 11.3432 14.0842 11.4051 13.9907C11.4356 13.9464 11.4737 13.9087 11.5073 13.8668C11.5651 13.7906 11.6275 13.7181 11.6943 13.6497C11.745 13.6004 11.8027 13.5592 11.8579 13.5148C11.9164 13.4646 11.9774 13.4174 12.0407 13.3733C12.1011 13.3339 12.1673 13.3035 12.2312 13.2698C12.2973 13.2324 12.3651 13.1984 12.4346 13.1678C12.5024 13.1406 12.5747 13.1232 12.6454 13.1024C12.7161 13.0816 12.7865 13.0567 12.8592 13.0432C12.9493 13.0287 13.0401 13.0196 13.1312 13.0158C13.1891 13.0117 13.2463 13.0016 13.3044 13.0021C13.4122 13.0052 13.5196 13.016 13.6258 13.0344C13.6687 13.0408 13.7119 13.0403 13.7546 13.0491C13.8283 13.0679 13.9011 13.0905 13.9725 13.1168C14.0443 13.1359 14.1151 13.1586 14.1847 13.1848C14.2238 13.2015 14.2582 13.2264 14.2963 13.2453C14.395 13.2917 14.4903 13.3452 14.5814 13.4053C14.8866 13.6149 15.1362 13.8956 15.3087 14.2233C15.4812 14.5509 15.5713 14.9156 15.5714 15.2859V19.6758L11.9753 17.1585C11.6222 16.9214 11.3454 16.5872 11.1782 16.1961C11.0111 15.805 10.9608 15.3739 11.0335 14.9549Z" fill="#C52528"/>
        </svg>
    );
  }
  
export default EmailNewsletterIcon;