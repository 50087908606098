import React, { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Box, Heading, Text, Button, useToast } from "@chakra-ui/react";
import { useGoogleLogin } from '@react-oauth/google';
import LoginForm from '../components/auth/login/Login.js';

const LoginPage = () => {
    const toast = useToast();
    useEffect(() => {
        document.documentElement.classList.add('auth-template');
    }, []);

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const response = await axios.post('https://skelet.ai:5000/api/auth/login-with-google', {
                code,
            });
            localStorage.setItem("token", response.data.token);
            toast({
                title: "Logged in",
                description: "You have successfully logged in.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            window.location.href = "/admin/dashboard";
        },
        flow: 'auth-code',
    });

    return (

        <Box sx={authBoxStyle}>
            <Text className="smallText">welcome back</Text>
            <Heading className="pageHeading">Login</Heading>
            <Box>
                <Button onClick={() => googleLogin()} className="socialLogin">
                    {/* <Link to="/api/auth/google"> */}
                    <Box display="flex" alignItems="center">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
                            <path d="M21.8025 11.25C21.8025 10.47 21.7323 9.72 21.6021 9H11.2207V13.26H17.1529C16.8924 14.63 16.1108 15.79 14.9384 16.57V19.34H18.5157C20.6 17.42 21.8025 14.6 21.8025 11.25Z" fill="#4285F4" />
                            <path d="M11.2191 22C14.1953 22 16.6904 21.02 18.5142 19.34L14.9368 16.57C13.9548 17.23 12.7022 17.63 11.2191 17.63C8.35325 17.63 5.91824 15.7 5.04645 13.1H1.37891V15.94C3.19264 19.53 6.91028 22 11.2191 22Z" fill="#34A853" />
                            <path d="M5.04724 13.09C4.82679 12.43 4.69652 11.73 4.69652 11C4.69652 10.27 4.82679 9.57001 5.04724 8.91001V6.07001H1.3797C0.628152 7.55001 0.197266 9.22001 0.197266 11C0.197266 12.78 0.628152 14.45 1.3797 15.93L4.23557 13.71L5.04724 13.09Z" fill="#FBBC05" />
                            <path d="M11.2192 4.38C12.8425 4.38 14.2855 4.94 15.4378 6.02L18.5943 2.87C16.6804 1.09 14.1953 0 11.2192 0C6.91029 0 3.19264 2.47 1.37891 6.07L5.04645 8.91C5.91825 6.31 8.35325 4.38 11.2192 4.38Z" fill="#EA4335" />
                        </svg>
                        <Text>Login with google</Text>
                    </Box>
                    {/* </Link> */}
                </Button>
            </Box>
            <Box className="normalLoginText">
                <Text>or use your email</Text>
            </Box>
            <LoginForm />
            <Box className="bottomInformation">
                <Box className="forgotPasswordContainer">
                    <Link to="/forgot-password">Forgot password?</Link>
                </Box>
                <Box className="authLinks">
                    <Text>New on skelet.ai? Join us, it's free.</Text>
                    <Link to="/register">Create an account</Link>
                </Box>
            </Box>
        </Box>
    );
}

const authBoxStyle = {
    maxWidth: '510px',
    padding: '40px 30px',
    background: '#293037',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    marginBottom: '40px',
    borderRadius: '4px',
    '.smallText': {
        color: '#98B3F8',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: '20px',
        textAlign: 'center',
    },
    '.pageHeading': {
        fontSize: '40px',
        lineHeight: '42px',
        marginBottom: '40px',
        color: '#fff',
        textAlign: 'center',

    },
    '.socialLogin': {
        background: 'transparent',
        height: '55px',
        lineHeight: '55px',
        border: '2px solid #fff',
        borderRadius: '4px',
        marginBottom: '30px',
        width: '100%',
        'p': {
            fontSize: '18px',
            fontWeight: 700,
            color: '#fff',
            marginLeft: '10px'
        },
        '&:hover': {
            background: '#fff',
            'p': {
                color: '#293037'
            }
        }
    },
    '.normalLoginText': {
        textAlign: 'center',
        position: 'relative',
        justifyContent: 'center',
        marginBottom: '20px',
        'p': {
            fontSize: '12px',
            lineHeight: '14px',
            textTransform: 'uppercase',
            color: '#CFE0ED',
            background: '#293037',
            position: 'relative',
            zIndex: 2,
            display: 'inline-block',
            padding: '0 10px',
        },
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            height: '1px',
            background: '#fff',
            zIndex: 1
        }
    },
    '.bottomInformation': {
        paddingTop: '30px',
        '.forgotPasswordContainer': {
            paddingBottom: '20px',
            marginBottom: '20px',
            borderBottom: '1px solid #98B3F8',
            textAlign: 'center',
            'a': {
                fontSize: '16px',
                color: '#98B3F8',
                display: 'inline-block'
            }
        },
        '.authLinks': {
            textAlign: 'center',
            'p': {
                fontSize: '16px',
                color: '#98B3F8'
            },
            'a': {
                fontSize: '16px',
                color: '#98B3F8',
                fontWeight: 700
            }
        }
    },
    '@media all and (max-width: 580px)': {
        background: 'transparent',
        paddingLeft: '15px',
        paddingRight: '15px',
        '.normalLoginText': {
            'p': {
                background: '#121e2a'
            }
        }
    }
}

export default LoginPage;
