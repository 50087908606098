import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppProvider } from "./contexts/AppContext.js";
import { AiImageProvider } from "./contexts/AiImageContext.js";
import { TtsProvider } from "./contexts/TtsContext.js";
import { UserProvider } from "./contexts/UserContext.js";
import { FolderProvider } from "./contexts/FolderContext.js";
import { ProjectProvider } from "./contexts/ProjectContext.js";
import { DocumentProvider } from "./contexts/DocumentContext.js";
import ProtectedRoute from './utils/ProtectedRoute.js';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import MainLayout from './layouts/MainLayout.js';
import FrontLayout from './layouts/FrontLayout.js';

const customTheme = extendTheme({
  breakpoints: {
    sm: '0', // Customize the `sm` breakpoint
    md: '800px', // Add your own breakpoint (e.g., `md`)
    lg: '1200px', // Add another breakpoint (e.g., `lg`)
  },
  styles: {
    global: {
      'input::placeholder': {
        color: '#9EC1DE',
        fontSize: '14px',
        fontWeight: 400
      },
      'textarea::placeholder': {
        color: '#9EC1DE',
        fontSize: '14px',
        fontWeight: 400
      },
    },
  },
});

function LoginRegisterHandler() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && (location.pathname === "/login" || location.pathname === "/register")) {
      navigate("/admin/dashboard");
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything
}

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <GoogleOAuthProvider clientId="119307790756-dsj6jms6qsrb3nu9ksa1nq1h6isvbbii.apps.googleusercontent.com">
        <Router>
          <LoginRegisterHandler />
          <Routes>
            <Route path="*" element={<FrontLayout />}></Route>
            <Route path="/admin/*" element={
              <UserProvider>
                <AppProvider>
                  <FolderProvider>
                    <ProjectProvider>
                      <DocumentProvider>
                        <AiImageProvider>
                          <TtsProvider>
                            <ProtectedRoute><MainLayout /></ProtectedRoute>
                          </TtsProvider>
                        </AiImageProvider>
                      </DocumentProvider>
                    </ProjectProvider>
                  </FolderProvider>
                </AppProvider>
              </UserProvider>
            } />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;
