import React from 'react';
import { Tooltip } from '@chakra-ui/react';

function CustomTooltip({ label, CustomIcon }) {
    return (
        <Tooltip hasArrow label={label} placement='top' bg="#1E5DFF" borderRadius="4px" fontSize="14px">
            <span><CustomIcon /></span>
        </Tooltip>
    )
}

export default CustomTooltip;
 