import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Icon } from '@chakra-ui/react';
import AiCHatFormIcon from '../../assets/images/AiChatFormIcon.js';
import AiImageChatIcon from '../../assets/images/AiImageChatIcon.js';
import AiTextToSpeeach from '../../assets/images/AiTextToSpeech.js';

const LeftIcon = () => {
    const location = useLocation();
    return ( 
        <Box sx={leftIconStytle}>
            <Box className="iconContainer">
                {location.pathname.includes('ai-images') &&
                    <Icon as={AiImageChatIcon} />
                }
                {location.pathname.includes('chat') &&
                    <Icon as={AiCHatFormIcon} />
                }

                {location.pathname.includes('dashboard') &&
                    <Icon as={AiCHatFormIcon} />
                }

                {location.pathname.includes('text-to-speech') &&
                    <Icon as={AiTextToSpeeach} />
                }
            </Box>
        </Box>
    );
};

const leftIconStytle = {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    '.iconContainer': {
        border: '1px solid #CFD5E3',
        width: '34px',
        height: '34px',
        position: 'relative',
        borderRadius: '34px',
        'svg': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            'circle': {
                display: 'none'
            }
        }
    }
}

export default LeftIcon;