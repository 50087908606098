function HamburgenMenuIcon() {
    return (
        <svg width="32" height="18" viewBox="0 0 32 18" fill="none">
            <path d="M6 1H31" stroke="#1B518F" strokeWidth="1.30435" strokeLinecap="round"/>
            <path d="M1 8.82608H31" stroke="#1B518F" strokeWidth="1.30435" strokeLinecap="round"/>
            <path d="M6 17H31" stroke="#1B518F" strokeWidth="1.30435" strokeLinecap="round"/>
        </svg>
    );
}

export default HamburgenMenuIcon;