import React from "react";
import { Text, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {formatTimeAgo} from '../../../utils/formatDate.js';
import { TEMPLATE_ICONS } from '../../../assets/images/templates/TemplatesImage.js';

function RecentActivityItem({details}) { 
  const icon = TEMPLATE_ICONS[details.templateType];
  const getLink = (link) => {
    if(details.templateType === 'AiImage'){
        return `/admin/ai-images/${details.id}`;
    }else if(details.templateType === 'tts'){
        return `/admin/text-to-speech/${details.id}`;
    } 
    return `/admin/my-documents/document/${details.id}`;
  }
  return (
    <Box sx={boxItemStyle}>
        <Box className="innerContainer">
            <Link className="title" to={getLink()}>{details.name.length > 30 ? details.name.substring(0, 25) + "..." : details.name}</Link>
            <Box display="flex" alignItems="center">
                {icon}
                <Text style={documentTypeStyle}>{formatTimeAgo(details.updatedAt)}</Text>
            </Box>
        </Box>
    </Box>
  );
}

const boxItemStyle = {
    padding: '10px',
    borderRadius: '4px',
    background: '#fff',
    marginBottom: '5px',
    flex: '0 0 33%',
    '.innerContainer': {
        padding: '15px',
        border:"1px solid #CFD5E3",
        borderRadius: '4px',
        '.title': {
            color: '#1E5DFF',
            fontSize: '16px',
            fontWeight: 600
        }
    },
    '@media all and (max-width: 1200px)': {
        flex: '0 0 100%',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        marginBottom: 0
    },
    '@media all and (max-width: 540px)': {
        '.innerContainer': {
            padding: '5px 10px 10px'
        }
    }
}


const documentTypeStyle = {
    color: '#1B518F',
    fontSize: '14px',
    lineHeihgt: '16px',
    fontWeight: 400,
    marginLeft: '5px'
}

export default RecentActivityItem;