function LinkedinPostIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#F2F9FF"/>
            <path d="M5.78418 6.49998H4V12H5.78418V6.49998Z" fill="#1B80E4"/>
            <path d="M10.644 6.56449C10.625 6.55855 10.6071 6.55206 10.5872 6.54646C10.5632 6.541 10.5393 6.53643 10.5148 6.53254C10.4202 6.51349 10.3163 6.5 10.1947 6.5C9.1546 6.5 8.49476 7.25857 8.27748 7.55152V6.49998H6.49329V12H8.27746V9.00001C8.27746 9.00001 9.62573 7.117 10.1947 8.49993V12H11.9784V8.28852C11.9784 7.45746 11.4104 6.76498 10.644 6.56449Z" fill="#1B80E4"/>
            <path d="M5.7453 4.87498C5.7453 5.35831 5.35453 5.75 4.87266 5.75C4.39075 5.75 4 5.35829 4 4.87498C4 4.39176 4.39075 4 4.87266 4C5.35453 4 5.7453 4.39176 5.7453 4.87498Z" fill="#1B80E4"/>
        </svg>
    );
  }
  
export default LinkedinPostIcon;