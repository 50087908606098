function DeleteIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M25 16V5.5C25 3.01473 22.9854 1 20.5 1H13M25 16L15.25 25M25 16H18.25C16.5931 16 15.25 17.3431 15.25 19V25M15.25 25H5.5C3.01473 25 1 22.9854 1 20.5V13" stroke="#8599CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 1L4.75 4.75M4.75 4.75L8.5 8.5M4.75 4.75L8.5 1M4.75 4.75L1 8.5" stroke="#8599CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}
  
export default DeleteIcon;